import { Injectable } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, merge } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { TabPosition, StoredTabsState } from './model';
import { ActiveTabService } from './tab-active.service';
import { TabGroupService } from './tab-group.service';
import { Tab } from './tab.class';

import { LocalStorageKey } from '../local-storage/local-storage.interface';
import { LocalStorageService } from '../local-storage/local-storage.service';

/**
 * Service for storing and getting tabs from local storage
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TabStorage {
  constructor(private readonly localStorageService: LocalStorageService) {}

  /**
   * Save tab list to localStorage
   *
   * @param groups - tabs state
   * @param activeTabPosition - active tab position
   */
  private syncTabListToLocalstorage(
    groups: Tab[][],
    activeTabPosition: TabPosition,
  ): void {
    // @TODO i.ablov do not save wizard tabs to localStorage.
    // There is no adequate way to restore wizard n > 1 step, but only to submit previous step!
    const tabListToSave = groups.map(group => {
      return group.map(tab => tab.serialize());
    });
    this.localStorageService.set(LocalStorageKey.Tablist, {
      tabs: tabListToSave,
      activeTabPosition: activeTabPosition,
    });
  }

  /**
   * Get tabs state from local storage
   */
  getTabs(): StoredTabsState {
    return this.localStorageService.get(LocalStorageKey.Tablist);
  }

  /**
   * Start watching for tabs state changing
   *
   * @param tabService - tab service to watch tabs group
   * @param activeTabService - active tab service to watch active tab position
   */
  startWatching(
    tabService: TabGroupService,
    activeTabService: ActiveTabService,
  ): void {
    combineLatest([
      // any tabs amount change...
      tabService.groups$,
      // any active tab change...
      activeTabService.activeTab$,
      // any tab data status change...
      tabService.groups$.pipe(
        switchMap(groups => merge(...groups.flat().map(tab => tab.status$))),
      ),
      // any tab pinning change...
      tabService.groups$.pipe(
        switchMap(groups => merge(...groups.flat().map(tab => tab.isPinned$))),
      ),
    ])
      .pipe(
        // a tiny debounce in order to reduce count of local storage updating
        debounceTime(0),
        untilDestroyed(this),
      )
      .subscribe(([groups, activeTab]) => {
        this.syncTabListToLocalstorage(
          groups,
          tabService.getTabPosition(activeTab),
        );
      });
  }
}
