import {
  IList,
  IListElement,
  IListColumn,
  IValue,
  IField,
  IDocument,
  IInput,
  ISelect,
  TRecordValue,
} from 'app/services/api5-service/api.interface';
import { getButtonList, IFormButtonUi } from 'components/form-button';
import { map, tap } from 'rxjs/operators';

import { getTypedField } from 'common/dynamic-form/utils/form-preparing';
import {
  getWrappedConfigsFromTypedField,
  setTypedFieldToServices,
} from 'common/dynamic-form/utils/formly-configs-generation';
import { DocHelper } from 'utils/dochelper';

import { ListTO } from './model/list-to.interface';
import { IListCellUi, IListColumnUi, IListRowUi } from './model/list.interface';

import { ISPFieldConfig, ISPFieldType, ISPFormState } from '../../model';
import { FIELD_HIDDEN_CLASS } from '../../utils/class-fields';

/**
 * Map list meta to list ui columns
 *
 * @param list - list meta
 * @param doc - doc instance
 */
export function getListColumnsUi(list: IList, doc: IDocument): IListColumnUi[] {
  return list.col.map(column => ({
    ...column,
    title: DocHelper.getMessage(`list_${column.$name}`, doc),
  }));
}

/**
 * Get row value by type
 *
 * @param element - list element
 * @param column - list column metadata
 * @param doc - doc instance
 */
export function getListCellValue(
  element: IListElement,
  column: IListColumnUi,
  doc: IDocument,
): string {
  const cellValue = (element[column.$name] as IValue)?.$;

  if (column.$type === 'msg') {
    const cellMsg = DocHelper.getMessage(cellValue, doc);
    return cellMsg || cellValue;
  }

  return cellValue;
}

/**
 * Get control metadata for cell. Used as binding between cell and fieldConfig
 *
 * @param element - list element
 * @param column - list column metadata
 */
export function getListCellControl(
  element: IListElement,
  column: IListColumn,
): IInput | ISelect | undefined {
  if (column.$type !== 'control') {
    return undefined;
  }

  const cell = element[column.$name];

  // there may be no control if the first line in the list is the "header" of the list with the column headings
  if (!cell) {
    return undefined;
  }

  if ('select' in cell) {
    return cell.select;
  }

  if ('input' in cell) {
    return cell.input;
  }

  return undefined;
}

/**
 * Get control metadata
 *
 * @param element - list element
 * @param column - list column metadata
 * @param state - dynamic form state
 */
export function getListCellControlConfig(
  element: IListElement,
  column: IListColumn,
  state: ISPFormState,
): ISPFieldConfig | undefined {
  if (column.$type !== 'control') {
    return undefined;
  }

  const cell = element[column.$name];

  // there may be no control, for example in list header
  if (!cell) {
    return undefined;
  }

  if ('select' in cell) {
    const field = getTypedField(
      {
        $name: cell.select.$name,
        $noname: cell.select.$noname,
        select: [cell.select],
      },
      state.doc,
    );
    setTypedFieldToServices(field, state);
    return getWrappedConfigsFromTypedField(field, {
      ...state,
      selectAnchorWidth: 120,
      selectDropdownWidth: '120px',
    })[0];
  }

  if ('input' in cell) {
    const field = getTypedField(
      {
        $name: cell.input.$name,
        $noname: cell.input.$noname,
        input: [cell.input],
      },
      state.doc,
    );
    setTypedFieldToServices(field, state);
    return getWrappedConfigsFromTypedField(field, state)[0];
  }

  return undefined;
}

/**
 * Get buttons local dto for cell from BE metadata
 *
 * @param element - list element
 * @param column - column metadata
 * @param state - dynamic form state
 */
export function getListCellButtons(
  element: IListElement,
  column: IListColumn,
  state: ISPFormState,
): IFormButtonUi[] | undefined {
  const cell = element[column.$name];
  if (typeof cell !== 'object' || !('button' in cell)) {
    return;
  }

  if (cell.button) {
    return getButtonList(
      state.doc,
      Array.isArray(cell.button) ? cell.button : [cell.button],
      state.showHints,
    ).map(button => {
      if (button.key) {
        button.keyValue = (element[button.key] as IValue)?.$;
      }
      return button;
    });
  }
}

/**
 * Map list meta elements to list ui rows
 *
 * @param control - list metadata
 * @param elements - list elements
 * @param state - dynamic form state
 */
export function getListRowsUi(
  control: IList,
  elements: IListElement[],
  state: ISPFormState,
): IListRowUi[] {
  if (!elements) {
    return [];
  }

  const columns = getListColumnsUi(control, state.doc);
  return elements.map(element => {
    const cells = columns.map(column => {
      const cell: IListCellUi = {
        ...column,
      };

      switch (cell.$type) {
        case 'img': {
          cell.value = getListCellValue(element, column, state.doc);
          const originalValue = element[column.$name];
          if (
            originalValue &&
            ('$spritesvg' in originalValue || '$img' in originalValue)
          ) {
            cell.isSvgSprite = originalValue.$spritesvg === 'yes';
            cell.img = originalValue.$img;
          }
          break;
        }
        case 'button':
          cell.buttons = getListCellButtons(element, column, state);
          break;
        case 'control':
          cell.control = getListCellControl(element, column);
          break;
        case 'msg':
        case 'data':
          cell.value = getListCellValue(element, column, state.doc);
          break;
      }

      cell.color = (element[column.$name] as TRecordValue)?.$color;

      if (cell.$hint === 'yes') {
        const hintValue = element[`${cell.$name}_hint`] as IValue;
        cell.hint = hintValue?.$;
      }

      return cell;
    });

    return {
      key: control.$key,
      keyValue: (element[control.$key] as IValue)?.$,
      cells,
      color: element.$color,
    };
  });
}

/**
 * Get control fields configs from list
 *
 * @param control - list meta
 * @param state - dynamic form state
 */
export function getControlConfgisFromListRows(
  control: IList,
  state: ISPFormState,
): ISPFieldConfig[] {
  const elements = state.listService.getListElems(control.$name);

  if (!elements) {
    return [];
  }

  return elements
    .map(element =>
      control.col
        .map(column => getListCellControlConfig(element, column, state))
        .filter(Boolean),
    )
    .flat();
}

/**
 * Get config for list field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getListConfig(
  control: IList,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.List> {
  const header = getListColumnsUi(control, state.doc);

  const fieldGroup = getControlConfgisFromListRows(control, state);

  const displayType = control.$type === 'table' ? 'table' : 'block';
  const templateOptions: ListTO = {
    originalControl: control,
    originalField: field,
    type: displayType,
    setValues: control.$setvalues,
    name: field.$name,
    header: header,
    rows$: state.listService.getListElems$(control.$name).pipe(
      map(elems => getListRowsUi(control, elems, state)),
      tap(rows => {
        const buttons = rows
          .map(row => row.cells.map(cell => cell.buttons).filter(Boolean))
          .flat()
          .flat();
        state.buttonsService.setListButtons(control.$name, buttons);
      }),
    ),
    alternation: displayType === 'table' && control.$alternation !== 'no',
    hover: control.$hover !== 'no',
    underline: control.$underline !== 'no',
    isHidden: !state.listService.getListElems(control.$name)?.length,
  };

  return {
    type: ISPFieldType.List,
    templateOptions,
    expressionProperties: {
      'templateOptions.isHidden': state.listService
        .getListElems$(control.$name)
        .pipe(map(list => !list?.length)),
      className: (_, formState, fieldConfig) =>
        fieldConfig.templateOptions.isHidden ||
        formState.hiddenService.isHidden(control.$name, field.$name)
          ? FIELD_HIDDEN_CLASS
          : '',
    },
    // fieldGroup for control in row
    fieldGroup,
  };
}
