import {
  IHtmlData,
  ITextData,
  IField,
  IDocument,
} from 'app/services/api5-service/api.interface';

import { FIELD_HIDDEN_CLASS } from 'common/dynamic-form/utils/class-fields';
import { isFullWidth } from 'common/dynamic-form/utils/is-full-width';
import { DocHelper } from 'utils/dochelper';

import { TextDataTO } from './model/textdata-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';
import { getImage } from '../../utils/get-image';
import { getLabel } from '../../utils/get-label';
import { isTextHighlighted } from '../../utils/is-text-highlighted';

/**
 * Get content for textdata
 *
 * @param control - control (subfield) metadata
 * @param doc - doc instance
 * @param value - custom value in doc for getting content function. Used after 'setValues', cause current doc don't get any update
 */
export function getContent(
  control: IHtmlData | ITextData,
  doc: IDocument,
  value?: string,
): string {
  const name = control.$name;
  const type = '$type' in control ? control.$type : '';
  switch (type) {
    case 'msg':
      return DocHelper.getMessage(name, doc);
    case 'msgdata':
      return DocHelper.getMessage(
        value || DocHelper.getStringValue(name, doc),
        doc,
      );
    case 'data':
      return value || DocHelper.getStringValue(name, doc);
    // because can be img with empty textdata with same name
    default:
      return '';
  }
}

/**
 * Get content for htmldata
 *
 * @param control - control (subfield) metadata
 * @param doc - doc instance
 * @param value - custom value in doc for getting content function. Used after 'setValues', cause current doc don't get any update
 */
export function getContentForHTMLData(
  control: IHtmlData | ITextData,
  doc: IDocument,
  value?: string,
): string {
  const name = control.$name;
  return value || DocHelper.getStringValue(name, doc);
}

/**
 * Get content for textdata config
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param doc - doc instance
 * @param value - custom value for textdata
 */
export function getTextdataContent(
  control: IHtmlData | ITextData,
  field: IField,
  doc: IDocument,
  value?: string,
): string {
  const isHtmlData = Boolean(field.htmldata);
  return isHtmlData
    ? getContentForHTMLData(control, doc, value)
    : getContent(control, doc, value);
}

/**
 * Get config for textdata field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getTextDataConfig(
  control: IHtmlData | ITextData,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.TextData> {
  const content = getTextdataContent(control, field, state.doc);
  const img = getImage(field, state.doc);

  const templateOptions: TextDataTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    content,
    isFullWidth: isFullWidth(field) || control.$fullwidth === 'yes',
    isHidden:
      // @WARN there is cases, when textdata field is empty, but have label to display
      state.hiddenService.isHidden(control.$name, field.$name) ||
      (!img && !content && !getLabel(field, state.doc)),
    img,
    isTextHighlight: isTextHighlighted(field),
    hintPlace: 'label',
  };
  return {
    type: ISPFieldType.TextData,
    wrappers: [ISPFieldWrapper.FieldBase],
    templateOptions,
    expressionProperties: {
      'templateOptions.content': (model, formState, fieldConfig) =>
        getTextdataContent(
          control,
          field,
          formState.doc,
          model[fieldConfig.key] as string,
        ),
      'templateOptions.isHidden': (_, formState, fieldConfig) =>
        formState.hiddenService.isHidden(control.$name, field.$name) ||
        (!fieldConfig.templateOptions.content &&
          !fieldConfig.templateOptions.img &&
          !fieldConfig.templateOptions.label),
      className: (_, formState, fieldConfig) =>
        formState.hiddenService.isHidden(control.$name, field.$name) ||
        (!fieldConfig.templateOptions.content &&
          !fieldConfig.templateOptions.img &&
          !fieldConfig.templateOptions.label)
          ? FIELD_HIDDEN_CLASS
          : '',
    },
  };
}
