<div class="layout" [style.--isp-layout-header-height]="headerHeight$ | async">
  <div [class]="['ispui-scrollbar_gray', 'form', formContainerClass]" #form>
    <div
      *ngIf="to.isHeaderVisible"
      [class]="['form__header', headerClass]"
      #header
    >
      <formly-field
        *ngFor="let field of to.headerFields"
        [field]="field"
      ></formly-field>
    </div>
    <div class="form__content">
      <formly-field
        *ngFor="let field of to.bodyFields"
        [field]="field"
      ></formly-field>
    </div>
    <div
      *ngIf="to.isFooterVisible"
      [class]="['form__footer', footerClass]"
      #footer
    >
      <formly-field
        *ngFor="let field of to.footerFields"
        [field]="field"
      ></formly-field>
    </div>
  </div>
  <div *ngIf="to.isSummaryVisible" class="summary ispui-scrollbar_gray">
    <formly-field
      *ngFor="let field of to.summaryFields"
      [field]="field"
    ></formly-field>
  </div>
</div>
