import { IField, IFormPage } from 'app/services/api5-service/api.interface';

import { forEachConfig } from 'common/dynamic-form/utils/formly-configs';

import { GroupTO } from './model/group-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
  ISPValidator,
} from '../../model';
import { FIELD_HIDDEN_CLASS } from '../../utils/class-fields';
import { isFullWidth } from '../../utils/is-full-width';
import { appendPageOptions } from '../../wrappers/page/page.utils';

/**
 * Check that group field should be hidden
 *
 * @param fieldGroup - group field config
 */
export function isGroupFieldShouldBeHidden(
  fieldGroup?: ISPFieldConfig[],
): boolean {
  return fieldGroup?.every(
    (field: ISPFieldConfig) => field.templateOptions.isHidden,
  );
}

/**
 * Get group field config for fields
 *
 * @param fields - fields configs
 */
export function getGroupConfig(fields: ISPFieldConfig[]) {
  const templateOptions: GroupTO = {
    inlineGroup: false,
    isHidden: isGroupFieldShouldBeHidden(fields),
  };

  const hasPasswordConfirmationValidation = fields.some(field => {
    if (field.type !== ISPFieldType.Password) {
      return;
    }
    return field.templateOptions.originalControl.$checkpasswd;
  });

  const config: ISPFieldConfig<ISPFieldType.Group> = {
    templateOptions,
    type: ISPFieldType.Group,
    expressionProperties: {
      'templateOptions.isHidden': (_, __, fieldConfig) =>
        isGroupFieldShouldBeHidden(fieldConfig.fieldGroup),
      className: (_, __, fieldConfig) =>
        fieldConfig.templateOptions.isHidden ? FIELD_HIDDEN_CLASS : '',
    },
    fieldGroup: fields,
    validators: {
      validation: hasPasswordConfirmationValidation
        ? [ISPValidator.PassMatch]
        : [],
    },
  };

  return config;
}

/**
 * Get group field config for inline field groups
 *
 * @param fields - fields to wrapp into group metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getInlineFieldGroupConfig(
  fields: ISPFieldConfig[],
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.Group> {
  const templateOptions: GroupTO = {
    inlineGroup: true,
    isFullWidth: isFullWidth(field),
    isHidden:
      state.hiddenService.isHidden(field.$name) ||
      isGroupFieldShouldBeHidden(fields),
    isShadow: state.conditionService.isShadow(field.$name),
    hintPlace: 'label',
  };

  forEachConfig(fields, config => {
    if (config.wrappers?.includes(ISPFieldWrapper.ValidationError)) {
      config.templateOptions.validationPopupSidePriority = ['bottom', 'top'];
    }
  });

  const config: ISPFieldConfig<ISPFieldType.Group> = {
    templateOptions,
    type: ISPFieldType.Group,
    wrappers: [ISPFieldWrapper.FieldBase],
    expressionProperties: {
      'templateOptions.isShadow': (_, formState) =>
        formState.conditionService.isShadow(field.$name),
      'templateOptions.isHidden': (_, formState, fieldConfig) =>
        formState.hiddenService.isHidden(field.$name) ||
        isGroupFieldShouldBeHidden(fieldConfig.fieldGroup),
      className: (_, __, fieldConfig) =>
        fieldConfig.templateOptions.isHidden ? FIELD_HIDDEN_CLASS : '',
    },
    fieldGroup: fields,
  };
  return config;
}

/**
 * Get group field config for page wrapper
 *
 * @param fields - fields to wrapp into group
 * @param state - dynamic form state
 * @param page - raw page metadata
 */
export function getPageGroupConfig(
  fields: ISPFieldConfig[],
  state: ISPFormState,
  page: IFormPage,
): ISPFieldConfig<ISPFieldType.Group> {
  const templateOptions: GroupTO = {
    inlineGroup: false,
    isHidden:
      state.hiddenService.isHidden(page.$name) ||
      isGroupFieldShouldBeHidden(fields),
  };

  const hasPasswordConfirmationValidation = fields.find(field => {
    if (field.type !== ISPFieldType.Password) {
      return;
    }
    return field.templateOptions.originalControl.$checkpasswd;
  });

  const config: ISPFieldConfig<ISPFieldType.Group> = {
    templateOptions,
    type: ISPFieldType.Group,
    wrappers: [ISPFieldWrapper.Page],
    expressionProperties: {
      'templateOptions.isHidden': (_, __, fieldConfig) =>
        state.hiddenService.isHidden(page.$name) ||
        isGroupFieldShouldBeHidden(fieldConfig.fieldGroup),
      className: (_, __, fieldConfig) =>
        state.hiddenService.isHidden(page.$name) ||
        fieldConfig.templateOptions.isHidden
          ? FIELD_HIDDEN_CLASS
          : '',
    },
    fieldGroup: fields,
    validators: hasPasswordConfirmationValidation
      ? {
          validation: [ISPValidator.PassMatch],
        }
      : undefined,
  };

  // @TODO i.ablov remove appending wrapper configs somewhere above. This shouldn't happen in config itself
  appendPageOptions(config, state, page);

  return config;
}
