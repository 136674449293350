import {
  FormButtonType,
  IDocument,
} from 'app/services/api5-service/api.interface';
import { IFormButtonUi } from 'components/form-button';

import { IPreparedFormModel } from 'common/dynamic-form/dynamic-form.interface';
import { prepareFormModel } from 'common/dynamic-form/utils/get-form-model';

export interface PrepareModelToSumbitParams {
  form: any;
  func: string;
  button?: IFormButtonUi;
  elid?: string;
  plid?: string;
  table_params?: any;
  sok?: boolean;
}

/**
 * Prepare model for submit form
 *
 * @param params - params for form submiting
 */
export function prepareModelToSubmit(
  params: PrepareModelToSumbitParams,
): IPreparedFormModel {
  const newModel = prepareFormModel(params.form);

  newModel.func = params.func;

  if (params.sok) {
    newModel.sok = 'ok';
  }

  if (params.elid) {
    newModel.elid = params.elid;
  } else {
    newModel.elid = '';
  }
  if (params.plid) {
    newModel.plid = params.plid;
  }

  if (params.table_params) {
    newModel.table_params = params.table_params;
  }

  if (params.button) {
    newModel.clicked_button = params.button.name;
    if (params.button.type === FormButtonType.Next) {
      newModel.snext = 'ok';
    }
    if (params.button.type === FormButtonType.Back) {
      newModel.sback = 'ok';
    }
  }

  return newModel;
}

export interface SubmitAndOpenNewWindowParams
  extends PrepareModelToSumbitParams {
  doc: IDocument;
  openNewWindow: boolean;
}

/**
 * Get submit action url
 *
 * @param doc
 */
function getActionUrl(doc: IDocument): string {
  return doc.metadata?.form?.$action
    ? doc.metadata.form.$action
    : doc.$host + doc.$binary;
}

/**
 * Create html form with provided model and params
 *
 * @param params - submit params
 * @param model - form model
 */
export function createFakeForm(
  params: SubmitAndOpenNewWindowParams,
  model: IPreparedFormModel,
): HTMLFormElement {
  const mapForm = document.createElement('form');
  if (params.openNewWindow) {
    mapForm.target = '_blank';
  }
  mapForm.method = 'POST';
  mapForm.action = getActionUrl(params.doc);
  mapForm.style.display = 'none';
  Object.keys(model).forEach(param => {
    const value = model[param];
    if (value instanceof FileList) {
      // you can't append file value to input in security reason
      return;
    }
    const mapInput = document.createElement('input');
    mapInput.type = 'hidden';
    mapInput.name = param;
    mapInput.setAttribute('value', value as string);
    mapForm.appendChild(mapInput);
  });
  return mapForm;
}

/**
 * Create form data from form model
 *
 * @param model - form model
 */
export function createFormData(model: IPreparedFormModel): FormData {
  return Object.keys(model).reduce((formData, key) => {
    if (model[key] instanceof FileList) {
      formData.set(key, model[key][0] as any);
    } else {
      formData.set(key, model[key] as any);
    }
    return formData;
  }, new FormData());
}

/**
 * Submit form and open new window
 *
 * @param params - params for form submiting
 */
export function submitForm(params: SubmitAndOpenNewWindowParams): void {
  const model = prepareModelToSubmit(params);

  const isHaveFiles = Object.values(model).some(v => v instanceof FileList);
  if (isHaveFiles) {
    const formData = createFormData(model);
    fetch(getActionUrl(params.doc), {
      method: 'POST',
      body: formData,
    }).then(() => {
      // probably we shouldn't just reload page
      // we should use response data to determine further action
      // but for now I use just reloading - need more extform cases to determine right action
      location.reload();
    });
  } else {
    const form = createFakeForm(params, model);
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }
}

/**
 * Returns page group name without prefix
 *
 * @param name - Name with prefix
 */
export function getUnprefixGroupName(name: string): string {
  return name.split(' ')[1] || name;
}
