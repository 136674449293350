import { Pipe, PipeTransform } from '@angular/core';

import { LayoutService } from 'common/dynamic-form/services/layout.service';

import { prefix } from './form-id-prefix.utils';

@Pipe({
  name: 'formIdPrefix',
})
export class FormIdPrefixDirective implements PipeTransform {
  constructor(private readonly layoutService: LayoutService) {}

  transform(selector: string): string {
    return prefix(this.layoutService, selector);
  }
}
