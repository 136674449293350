import { CdkTreeNodeOutlet } from '@angular/cdk/tree';
import { Directive, ViewContainerRef } from '@angular/core';

/**
 * Outlet for nested CdkNode. Put `[ispTreeNodeOutlet]` on a tag to place children dataNodes
 * inside the outlet.
 */
@Directive({
  selector: '[ispTreeNodeOutlet]',
})
export class TreeNodeOutletDirective implements CdkTreeNodeOutlet {
  constructor(public viewContainer: ViewContainerRef) {}
}
