import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageBusService } from 'app/services/messagebus/messagebus.service';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { IFormCollapseEvent } from './model/form-collapse.interface';

const SCROLL_DELAY = 5;

/**
 * Collapsible component module. Designed specifically for forms.
 *
 * Usage:
 * ```html
 * <isp-form-collapse [data]="page">
 *   <div>put the collapsible contents here</div>
 * </isp-form-collapse>
 * ```
 */
@UntilDestroy()
@Component({
  selector: 'isp-form-collapse',
  templateUrl: './form-collapse.component.html',
  styleUrls: ['./scss/form-collapse.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCollapseComponent implements OnInit {
  /** Page header title */
  @Input() title: string;

  /** Page name identifier */
  @Input() name: string;

  /** Whether the collapsible is opened */
  @Input() opened = false;

  /** Is page could be collapsed */
  @Input() collapsible = true;

  /** Collapse msg */
  @Input() collapseTooltipMsg: string;

  /** Expand msg */
  @Input() expandTooltipMsg: string;

  /** Header tooltip */
  get headerTooltip(): string {
    return this.opened ? this.collapseTooltipMsg : this.expandTooltipMsg;
  }

  /** Display header */
  @Input() displayHeader = true;

  /** Refresh observable */
  @Input() refresh$: Observable<any> = null;

  /** Whether or not the component can show the refresh icon */
  @Input() canRefresh: boolean;

  /** Tooltip for refresh button */
  @Input() refreshTooltipMsg: string;

  /** @HACK custom modificator for header, for clients needs */
  @Input() classNameModificator = 'default';

  /** Collapse elements name */
  get id(): string {
    return `collapse-${this.name}`;
  }

  /** Header icon name */
  get icon(): string {
    return this.opened ? 'down' : 'up';
  }

  /** Header icon custom styles */
  readonly iconStyles = {
    width: '10px',
    height: '10px',
  };

  /** Collapsible's expand/collapse event */
  @Output() readonly toggle = new EventEmitter<IFormCollapseEvent>();

  /** Refresh icon custom styles */
  readonly refreshIconStyles = {
    width: '15px',
    height: '13px',
  };

  /** Whether the collapsible's button is refreshing */
  isRefreshing: boolean;

  @ViewChild('header') header: ElementRef<HTMLElement>;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly bus: MessageBusService,
  ) {}

  /**
   * Subscribe to event of page-collapse toggling from other components
   */
  private initPageToggle(): void {
    this.bus
      .on$('form-collapse-toggle')
      .pipe(
        filter(event => event.payload.name === this.name),
        untilDestroyed(this),
      )
      .subscribe(event => {
        this.emitCollapseEvent(event.payload.openState);

        // set timeout for waiting until page toggles it's state and rerenders
        setTimeout(() => {
          event.response();
        }, SCROLL_DELAY);
      });
  }

  /**
   * Subscribe to event of scrolling to page-collapse element
   */
  private initPageScroll(): void {
    this.bus
      .on$('form-collapse-scroll-to')
      .pipe(
        filter(event => event.payload.name === this.name),
        untilDestroyed(this),
      )
      .subscribe(event => {
        const headerElement = this.header.nativeElement as HTMLElement;
        switch (event.payload.scrollPosition) {
          case 'top':
            return headerElement.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'end',
            });
          case 'bottom':
            return headerElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'end',
            });
          default:
            return headerElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
        }
      });
  }

  ngOnInit(): void {
    this.initPageToggle();
    this.initPageScroll();
  }

  /**
   * Toggles the collapsible
   *
   * @param enterKeydownEvent - passed if method was called on KeyboardEvent with enter button
   */
  toggleCollapse(enterKeydownEvent?: KeyboardEvent): void {
    // for avoiding form submit
    if (enterKeydownEvent) {
      enterKeydownEvent.preventDefault();
      enterKeydownEvent.stopPropagation();
    }
    this.emitCollapseEvent();
  }

  /**
   * Emit collapse event
   *
   * @param isOpened
   */
  emitCollapseEvent(isOpened = !Boolean(this.opened)): void {
    if (isOpened === this.opened) {
      return;
    }
    this.opened = isOpened;

    const event = {
      isOpened: this.opened,
      name: this.name,
    };
    this.toggle.next(event);
    this.bus.emit('form-collapse-state', event);
  }

  /**
   * Refreshes the collapsible's content
   *
   * @param event - mouse event
   */
  refreshContent(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.refresh$) {
      return;
    }
    this.isRefreshing = true;
    this.refresh$.pipe(first()).subscribe(() => {
      this.isRefreshing = false;
      this.changeDetectorRef.markForCheck();
    });
  }
}
