<ng-container *ngIf="doc$ | async as doc">
  <div class="page__content">
    <h1 class="ispui-h2 title">{{ title$ | async }}</h1>
    <isp-dynamic-form
      class="form"
      [doc]="doc"
      [showBlockingPrealoder]="false"
      [showGeneralError]="true"
      [context]="context$ | async"
      [model]="model"
      (buttonClick)="handleButtonClick($event)"
    >
      <ng-container *ispDynamicFormTemplate="socialLinksConfig; let field">
        <isp-social-links [config]="field.to.replacedConfig"></isp-social-links>
      </ng-container>
    </isp-dynamic-form>
  </div>
</ng-container>
