import { IInput, IField } from 'app/services/api5-service/api.interface';

import { HiddenTO } from './model/hidden-to.interface';

import { ISPFieldConfig, ISPFieldType } from '../../model';
import { FIELD_HIDDEN_CLASS } from '../../utils/class-fields';

/**
 * Get config for hidden field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 */
export function getHiddenConfig(
  control: IInput,
  field: IField,
): ISPFieldConfig<ISPFieldType.Hidden> {
  const templateOptions: HiddenTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    // @WARN isHidden must be explicitly declared, cause group fields check this flag
    isHidden: true,
  };

  const config: ISPFieldConfig<ISPFieldType.Hidden> = {
    key: control.$name,
    type: ISPFieldType.Hidden,
    templateOptions,
    className: FIELD_HIDDEN_CLASS,
    expressionProperties: {
      // @WARN this dummy function need to override common options expressionProperties
      className: () => FIELD_HIDDEN_CLASS,
    },
  };

  return config;
}
