<ng-container *ispDynamicFormTemplate="buttonsConfig; let field">
  <ng-container *ngIf="field.to.buttonListSubject | async as buttonList">
    <footer *ngIf="buttonList.length && showFooter">
      <isp-form-buttons
        [buttons]="buttonList"
        [hintAttachSelector]="buttonsHintAttachSelector | formIdPrefix"
        [hintPlacementOrder]="['top', 'rightBottom']"
        (btnClick)="emitButtonClick($event)"
      ></isp-form-buttons>
    </footer>
  </ng-container>
</ng-container>

<ng-container *ispDynamicFormTemplate="generalErrorConfig; let field">
  <div class="form__general-error" *ngIf="field.to.errorMsg">
    {{ field.to.errorMsg }}
  </div>
</ng-container>

<form
  *ngIf="formGroup$ | async as formGroup"
  [formGroup]="formGroup"
  [id]="formId"
  class="form"
  autocomplete="off"
>
  <ispui-tooltip-wrapper>
    <formly-form
      class="form__formly-component"
      [form]="formGroup"
      [model]="model$ | async"
      [fields]="configs$ | async"
      [options]="options$ | async"
      (keyup.enter)="submitFromKeyboard($event)"
    ></formly-form>

    <!-- @TODO i.ablov move this preloader to TabItemComponent! -->
    <ispui-preloader-round
      class="form__preloader"
      [loading]="(isBlocked$ | async) && showBlockingPrealoder"
      view="fixed"
      [spinnerSize]="60"
    ></ispui-preloader-round>
  </ispui-tooltip-wrapper>
</form>
