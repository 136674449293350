import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { ChatFilesComponent } from './chat-files.component';

@NgModule({
  declarations: [ChatFilesComponent],
  imports: [CommonModule, SvgIconModule],
  exports: [ChatFilesComponent],
})
export class ChatFilesModule {}
