<isp-form-collapse
  [name]="to.name"
  [title]="to.title"
  [opened]="to.opened"
  [displayHeader]="to.displayHeader"
  [collapsible]="to.collapsible"
  [collapseTooltipMsg]="to.collapseTooltipMsg"
  [expandTooltipMsg]="to.expandTooltipMsg"
  [collapsible]="to.collapsible"
  (toggle)="emitPageCollapseEvent($event)"
  [class.page_is_hidden]="to.isHidden"
>
  <ng-container #fieldComponent></ng-container>
</isp-form-collapse>
