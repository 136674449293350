import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';

import { untilDestroyed } from '@ngneat/until-destroy';
import { FormService } from 'app/form/form.service';
import { MessageBusService } from 'app/services/messagebus/messagebus.service';
import InputMask from 'inputmask';
import { BehaviorSubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { HTMLIspuiInputElement } from '@ispui/input/dist/';

import { awaitStencilElement } from 'utils/await-stencil-element';

import { setAutofocus } from './input.utils';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * `@type=text` input field component
 *
 * Use only with Formly
 */
@Component({
  selector: 'isp-formly-input-field',
  templateUrl: './input.component.html',
  styleUrls: ['./scss/formly-input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFieldComponent
  extends ISPFieldTypeBase<ISPFieldType.InputText>
  implements OnInit, AfterViewInit
{
  /** Whether the field control is expanded */
  private readonly isZoomedSubject = new BehaviorSubject(false);

  /** Input mask controller */
  private mask?: InputMask.Instance;

  /** Value for expanded field */
  private fieldValue: string;

  private get inputElement(): HTMLInputElement {
    return this.ispuiInputElement.nativeElement.nativeInput;
  }

  /** Field expanded observable */
  readonly isZoomed$ = this.isZoomedSubject.asObservable();

  /** Input element reference */
  @ViewChild('inputElement')
  ispuiInputElement: ElementRef<HTMLIspuiInputElement>;

  constructor(
    private readonly bus: MessageBusService,
    @Optional() private readonly formService?: FormService,
  ) {
    super();
  }

  private setFocus(): void {
    this.ispuiInputElement?.nativeElement?.focusNative();
  }

  /**
   * Set mask to input
   *
   * @param inputControl - input html element, on which mask will be applied
   * @param mask - mask value
   */
  private setMask(inputControl: HTMLInputElement, mask?: string): void {
    this.mask = InputMask({
      mask,
      showMaskOnHover: false,
      placeholder: this.to.invisibleMask ? '' : '_',
    });
    this.mask.mask(inputControl);
  }

  private removeMask(): void {
    this.mask?.remove();
    this.mask = undefined;
  }

  /**
   * Update input mask
   *
   * @param inputControl - input html element, on which mask will be applied
   * @param mask - mask value
   */
  private changeMask(inputControl: HTMLInputElement, mask?: string): void {
    if (!mask) {
      this.removeMask();
      return;
    }

    if (!this.mask) {
      this.setMask(inputControl, mask);
    } else {
      // keep cursor position on the same place
      const prevCursorPosition = inputControl.selectionStart;
      this.removeMask();
      this.setMask(inputControl, mask);
      inputControl.setSelectionRange(prevCursorPosition, prevCursorPosition);
    }
  }

  /**
   * Update unmasked values in mask, when control value was updated outside of mask (for example by form 'setValue')
   *
   * @param unmaskedValue - new value for control
   */
  private updateMaskValue(unmaskedValue: string): void {
    if (this.mask && unmaskedValue !== undefined) {
      this.mask.setValue(unmaskedValue);
      this.ispuiInputElement.nativeElement.value = unmaskedValue;

      // value in input will be masked at this moment
      const maskedValue = this.ispuiInputElement.nativeElement.value;

      // @HACK disable async validators, because BE return value without mask placeholder, but mask apply value with placeholder.
      // this starts infinite validation loop. So, in order to prevent it - disable validation on that moments
      const validators = this.formControl.asyncValidator;
      this.formControl.clearAsyncValidators();
      this.formControl.setValue(maskedValue);
      this.formControl.setAsyncValidators(validators);
    }
  }

  /**
   * Subscribe to field toggle, for synchronize field value
   */
  ngOnInit(): void {
    this.bus
      .on$('dynamic-form-set-focus')
      .pipe(
        filter(
          event =>
            event.payload.key === this.key &&
            event.payload.formId === this.formState.layoutService.formId,
        ),
        untilDestroyed(this),
      )
      .subscribe(() => this.setFocus());

    this.bus
      .on$('dynamic-form-update-mask')
      .pipe(
        filter(
          event =>
            event.payload.key === this.key &&
            event.payload.formId === this.formState.layoutService.formId,
        ),
        untilDestroyed(this),
      )
      .subscribe(event => {
        this.changeMask(this.inputElement, event.payload.mask);
      });

    this.bus
      .on$('dynamic-form-update-mask-value')
      .pipe(
        filter(
          event =>
            event.payload.key === this.key &&
            event.payload.formId === this.formState.layoutService.formId,
        ),
        untilDestroyed(this),
      )
      .subscribe(event => {
        this.updateMaskValue(event.payload.unmaskedValue);
      });

    this.isZoomed$
      .pipe(
        filter(Boolean),
        tap(() => {
          this.fieldValue = this.formControl.value.replace(/\s+/g, '\n');
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.to.autofocus) {
      await setAutofocus(
        this.ispuiInputElement.nativeElement,
        this.field,
        this.formState,
      );
    }

    await awaitStencilElement(this.ispuiInputElement.nativeElement);

    this.to.inputElementSubject.next(this.inputElement);
    if (this.to.mask) {
      this.setMask(this.inputElement, this.to.mask);
    }
  }

  get zoomButtonIcon(): 'ff-unzoom-a' | 'ff-zoom-a' {
    return this.isZoomedSubject.value ? 'ff-unzoom-a' : 'ff-zoom-a';
  }

  toggleFocus(isFocused = false): void {
    this.to.isFocused = isFocused;
  }

  handleTextareaBlur(): void {
    this.toggleFocus(false);
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity();
  }

  /**
   * Get placeholder value
   */
  getPlaceholder(): string {
    if (this.to.isMixed) {
      // sort of a hack: ispui-input has the logic that sets isEmpty property to false on change
      // and blur when placeholder property is truthy but in some cases placeholder property
      // is set after blur handler execution and for those cases we need this
      if (this.ispuiInputElement) {
        this.ispuiInputElement.nativeElement.isEmpty = false;
      }

      return this.to.mixedPlaceholder;
    }
    return this.to.placeholder;
  }

  /**
   * Access to control value
   */
  get controlValue(): string {
    return this.fieldValue;
  }

  set controlValue(newValue: string) {
    // remove validator before update value,
    // becouse validation will be triggered on each field change
    const validator = this.formControl.asyncValidator;
    this.formControl.asyncValidator = null;
    this.formControl.patchValue(newValue.replace(/\n+/g, ' '), {
      emitEvent: false,
    });
    this.formControl.asyncValidator = validator;
  }

  /**
   * Toggle the zoom expansion/collapse
   */
  toggleZoom(): void {
    if (this.to.zoom !== undefined) {
      this.isZoomedSubject.next(!this.isZoomedSubject.value);
    }
  }

  /**
   * Set unlimit value to control
   */
  setUnlimitValue(): void {
    this.formControl.setValue(this.to.unlimit);
  }
}
