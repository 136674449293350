import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileComponent),
      multi: true,
    },
  ],
  selector: 'isp-file',
  templateUrl: './file.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileComponent implements ControlValueAccessor {
  private _value: FileList | null;

  @Input()
  label: string;

  @Input()
  accept: string;

  @Input()
  name: string;

  @Input()
  invalid: boolean;

  @Input()
  hint: string;

  @Input()
  multiple: boolean;

  @Input()
  required: boolean;

  @Input()
  disabled: boolean;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  private onChange: (value: FileList | '') => void = () => undefined;

  private onTouch: () => void = () => undefined;

  set value(value: FileList | null) {
    this._value = value;
    this.onChange(value === null ? '' : value);
    this.onTouch();
  }

  get value() {
    return this._value;
  }

  registerOnChange(onChange: (value: FileList | '') => void): void {
    this.onChange = onChange;
  }

  writeValue(value: FileList | ''): void {
    this._value = value === '' ? null : value;
    this.cdr.markForCheck();
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }
}
