import { AbstractControl } from '@angular/forms';

import { FormlyConfig } from '@ngx-formly/core';
import { ValidationMessageOption } from '@ngx-formly/core/lib/services/formly.config';
import { IControl, IInput } from 'app/services/api5-service/api.interface';
import { Observable, combineLatest, of, isObservable } from 'rxjs';
import { map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';

import { isInvisibleRecaptchaError } from 'common/dynamic-form/types';
import { someConfig } from 'common/dynamic-form/utils/formly-configs';
import { getOriginalControlFromConfig } from 'common/dynamic-form/utils/formly-configs-generation';
import { DocHelper } from 'utils/dochelper';

import { ISPFieldConfig, ISPFormState, ISPValidator } from '../../model';

/**
 * Check that there is some fields to display backend error
 *
 * @param configs - form configs
 * @param state - form state
 */
export function isThereSomeConfigForBackendError(
  configs: ISPFieldConfig[],
  state: ISPFormState,
): boolean {
  return (
    !isInvisibleRecaptchaError(state.doc) &&
    someConfig(configs, config => {
      const control = getOriginalControlFromConfig(config);
      if (!control) {
        return false;
      }
      return control.$name === state.doc.error.$object;
    })
  );
}

/**
 * Get general backend error
 *
 * @param configs - field configs
 * @param state - dynamic form state
 * @returns return empty string if there is no backend error, or if there is some field, that have related field name
 */
export function getGeneralBackendError(
  configs: ISPFieldConfig[],
  state: ISPFormState,
): string {
  const thereIsNoBackendError = !state.doc.error;
  if (
    thereIsNoBackendError ||
    isThereSomeConfigForBackendError(configs, state)
  ) {
    return '';
  }

  return DocHelper.getError(state.doc);
}

/**
 * Append backend validator
 *
 * @param config - config to append
 * @param control - control (subfield) metadata
 * @param state - dynamic form state
 */
export function appendBackendErrorValidator(
  config: ISPFieldConfig,
  control: IControl,
  state: ISPFormState,
): void {
  const isHaveServerError = control.$name === state.doc.error?.$object;
  if (!isHaveServerError) {
    return;
  }

  if (!Array.isArray(config.validators?.validation)) {
    config.validators = {
      ...config.validators,
      validation: [],
    };
  }
  config.validators.validation.push(ISPValidator.ErrorFromBackend);
}

/**
 * Append async validator
 *
 * @param config - config to append
 * @param control - control (subfield) metadata
 * @param state - dynamic form state
 */
export function appendAsyncValidator(
  config: ISPFieldConfig,
  control: IControl,
  state: ISPFormState,
): void {
  if (!control.$check) {
    return;
  }

  config.modelOptions = {
    updateOn: 'blur',
    ...config.modelOptions,
  };

  const asyncValidationName = `check_${control.$check}_${control.$name}`;
  config.asyncValidators = {
    [asyncValidationName]: {
      expression: (formControl: AbstractControl) => {
        const value = formControl.value;
        // @WARN async validation performed only on string values! For now - just because
        if (!formControl.dirty || typeof value !== 'string' || value === '') {
          return of(true).toPromise();
        }

        const isZoomControl = (control as IInput).$zoom;
        const valuesToPerformCheck: string[] = isZoomControl
          ? value.split(' ')
          : [value];
        return combineLatest(
          valuesToPerformCheck.map(v =>
            state.validationService
              .performAsyncValidation(control, v, state)
              .pipe(
                tap(checkResult => {
                  if (checkResult.error) {
                    // register BE validation error msg under async field validation name
                    state.validationService.registerValidationMessage(
                      asyncValidationName,
                      DocHelper.getError(checkResult),
                    );
                  } else if (checkResult.value?.$) {
                    state.validationService.applySuggestedValueAfterValidation(
                      checkResult,
                      control,
                      formControl,
                      v,
                    );
                  }
                }),
                map(checkResult => checkResult.$func !== 'error'),
              ),
          ),
        )
          .pipe(
            map(validationPassResults =>
              validationPassResults.every(passed => passed === true),
            ),
          )
          .toPromise();
      },
    },
  };
}

/**
 * Append validation error options, specific for validation-error field wrapper
 *
 * @param config - config to append
 * @param control - control (subfield) metadata
 * @param state - dynamic form state
 */
export function appendValidationErrorOptions(
  config: ISPFieldConfig,
  control: IControl,
  state: ISPFormState,
): void {
  appendBackendErrorValidator(config, control, state);
  appendAsyncValidator(config, control, state);
}

/**
 * Get validation error message
 *
 * @WARN formly depended code. Copy from 'formly-validation-message' component
 * https://github.com/ngx-formly/ngx-formly/blob/main/src/core/src/lib/templates/formly.validation-message.ts
 * @param field - field config
 * @param formlyConfig - formly config service
 */
export function getErrorMessage(
  field: ISPFieldConfig,
  formlyConfig: FormlyConfig,
): Observable<string> | string | undefined {
  const formControl = field.formControl;
  if (!formControl.errors) {
    return undefined;
  }

  const errors = Object.entries(formControl.errors);
  // sort errors in a such way, that BE error will be on the first place to display
  errors.sort((a, b) =>
    a[0] === ISPValidator.ErrorFromBackend
      ? -1
      : b[0] === ISPValidator.ErrorFromBackend
      ? 1
      : 0,
  );

  for (const [error, errorResult] of errors) {
    let message: ValidationMessageOption['message'] =
      formlyConfig.getValidatorMessage(error);

    if (errorResult !== null && typeof errorResult === 'object') {
      if (errorResult.errorPath) {
        message = undefined;
      }

      if (errorResult.message) {
        message = errorResult.message;
      }
    }

    if (field.validation?.messages?.[error]) {
      message = field.validation.messages[error];
    }

    if (field.validators?.[error]?.message) {
      message = field.validators[error].message;
    }

    if (field.asyncValidators?.[error]?.message) {
      message = field.asyncValidators[error].message;
    }

    if (typeof message === 'function') {
      return message(errorResult, field);
    }

    return message;
  }
  return undefined;
}

/**
 * Get validation error message stream
 *
 * @param field - field config
 * @param formlyConfig - formly config service
 */
export function getErrorMessage$(
  field: ISPFieldConfig,
  formlyConfig: FormlyConfig,
): Observable<string | undefined> {
  return field.formControl.statusChanges.pipe(
    // start with null to instantly trigger get error logic
    startWith(null),
    switchMap(() => {
      const msg = getErrorMessage(field, formlyConfig);
      return isObservable(msg) ? msg : of(msg);
    }),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    }),
  );
}
