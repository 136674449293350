import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import {
  ISelectOption,
  ISelectOptionList,
} from 'app/services/api5-service/api.interface';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  filter,
  pluck,
  switchMap,
  startWith,
  map,
  distinctUntilChanged,
} from 'rxjs/operators';

import { IFormModel } from '../dynamic-form.interface';

@Injectable()
export class SelectService {
  /** Form group control. To get selected option from select controls values */
  private readonly formGroupSubjectSubject: BehaviorSubject<FormGroup | null> =
    new BehaviorSubject(null);

  /** So called slist. Options for select controls in form */
  private readonly selectOptionsListSubject: BehaviorSubject<
    Record<string, ISelectOption[]>
  > = new BehaviorSubject({});

  private readonly selectedOptions: Observable<Record<string, ISelectOption>> =
    combineLatest([
      this.formGroupSubjectSubject.pipe(
        filter(form => Boolean(form)),
        switchMap(form => form.valueChanges.pipe(startWith(form.value))),
      ),
      this.selectOptionsListSubject,
    ]).pipe(
      distinctUntilChanged(
        ([prevModel, prevSelects], [nextModel, nextSelects]) => {
          const prevSelectsKeys = Object.keys(prevSelects);
          const nextSelectsKeys = Object.keys(nextSelects);
          const sameKeys = this.isSameArrays(prevSelectsKeys, nextSelectsKeys);
          if (!sameKeys) {
            return false;
          }

          const selectParamsSame = prevSelectsKeys.every(
            key => prevModel[key] === nextModel[key],
          );
          return selectParamsSame;
        },
      ),
      map(([model, slists]) => this.getSelectedOptions(model, slists)),
    );

  private getSelectedOptions(
    model: IFormModel,
    slists: Record<string, ISelectOption[]>,
  ): Record<string, ISelectOption | undefined> {
    return Object.entries(slists).reduce<
      Record<string, ISelectOption | undefined>
    >((selectedOptions, [name, slist]) => {
      if (model[name] === undefined) {
        return selectedOptions;
      }

      const selectedOption = slist.find(option => option.$key === model[name]);
      selectedOptions[name] = selectedOption || undefined;

      return selectedOptions;
    }, {});
  }

  private isSameArrays(a: string[], b: string[]): boolean {
    return a.length === b.length && a.every(ae => b.includes(ae));
  }

  /**
   * Map BE slists metadata to local dto select options record
   *
   * @param slists - slists metadata
   */
  private getSelectOptionsFromSlists(
    slists: ISelectOptionList[],
  ): Record<string, ISelectOption[]> {
    return slists.reduce<Record<string, ISelectOption[]>>((options, slist) => {
      options[slist.$name] = slist.val || [];
      return options;
    }, {});
  }

  setFormGroup(formGroup: FormGroup): void {
    this.formGroupSubjectSubject.next(formGroup);
  }

  /**
   * Get stream of options for select with provided name
   *
   * @param name - select control name
   */
  getOptionsForSelect$(name: string): Observable<ISelectOption[]> {
    return this.selectOptionsListSubject.pipe(
      pluck(name),
      filter(options => Boolean(options)),
    );
  }

  /**
   * Get options for select with provided name
   *
   * @param name - select control name
   */
  getOptionsForSelect(name: string): ISelectOption[] | undefined {
    return this.selectOptionsListSubject.value[name];
  }

  /**
   * Get stream of selected option of select control with provided name
   *
   * @param name - select control name
   */
  getSelectedOptionFromSelect$(
    name: string,
  ): Observable<ISelectOption | undefined> {
    return this.selectedOptions.pipe(
      pluck(name),
      map(option => option || undefined),
      distinctUntilChanged(),
    );
  }

  getSelectedOptionFromSelect(name: string): ISelectOption | undefined {
    if (!this.formGroupSubjectSubject.value) {
      return;
    }
    const selectedOptions = this.getSelectedOptions(
      this.formGroupSubjectSubject.value.value,
      this.selectOptionsListSubject.value,
    );
    return selectedOptions[name];
  }

  /**
   * Set options slists. Create new options record
   *
   * @param slists - BE slists metadata
   */
  setOptions(slists?: ISelectOptionList[]): void {
    if (slists) {
      const optionsRecords = this.getSelectOptionsFromSlists(slists);

      this.selectOptionsListSubject.next(optionsRecords);
    }
  }

  /**
   * Update options slists with overriding options by names, but keeping existed options.
   *
   * @param slists - BE slists metadata
   */
  overrideOptions(slists?: ISelectOptionList[]): void {
    if (slists) {
      const optionsRecords = this.getSelectOptionsFromSlists(slists);

      this.selectOptionsListSubject.next({
        ...this.selectOptionsListSubject.value,
        ...optionsRecords,
      });
    }
  }

  clear(): void {
    this.formGroupSubjectSubject.next(null);
    this.selectOptionsListSubject.next({});
  }
}
