<isp-svg-icon
  *ngIf="to.img?.icon"
  class="textdata__img"
  [name]="to.img.icon"
  [customStyle]="{ width: to.img.width, height: to.img.height }"
></isp-svg-icon>
<img
  *ngIf="to.img?.url"
  class="textdata__image"
  [alt]="to.content"
  [width]="to.img.width"
  [src]="to.img.url"
  [height]="to.img.height"
/>
<div
  class="textdata__content ispui-text"
  [style.fontSize]="size$ | async"
  [style.fontFamily]="fontFamily$ | async"
  [ngClass]="{
    'ispui-text_warn': to.isTextHighlight,
    textdata__content_fullwidth: to.isFullWidth
  }"
  [innerHTML]="to.content"
></div>
