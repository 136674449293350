import { Injectable, OnDestroy } from '@angular/core';
import { Params } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TabItemService } from 'app/layout/tab-item/tab-item.service';
import { IFormCollapseEvent } from 'components/form-collapse';
import { combineLatest, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ILinkClickEvent } from 'common/dynamic-form/types';
import { createProgressIdForToolbutton } from 'common/progress-task-modal/progress-task.utils';
import { convertStringToParamsWithDecode } from 'utils/convert-string-to-params';
import { DocHelper } from 'utils/dochelper';

import { getUnprefixGroupName } from './form.utils';

import {
  IFormButtonClickEvent,
  IFormWizardClickEvent,
} from '../../common/dynamic-form/dynamic-form.interface';
import { ActionService } from '../services/action.service';
import {
  FormButtonType,
  Func,
  IToolButtonEvent,
} from '../services/api5-service/api.interface';
import { Api5Service } from '../services/api5-service/api5.service';
import { ActiveTabService, TabGroupService, Tab } from '../services/tab';

/**
 * Form service
 */
@UntilDestroy()
@Injectable()
export class FormService implements OnDestroy {
  private get tab(): Tab {
    return this.tabItemService.tab;
  }

  /** Page/collapsible toggle events */
  private readonly pageToggle$ = new Subject<IFormCollapseEvent>();

  constructor(
    private readonly tabItemService: TabItemService,
    private readonly tabService: TabGroupService,
    private readonly activeTabService: ActiveTabService,
    private readonly actionService: ActionService,
    private readonly api: Api5Service,
  ) {}

  /**
   * Opens new tab
   *
   * @param func - function to run
   * @param params - form data
   * @param isChild - should tab open as a child tab of current tab
   */
  private openTab(func: Func, params: Params, isChild = false): void {
    if (isChild) {
      this.actionService.openChild({ func, ...params }, this.tab).subscribe();
    } else {
      this.actionService.open({ func, ...params }).subscribe();
    }
  }

  /**
   * Submits the form. Also sets the `sok` parameter with value `ok`
   *
   * @param event - button click event
   */
  private submitForm(event: IFormButtonClickEvent): void {
    event.button.preloaderSubject.next(true);
    this.actionService
      .prepareAndSubmitForm$({
        form: event.form,
        button: event.button,
        tab: this.tab,
        emitOnError: true,
        shouldHandleError: false,
      })
      .subscribe(() => {
        event.button.preloaderSubject.next(false);
      });
  }

  /**
   * Resets the whole form
   */
  private resetForm(): void {
    console.warn('сбросить форму на дефолтные значения');
  }

  /**
   * Subscribes the the `pageToggle$` subject
   * and whenever the new value is pushed:
   * - saves new collapsible state to the tab's document and updates the tab
   * - saves the state to the backend by calling `func=collapse`
   */
  initCollapsibleStateSave(): void {
    this.pageToggle$
      .pipe(
        switchMap(({ isOpened, name }) => {
          const doc = this.tab.doc;
          const pageList = DocHelper.getFormPageList(doc);
          if (!pageList) {
            return;
          }
          pageList.forEach(p => {
            if (p.$name === name) {
              p.$collapsed = isOpened ? 'no' : 'yes';
            }
          });

          this.tabService.updateTabDoc(this.tab, doc);
          this.activeTabService.setActive(this.tab);

          return combineLatest([
            this.api.collapse({
              action: this.tab.func,
              collapse: isOpened ? 'off' : 'on',
              page: getUnprefixGroupName(name),
            }),
          ]);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.pageToggle$.complete();
  }

  /**
   * Puts the collapsible's (page) event in the subject.
   * When new event is pushed - the service sends the state save request to `func=collapse`.
   *
   * @param event - collapsible toggle event
   */
  saveCollapseState(event: IFormCollapseEvent): void {
    this.pageToggle$.next(event);
  }

  /**
   * Handles the form button click depending on button's type
   *
   * @param event - form button click event
   */
  handleButtonClick(event: IFormButtonClickEvent): void {
    const isFormTargetBlank = this.tab.doc.metadata?.form?.$target === '_blank';
    const button = event.button;
    if (
      button.type === FormButtonType.Ok &&
      (button.act === FormButtonType.Blank || isFormTargetBlank)
    ) {
      this.actionService.prepareAndSubmitFakeFormAndOpenNewWindow(
        event,
        this.tab,
      );
      return;
    }
    switch (button.type) {
      case FormButtonType.Ok:
      case FormButtonType.Next:
      case FormButtonType.Back:
      case FormButtonType.Submit:
      case undefined:
        return this.submitForm(event);
      case FormButtonType.Cancel:
        this.closeByCancel();
        return button.func ? this.openTab(button.func, event.form) : null;
      case FormButtonType.Blank:
        return this.actionService.prepareAndSubmitFakeFormAndOpenNewWindow(
          event,
          this.tab,
        );
      case FormButtonType.Func:
        return this.openTab(button.func, event.form, true);
      case FormButtonType.Reset:
        return this.resetForm();
      default:
    }
  }

  /**
   * Form wizard clicks handler
   *
   * @param event - event for wizard clicks, contains wizard step and form value
   */
  handleWizardClick(event: IFormWizardClickEvent): void {
    this.actionService
      .prepareAndSubmitForm$({
        form: event.form,
        tab: this.tab,
        additionalParams: {
          func: event.button.func,
          sok: false,
        },
      })
      .subscribe();
  }

  /**
   * Closes the current tab
   */
  closeByCancel(): void {
    const refreshParentTab =
      this.tab.doc.metadata?.form?.$cancelrefresh === 'yes';
    this.tabService.close(this.tab, refreshParentTab);
  }

  handleResponseAfterProgress(): void {
    this.actionService.handleResponseAfterProgress(this.tab);
  }

  /**
   * Opens new tab by link click from form's field. Doesn't handle `href`-links
   *
   * @param event - form link click event
   * @param event.isNewTab
   * @param event.funcAndParams
   */
  openNewTabByLinkClick({ isNewTab, funcAndParams }: ILinkClickEvent): void {
    const params = {
      ...convertStringToParamsWithDecode(`func=${funcAndParams}`),
    };

    if (isNewTab) {
      // `null` is passed in the second argument because we don't know in advance what type the function has
      this.actionService.handleAction(params, null, true);
    } else {
      this.actionService.openChild(params, this.tab).subscribe();
    }
  }

  /**
   * Handles the toolbar button click
   *
   * @param event - clicked toolbar button as an emitted event
   */
  handleToolbarClick(event: IToolButtonEvent): void {
    const params = {
      elidList: [this.tab.elid],
      toolbtn: event.btn,
      tab: this.tab,
      buttonElement: event.target,
      progressId: event?.btn?.$progressbar
        ? createProgressIdForToolbutton(event.btn.$progressbar)
        : '',
      doc: this.tab.doc,
    };

    this.actionService.handleToolBtn(params).subscribe();
  }
}
