<!-- @TODO i.ablov probably field wrapper shouldn't know anything about isHidden, this will be controlled by field itself -->
<div
  [class.field-base_is_hidden]="to.isHidden"
  [ngClass]="{
    'field-base__wrap_input-like': to.hintDisplayMode === 'input-like',
    'field-base__wrap_center': to.hintDisplayMode === 'center'
  }"
>
  <div
    *ngIf="to.label"
    class="field-base__label-wrap"
    [style.marginBottom]="to.labelOffset"
  >
    <label
      [htmlFor]="id"
      [style.fontWeight]="to.labelWeight"
      [style.fontSize]="to.labelSize"
      >{{ to.label }}<span *ngIf="to.required" class="required-mark">*</span>
      <span *ngIf="!to.hideDesc && to.desc">, {{ to.desc }}</span>
    </label>
    <ng-container
      [ngTemplateOutlet]="hintPlace === 'label' ? hint : null"
    ></ng-container>
  </div>

  <div class="field-base__field-wrap">
    <ng-container #fieldComponent></ng-container>
    <ng-container
      [ngTemplateOutlet]="hintPlace === 'field' || !to.label ? hint : null"
    ></ng-container>
  </div>
</div>

<div
  *ngIf="getPlainHint() as plainhintMsg"
  [innerHTML]="plainhintMsg"
  class="field-base__plainhint"
></div>

<ng-template #hint>
  <ispui-tooltip
    *ngIf="to.hint"
    class="field-base__hint"
    [placement-order]="['right', 'bottomLeft', 'topLeft', 'bottomRight']"
    [attach-selector]="hintAttachContainer | formIdPrefix"
    [scroll-selector]="hintAttachContainer | formIdPrefix"
    #tooltip
  >
    <div slot="content" [innerHTML]="getHint()"></div>
    <isp-svg-icon name="banner2-icon" [customStyle]="iconSize"></isp-svg-icon>
  </ispui-tooltip>
</ng-template>
