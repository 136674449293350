import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDocument } from 'app/services/api5-service/api.interface';
import { docInjectionToken } from 'components/chat/chat.interface';
import { IFormButtonUi } from 'components/form-button';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { IFormModel } from './dynamic-form.interface';
import { DynamicFormService } from './dynamic-form.service';
import { ISPFieldType, DynamicFormContext } from './model';
import { ButtonsService } from './services/buttons.service';
import { CaptchaService } from './services/captcha.service';
import { ConditionService } from './services/condition.service';
import { DisabledService } from './services/disabled.service';
import { FontService } from './services/font.service';
import { HiddenService } from './services/hidden.service';
import {
  DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR,
  LayoutService,
} from './services/layout.service';
import { ListFieldService } from './services/list-field.service';
import { MixedService } from './services/mixed-service';
import { ModeService } from './services/mode.service';
import { SelectService } from './services/select.service';
import { SetValuesService } from './services/set-values.service';
import { ValidationService } from './services/validation.service';
import { DynamicFormTemplateDirective, TemplateConfig } from './types';
import { FIELD_HIDDEN_CLASS } from './utils/class-fields';
import { mergeConfigs } from './utils/formly-configs';
import { getGeneralBackendError } from './wrappers/validation-error/validation-error.utils';

/**
 * Field list component
 *
 * Usage:
 * ```html
 * <isp-dynamic-form [doc]="doc$ | async"></isp-dynamic-form>
 * ```
 */
@UntilDestroy()
@Component({
  selector: 'isp-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./scss/dynamic-form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DynamicFormService,
    SetValuesService,
    ValidationService,
    ConditionService,
    DisabledService,
    ModeService,
    HiddenService,
    ButtonsService,
    MixedService,
    ListFieldService,
    SelectService,
    LayoutService,
    CaptchaService,
    FontService,
    {
      provide: docInjectionToken,
      useExisting: DynamicFormService,
    },
  ],
})
export class DynamicFormComponent implements OnInit, AfterViewInit {
  private readonly doc$: BehaviorSubject<IDocument | null> =
    new BehaviorSubject(null);

  readonly buttonList$ = this.buttonsService.getFooterButtons$;

  readonly formGroup$ = this.dynamicFormService.formGroup$;

  readonly configs$ = this.dynamicFormService.configs$;

  readonly model$ = this.dynamicFormService.model$;

  readonly options$ = this.dynamicFormService.options$;

  readonly isBlocked$ = this.setValuesService.blockedSetValues$;

  readonly formId = this.layoutService.formId;

  /** Set form model */
  @Input() set model(model: IFormModel | null) {
    this.dynamicFormService.model = model || {};
  }

  /** Get form model. @WARN use outside of component */
  get model(): IFormModel {
    return this.dynamicFormService.model;
  }

  /** Context information for dynamic-form */
  @Input() set context(context: DynamicFormContext) {
    this.dynamicFormService.context = context;
  }

  @Input() set dropdownParentSelector(element: string) {
    this.dynamicFormService.dropdownParentSelector = element;
  }

  @Input() set showHints(canShow: boolean) {
    this.dynamicFormService.showHints = canShow;
  }

  /** Dropdown list width. It cannot be setted by css, cause dropdown opens out of dynamic-form css scope */
  @Input() set selectDropdownWidth(width: string) {
    this.dynamicFormService.selectDropdownWidth = width;
  }

  /** Should the form initialize in base mode (when it comes with two modes) */
  @Input() set startInBaseMode(isBase: boolean) {
    this.dynamicFormService.startInBaseMode = isBase;
  }

  /** Document instance */
  @Input() set doc(doc: IDocument | null) {
    this.doc$.next(doc);
  }

  @Input() showFooter = true;

  /** Should general error be displayed in form layout */
  @Input() set showGeneralError(display: boolean) {
    this.dynamicFormService.showGeneralError = display;
  }

  /**
   * Flag to control the synchronization of the model with doc
   *
   * @TODO e.polyakov for the branding form, there is no need to synchronize with doc, remove this after BILLDr-543
   */
  @Input() set needSyncModelWithDoc(needSync: boolean) {
    this.dynamicFormService.needSyncModelWithDoc = needSync;
  }

  @Input() showBlockingPrealoder = true;

  /** Collapsible's toggle event */
  @Output() readonly collapseToggle = this.dynamicFormService.collapseEvent;

  /** Button click event */
  @Output() readonly buttonClick = this.dynamicFormService.buttonClickEvent;

  /** Link click event */
  @Output() readonly linkClick = this.dynamicFormService.linkClickEvent;

  /** Model change event */
  @Output() readonly changeModel: EventEmitter<IFormModel> = new EventEmitter();

  /** Templates for dynamic-form */
  @ContentChildren(DynamicFormTemplateDirective)
  contentTemplates: QueryList<DynamicFormTemplateDirective>;

  /** Templates for dynamic-form */
  @ViewChildren(DynamicFormTemplateDirective)
  viewTemplates: QueryList<DynamicFormTemplateDirective>;

  @Input('buttonsConfig') buttonsConfigPartial: Partial<TemplateConfig>;

  buttonsConfig: TemplateConfig = {
    type: ISPFieldType.Template,
    templateOptions: {
      layoutPlace: 'footer',
      order: 0,
      buttonListSubject: this.buttonList$,
      isHidden:
        !this.showFooter ||
        !Boolean(this.buttonsService.getFooterButtons().length),
    },
    expressionProperties: {
      'templateOptions.isHidden': () =>
        !this.showFooter ||
        !Boolean(this.buttonsService.getFooterButtons().length),
      className: (_, __, fieldConfig) =>
        fieldConfig.templateOptions.isHidden ? FIELD_HIDDEN_CLASS : '',
    },
  };

  generalErrorConfig: TemplateConfig = {
    type: ISPFieldType.Template,
    templateOptions: {
      layoutPlace: 'header',
      order: 3,
    },
    expressionProperties: {
      'templateOptions.errorMsg': () =>
        getGeneralBackendError(
          this.dynamicFormService.configs,
          this.dynamicFormService.options.formState,
        ),
      'templateOptions.isHidden': (_, __, fieldConfig) =>
        !this.dynamicFormService.showGeneralError ||
        !fieldConfig.templateOptions.errorMsg,
      className: (_, __, fieldConfig) =>
        fieldConfig.templateOptions.isHidden ? FIELD_HIDDEN_CLASS : '',
    },
  };

  readonly buttonsHintAttachSelector =
    DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR;

  constructor(
    private readonly layoutService: LayoutService,
    private readonly buttonsService: ButtonsService,
    private readonly setValuesService: SetValuesService,
    private readonly dynamicFormService: DynamicFormService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.buttonsConfig = mergeConfigs(
      this.buttonsConfig,
      this.buttonsConfigPartial as TemplateConfig,
    );

    this.formGroup$
      .pipe(
        switchMap(group => group.valueChanges),
        untilDestroyed(this),
      )
      .subscribe(model => {
        this.changeModel.emit(model);
      });
  }

  ngAfterViewInit(): void {
    this.doc$
      .pipe(
        filter(doc => Boolean(doc)),
        untilDestroyed(this),
      )
      .subscribe(doc => {
        const contentTemplates = Array.from(this.contentTemplates || []);
        const viewTemplates = Array.from(this.viewTemplates || []);
        const templates = [...viewTemplates, ...contentTemplates].map(
          template => template.config,
        );

        this.dynamicFormService.init(doc, templates);

        // settimeout in order to avoid using 'detectChanges' in ngAfterViewInit due to ChangeDetectionStrategy.OnPush strategy
        setTimeout(() => {
          this.cdr.markForCheck();
        });
      });
  }

  /**
   * Emits the form button click event and form's value
   *
   * @param button - clicked button
   */
  emitButtonClick(button: IFormButtonUi): void {
    this.dynamicFormService.emitButtonClick(button);
  }

  /**
   * Sends the keyboard event to submit
   *
   * @param event - keyboard "Enter" key press event
   */
  submitFromKeyboard(event: KeyboardEvent): void {
    this.dynamicFormService.submitFromKeyboard(event);
  }

  /**
   * Reset the form
   */
  resetForm(): void {
    this.dynamicFormService.resetForm();
  }
}
