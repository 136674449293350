<!-- class tree-field-tooltip-parent is used for internal ispui-tootip components, to pass them attachNode parameter -->
<isp-tree
  ispTreeNodeOutlet
  [dataSource]="to.dataSource"
  [treeControl]="treeControl"
  class="tree tree-field-tooltip-parent"
  [style.height]="height"
>
  <isp-nested-tree-node
    #treeElement
    *ispTreeNodeDef="let node"
    class="tree__branch"
  >
    <div
      class="tree__label"
      [ngClass]="{ tree__label_is_selected: node.$key === formControl.value }"
    >
      <ispui-preloader-round
        *ngIf="node.isLoading; else folder"
        [loading]="node.isLoading"
        view="local"
        style="width: 20px; height: 20px"
        [spinnerSize]="20"
      >
      </ispui-preloader-round>
      <ng-template #folder>
        <isp-svg-icon
          (click)="toggle(node)"
          [customStyle]="{ width: '20px', height: '20px' }"
          [name]="getTreeIcon(node) | iconSimular"
          [ngClass]="{ tree__icon_is_extendable: isExpandable(node) }"
        ></isp-svg-icon>
      </ng-template>
      <span class="tree__text" (click)="select(node)" (dblclick)="toggle(node)">
        <ispui-tooltip ellipsis viewport-selector=".tree-field-tooltip-parent">
          {{ node.$ }}
        </ispui-tooltip>
      </span>
    </div>
    <ng-container
      ispTreeNodeOutlet
      *ngIf="treeControl.isExpanded(node)"
    ></ng-container>
  </isp-nested-tree-node>
</isp-tree>
