import { Params } from '@angular/router';

import {
  IDesktopMeta,
  IDocument,
} from 'app/services/api5-service/api.interface';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

import { DocHelper } from 'utils/dochelper';

import {
  ITabState,
  SerializedTab,
  TabStatus,
  TabParams,
  TabType,
} from './model';
import {
  getMenuGroupName,
  getTabParamsFromDoc,
  getTabParamsHash,
  queryParamsToTabParams,
} from './tab.utils';

/**
 * Tab class
 */
export class Tab {
  /** Property for generate tab's id. @WARN don't use zero in order for using id as boolean! */
  private static idCounter = 1;

  /** Tab internal id */
  private readonly _id: number = Tab.idCounter++;

  /** Tab data state */
  private readonly statusSubject: BehaviorSubject<TabStatus> =
    new BehaviorSubject(TabStatus.Empty);

  /** Restored from local storage or setted manualy isPinned state. Used for initial displaying for doc lazy loading */
  private readonly defaultIsPinnedSubject: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  /** Tab query params */
  private _params: TabParams;

  /** Menu group node name for propper icon displaying */
  private _groupName: string;

  /** Restored from local storage or setted manualy title. Used for initial displaying for doc lazy loading */
  private readonly defaultTitle?: string;

  /** Tab doc subject */
  private readonly docSubject: BehaviorSubject<IDocument | null> =
    new BehaviorSubject<IDocument>(null);

  /** Is data for this tab should be loaded after this tab will be activated. If this flag is false, then data starts loading imidiatly */
  private _lazyLoadable = true;

  get id(): string {
    return `isp-tab_${this._id}`;
  }

  get type$(): Observable<TabType | undefined> {
    return this.doc$.pipe(
      map(doc => DocHelper.type(doc)),
      startWith(DocHelper.typeFromFunc(this._params.func)),
      distinctUntilChanged(),
    );
  }

  /** Doc metadata type */
  get type(): TabType | undefined {
    return this.docSubject.value
      ? DocHelper.type(this.docSubject.value)
      : DocHelper.typeFromFunc(this._params.func);
  }

  get func(): string {
    return this._params.func;
  }

  /** Tab full query params */
  get params(): TabParams {
    return this._params;
  }

  /** Tab full query params sorted string. Need to compare tabs and compare it with route url */
  get paramsHash(): string {
    return getTabParamsHash(this._params);
  }

  /** Params count. Can be used for analyzing tabs redirection match */
  get paramsCount(): number {
    return Object.keys(this._params).length;
  }

  get doc(): IDocument | null {
    return this.docSubject.value;
  }

  /** Doc observable for load doc when restored tab */
  readonly doc$ = this.docSubject.pipe(filter(doc => Boolean(doc)));

  /** Parent doc id */
  get plid(): string {
    return this.docSubject.value ? DocHelper.plid(this.docSubject.value) : '';
  }

  /** Tab doc id */
  get elid(): string {
    return this.docSubject.value ? DocHelper.elid(this.docSubject.value) : '';
  }

  get groupName(): string {
    return this._groupName;
  }

  /** Title of tab */
  get title(): string {
    if (this.docSubject.value) {
      return DocHelper.getMessage('title', this.docSubject.value);
    }

    return this.defaultTitle || '';
  }

  get title$(): Observable<string> {
    return this.doc$.pipe(
      map(doc => DocHelper.getMessage('title', doc)),
      startWith(this.defaultTitle || ''),
      distinctUntilChanged(),
    );
  }

  /** Is tab pinned on tab-bar. Pinned tabs are unclosable, and always stays on the left side among other tabs */
  get isPinned(): boolean {
    if (this.docSubject.value) {
      return DocHelper.isPinned(this.docSubject.value);
    }

    return this.defaultIsPinnedSubject.value;
  }

  get isPinned$(): Observable<boolean> {
    return combineLatest([this.defaultIsPinnedSubject, this.docSubject]).pipe(
      map(([defaultIsPinned, doc]) =>
        doc ? DocHelper.isPinned(doc) : defaultIsPinned,
      ),
      distinctUntilChanged(),
    );
  }

  get isZazyLoadable(): boolean {
    return this._lazyLoadable;
  }

  readonly status$ = this.statusSubject.asObservable();

  get status(): TabStatus {
    return this.statusSubject.value;
  }

  /** Tab state */
  state: ITabState = {
    progressIdSubject: new BehaviorSubject(null),
    progressModalStateSubject: new BehaviorSubject(null),
    progressModalUplaodStateSubject: new BehaviorSubject(null),
    reportSubmitModel: null,
    reportList: undefined,
    reportParams: {} as any,
  };

  constructor(params: {
    params: TabParams;
    title: string;
    groupName: string;
    isPinned: boolean;
  }) {
    this._params = params.params;
    this._groupName = params.groupName;
    this.defaultTitle = params.title;
    this.defaultIsPinnedSubject.next(params.isPinned);
  }

  /**
   * Create tab instance from doc instance
   *
   * @param doc - doc instance
   * @param desktop
   */
  static createFromDoc(doc: IDocument, desktop: IDesktopMeta): Tab {
    const groupName = getMenuGroupName(doc.$func, desktop);
    const tab = new Tab({
      params: getTabParamsFromDoc(doc),
      groupName,
      isPinned: DocHelper.isPinned(doc),
      title: DocHelper.getMessage('title', doc),
    });
    tab.updateDoc(doc, desktop);

    return tab;
  }

  /**
   * Create tab from serialized tab data
   *
   * @param tabData - tab data
   */
  static deserialize(tabData: SerializedTab): Tab {
    const tabInstance = new Tab({
      params: tabData.params,
      title: tabData.title,
      groupName: tabData.groupName,
      isPinned: tabData.isPinned,
    });
    return tabInstance;
  }

  /**
   * Update tab status. Status reflect tab data lifecycle and state. Should be invoked after every docSubject change
   *
   * @param doc - doc instance
   */
  private updateStatus(doc: IDocument): void {
    if (!doc) {
      this.statusSubject.next(TabStatus.Empty);
      return;
    }

    const isErrorDoc = doc.$func === 'error' || doc.error;
    if (isErrorDoc) {
      this.statusSubject.next(TabStatus.Error);
      return;
    }

    const wasReady = this.statusSubject.value === TabStatus.Ready;
    if (wasReady) {
      this.statusSubject.next(TabStatus.Changed);
    } else {
      this.statusSubject.next(TabStatus.Ready);
    }
  }

  /**
   * Get tab data for saving into local storage
   */
  serialize(): SerializedTab {
    const serializedData: SerializedTab = {
      params: this._params,
      title: this.title,
      groupName: this.groupName,
      isPinned: this.isPinned,
    };
    return serializedData;
  }

  /**
   * Update tab instance with new doc data
   *
   * @param doc - doc instance
   * @param desktop
   */
  updateDoc(doc: IDocument, desktop: IDesktopMeta): void {
    if (doc.$func !== 'error') {
      this._params = getTabParamsFromDoc(doc);
      this._groupName = getMenuGroupName(doc.$func, desktop);
      this.docSubject.next(doc);
    }
    this.updateStatus(doc);
  }

  reemitDoc(): void {
    if (this.docSubject.value) {
      this.docSubject.next(this.docSubject.value);
    }
  }

  /**
   * Toggle progress modal for tab
   *
   * @param percent - files upload percent. Null for turning modal off
   * @param commentMsg
   */
  setUploadFileProgressModal(
    percent: number | null,
    commentMsg?: string,
  ): void {
    // @TODO i.ablov use 'progressModalStateSubject' instead!
    this.state.progressModalUplaodStateSubject.next(
      percent
        ? {
            comment: commentMsg,
            percent,
            percentDisplay: true,
          }
        : null,
    );
  }

  /**
   * Compare tab params and query params on including
   *
   * @param queryParams
   */
  isIncludeQueryParams(queryParams: Params): boolean {
    const paramsArray = Object.entries(this.params);
    const compareParamsArray = Object.entries(queryParams);

    return compareParamsArray.every(([key, value]) =>
      paramsArray.some(([k, v]) => k === key && value === v),
    );
  }

  /**
   * Compare tab params and query params for strict equality
   *
   * @param queryParams
   */
  matchQueryParams(queryParams: Params): boolean {
    const queryTabParams = queryParamsToTabParams(queryParams);
    if (!queryTabParams) {
      return false;
    }

    const paramsHash = getTabParamsHash(queryTabParams);
    return this.paramsHash === paramsHash;
  }

  setPinned(pinned: boolean): void {
    this.defaultIsPinnedSubject.next(pinned);

    if (this.docSubject.value) {
      this.docSubject.value.$pin = pinned ? 'yes' : 'no';
      this.docSubject.next(this.docSubject.value);
    }
  }

  setLazyLoadable(lazyLaodable: boolean): void {
    this._lazyLoadable = lazyLaodable;
  }

  onDestroy(): void {
    this.docSubject.complete();
    this.statusSubject.complete();
    this.defaultIsPinnedSubject.complete();
  }
}
