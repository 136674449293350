import { IField, ISelect } from 'app/services/api5-service/api.interface';

import { FIELD_HIDDEN_CLASS } from 'common/dynamic-form/utils/class-fields';

import { RadioTO } from './model/radio-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';
import {
  autoselectSelectOptions,
  getDependSelectOptions,
  isSelectHidden,
} from '../select/select.utils';

/**
 * Get config for radio field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getRadioConfig(
  control: ISelect,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.Radio> {
  const options = state.selectService.getOptionsForSelect(control.$name) || [];
  const templateOptions: RadioTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    options: options,
    depend: control.$depend,
    inlineSelect: control.$inline === 'yes',
    hintPlace: 'label',
    isHidden: isSelectHidden(state, control, field, options),
  };

  const config: ISPFieldConfig<ISPFieldType.Radio> = {
    key: control.$name,
    type: ISPFieldType.Radio,
    wrappers: [ISPFieldWrapper.FieldBase],
    templateOptions,
    expressionProperties: {
      className: (_, __, fieldConfig) =>
        fieldConfig.templateOptions.isHidden ? FIELD_HIDDEN_CLASS : '',
      'templateOptions.isHidden': (_, formState, fieldConfig) =>
        isSelectHidden(
          formState,
          fieldConfig.templateOptions.originalControl,
          fieldConfig.templateOptions.originalField,
          fieldConfig.templateOptions.options,
        ),
      'templateOptions.options': (model, formState, fieldConfig) => {
        const selectOptions =
          formState.selectService.getOptionsForSelect(control.$name) || [];

        const isSelectDepends = Boolean(fieldConfig.templateOptions.depend);

        if (isSelectDepends) {
          const dependOptions = getDependSelectOptions(
            selectOptions,
            model,
            fieldConfig,
          );
          autoselectSelectOptions(dependOptions, model, fieldConfig);

          return dependOptions;
        } else {
          autoselectSelectOptions(selectOptions, model, fieldConfig);

          return selectOptions;
        }
      },
    },
  };

  // ispui radio doesn't implement blur event
  config.modelOptions = {
    updateOn: 'change',
  };

  return config;
}
