<isp-svg-icon
  *ngIf="to.img?.icon"
  class="link-icon"
  [name]="to.img.icon"
  [customStyle]="{ width: to.img.width, height: to.img.height }"
></isp-svg-icon>
<img
  *ngIf="to.img?.url"
  class="link-image"
  [alt]="to.caption"
  [width]="to.img.width"
  [src]="to.img.url"
  [height]="to.img.height"
/>
<!-- TODO: Сслыки с `@internal=yes` -->
<ispui-link
  *ngIf="to.isInternal; else hrefLink"
  (click)="openTab()"
  (keyup.enter)="openTab()"
  (keyup.space)="openTab()"
  [tabIndex]="0"
  role="link"
  type="default-hover"
  >{{ to.caption }}</ispui-link
>
<ng-template #hrefLink>
  <a class="isp-link" [href]="to.href" [target]="to.target">{{ to.caption }}</a>
</ng-template>
