import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { ChatRateComponent } from './chat-rate.component';

@NgModule({
  declarations: [ChatRateComponent],
  imports: [CommonModule, SvgIconModule],
  exports: [ChatRateComponent],
})
export class ChatRateModule {}
