import { IDocument, IField } from 'app/services/api5-service/api.interface';

import {
  AutocompleteSelectControlWithValue,
  getTypeControlForAutocomplete,
  isControlAutocomplete,
} from './get-typed-control-for-autocomplete';
import {
  getTypeControlForRadio,
  isControlRadio,
  RadioControlWithValue,
} from './get-typed-control-for-radio';
import {
  getTypeControlForRadioImg,
  isControlRadioImg,
  RadioImgControlWithValue,
} from './get-typed-control-for-radioimg';
import {
  getTypeControlForSelect,
  SelectControlWithValue,
} from './get-typed-control-for-select';
import {
  getTypeControlForSwitcher,
  isControlSwitcher,
  SwitcherControlWithValue,
} from './get-typed-control-for-switcher';

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param field - field metadata
 * @param doc - doc
 */
export function getTypeControlsForSelect(
  field: IField,
  doc: IDocument,
): (
  | AutocompleteSelectControlWithValue
  | RadioControlWithValue
  | RadioImgControlWithValue
  | SelectControlWithValue
  | SwitcherControlWithValue
)[] {
  return (field.select || []).map(control => {
    switch (true) {
      case isControlSwitcher(control):
        return getTypeControlForSwitcher(control, doc);
      case isControlRadio(control):
        return getTypeControlForRadio(control, doc);
      case isControlRadioImg(control):
        return getTypeControlForRadioImg(control, doc);
      case isControlAutocomplete(control):
        return getTypeControlForAutocomplete(control, doc);
      default:
        return getTypeControlForSelect(control, doc);
    }
  });
}
