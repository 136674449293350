import { CdkTree } from '@angular/cdk/tree';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { TreeNodeOutletDirective } from './outlet.directive';

/**
 * Wrapper for the CdkTable with Material design styles.
 */
@Component({
  selector: 'isp-tree',
  exportAs: 'ispTree',
  template: '<ng-container ispTreeNodeOutlet></ng-container>',
  styleUrls: [
    './scss/nested-tree-node/_nested-tree-node.scss',
    './scss/tree/_tree.scss',
    './scss/tree-node/_tree-node.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line no-use-before-define
  providers: [{ provide: CdkTree, useExisting: TreeComponent }],
})
export class TreeComponent<T> extends CdkTree<T> {
  // outlets within the tree's template where the dataNodes will be inserted.
  @ViewChild(TreeNodeOutletDirective, { static: true })
  _nodeOutlet: TreeNodeOutletDirective;

  @HostBinding('attr.role') role = 'tree';

  @HostBinding('class.tree') readonly treeClass = true;
}
