export enum TabStatus {
  /** Tab in empty status contain only minimal data for right displaying and correct lazy loading */
  Empty = 'empty',
  /** Tab in ready status have loaded doc and initiallized template */
  Ready = 'ready',
  /**
   * Same as ready. But this status can be reached only after valid doc was changed on another doc,
   * or just updated in process of tab lifetime. Can be used to detect changes on tab
   */
  Changed = 'changed',
  /** Tab in error status have error doc */
  Error = 'error',
}
