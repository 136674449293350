import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ISPUITooltipModule } from '@ispui/ng-tooltip';

import { FormCollapseComponent } from './form-collapse.component';

import { SvgIconModule } from '../../common/svg-icon-module/svg-icon.module';

/**
 * Collapsible component module. Designed specifically for forms.
 *
 * Usage:
 * ```html
 * <isp-form-collapse [data]="page">
 *   <div>put the collapsible contents here</div>
 * </isp-form-collapse>
 * ```
 */
@NgModule({
  declarations: [FormCollapseComponent],
  exports: [FormCollapseComponent],
  imports: [CommonModule, SvgIconModule, ISPUITooltipModule],
})
export class FormCollapseModule {}
