import { Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';

import {
  ConfirmEvent,
  ConfirmOptions,
  ISPUIConfirmManager,
} from '@ispui/confirm';

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  private readonly confirmManager = new ISPUIConfirmManager();

  open(options: ConfirmOptions): Observable<ConfirmEvent> {
    return from(this.confirmManager.open(options));
  }

  close(): void {
    this.confirmManager.close();
  }
}
