<ng-lottie
  *ngIf="isAnimated; else sprite"
  [styles]="customStyle"
  [options]="getAnimationOptions(name)"
>
</ng-lottie>

<ng-template #sprite>
  <isp-svg-icon
    *ngIf="inSprite; else img"
    [name]="name | iconSimular"
    [customStyle]="customStyle"
  ></isp-svg-icon>
</ng-template>

<ng-template #img>
  <div
    [style.background]="getBgStyle() | trust: 'style'"
    [ngStyle]="customStyle"
  ></div>
</ng-template>
