export type RGBAColorChanels = [number, number, number, number];

/**
 * Check that string is hex color
 *
 * @param value - hex string
 */
export function isHexColor(value: string | null): boolean {
  return /^#([A-F0-9]{3,4}|[A-F0-9]{6}|[A-F0-9]{8})$/i.test(value);
}

/**
 * Remove alpha chanel from hex string, if hex color is fully opaque (alpha = 100%)
 *
 * @param value - hex string
 */
export function removeAlphaChanelIfOpaque(value: string | null): string | null {
  if (isHexColor(value)) {
    if (value.length === 5 && value.substring(4) === 'f') {
      return value.substring(0, 4);
    } else if (value.length === 9 && value.substring(7) === 'ff') {
      return value.substring(0, 7);
    }
  }
  return value;
}

/**
 * Convert hex string to rgba integer chanels
 *
 * @param value - hex string
 */
export function hexToRgbaChanels(value: string | null): RGBAColorChanels {
  const color = isHexColor(value) ? value.substring(1) : '000000ff';
  const chanelsLength = color.length === 3 || color.length === 4 ? 1 : 2;
  const chanelsHex =
    chanelsLength === 1 ? [...color].map(h => `${h}0`) : color.match(/../g);
  const chanelsInt = chanelsHex.map(hex => Number.parseInt(hex, 16));

  return (
    chanelsInt.length === 3 ? chanelsInt.concat([255]) : chanelsInt
  ) as RGBAColorChanels;
}

/**
 * Convert rgba integer chanels to hex string
 *
 * @param value - rgba integer chanels
 */
export function getHexFromRGBAChanels(value: RGBAColorChanels): string {
  return removeAlphaChanelIfOpaque(
    `#${value
      .map(v => {
        const hexValue = Number(v).toString(16);
        return hexValue.length === 1 ? `0${hexValue}` : hexValue;
      })
      .join('')}`,
  );
}

/**
 * Extrapolate 4d vector point by two original vector points
 *
 * @param original - original point
 * @param target - target point, related to original point
 * @param analogue - point, that should be used as base point in space for analogue
 */
export function linearExtrapolation(
  original: RGBAColorChanels,
  target: RGBAColorChanels,
  analogue: RGBAColorChanels,
): RGBAColorChanels {
  const direction = target.map((v, i) => v - original[i]);
  const newVector = direction
    .map((v, i) => v + analogue[i])
    .map(v => Math.round(v)) as RGBAColorChanels;
  return newVector;
}

/**
 * Get new hex color by analogue with two another colors
 *
 * @param original - original hex color
 * @param target - target hex color, that depends on original color
 * @param analogue - color, that should be used as new original color
 */
export function getColorByAnalogue(
  original: string,
  target: string,
  analogue: string | null,
): string {
  if (!isHexColor(analogue)) {
    return '';
  }

  const originalRGBA = hexToRgbaChanels(original);
  const targetRGBA = hexToRgbaChanels(target);
  const analogueRGBA = hexToRgbaChanels(analogue);

  const relationRGBA = linearExtrapolation(
    originalRGBA,
    targetRGBA,
    analogueRGBA,
  ).map(v => Math.max(Math.min(v, 255), 0)) as RGBAColorChanels;
  return getHexFromRGBAChanels(relationRGBA);
}
