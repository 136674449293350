<div
  class="input-base__input-wrap"
  [class.input-base__input-wrap_fullwidth]="to.isFullWidth"
  [class.input-base__input-wrap_is_hidden]="to.isHidden"
  [ngClass]="{
    'input-base__input-wrap_input-like': to.hintDisplayMode === 'input-like',
    'input-base__input-wrap_center': to.hintDisplayMode === 'center'
  }"
>
  <ng-container #fieldComponent></ng-container>

  <!-- @WARN do not use *ngIf, cause hint should take place in dom in fullwidth mode. And disapear in other way. This ruled by css -->
  <ispui-tooltip
    class="input-base__hint"
    [placement-order]="['right', 'bottomLeft', 'topLeft', 'bottomRight']"
    [attach-selector]="hintAttachContainer | formIdPrefix"
    [scroll-selector]="hintAttachContainer | formIdPrefix"
    [class.input-base__hint_invisible]="
      !haveHintMessageToDisplay || (isErrorDisplayed | async)
    "
    #tooltip
  >
    <div slot="content" [innerHTML]="getHint()"></div>
    <isp-svg-icon name="banner2-icon" [customStyle]="iconSize"></isp-svg-icon>
  </ispui-tooltip>
</div>
<div *ngIf="!to.hideDesc && to.desc" class="input-base__desc_outside">
  {{ to.desc }}
</div>
<div
  *ngIf="getPlainHint() as plainhintMsg"
  [innerHTML]="plainhintMsg"
  class="input-base__plainhint"
></div>
