import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

import { PageInfoService } from 'app/services/page-info/page-info.service';
import { IFormButtonUi } from 'components/form-button';

import { IListRowUi, IListCellUi } from './model/list.interface';

import { DynamicFormService } from '../../dynamic-form.service';
import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * List formly component
 */
@Component({
  selector: 'isp-formly-list',
  templateUrl: './list.component.html',
  styleUrls: ['./scss/list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListFieldComponent extends ISPFieldTypeBase<ISPFieldType.List> {
  /** A11y `role` attribute */
  @HostBinding('attr.role') role = 'list';

  constructor(
    private readonly pageInfoService: PageInfoService,
    private readonly dynamicFormService: DynamicFormService,
  ) {
    super();
  }

  getCellClasses(cell: IListCellUi) {
    const classes: Record<string, boolean> = {
      ['list__cell']: true,
    };

    if (cell.$type) {
      if (cell.$type === 'data') {
        // @HACK!!!
        // ispmgr use special styling for it's cell data types
        // billmgr do not prepare for it. It's even not prepared for native browser styling
        // so turn down styles for billmgr list cells
        if (this.pageInfoService.isBillmgr) {
          classes.list__cell_type_data__billmgr = true;
        } else {
          classes.list__cell_type_data = true;
        }
      } else {
        classes[`list__cell_type_${cell.$type}`] = true;
      }
    }

    if (cell.$align) {
      classes[`list__cell_align_${cell.$align}`] = true;
    }

    if (cell.color) {
      classes[`list__cell_color_${cell.color}`] = true;
    }

    return classes;
  }

  /**
   * Whether the table should appear with head
   *
   * @param rowList - row list
   */
  displayHeader(rowList: IListRowUi[]): boolean {
    return this.to.type === 'table' && rowList.length > 0;
  }

  emitButtonClick(button: IFormButtonUi, row: IListRowUi): void {
    this.dynamicFormService.emitButtonClick(button, {
      // if value doesn't set, set '' for backend
      [row.key]: button.keyValue ?? row.keyValue ?? '',
    });
  }

  /**
   * TrackBy function for row
   *
   * @param index
   */
  trackByRowFn(index: number): number | string {
    return index;
  }

  /**
   * TrackBy function for cell
   *
   * @param index
   * @param cell
   */
  trackByCellFn(index: number, cell: IListCellUi): string {
    return `${cell.value}${cell.$name}${cell.$type}${index}`;
  }
}
