import { LayoutService } from 'common/dynamic-form/services/layout.service';

/**
 * Prefix query selector by form id
 *
 * @param layoutService - form layout service
 * @param selector
 */
export function prefix(layoutService: LayoutService, selector: string): string {
  return `#${layoutService.formId} ${selector}`;
}
