import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  retryWhen,
  skip,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { WINDOW, WindowWrapper } from '@ngispui/window-service';

import { DocHelper } from 'utils/dochelper';

import { Route } from './app.interface';
import {
  Func,
  IDesktopMeta,
  IDocument,
} from './services/api5-service/api.interface';
import { Api5Service } from './services/api5-service/api5.service';
import { ConfirmService } from './services/confirm/confirm.service';
import { HttpBaseService } from './services/http-base-service/http-base.service';
import { TooltipService } from './services/tooltip-service/tooltip.service';

const GET_DESKTOP_RETRY_COUNT = 5;

const RETRY_TIMEOUT = 1000;

/**
 * App service main service of application
 */
@Injectable({
  providedIn: 'root',
})
export class AppService {
  /** Desktop metadata subject */
  private readonly desktopSubject: BehaviorSubject<IDesktopMeta | null> =
    new BehaviorSubject(null);

  /** Doc property $features stream */
  private readonly docFeatures$ = this.httpBaseService.docFeatures$;

  /** IsLoading app flag subject */
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  /** Desktop metadata observable */
  readonly desktop$ = this.desktopSubject.pipe(
    filter(desktop => desktop !== null),
  );

  /** Returns the binary path (such as `/ispmgr` or `/billmgr` part in the URI) */
  get binary(): string | undefined {
    return this.desktopSubject.value?.$binary;
  }

  /** Return the host */
  get host(): string | undefined {
    return this.desktopSubject.value?.$host;
  }

  /** Return desktop instance */
  get desktop(): IDesktopMeta | null {
    return this.desktopSubject.value;
  }

  constructor(
    private readonly api: Api5Service,
    private readonly router: Router,
    private readonly titleService: Title,
    @Inject(WINDOW) private readonly window: WindowWrapper,
    private readonly httpBaseService: HttpBaseService,
    private readonly tooltipService: TooltipService,
    private readonly confirmService: ConfirmService,
  ) {}

  /**
   * Sets the current's app title (`<title>`)
   *
   * @param doc - desktop meta
   */
  private setTitle(doc: IDesktopMeta): void {
    const title = doc.messages?.msg?.title;
    if (title) {
      this.titleService.setTitle(title);
    }
  }

  /**
   * Subscribe to new doc features for page reloading
   */
  private subscribeToDocFeatures(): void {
    this.docFeatures$
      .pipe(
        filter(features => Boolean(features)),
        distinctUntilChanged(),
        skip(1),
        // if need to reboot the whole interface,
        // make sure that all running requests to the server are completed
        switchMap(() =>
          this.httpBaseService.activeRequestsCount$.pipe(
            filter(requestsCount => requestsCount === 0),
          ),
        ),
      )
      .subscribe(() => {
        // setTimeout for time to handle all response, like close form
        setTimeout(() => {
          // for recover tabs from localstorage only
          location.hash = '';
          document.location.reload();
        });
      });
  }

  /**
   * Check auth for dev mode
   *
   * @WARN - this is devel function. This should not appear in production!
   * @param doc - desktop data
   */
  private checkAuth(doc: IDocument): void {
    if (doc?.$func === Func.Logon) {
      this.confirmService
        .open({
          header: 'Ошибка авторизации',
          description: 'Перейти на форму авторизации?',
        })
        .pipe(filter(confirmResult => confirmResult.resolve))
        .subscribe(() => {
          this.router.navigateByUrl(Route.Login);
        });
    }
  }

  /**
   * Init app method
   */
  init$(): Observable<IDesktopMeta> {
    this.subscribeToDocFeatures();
    this.tooltipService.setOnlyOnePopupMode();
    return this.getDesktop$();
  }

  getDesktop$(): Observable<IDesktopMeta> {
    return this.api.desktop().pipe(
      tap(data => this.checkAuth(data)),
      retryWhen(errors =>
        errors.pipe(delay(RETRY_TIMEOUT), take(GET_DESKTOP_RETRY_COUNT)),
      ),
      tap(desktop => {
        this.desktopSubject.next(desktop);
        this.isLoading$.next(false);
        this.setTitle(desktop);
      }),
      catchError(error => {
        this.window.location.reload();
        return throwError(error);
      }),
    );
  }

  getUserName(): string {
    return this.desktopSubject.value.user?.$name;
  }

  getUserName$(): Observable<string> {
    return this.desktop$.pipe(
      map(desktop => desktop.user?.$name),
      distinctUntilChanged(),
    );
  }

  /**
   * Get message from desktop's doc
   *
   * @param name
   */
  getDesktopMessage(name: string): string {
    return DocHelper.getMessage(name, this.desktopSubject.value);
  }

  reemitDoc(): void {
    this.desktopSubject.next(this.desktopSubject.value);
  }
}
