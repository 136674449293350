import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

import {
  INotifyBanner,
  INotifyEvent,
  NgISPUINotificationService,
  NotifyBannerEvents,
  NotifyBannerTypes,
} from '@ngispui/notification';

import { INotificationBanner } from './api5-service/api.interface';
import { Api5Service } from './api5-service/api5.service';

/**
 * Internal type for notification classification:
 * - bannerList - notifications from notify request, such as service opening, renewal, etc;
 * - banners - banners that can come in doc.banner, in orion they appear under the page title;
 * - internal - our internal notifications, including through them errors are displayed when submitting a form.
 */
export type NotificationSource = 'bannerList' | 'banners' | 'internal';

/** The type of notification with the source they will be classified by */
export type Notification = INotifyBanner<INotificationBanner> & {
  source: NotificationSource;
};

/** The type of notification event with optional data notificationBanner */
export type NotificationEvent = INotifyEvent<INotificationBanner>;

/**
 * Notification service
 * provides show banner
 */
@Injectable({
  providedIn: 'root',
})
export class ISPNotificationService {
  /**
   * Map of notifications currently displayed
   */
  private readonly displayedNotifications: Map<number, Notification> =
    new Map();

  /**
   * @HACK NGISPUI Notification Service doesn't complete notification subscription
   * after 'removeBanner' function, so we need to use are own unsubscription system
   */
  private readonly displayedNotificationDestroySubject: Subject<number> =
    new Subject();

  readonly displayedNotificationsSize$: BehaviorSubject<number> =
    new BehaviorSubject(this.displayedNotifications.size);

  constructor(
    private readonly notificationService: NgISPUINotificationService,
    private readonly api: Api5Service,
  ) {}

  /**
   * Visually hide notification and clean up internal stores
   * and unsubscribe from notification events
   *
   * @param id - notification id
   */
  private deleteNotification(id: number) {
    this.notificationService.removeBanner(Number(id));
    this.displayedNotifications.delete(id);
    this.displayedNotificationDestroySubject.next(id);
    this.displayedNotificationsSize$.next(this.displayedNotifications.size);
  }

  private sendCloseAllBannersRequest(banners: Notification[]): void {
    const bannerForDismiss = new Set<INotificationBanner>();

    banners.forEach(banner => {
      bannerForDismiss.add(banner.data);
    });

    bannerForDismiss.forEach(banner => {
      const params: Params = { id: banner.$id };
      if (banner.$infoelid) {
        params.elid = banner.$infoelid;
      }
      this.api.dismiss(params).subscribe();
    });
  }

  private sendCloseAllNotificationsRequest(): void {
    this.api.deleteAllNotifications().subscribe();
  }

  close(id: number): void {
    this.notificationService.removeBanner(id);
  }

  /**
   * Show error banner
   *
   * @param errorMsg
   * @param title
   * @param link
   */
  showError(
    errorMsg: string,
    title: string,
    link: string = null,
  ): Observable<NotificationEvent> {
    const notificationId = Date.now();
    const notification: Notification = {
      id: notificationId,
      title,
      content: errorMsg,
      type: NotifyBannerTypes.ERROR_FAST,
      duration: Infinity,
      link,
      source: 'internal',
    };

    return this.showNotification(notification);
  }

  showNotification(
    notificationObject: Notification,
  ): Observable<NotificationEvent> {
    const notification = notificationObject;

    if (!notification.id) {
      notification.id = Date.now();
    }

    this.displayedNotifications.set(notification.id, notification);
    this.displayedNotificationsSize$.next(this.displayedNotifications.size);

    return this.notificationService.push(notification).pipe(
      tap(result => {
        if (result.type === NotifyBannerEvents.CLOSE) {
          this.deleteNotification(notification.id);
        }
      }),
      takeUntil(
        this.displayedNotificationDestroySubject.pipe(
          // @WARN this debounce is required to event pass throuh
          debounceTime(0),
          filter(id => id === notification.id),
        ),
      ),
    );
  }

  /**
   * Hides all of the notification list and sends a request to the server
   *
   * Since we have banners and notifications from the backend,
   * and our internal notifications in the notification list,
   * send the necessary requests for banners and notifications from the backend
   */
  hideAllAndRequestOnServer(): void {
    const notifications = Array.from(this.displayedNotifications.values());

    const hasFromBannerList = notifications.some(
      notification => notification.source === 'bannerList',
    );
    if (hasFromBannerList) {
      this.sendCloseAllNotificationsRequest();
    }

    const banners = notifications.filter(
      notification => notification.source === 'banners',
    );
    if (banners.length > 0) {
      this.sendCloseAllBannersRequest(banners);
    }

    this.hideAll();
  }

  /**
   * Visually hide all notifications
   */
  hideAll(): void {
    Array.from(this.displayedNotifications.keys())
      .filter(id =>
        this.notificationService.isNotifyBannerShowing({
          id: Number(id),
        } as any),
      )
      .forEach(id => {
        this.deleteNotification(id);
      });
  }

  /**
   * Visually hide notifications by id
   *
   * @param ids - notification ids
   */
  hideByIds(ids: number[] | string[]): void {
    ids.forEach(id => {
      this.hideById(id);
    });
  }

  /**
   * Visually hide notification by id
   *
   * @param id - notification id
   */
  hideById(id: number | string): void {
    const idNumber = typeof id === 'number' ? id : parseInt(id, 10);
    if (isNaN(idNumber)) {
      return;
    }

    const notificationShowing = this.notificationService.isNotifyBannerShowing({
      id: idNumber,
    } as any);
    if (notificationShowing) {
      this.deleteNotification(idNumber);
    }
  }
}
