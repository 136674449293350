import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, interval } from 'rxjs';
import { map } from 'rxjs/operators';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Date-time field component
 */
@Component({
  selector: 'isp-formly-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./scss/datetime.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimeComponent
  extends ISPFieldTypeBase<ISPFieldType.DateTime>
  implements OnInit, OnDestroy
{
  /** 1 second in milliseconds */
  private readonly interval = 1000;

  /** Subject with current server time in unix timestamp */
  private readonly dateTimeSubject = new BehaviorSubject<Date>(null);

  /** Human readable time observable */
  readonly dateTime$ = this.dateTimeSubject.pipe(
    map(date => this.getDateTimeString(date)),
  );

  private setDateFromString(date: string): void {
    this.dateTimeSubject.next(new Date(String(date).replace(/-/g, '/')));
  }

  /**
   * Adds one second to the date
   */
  private appendTime(): void {
    const currentDate = this.dateTimeSubject.value;
    currentDate.setSeconds(currentDate.getSeconds() + 1);
    this.dateTimeSubject.next(currentDate);
  }

  /**
   * Converts unix timestamp into the human readable string
   *
   * @param date - time value
   */
  private getDateTimeString(date: Date): string {
    return date.toLocaleString();
  }

  /**
   * OnInit lifecycle hook.
   * Sends the first value to the `dateTimeSubject` and starts time counting
   */
  ngOnInit(): void {
    this.setDateFromString(this.to.time);

    this.formControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.setDateFromString(value);
      });

    interval(this.interval)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.appendTime();
      });
  }

  ngOnDestroy(): void {
    this.dateTimeSubject.complete();
  }

  toggleFocus(isFocused = false): void {
    this.to.isFocused = isFocused;
  }
}
