import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChatComponent } from './chat.component';

import { SvgIconModule } from '../../common/svg-icon-module/svg-icon.module';
import { ChatBubbleModule } from '../chat-bubble';

/**
 * Chat component module
 */
@NgModule({
  declarations: [ChatComponent],
  imports: [CommonModule, ChatBubbleModule, SvgIconModule],
  exports: [ChatComponent],
})
export class ChatModule {}
