<div
  class="chat-bubble"
  [ngClass]="[
    'chat-bubble_type_' + data.type,
    'chat-bubble_align_' + data.align || 'left'
  ]"
>
  <ng-container *ngIf="data.avatar">
    <img
      *ngIf="
        !data.avatar.isDefault && data.avatar.path;
        else bubbleImageDefault
      "
      [src]="data.avatar.path"
      [alt]="data.avatar.caption"
      class="chat-bubble__avatar"
    />

    <ng-template #bubbleImageDefault>
      <isp-svg-icon
        class="chat-bubble__avatar"
        [name]="data.avatar.defaultSvg"
        [customStyle]="{ width: '35px', height: '35px' }"
      >
      </isp-svg-icon>
    </ng-template>
  </ng-container>
  <div class="chat-bubble__content">
    <span *ngIf="data.chatTitle" class="chat-bubble__title">
      <span class="chat-bubble__chat-title">{{ data.chatTitle }}</span>
      <span class="chat-bubble__chat-title-caption">{{
        data.chatTitleCaption
      }}</span>
    </span>

    <span *ngIf="data.subtitle" class="chat-bubble__subtitle">
      {{ data.subtitle }}</span
    >

    <p class="chat-bubble__body" [innerHTML]="data.body"></p>

    <isp-chat-files [files]="data.files"></isp-chat-files>

    <isp-chat-rate
      *ngIf="data.rates?.canShowRates"
      [rates]="data.rates"
      [id]="data.id"
      [labels]="data.labels"
    >
    </isp-chat-rate>
  </div>
</div>
