import { Injectable } from '@angular/core';

import {
  IDesktopPageInfo,
  IExtformPageInfo,
  PageInfo,
} from 'app/app.interface';
import { environment } from 'environments/environment';

declare const pageInfo: IDesktopPageInfo | IExtformPageInfo;

/**
 * Service for getting server side rendered page data
 */
@Injectable({
  providedIn: 'root',
})
export class PageInfoService {
  static get desktopPageInfo(): IDesktopPageInfo {
    return pageInfo as IDesktopPageInfo;
  }

  static get extformPageInfo(): IExtformPageInfo {
    return pageInfo as IExtformPageInfo;
  }

  static get pageInfo(): PageInfo {
    return pageInfo;
  }

  get desktopPageInfo(): IDesktopPageInfo {
    return pageInfo as IDesktopPageInfo;
  }

  get extformPageInfo(): IExtformPageInfo {
    return pageInfo as IExtformPageInfo;
  }

  get pageInfo(): PageInfo {
    return pageInfo;
  }

  get mgrHost(): string {
    if (environment.production) {
      return pageInfo.baseUrl;
    } else {
      return environment.mgrHost;
    }
  }

  get isBillmgr(): boolean {
    const product = pageInfo.binary || environment.mgrHost;
    return product === '/billmgr' || product === '/manager/billmgr';
  }
}
