import { IControl } from 'app/services/api5-service/api.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldTypeWithControl,
} from '../../model';
import { findConfig } from '../../utils/formly-configs';

/**
 * Get original control metadata. if it can be extracted, from field config
 *
 * @param config - field config
 */
export function getOriginalControlFromConfig(
  config: ISPFieldConfig,
): IControl | undefined {
  if (
    config.type === ISPFieldType.Group ||
    config.type === ISPFieldType.PhoneGroup
  ) {
    // group field use control meta from first field
    const firstControlInGroup = findConfig(
      config.fieldGroup,
      f => 'originalControl' in f.templateOptions,
    ) as ISPFieldConfig<ISPFieldTypeWithControl> | undefined;
    return firstControlInGroup?.templateOptions.originalControl;
  } else if (
    config.type !== ISPFieldType.ListFilter &&
    config.type !== ISPFieldType.Layout &&
    config.type !== ISPFieldType.SiteSummary &&
    config.type !== ISPFieldType.Template &&
    config.type !== ISPFieldType.ChatSummary &&
    config.type !== ISPFieldType.FormScroller
  ) {
    return config.templateOptions.originalControl;
  }
}
