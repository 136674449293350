import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  BehaviorSubject,
  Observable,
  OperatorFunction,
  pipe,
  Subject,
  throwError,
} from 'rxjs';
import { catchError, filter, finalize, map, share, tap } from 'rxjs/operators';

import { CustomHttpParamEncoder } from './custom-http-param-encode.class';
import { IHttpParam } from './http-base.interface';

import { IDocumentBase, IFuncResponse } from '../api5-service/api.interface';
import { PageInfoService } from '../page-info/page-info.service';

/** Http Service Unavailable */
const HTTP_CODE_SERVICE_UNAVAILABLE = 503;

/**
 * Http service for ISPmanager/BILLmanager 5
 * with 503 error handler
 */
@Injectable({
  providedIn: 'root',
})
export class HttpBaseService {
  /** The host of the manager */
  private readonly mgrHost = this.pageInfoService.mgrHost;

  /** Default output format */
  private readonly outputFormatParam = { out: 'xjson' };

  /** Header for 503 error output in json */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly forJsonOutputHeaders = { 'ISP-Client': 'Web-interface' };

  /** Doc property $features subject */
  private readonly docFeaturesSubject = new Subject<string>();

  /** Doc property $notify subject */
  private readonly docNotifySubject = new Subject<string>();

  /** Number of requests currently executing subject */
  private readonly activeRequestsCountSubject = new BehaviorSubject<number>(0);

  /** Doc property $features stream */
  readonly docFeatures$ = this.docFeaturesSubject.asObservable();

  /** Doc property $notify stream */
  readonly docNotify$ = this.docNotifySubject.asObservable();

  /** Number of requests currently executing stream */
  readonly activeRequestsCount$ =
    this.activeRequestsCountSubject.asObservable();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly pageInfoService: PageInfoService,
  ) {}

  /**
   * Convert params to FormData for upload files
   *
   * @param params
   */
  private convertToFormData(params: IHttpParam): FormData {
    const formData = new FormData();
    Object.keys(params).forEach(key => {
      const value =
        (params[key] as any[] | FileList | string | { name: string }) || '';

      // check on filelist
      if (typeof value[0] === 'object') {
        if ((value as FileList).length > 1) {
          for (const file of value as FileList) {
            formData.append(`${key}[]`, file);
          }
        } else {
          formData.append(`${key}`, value[0]);
        }
      } else if (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        typeof (value as any).name === 'string'
      ) {
        // что тут происходит!?
        formData.append(key, value as any, (value as any).name);
      } else {
        formData.append(key, value as string);
      }
    });
    return formData;
  }

  /**
   * Handle error for event base http request
   *
   * @param error - error object
   */
  private handleErrorEvent(
    error: HttpErrorResponse,
  ): Observable<HttpEvent<any>> {
    if (error.status === HTTP_CODE_SERVICE_UNAVAILABLE) {
      const url = error.error;
      return this.get503Event(url);
    }
    // errorEmitter handle may be here
    return throwError(error);
  }

  /**
   * Request for get response of 503 error(long request)
   *
   * @param stringParam - request params
   */
  private get503Event(stringParam: string): Observable<HttpEvent<any>> {
    const url = `/${stringParam}`;
    return this.httpClient
      .get(url, {
        headers: this.forJsonOutputHeaders,
        observe: 'events',
      })
      .pipe(
        catchError(error => this.handleErrorEvent(error)),
        share(),
      );
  }

  /**
   * Error handler
   *
   * @param error - error object
   */
  private handleError<R extends IDocumentBase = IDocumentBase>(
    error: HttpErrorResponse,
  ): Observable<IFuncResponse<R>> {
    if (error.status === HTTP_CODE_SERVICE_UNAVAILABLE) {
      const url = error.error;
      // handle 503 error
      return this.get503<R>(url);
    }
    // errorEmitter handle may be here
    return throwError(error);
  }

  /**
   * Request for get response of 503 error(long request)
   *
   * @param stringParam - request params
   */
  private get503<R extends IDocumentBase = IDocumentBase>(
    stringParam: string,
  ): Observable<IFuncResponse<R>> {
    const url = `/${stringParam}`;

    return this.httpClient
      .get<IFuncResponse<R>>(url, { headers: this.forJsonOutputHeaders })
      .pipe(
        catchError(error => this.handleError<R>(error)),
        share(),
      );
  }

  /**
   * Returns `doc` key value from request's response
   */
  private getDocument<K extends IDocumentBase>(): OperatorFunction<
    IFuncResponse<K>,
    K
  > {
    return pipe(map(meta => (meta.doc ? meta.doc : (meta as unknown as K))));
  }

  /**
   * Update doc $notify identifier
   */
  private updateDocNotify<K extends IDocumentBase>(): OperatorFunction<K, K> {
    return pipe(tap(doc => this.docNotifySubject.next(doc.$notify)));
  }

  /**
   * Update doc features and check the need for reloading page
   */
  private updateDocFeatures<K extends IDocumentBase>(): OperatorFunction<K, K> {
    return pipe(tap(doc => this.docFeaturesSubject.next(doc.$features)));
  }

  /**
   * Convert to HttpParams with encode
   *
   * @param params - request params
   */
  private convertParamsToHttpParams(params: IHttpParam): HttpParams {
    let httpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    Object.keys(params).forEach(key => {
      httpParams = httpParams.set(key, params[key]);
    });
    return httpParams;
  }

  /**
   * Create observable with the mechanism of registration of requests to the server
   * (new request to the server is initiated - the count of requests increases,
   * request completes - the count of requests decreases)
   *
   * @param request
   */
  private addRegistrationNumberOfRequests<T>(
    request: Observable<T>,
  ): Observable<T> {
    return new Observable<T>(observer => {
      this.activeRequestsCountSubject.next(
        this.activeRequestsCountSubject.value + 1,
      );
      const subscribeToRequest = request
        .pipe(
          finalize(() =>
            this.activeRequestsCountSubject.next(
              this.activeRequestsCountSubject.value - 1,
            ),
          ),
        )
        .subscribe(observer);

      return () => subscribeToRequest.unsubscribe();
    });
  }

  /**
   * Get request
   *
   * @param params - request params
   * @param headers - object with headers
   */
  get<
    R extends IDocumentBase = IDocumentBase,
    D extends IHttpParam = IHttpParam,
  >(params: D, headers?: HttpHeaders): Observable<R> {
    const requestFunction = this.httpClient.get<IFuncResponse<R>>(
      this.mgrHost,
      {
        params: this.convertParamsToHttpParams({
          ...this.outputFormatParam,
          ...params,
        }),
        headers: { ...this.forJsonOutputHeaders, ...headers },
      },
    );
    const requestObservable =
      this.addRegistrationNumberOfRequests(requestFunction);

    return requestObservable.pipe(
      catchError(err => this.handleError<R>(err)),
      this.getDocument(),
      this.updateDocFeatures(),
      this.updateDocNotify(),
      share(),
    );
  }

  /**
   * Post request
   *
   * @param params - request params
   * @param action - api handler path
   * @param headers - object with headers
   */
  post<
    R extends IDocumentBase = IDocumentBase,
    D extends IHttpParam = IHttpParam,
  >(params: D, action?: string, headers?: HttpHeaders): Observable<R> {
    const requestFunction = this.httpClient.post(
      action || this.mgrHost,
      this.convertParamsToHttpParams({
        ...this.outputFormatParam,
        ...params,
      }),
      { headers: { ...this.forJsonOutputHeaders, ...headers } },
    );
    const requestObservable =
      this.addRegistrationNumberOfRequests(requestFunction);

    return requestObservable.pipe(
      catchError(err => this.handleError<R>(err)),
      this.getDocument(),
      this.updateDocFeatures(),
      this.updateDocNotify(),
      share(),
    );
  }

  /**
   * Post request with observe. Usually used for uploading media files to server
   *
   * @param params - request params
   * @param action - api handler path
   */
  uploadFile(params: IHttpParam, action?: string): Observable<HttpEvent<any>> {
    const requestFunction = this.httpClient.post(
      action || this.mgrHost,
      this.convertToFormData({ ...params, ...this.outputFormatParam }),
      {
        reportProgress: true,
        observe: 'events',
        headers: { ...this.forJsonOutputHeaders, 'ngsw-bypass': '' },
      },
    );
    const requestObservable =
      this.addRegistrationNumberOfRequests(requestFunction);

    return requestObservable.pipe(
      filter(event =>
        [HttpEventType.UploadProgress, HttpEventType.Response].includes(
          event.type,
        ),
      ),
      catchError(err => this.handleErrorEvent(err)),
      share(),
    );
  }
}
