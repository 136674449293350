import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { IFormButtonUi } from 'components/form-button';

import { PopupPlacement } from '@ispui/tooltip';

@Component({
  selector: 'isp-form-buttons',
  templateUrl: './form-buttons.component.html',
  styleUrls: ['./scss/form-buttons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormButtonsComponent {
  /** Buttons metadata */
  @Input() buttons: IFormButtonUi[];

  /** Button hint attach container selector */
  @Input() hintAttachSelector: string;

  /** Button hint placement order */
  @Input() hintPlacementOrder: PopupPlacement[];

  /** Button click event */
  @Output() readonly btnClick = new EventEmitter<IFormButtonUi>();

  /**
   * TrackBy function for buttons
   *
   * @param _ - button index
   * @param button - button object
   */
  trackByButtonFn(_: number, button: IFormButtonUi): string {
    return button.name;
  }
}
