import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Component for using icons in app
 */
@Component({
  selector: 'isp-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./scss/svg-icon.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent {
  /** Icon name */
  @Input() name: string;

  /** Custom styles for icon */
  @Input() customStyle: {};
}
