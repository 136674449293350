import {
  IDocument,
  IField,
  ISelect,
  ISelectOption,
  SelectType,
} from 'app/services/api5-service/api.interface';

import { IFormModel } from 'common/dynamic-form/dynamic-form.interface';
import { getLabel } from 'common/dynamic-form/utils/get-label';
import { DocHelper } from 'utils/dochelper';

import { SelectTO } from './model/select-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';
import { FIELD_HIDDEN_CLASS } from '../../utils/class-fields';

/**
 * Check select visibility by select hiding logic
 *
 * @param formState - form state
 * @param control - control (subfield) metadata
 * @param field - field config
 * @param options - original select options
 */
export function isSelectHidden(
  formState: ISPFormState,
  control: ISelect,
  field: IField,
  options: ISelectOption[],
): boolean {
  const controlName = control.$name;
  const fieldName = field.$name;
  const isDepend = Boolean(control.$depend);

  const isHiddenByCondition = formState.hiddenService.isHidden(
    controlName,
    fieldName,
  );
  const isSelectEmpty = !options.length;
  const isSelectDependAndOnlyNullIsLeft =
    isDepend && options.length === 1 && options[0].$key === 'null';
  return (
    isHiddenByCondition || isSelectEmpty || isSelectDependAndOnlyNullIsLeft
  );
}

/**
 * Get depend select options
 *
 * @param options - original select options
 * @param model - form model
 * @param fieldConfig - field config
 */
export function getDependSelectOptions(
  options: ISelectOption[],
  model: IFormModel,
  fieldConfig: ISPFieldConfig<ISPFieldType.Radio | ISPFieldType.Select>,
): ISelectOption[] {
  const dependField = fieldConfig.templateOptions.depend;
  const dependValue = model[dependField];
  if (!dependValue) {
    return [];
  }
  const newOptionList = options.filter(
    o => o.$depend === undefined || o.$depend === dependValue,
  );
  return newOptionList;
}

/**
 * Autoselect first option from all select options
 *
 * @param options - select options
 * @param model - form model
 * @param fieldConfig - field config
 * @param allowNull - allow autoslect 'null' as first option. Extform license.order and handlers table filter case
 */
export function autoselectSelectOptions(
  options: ISelectOption[],
  model: IFormModel,
  fieldConfig: ISPFieldConfig<
    | ISPFieldType.Radio
    | ISPFieldType.RadioImg
    | ISPFieldType.Select
    | ISPFieldType.Switcher
  >,
  allowNull?: boolean,
): void {
  if (
    fieldConfig.templateOptions.isHidden ||
    fieldConfig.templateOptions.disabled
  ) {
    return;
  }

  const value = model[fieldConfig.key];

  let valueArray: string[];
  if (Array.isArray(value)) {
    valueArray = value;
  } else {
    // @WARN you should split options only for multiple select!
    // There can be simple select with options, that contain ',' in key
    if (
      fieldConfig.type === ISPFieldType.Select &&
      fieldConfig.templateOptions.multiple
    ) {
      valueArray = value ? String(value).split(',') : [];
    }
    valueArray = value ? [String(value)] : [];
  }

  const currentValueIsInOptions = valueArray.every(v =>
    options.some(o => o.$key === v),
  );

  if (currentValueIsInOptions) {
    return;
  }
  // ...mean we must change formControl value, if there is no such options

  const haveOptionsToChoose = options.length > 0;
  const isNotSelectOrSingleSelect =
    fieldConfig.type === ISPFieldType.Radio ||
    fieldConfig.type === ISPFieldType.RadioImg ||
    fieldConfig.type === ISPFieldType.Switcher ||
    !fieldConfig.templateOptions.multiple;

  // single select automatically select first available option
  if (isNotSelectOrSingleSelect && haveOptionsToChoose) {
    const firstOptionValue = options[0].$key;
    const firstOptionIsNotNull = firstOptionValue !== 'null';
    const isOptionValueDistinct = firstOptionValue !== value;

    if (isOptionValueDistinct && (firstOptionIsNotNull || allowNull)) {
      fieldConfig.formControl.setValue(firstOptionValue, {
        emitEvent: true,
      });
      return;
    }
  }

  fieldConfig.formControl.setValue('', {
    emitEvent: true,
  });
}

/**
 * Get config for select field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getSelectConfig(
  control: ISelect,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.Select> {
  const options = state.selectService.getOptionsForSelect(control.$name) || [];
  const templateOptions: SelectTO = {
    inputLabel: getLabel(field, state.doc),
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    options: options,
    multiple: control.$type === SelectType.Multiple,
    depend: control.$depend,
    // why 'null' !?
    placeholder: DocHelper.getMessage('null', state.doc),
    notFoundText: DocHelper.getMessage('select_option_not_found', state.doc),
    selectAllText: DocHelper.getMessage('msg_select_all', state.doc),
    hintPlace: 'field',
    mixedPlaceholder: DocHelper.getMessage('placeholder_mixed_msg', state.doc),
    isMixed: state.mixedService.isControlMixed(control),
    listWidth: state.selectDropdownWidth,
    isPrefixForInput: false,
    anchorWidth: state.selectAnchorWidth,
    isHidden: isSelectHidden(state, control, field, options),
  };

  return {
    key: control.$name,
    type: ISPFieldType.Select,
    wrappers: [ISPFieldWrapper.InputBase, ISPFieldWrapper.ValidationError],
    templateOptions,
    modelOptions: {
      updateOn: 'change',
    },
    expressionProperties: {
      className: (_, __, fieldConfig) =>
        fieldConfig.templateOptions.isHidden ? FIELD_HIDDEN_CLASS : '',
      'templateOptions.isHidden': (_, formState, fieldConfig) =>
        isSelectHidden(
          formState,
          fieldConfig.templateOptions.originalControl,
          fieldConfig.templateOptions.originalField,
          fieldConfig.templateOptions.options,
        ),
      'templateOptions.isMixed': (_, formState) =>
        formState.mixedService.isControlMixed(control),
      'templateOptions.options': (model, formState, fieldConfig) => {
        const selectOptions =
          formState.selectService.getOptionsForSelect(control.$name) || [];

        const isSelectDepends = Boolean(fieldConfig.templateOptions.depend);

        if (isSelectDepends) {
          const dependOptions = getDependSelectOptions(
            selectOptions,
            model,
            fieldConfig,
          );
          autoselectSelectOptions(dependOptions, model, fieldConfig, true);

          return dependOptions;
        } else {
          autoselectSelectOptions(selectOptions, model, fieldConfig);

          return selectOptions;
        }
      },
    },
  };
}

/**
 * Wrap text with span, that has style font family
 *
 * @param text - text to wrap
 * @param font - font
 */
export function wrapTextWithFont(text: string, font: string) {
  return `<span style="font-family: ${font}">${text}</span>`;
}

/**
 * Add span that has image background-image, and wrap it with span
 *
 * @param text - text to wrap
 * @param imageURL - image url
 */
export function wrapTextWithImage(text: string, imageURL: string) {
  const imageTemplate = `<span class="select__option-image" style="background-image: url('${imageURL}');"></span>`;

  return `<span class="select__option-container">${imageTemplate}${text}</span>`;
}

/**
 * Get the html template of an option for when the option has an image and when it doesn't, or has custom font
 *
 * @param option - select option data from doc.slist
 * @param optionText - option text, which may contain html as search result among options
 * @param doc - form doc
 * @param hasFont - is BE send font
 */
export function getHTMLTemplateForOption(
  option: ISelectOption,
  optionText: string,
  doc: IDocument,
  hasFont = false,
): string {
  let htmlTextTemplate = optionText;

  if (hasFont) {
    htmlTextTemplate = wrapTextWithFont(
      htmlTextTemplate,
      (option as ISelectOption).$key,
    );
  }

  if (!('$image' in option) && htmlTextTemplate) {
    return htmlTextTemplate;
  }

  const htmlOptionTemplate = wrapTextWithImage(htmlTextTemplate, option.$image);

  return htmlOptionTemplate;
}

/**
 * Get the html template of button for when the option has an image and when it doesn't, or has custom font
 *
 * @param option - select option data from doc.slist
 * @param optionText - option text, which may contain html as search result among options
 * @param doc - form doc
 * @param hasFont - is BE send font
 */
export function getHTMLTemplateForButton(
  option: ISelectOption | ISelectOption[] | undefined,
  optionText: string,
  doc: IDocument,
  hasFont = false,
): string {
  if (!option) {
    return DocHelper.getMessage('null', doc);
  }

  let htmlTextTemplate = optionText;

  if (Array.isArray(option)) {
    htmlTextTemplate = option
      .map(o => (hasFont ? wrapTextWithFont(o.$, o.$key) : o.$))
      .join(',');

    return htmlTextTemplate;
  }

  if (hasFont) {
    htmlTextTemplate = wrapTextWithFont(
      htmlTextTemplate,
      (option as ISelectOption).$key,
    );
  }

  if (!('$image' in option) && htmlTextTemplate) {
    return htmlTextTemplate;
  }

  const htmlOptionTemplate = wrapTextWithImage(htmlTextTemplate, option.$image);

  return htmlOptionTemplate;
}
