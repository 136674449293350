import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Option } from '@ispui/switcher/dist/custom-elements';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Switcher field component
 *
 * Use only with Formly
 */
@Component({
  selector: 'isp-formly-switcher-field',
  templateUrl: './switcher.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitcherFieldComponent extends ISPFieldTypeBase<ISPFieldType.Switcher> {
  get optionsUi(): Option[] {
    return this.to.options.map(option => ({
      text: option.$,
      value: option.$key,
    }));
  }
}
