import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';
import { ISPDatePickerModule } from 'components/date-picker';

import { DatePickerFieldComponent } from './date-picker.component';

import { ISPFieldType } from '../../model';

/**
 * Formly's input field component
 */
@NgModule({
  declarations: [DatePickerFieldComponent],
  exports: [DatePickerFieldComponent],
  imports: [
    ReactiveFormsModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.DatePicker,
          component: DatePickerFieldComponent,
        },
      ],
    }),
    ISPDatePickerModule,
  ],
})
export class DatePickerFieldModule {}
