import {
  FormButtonType,
  IDocument,
  IFormButton,
} from 'app/services/api5-service/api.interface';
import { BehaviorSubject } from 'rxjs';

import { ThemePalette } from '@ispui/button';

import { DocHelper } from 'utils/dochelper';

import { IFormButtonUi } from './model/form-button.interface';

/** List of button types that can be used to submit the form */
const SUBMIT_BUTTON_TYPE_LIST: FormButtonType[] = [
  FormButtonType.Ok,
  FormButtonType.Next,
  FormButtonType.Submit,
  FormButtonType.Blank,
];

/**
 * Is it a button to submit the form or not
 *
 * @param button - button meta
 */
function isSubmitButton(button: IFormButton): boolean {
  return SUBMIT_BUTTON_TYPE_LIST.includes(button.$type);
}

/**
 * Is it a button to submit the form or not
 *
 * @param button - button meta
 */
function isSubmitButtonUi(button: IFormButtonUi): boolean {
  return SUBMIT_BUTTON_TYPE_LIST.includes(button.type);
}

/**
 * Is it a button with delete action
 *
 * @param button - button meta
 */
function isDeleteButton(button: IFormButton): boolean {
  // @HACK this button have special displaying in ispmgr
  return button.$name === 'delete';
}

/**
 * Is it a button for busket cleaning
 *
 * @param button - button meta
 */
function isCleanBusketButton(button: IFormButton): boolean {
  // @HACK this button have special displaying in billmgr
  return button.$name === 'clearbasket';
}

/**
 * Is it a button with settings action
 *
 * @param button - button meta
 */
function isSettingButton(button: IFormButton): boolean {
  // @HACK this button have special displaying in ispmgr
  return button.$name === 'settings';
}

/**
 * Is it a button cancel action
 *
 * @param button - button meta
 */
function isCancelButton(button: IFormButton): boolean {
  return button.$type === FormButtonType.Cancel || button.$name === 'cancel';
}

/**
 * Get msg from doc
 *
 * @param doc - doc instance
 * @param code - msg code
 */
function getMsg(doc: IDocument, code: string): string {
  return DocHelper.getMessage(code, doc);
}

/**
 * Get button title
 *
 * @param doc - doc instance
 * @param button - button meta
 */
function getButtonLabel(doc: IDocument, button: IFormButton): string {
  return isDeleteButton(button) || isSettingButton(button)
    ? ''
    : getMsg(doc, `msg_${button.$name}`);
}

/**
 * Get hint for button
 *
 * @param doc - doc instance
 * @param name - button name
 * @param forDisabledState - is hint for button disabled state
 */
function getHint(
  doc: IDocument,
  name: string,
  forDisabledState: boolean,
): string {
  const suffix = forDisabledState ? '_disabled' : '';
  return getMsg(doc, `hint_${name}${suffix}`);
}

/**
 * Get button display type
 *
 * @param button - button metadata
 */
function getButtonDisplayType(
  button: IFormButton,
): IFormButtonUi['displayType'] {
  // @HACK only 'cancel' button displayed as a link
  return isCancelButton(button) || button.$theme === 'link' ? 'link' : 'button';
}

/**
 * Get button theme
 *
 * @param button - button metadata
 * @param buttons - all buttons
 */
function getButtonTheme(
  button: IFormButton,
  buttons: IFormButton[],
): ThemePalette {
  if (['yellow'].includes(button.$color)) {
    return 'accent';
  }

  if (isCleanBusketButton(button)) {
    return 'fourth';
  }

  const isInstallButton = button.$name === 'install';
  const isThereSomeBuyButton = buttons.some(btn => btn.$name === 'buy');
  // @HACK for ispmgr modules install page
  const specialIspMgrCase = isInstallButton && isThereSomeBuyButton;

  if (
    isDeleteButton(button) ||
    isSettingButton(button) ||
    specialIspMgrCase ||
    !isSubmitButton(button) ||
    button.$theme === 'sixth'
  ) {
    return 'sixth';
  }

  return 'primary';
}

/**
 * Get icon for button or link
 *
 * @param button - button metadata
 */
function getIcon(button: IFormButton): string | undefined {
  // @HACK use certain button icons depending on button names
  if (isDeleteButton(button) || isCleanBusketButton(button)) {
    return 't-busket';
  } else if (isSettingButton(button)) {
    return 'p-attr';
  }
  return button.$icon;
}

/**
 * Get button color
 *
 * @param button - button mapped metadata
 */
function getButtonColor(button: IFormButton): 'accent' | undefined {
  // @HACK we need to use 'accent' color for this icon, cause it has 'red' color
  if (isDeleteButton(button) || isCleanBusketButton(button)) {
    return 'accent';
  }
  return undefined;
}

/**
 * Create form button object for button component
 *
 * @param options - options for button
 */
export function createButton(
  options: Partial<
    Omit<IFormButtonUi, 'disabledSubject' | 'id' | 'preloaderSubject'>
  > & { disabled?: boolean },
): IFormButtonUi {
  return {
    id: Symbol('buttonId'),
    preloaderSubject: new BehaviorSubject(false),
    disabledSubject: new BehaviorSubject(options.disabled),
    name: options.name,
    displayType: options.displayType,
    label: options.label,
    type: options.type,
    act: options.act,
    blocking: options.blocking,
    icon: options.icon,
    offset: options.offset,
    hint: options.hint,
    func: options.func,
    keepform: options.keepform,
    key: options.key,
    buttonTheme: options.buttonTheme,
    originalDisabled: options.originalDisabled,
  };
}

/**
 * Get button list for dynamic-form footer or for buttons in list field row
 *
 * @param doc - doc instance
 * @param buttons - buttons metadata
 * @param showHints - is hints can be shown
 * @param isDisableSubmitButton - disable submit button
 */
export function getButtonList(
  doc: IDocument,
  buttons: IFormButton[],
  showHints: boolean,
  isDisableSubmitButton?: boolean,
): IFormButtonUi[] {
  return buttons.map(button => {
    const btn: IFormButtonUi = createButton({
      name: button.$name,
      label: getButtonLabel(doc, button),
      displayType: getButtonDisplayType(button),
      icon: getIcon(button),
      disabled: button.$disabled === 'yes',
      offset: button.$offset === 'yes',
      keepform: button.$keepform,
      type: button.$type,
      func: button.$func,
      blocking: button.$blocking,
      act: button.$act,
      key: button.$key,
      originalDisabled: button.$disabled === 'yes',
    });

    if (isDisableSubmitButton && isSubmitButton(button)) {
      btn.disabledSubject.next(true);
    }

    if (btn.displayType === 'button') {
      btn.buttonTheme = getButtonTheme(button, buttons);
      btn.buttonColor = getButtonColor(button);
    }

    if (showHints) {
      btn.hint = getHint(doc, button.$name, false);
      btn.hintDisabled = getHint(doc, button.$name, true);
    }

    if (doc.metadata?.form?.$target) {
      btn.act = doc.metadata.form.$target;
    }

    return btn;
  });
}

/**
 * Get the form submit button from the general list of buttons
 *
 * @param buttonList - list of buttons to search for form submit button
 */
export function getFirstSubmitButton(
  buttonList: IFormButtonUi[],
): IFormButtonUi | undefined {
  return buttonList.find(button => isSubmitButtonUi(button));
}

/**
 * Get the list of form submit button from the general list of buttons
 *
 * @param buttonList - list of buttons to search for form submit button
 */
export function getSubmitButtonsList(
  buttonList: IFormButtonUi[],
): IFormButtonUi[] {
  return buttonList.filter(button => isSubmitButtonUi(button));
}

/**
 * Set the new disable state of the form submit button
 *
 * @param buttonList - list of buttons to search for form submit button
 * @param newState - the button will be disabled or not
 */
export function setSubmitButtonDisableState(
  buttonList: IFormButtonUi[],
  newState: boolean,
): void {
  const submitButtonsList = getSubmitButtonsList(buttonList);
  for (const submitButton of submitButtonsList) {
    submitButton.disabledSubject.next(newState);
  }
}
