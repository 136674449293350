import { IField, ISelect } from 'app/services/api5-service/api.interface';

import { getLabel } from 'common/dynamic-form/utils/get-label';

import { AutocompleteSelectTO } from './model/autocomplete-select-to.interface';

import { ISPFieldConfig, ISPFieldType, ISPFieldWrapper } from '../../model';
import { ISPFormState } from '../../model/form-state.interface';

/**
 * Check that autocomplete select value is search value and not some selected option
 *
 * @param field - autocomplete select field config
 * @param state
 */
export function isSelectedKeyValue(
  field: ISPFieldConfig<ISPFieldType.AutocompleteSelect>,
  state: ISPFormState,
): boolean {
  // autocomplete select emits as options keys as search value
  // the only way to determine which one was emited - check for that option to exist
  const options =
    state.selectService.getOptionsForSelect(
      field.templateOptions.originalControl.$name,
    ) || [];
  const value = field.formControl.value;
  return options.some(o => o.$key === value);
}

/**
 * Get config for autocomplete select field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getAutocompleteConfig(
  control: ISelect,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.AutocompleteSelect> {
  const templateOptions: AutocompleteSelectTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    inputLabel: getLabel(field, state.doc),
    options: state.selectService.getOptionsForSelect(control.$name) || null,
    hintPlace: 'field',
  };

  return {
    key: control.$name,
    type: ISPFieldType.AutocompleteSelect,
    wrappers: [ISPFieldWrapper.InputBase],
    templateOptions,
    expressionProperties: {
      'templateOptions.options': (_, formState, __) => {
        // @WARN
        // Do not use the dependent fields mechanism as it works for the select component,
        // because the options are generated on the BE and they are already filtered
        // Therefore, you do not need to pay attention to the depend flag for the autocomplete field
        return (
          formState.selectService.getOptionsForSelect(control.$name) || null
        );
      },
    },
    modelOptions: {
      updateOn: 'change',
    },
  };
}
