import { BrandingPreviewData } from 'app/branding/model/branding-preview-data.interface';
import { MainMenuFilter as MenuFilter } from 'app/layout/side-bar/side-bar.interface';
import { TableSettings } from 'app/list/table/view-mode-button/view-mode.service';

import { AceEditorOptions } from 'common/dynamic-form/types/code/code.interface';

import { StoredTabsState } from '../tab';

/** Declared local storage keys */
export enum LocalStorageKey {
  BrandingPreviewModel = 'isp_branding_preview_model',
  MainMenuState = 'isp_main_menu_state',
  MainMenuFilter = 'isp_main_menu_filter',
  Tablist = 'isp_tab-list_V2',
  CodeEditorSettings = 'isp_code_editor_settings',
  TablesSettings = 'isp_tables_settings',
}

export interface LocalStorageValue {
  [LocalStorageKey.BrandingPreviewModel]: Record<string, BrandingPreviewData>;
  [LocalStorageKey.MainMenuState]: Record<string, boolean>;
  [LocalStorageKey.MainMenuFilter]: {
    filterBy: MenuFilter;
  };
  [LocalStorageKey.Tablist]: StoredTabsState;
  [LocalStorageKey.CodeEditorSettings]: Partial<AceEditorOptions>;
  [LocalStorageKey.TablesSettings]: TableSettings;
}
