import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Option, SelectBuilderParams, SelectEvent } from '@ispui/select';

import {
  DYNAMIC_FORM_HEADER_SELECTOR,
  DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR,
} from 'common/dynamic-form/services/layout.service';
import { prefix } from 'common/form-id-prefix/form-id-prefix.utils';

import {
  getHTMLTemplateForButton,
  getHTMLTemplateForOption,
} from './select.utils';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

const COLORS = {
  yellow: '#f2a504',
  blue: '#0279c0',
  red: '#e54993',
  green: '#1d9052',
  cyan: '#00bbbb',
};

/**
 * Select field component
 *
 * Use only with Formly
 */
@Component({
  selector: 'isp-formly-select-field',
  templateUrl: './select.component.html',
  styleUrls: ['./scss/select.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFieldComponent
  extends ISPFieldTypeBase<ISPFieldType.Select>
  implements OnInit
{
  private readonly selectBuilderParamsSubject: BehaviorSubject<SelectBuilderParams | null> =
    new BehaviorSubject(null);

  /** Dropdown open state with a list of select options */
  private isDropdownOpen = false;

  /** Flag of whether the selected option has an image */
  private isImageOptionSelected: boolean;

  /** Whether to hide the text on the open/close dropdown button of the select */
  hideTextInSelectButton: boolean;

  /** Color of selected option */
  selectedColor: string;

  /** Select builder params */
  selectBuilderParams$ = this.selectBuilderParamsSubject.pipe(
    filter(params => Boolean(params)),
  );

  get optionsUI(): Option[] {
    const to = this.to.options;
    return (
      to?.map(option => {
        const htmlFunc: Option['html'] = optionUI => {
          return getHTMLTemplateForOption(
            option,
            optionUI.textHtml,
            this.formState.doc,
            Boolean(
              this.field.templateOptions.originalControl.$targetfontfamilyview,
            ),
          );
        };

        return {
          text: option.$,
          value: option.$key,
          color: COLORS[option.$color],
          html: htmlFunc,
        };
      }) || []
    );
  }

  /**
   * Update text hide state on select button
   */
  private updateButtonTextState(): void {
    this.hideTextInSelectButton =
      this.to.isPrefixForInput &&
      this.isImageOptionSelected &&
      !this.isDropdownOpen;
  }

  ngOnInit(): void {
    const buttonTextFuncHTML: SelectBuilderParams['buttonTextFuncHTML'] = (
      state,
      _,
      buttonText,
    ) => {
      const selectedOption = Array.isArray(state.value)
        ? this.to.options.filter(selectOption =>
            state.value.includes(selectOption.$key),
          )
        : this.to.options.find(
            selectOption => selectOption.$key === state.value,
          );
      return getHTMLTemplateForButton(
        selectedOption,
        buttonText,
        this.formState.doc,
        Boolean(
          this.field.templateOptions.originalControl.$targetfontfamilyview,
        ),
      );
    };

    // @WARN If you update this config don't forget to update select config in login form!!!
    this.selectBuilderParamsSubject.next({
      legend: this.to.inputLabel,
      id: this.id,
      placeholder: this.to.placeholder,
      width: this.to.anchorWidth,
      maxWidth: 500,
      notFoundText: this.to.notFoundText,
      searchCount: 10,
      toggle: true,
      toggleOnText: this.to.selectAllText,
      toggleOffText: this.to.selectAllText,
      reflectColor: true,
      buttonTextFuncHTML,
      $dropdown: (() => {
        const dropdownParams: SelectBuilderParams['$dropdown'] = {
          popupClass: 'isp-dynamic-form-select-dropdown-popup',
        };

        switch (true) {
          case Boolean(this.formState.dropdownParentSelector):
            dropdownParams.parentSelector = prefix(
              this.formState.layoutService,
              this.formState.dropdownParentSelector,
            );
            break;
          case this.to.layoutPlace === 'header':
            dropdownParams.parentSelector = prefix(
              this.formState.layoutService,
              DYNAMIC_FORM_HEADER_SELECTOR,
            );
            // @TODO i.ablov remove 'any' by adding this properties to ispui/select DropdownSettings interface
            (dropdownParams as any).direction = 'bottom';
            (dropdownParams as any).strictDirection = true;
            break;
          case this.to.layoutPlace === 'body':
            dropdownParams.parentSelector = prefix(
              this.formState.layoutService,
              DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR,
            );
            break;
        }

        return dropdownParams;
      })(),
      $button: {
        style: {
          /* eslint-disable @typescript-eslint/naming-convention */
          '--ispui-select-button-legend__width':
            'var(--ispui-input__width, 400px)',
          '--ispui-select-button__width': 'var(--ispui-input__width, 400px)',
          '--ispui-select-button-legend__pl': '15px',
          '--ispui-select-button__pl': '15px',
          /* eslint-enable @typescript-eslint/naming-convention */
        },
      },
      $list: {
        style: {
          marginRight: '0px',
          paddingRight: '10px',
          // @WARN '-10px' because paddingRight above
          'min-width': `calc(${this.to.listWidth} - 10px)`,
          'max-width': `calc(${this.to.listWidth} - 10px)`,
          /* eslint-disable @typescript-eslint/naming-convention */
          '--ispui-select-list__item-height': '35px',
          '--ispui-select-list__max-height': '280px',
          '--ispui-select-list__item-br': '3px',
          '--ispui-select-list__item-checkbox-pl': '15px',
          /* eslint-enable @typescript-eslint/naming-convention */
        },
      },
      $search: {
        style: {
          padding: '10px 10px',
          margin: '0',
        },
      },
      $toggle: {
        style: {
          /* eslint-disable @typescript-eslint/naming-convention */
          '--ispui-select-toggle__checkbox-pl': '15px',
          /* eslint-enable @typescript-eslint/naming-convention */
          padding: '5px 0px 15px 20px',
        },
      },
    });
  }

  /**
   * Model change event handler
   *
   * @param newValue - select's new value
   */
  onModelChange(newValue: any): void {
    if (newValue === undefined && this.field.defaultValue !== undefined) {
      this.formControl.setValue(this.field.defaultValue, {
        emitEvent: false,
      });
    }

    const selectedOption = this.to.options.find(op => op.$key === newValue);

    if (selectedOption) {
      this.isImageOptionSelected = '$image' in selectedOption;
    }
    this.updateButtonTextState();

    this.selectedColor = selectedOption?.$color;
  }

  /**
   * Get mixed placeholder to insert in content
   */
  getMixedPlaceholder(): string {
    return `"${this.to.mixedPlaceholder}"`;
  }

  toggleFocus(isFocused = false): void {
    this.to.isFocused = isFocused;
  }

  /**
   * Check if dropdown is open
   *
   * @param event - select event
   */
  checkDropdownOpen(event: CustomEvent<SelectEvent>): void {
    const eventsWithOpenDropdown = ['dropdown-open', 'search'];
    this.isDropdownOpen = eventsWithOpenDropdown.includes(event.detail.name);
    this.updateButtonTextState();
  }
}
