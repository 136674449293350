import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { ChatBubbleComponent } from './chat-bubble.component';
import { ChatFilesModule } from './chat-files/chat-files.module';
import { ChatRateModule } from './chat-rate/chat-rate.module';

@NgModule({
  declarations: [ChatBubbleComponent],
  imports: [CommonModule, ChatRateModule, ChatFilesModule, SvgIconModule],
  exports: [ChatBubbleComponent],
})
export class ChatBubbleModule {}
