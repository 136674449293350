<div
  class="container"
  [ngClass]="{
    container_right: previewName === 'right',
    container_center: previewName === 'center'
  }"
>
  <div class="background" [ngStyle]="backgroundStyles$ | async"></div>
  <div
    class="page"
    [ngClass]="{
      page_shadow: displayShadow$ | async
    }"
  >
    <ng-container [ngTemplateOutlet]="previewForm"></ng-container>
  </div>
</div>

<ng-template #previewForm>
  <svg
    width="92"
    height="162"
    viewBox="0 0 92 162"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.50586"
      y="77.9321"
      width="35.5055"
      height="12.7284"
      rx="3"
      fill="#1A76E2"
    />
    <rect
      x="41.6992"
      y="82.6216"
      width="35.5055"
      height="2.00975"
      rx="1.00487"
      fill="#1A76E2"
    />
    <rect
      x="1.50586"
      y="152.361"
      width="27.4665"
      height="2.00975"
      rx="1.00487"
      fill="#1F364A"
    />
    <rect
      x="31.6523"
      y="152.361"
      width="27.4665"
      height="2.00975"
      rx="1.00487"
      fill="#1F364A"
    />
    <rect
      x="31.6523"
      y="159.061"
      width="27.4665"
      height="2.00975"
      rx="1.00487"
      fill="#727272"
    />
    <rect
      x="63.8086"
      y="152.361"
      width="27.4665"
      height="2.00975"
      rx="1.00487"
      fill="#1F364A"
    />
    <path
      d="M4.18555 47.7861L82.5657 47.7861"
      stroke="#D9D9D9"
      stroke-width="7"
      stroke-linecap="round"
    />
    <path
      d="M4.18555 61.1846L82.5657 61.1846"
      stroke="#D9D9D9"
      stroke-width="7"
      stroke-linecap="round"
    />
    <path
      d="M4.18555 34.3877L82.5657 34.3877"
      stroke="#D9D9D9"
      stroke-width="7"
      stroke-linecap="round"
    />
    <path
      d="M28.3865 3.24741C27.6529 3.24741 27.0797 3.58293 26.7015 4.16141C26.3461 3.55979 25.7616 3.24741 24.9936 3.24741C24.4548 3.24741 24.0307 3.44409 23.6983 3.76804V3.3284H22.6094V8.90494H23.6983V5.79272C23.6983 4.75146 24.1453 4.34652 24.8216 4.34652C25.4635 4.34652 25.9793 4.7746 25.9793 5.86214V8.90494H27.0683V5.79272C27.0683 4.75146 27.5497 4.34652 28.226 4.34652C28.8564 4.34652 29.3378 4.7746 29.3378 5.86214V8.90494H30.4382V5.75801C30.4382 4.14984 29.6932 3.24741 28.3865 3.24741Z"
      fill="#D9D9D9"
    />
    <path
      d="M33.8328 3.23584C32.4 3.23584 31.7351 4.18455 31.5976 4.89029L32.5834 5.09854C32.7438 4.5085 33.2023 4.24239 33.7525 4.21926C34.2225 4.18455 34.8415 4.42751 34.7612 4.97128C34.7039 5.36465 34.0964 5.41092 33.3857 5.59604C32.251 5.89685 31.4944 6.2208 31.3683 7.31991C31.2652 8.2339 31.9644 9.10163 33.2597 8.98593C34.1193 8.90494 34.5893 8.48844 34.7956 8.24547V8.90494H35.8731V5.28366C35.8731 3.80275 34.9905 3.23584 33.8328 3.23584ZM34.7956 6.52161C34.7956 7.40089 33.9703 7.94466 33.4087 7.99094C32.8012 8.03722 32.5948 7.80583 32.5604 7.5513C32.5261 7.33148 32.5834 6.85712 33.5691 6.60259C34.3027 6.41748 34.7956 6.27864 34.7956 6.27864V6.52161Z"
      fill="#D9D9D9"
    />
    <path
      d="M39.8079 3.24741C39.2348 3.24741 38.7534 3.4788 38.3866 3.8606V3.3284H37.2976V8.90494H38.3866V5.79272C38.3866 4.75146 38.9253 4.34652 39.6016 4.34652C40.2435 4.34652 40.7593 4.7746 40.7593 5.86214V8.90494H41.8482V5.75801C41.8482 4.14984 41.1032 3.24741 39.8079 3.24741Z"
      fill="#D9D9D9"
    />
    <path
      d="M45.6344 3.23584C44.2015 3.23584 43.5367 4.18455 43.3992 4.89029L44.3849 5.09854C44.5454 4.5085 45.0039 4.24239 45.5541 4.21926C46.0241 4.18455 46.6431 4.42751 46.5628 4.97128C46.5055 5.36465 45.898 5.41092 45.1873 5.59604C44.0525 5.89685 43.296 6.2208 43.1699 7.31991C43.0668 8.2339 43.766 9.10163 45.0612 8.98593C45.9209 8.90494 46.3909 8.48844 46.5972 8.24547V8.90494H47.6747V5.28366C47.6747 3.80275 46.7921 3.23584 45.6344 3.23584ZM46.5972 6.52161C46.5972 7.40089 45.7719 7.94466 45.2102 7.99094C44.6027 8.03722 44.3964 7.80583 44.362 7.5513C44.3276 7.33148 44.385 6.85712 45.3707 6.60259C46.1043 6.41748 46.5972 6.27864 46.5972 6.27864V6.52161Z"
      fill="#D9D9D9"
    />
    <path
      d="M53.1778 3.3284V3.93002C52.7766 3.50194 52.2035 3.24741 51.4929 3.24741C49.8881 3.24741 48.9253 4.5432 48.9253 6.11667C48.9253 7.69013 49.8881 8.98593 51.4929 8.98593C52.2035 8.98593 52.7766 8.7314 53.1778 8.30332V8.4653C53.1778 9.76109 52.4901 10.409 51.5846 10.409C51 10.409 50.4612 10.1082 50.1861 9.65697L49.2691 10.2586C49.7391 11.0106 50.6561 11.5081 51.6189 11.5081C53.0288 11.5081 54.2782 10.6867 54.2782 8.36117V3.3284H53.1778ZM51.6304 7.88682C50.6332 7.88682 50.1174 7.07695 50.1174 6.11667C50.1174 5.15639 50.6217 4.34652 51.6304 4.34652C52.6276 4.34652 53.132 5.15639 53.132 6.11667C53.132 7.07695 52.6162 7.88682 51.6304 7.88682Z"
      fill="#D9D9D9"
    />
    <path
      d="M60.5542 6.67201C60.5886 6.4869 60.6 6.30178 60.6 6.11667C60.6 4.5432 59.7403 3.24741 58.1356 3.24741C56.5308 3.24741 55.4763 4.5432 55.4763 6.11667C55.4763 7.69013 56.5652 8.98593 58.17 8.98593C59.1901 8.98593 59.9811 8.4653 60.4281 7.67856L59.4309 7.19264C59.1787 7.60915 58.7546 7.88682 58.17 7.88682C57.3676 7.88682 56.8633 7.36619 56.7028 6.67201H60.5542ZM58.1356 4.34652C58.9609 4.34652 59.3621 4.90186 59.4767 5.63075H56.6799C56.8289 4.90186 57.3103 4.34652 58.1356 4.34652Z"
      fill="#D9D9D9"
    />
    <path
      d="M64.4738 3.23584C63.9007 3.23584 63.4193 3.46723 63.0525 3.84903V3.3284H61.9635V8.90494H63.0525V5.79272C63.0525 4.75146 63.6027 4.34652 64.2675 4.34652C64.428 4.34652 64.5655 4.36966 64.7031 4.42751L64.9094 3.28212C64.7718 3.25898 64.6228 3.23584 64.4738 3.23584Z"
      fill="#D9D9D9"
    />
    <path
      d="M11.9595 3.25306C11.2488 3.25306 10.6871 3.50759 10.2745 3.93567V0.811878H9.18555V8.91059H10.2745V8.30898C10.6871 8.73705 11.2488 8.99158 11.9595 8.99158C13.5642 8.99158 14.5271 7.69579 14.5271 6.12232C14.5271 4.54886 13.5757 3.25306 11.9595 3.25306ZM11.8334 7.89247C10.8361 7.89247 10.3318 7.0826 10.3318 6.12232C10.3318 5.16205 10.8247 4.35217 11.8334 4.35217C12.8306 4.35217 13.335 5.16205 13.335 6.12232C13.335 7.0826 12.8306 7.89247 11.8334 7.89247Z"
      fill="#606060"
    />
    <path
      d="M15.5912 8.91059H16.6802V3.33405H15.5912V8.91059ZM16.2166 2.64007C16.5604 2.64007 16.847 2.35083 16.847 1.99217C16.847 1.64508 16.5604 1.35584 16.2166 1.35584C15.8612 1.35584 15.5747 1.64508 15.5747 1.99217C15.5747 2.35083 15.8612 2.64007 16.2166 2.64007Z"
      fill="#606060"
    />
    <path
      d="M17.8614 0.233398V8.91059H18.9618V0.233398H17.8614Z"
      fill="#606060"
    />
    <path
      d="M20.224 0.233398V8.91059H21.3244V0.233398H20.224Z"
      fill="#606060"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.20312 3.32031C1.65084 3.32031 1.20312 3.76803 1.20312 4.32031V6.93329H8.18315V4.32031C8.18315 3.76803 7.73544 3.32031 7.18315 3.32031H2.20312ZM8.18315 7.73846H1.20312V7.75523C1.20312 8.30751 1.65084 8.75523 2.20313 8.75523H7.18315C7.73544 8.75523 8.18315 8.30751 8.18315 7.75523V7.73846Z"
      fill="#FFE03A"
    />
  </svg>
</ng-template>
