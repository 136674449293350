import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';
import { ExtfromPreviewModule } from 'app/branding/extform/preview/preview.module';

import { ISPUIRadioModule } from '@ispui/ng-radio';

import { RadioImgFieldComponent } from './radioimg.component';

import { ISPFieldType } from '../../model';

/**
 * Radio field module
 */
@NgModule({
  declarations: [RadioImgFieldComponent],
  imports: [
    CommonModule,
    ISPUIRadioModule,
    ReactiveFormsModule,
    ExtfromPreviewModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.RadioImg,
          component: RadioImgFieldComponent,
        },
      ],
    }),
  ],
})
export class RadioImgFieldModule {}
