import {
  IDocument,
  IFormSummary,
} from 'app/services/api5-service/api.interface';

/** Typed summary data is same as original backend metadata */
export type TypedSummary = IFormSummary;

/**
 * Map fields list to typed page with custom page metadata
 *
 * @param doc - doc
 */
export function getTypedSummary(doc: IDocument): TypedSummary | undefined {
  return doc.metadata?.summary;
}
