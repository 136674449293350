import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';

import { ChatService } from 'components/chat/chat.service';

import { IChatBubbleRate, IChatBubbleRates } from './model/chat-rate.interface';

import { IChatBubbleLabels } from '../model/chat-bubble.interface';

@Component({
  selector: 'isp-chat-rate',
  templateUrl: './chat-rate.component.html',
  styleUrls: ['./scss/chat-rate.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatRateComponent {
  @Input() id: string;

  @Input() rates: IChatBubbleRates;

  @Input() labels: IChatBubbleLabels;

  constructor(
    private readonly chatService: ChatService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  private setRate(rate: IChatBubbleRate): void {
    this.rates.isRated = true;
    this.rates.rates = this.rates.rates.filter(r => r.func === rate.func);
  }

  /**
   * Handle rate click event when message is not yet rated
   *
   * @param rate - rate data
   */
  handleRateClick(rate: IChatBubbleRate): void {
    if (this.rates.isRated) {
      return;
    }

    this.chatService.rateMessage(this.id, rate.func).subscribe(() => {
      this.setRate(rate);
      this.cdr.markForCheck();
    });
  }

  /**
   * TrackBy function for rates
   *
   * @param index - rate index
   * @param rate - rate
   */
  trackByRate(index: number, rate: IChatBubbleRate): string {
    return `${index}_${rate.img}`;
  }
}
