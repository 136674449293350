import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppService } from 'app/app.service';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  pairwise,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { TabPosition } from './model';
import { TabGroupService } from './tab-group.service';
import { Tab } from './tab.class';
import { getMenuGroupName, queryParamsToTabParams } from './tab.utils';

import { IDesktopMeta } from '../api5-service/api.interface';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ActiveTabService {
  private tabService: TabGroupService;

  /** Active tab subject */
  private readonly activeTabSubject = new BehaviorSubject<Tab | null>(null);

  /** Active tab stream */
  readonly activeTab$ = this.activeTabSubject.pipe(distinctUntilChanged());

  get activeTab(): Tab | null {
    return this.activeTabSubject.value;
  }

  constructor(
    private readonly roter: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly appService: AppService,
  ) {}

  /**
   * Start watching for route query params change in order to get tab to activate, or create new one tab to activate
   *
   * @WARN immediately create new tab after subscription, if there is no match of activeTab with tabs groups
   * @WARN start watching after desktop meta loaded, in order of properly tab creation
   */
  private watchRouterForActiveTab(): Observable<Tab> {
    return this.appService.desktop$.pipe(
      switchMap(desktop =>
        this.activatedRoute.queryParams.pipe(
          startWith(this.activatedRoute.snapshot.queryParams),
          // check for current active tab, in order to not emit redundant event
          withLatestFrom(this.activeTabSubject),
          map(([queryParams, activeTab]) => {
            // in order to prevent redirection on duplication tab
            const isActiveTab = activeTab?.matchQueryParams(queryParams);
            if (isActiveTab) {
              return null;
            }

            const existedTabs = this.tabService.groups.flat();

            const existedTab = existedTabs.find(tab =>
              tab.matchQueryParams(queryParams),
            );
            if (existedTab) {
              return existedTab;
            }

            const pinnedTab = existedTabs.find(tab => {
              const func =
                queryParams.func ||
                queryParams.startform ||
                queryParams.startpage;
              return tab.isPinned && tab.func === func;
            });
            if (pinnedTab) {
              return pinnedTab;
            }

            const newTab = this.createFromUrl(queryParams, desktop);
            if (newTab) {
              this.tabService.add(newTab);
              return newTab;
            }

            return null;
          }),
          // not all routes can be converted to tab. Null mean that there is no tab from route to activate
          filter(tab => tab !== null),
          distinctUntilChanged(),
        ),
      ),
      untilDestroyed(this),
    );
  }

  /**
   * Detect active tab in all tabs
   *
   * @param groups - tabs groups
   * @param param - active tab prev position
   * @param param.index - index in group
   * @param param.gIndex - group index
   * @returns
   */
  private detectActiveTab(
    groups: Tab[][],
    { index, gIndex }: TabPosition = { index: -1, gIndex: -1 },
  ): Tab | null {
    if (!groups.length) {
      return null;
    }

    if (index < 0 || gIndex < 0) {
      return groups[0][0] || null;
    } else {
      for (let i = gIndex; i >= 0; i--) {
        for (let j = index; j >= 0; j--) {
          if (groups[i]?.[j]) {
            return groups[i][j];
          }
        }
      }

      return null;
    }
  }

  /**
   * Start watching for tabs groups changing in order to detect activeTab closing. Detect and return new tab to activate
   */
  private watchTabsChangesForTabActivation(): Observable<Tab> {
    const activeTabPosition = this.activeTabSubject.pipe(
      switchMap(activeTab =>
        this.tabService.groups$.pipe(
          map(() => this.tabService.getTabPosition(activeTab)),
        ),
      ),
      startWith(null),
      pairwise(),
      map(([prev, current]) => [prev || current, current]),
    );

    return activeTabPosition.pipe(
      withLatestFrom(this.tabService.groups$),
      map(
        ([
          [prevActiveTabPosition, currentActiveTabPosition],
          currentGroups,
        ]) => {
          if (currentActiveTabPosition.index < 0) {
            const newTabToActivate = this.detectActiveTab(
              currentGroups,
              prevActiveTabPosition,
            );
            return newTabToActivate;
          } else {
            return null;
          }
        },
      ),
      // null means that there is no need to change active tab
      filter(tab => tab !== null),
      untilDestroyed(this),
    );
  }

  /**
   * Watch for active tab doc evolution for updating url in runtime
   */
  private watchForActiveTabDoc(): void {
    this.activeTabSubject
      .pipe(
        filter(tab => Boolean(tab)),
        switchMap(tab => merge(tab.doc$, of(tab.doc)).pipe(mapTo(tab))),
        // small debounce just in case...
        debounceTime(0),
        untilDestroyed(this),
      )
      .subscribe(tab => {
        this.setTabRouteQueryParams(tab);
      });
  }

  /**
   * Set tab route query params into current browser url. Can trigger active tab change
   *
   * @param tab - tab instance
   */
  private setTabRouteQueryParams(tab: Tab): void {
    const reducedParams = {
      ...tab.params,
    };
    // remove 'out' property from tab params in order to properly compare it with url query params from orion
    delete reducedParams.out;

    this.roter.navigate([], {
      queryParams: reducedParams,
    });
  }

  init(tabService: TabGroupService, initialActiveTab?: Tab): void {
    this.tabService = tabService;

    if (initialActiveTab) {
      this.setActive(initialActiveTab);
    } else {
      const tabToActivate = this.detectActiveTab(this.tabService.groups);
      this.setActive(tabToActivate);
    }

    // watch in runtime for tabs and route state in order to keep some tab active

    // watch all tabs and active tab. If active tab is not exist - activate some other tab
    this.watchTabsChangesForTabActivation().subscribe(tab => {
      this.setActive(tab);
    });

    // watch for routing change. Activate tab, that correspond to some existed tab, OR create new one
    // @WARN maybe this is redundant
    this.watchRouterForActiveTab().subscribe(tab => {
      this.setActive(tab);
    });

    this.watchForActiveTabDoc();
  }

  /**
   * Set active tab
   *
   * @param tab - tab instance
   */
  setActive(tab?: Tab): void {
    this.activeTabSubject.next(tab || null);
  }

  /**
   * Create tab instance from url
   *
   * @param queryParams - url query params
   * @param desktop
   */
  createFromUrl(queryParams: Params, desktop: IDesktopMeta): Tab | null {
    // seek for any func-like param in queryParams
    const tabParams = queryParamsToTabParams(queryParams);
    if (!tabParams) {
      return null;
    }

    const groupName = getMenuGroupName(tabParams.func, desktop);
    // @WARN we don't know tab title on the moment of creating it from url
    // AND tabs docs with title downloads lazily,
    // so we must to set it active, in order to start loading tab doc
    return new Tab({
      params: tabParams,
      title: '',
      groupName,
      isPinned: false,
    });
  }
}
