<div class="container">
  <div class="option" *ngFor="let option of optionsUi; trackBy: trackByFunc">
    <div
      *ngIf="option.img || option.customSvg"
      class="img"
      [ngClass]="{ img_active: option.key === formControl.value }"
      (click)="onImgClick(option)"
    >
      <isp-extform-preview
        *ngIf="option.customSvg; else imgBlock"
        [previewName]="option.customSvg"
      ></isp-extform-preview>
      <ng-template #imgBlock>
        <img [src]="option.img" [alt]="option.msg" />
      </ng-template>
    </div>
    <ispui-radio
      class="button"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [attr.value]="option.key"
      [attr.name]="field.id"
    >
      <span>
        {{ option.msg }}
      </span>
    </ispui-radio>
  </div>
</div>
