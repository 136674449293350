import {
  ITree,
  IField,
  ITlistVal,
} from 'app/services/api5-service/api.interface';

import { TreeTO } from './model/tree-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';
import { isFullWidth } from '../../utils/is-full-width';

/**
 * Returns transformed tslit val items with added children for expand
 *
 * @param tlistVal - list value to transform
 */
export function transformTListVal(
  tlistVal: ITlistVal | ITlistVal[],
): ITlistVal[] {
  if (Array.isArray(tlistVal)) {
    return tlistVal.map(branch => {
      const newBranch = { ...branch };
      if (newBranch.$collapsed) {
        newBranch.tlist = { val: [] };
      } else if (branch?.tlist?.val) {
        newBranch.tlist.val = transformTListVal(branch.tlist.val);
      } else if (branch?.tlist) {
        delete newBranch.tlist;
      }
      return newBranch;
    });
  } else if (tlistVal.tlist?.val) {
    const newBranch = { ...tlistVal };
    newBranch.tlist.val = transformTListVal(newBranch.tlist.val);
    return [newBranch];
  } else if (!tlistVal.tlist || Object.keys(tlistVal.tlist).length === 0) {
    return [
      {
        ...tlistVal,
        tlist: {
          val: [],
        },
      },
    ];
  } else {
    return [];
  }
}

/**
 * Get config for tree field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getTreeConfig(
  control: ITree,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.Tree> {
  const templateOptions: TreeTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    dataSource: state.doc?.tlist?.[0]?.val
      ? transformTListVal(state.doc?.tlist?.[0]?.val)
      : [],
    rows: parseInt(control.$rows, 10),
    isFullWidth: isFullWidth(field),
  };

  return {
    key: control.$name,
    type: ISPFieldType.Tree,
    wrappers: [ISPFieldWrapper.FieldBase],
    templateOptions,
  };
}
