import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  IDocument,
  TSetValueType,
} from 'app/services/api5-service/api.interface';
import { MessageBusService } from 'app/services/messagebus/messagebus.service';
import { ISPNotificationService } from 'app/services/notification.service';
import { IDocService } from 'components/chat/chat.interface';
import { getButtonList, IFormButtonUi } from 'components/form-button';
import { IFormCollapseEvent } from 'components/form-collapse';
import { Subject, merge, BehaviorSubject, timer } from 'rxjs';
import {
  takeUntil,
  tap,
  distinctUntilChanged,
  filter,
  startWith,
  skip,
  skipWhile,
  take,
  map,
  pairwise,
  debounce,
} from 'rxjs/operators';

import { DocHelper } from 'utils/dochelper';

import { IFormButtonClickEvent, IFormModel } from './dynamic-form.interface';
import { isFieldValid, isFormSubmittable } from './dynamic-form.utils';
import {
  ISPFieldConfig,
  ISPFormOptions,
  ISPFieldType,
  ISPFormState,
  ISPFieldTypeWithControl,
  DynamicFormContext,
  ISPValidator,
} from './model';
import { ButtonsService } from './services/buttons.service';
import { CaptchaService } from './services/captcha.service';
import { ConditionService } from './services/condition.service';
import { DisabledService } from './services/disabled.service';
import { HiddenService } from './services/hidden.service';
import {
  DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR,
  LayoutService,
} from './services/layout.service';
import { ListFieldService } from './services/list-field.service';
import { MixedService } from './services/mixed-service';
import { ModeService } from './services/mode.service';
import { SelectService } from './services/select.service';
import { SetValuesService } from './services/set-values.service';
import { ValidationService } from './services/validation.service';
import {
  TemplateConfig,
  concatTemplateConfigs,
  getLayoutConfig,
  isSelectedKeyValue,
  ILinkClickEvent,
} from './types';
import { getTypedForm, TypedForm } from './utils/form-preparing';
import {
  forEachConfig,
  findConfig,
  filterConfigs,
} from './utils/formly-configs';
import {
  getConfigsFromTypedForm,
  setTypedFormToServices,
} from './utils/formly-configs-generation';
import { isFieldWithControl } from './utils/is-field-with-control';
import { removePropertyList } from './utils/remove-prop';
import { getGeneralBackendError } from './wrappers/validation-error/validation-error.utils';

/**
 * Default list dropdown width
 *
 * @WARN setted from js, it cannot be setted from css variables, cause dropdown opens out of dynamic-form css scope
 */
const LIST_DROPDOWN_DEFAULT_WIDTH = '400px';

const FORM_RENDER_TIME = 100;

/**
 * Field list service
 */
@UntilDestroy()
@Injectable()
export class DynamicFormService implements IDocService {
  private destroy$: Subject<void> = new Subject();

  private readonly formGroupSubject: BehaviorSubject<FormGroup | null> =
    new BehaviorSubject(null);

  private readonly configsSubject: BehaviorSubject<ISPFieldConfig[]> =
    new BehaviorSubject([]);

  private templateConfigs: TemplateConfig[];

  private readonly optionsSubject: BehaviorSubject<ISPFormOptions | null> =
    new BehaviorSubject(null);

  private readonly modelSubject: BehaviorSubject<IFormModel> =
    new BehaviorSubject({});

  /** Dynamic form context information */
  private readonly contextSubject: BehaviorSubject<DynamicFormContext | null> =
    new BehaviorSubject(null);

  readonly formGroup$ = this.formGroupSubject.pipe(
    filter(form => Boolean(form)),
  );

  readonly configs$ = this.configsSubject.asObservable();

  readonly options$ = this.optionsSubject.pipe(
    filter(options => Boolean(options)),
  );

  readonly model$ = this.modelSubject.asObservable();

  readonly context$ = this.contextSubject.pipe(
    filter(context => Boolean(context)),
  );

  get formGroup(): FormGroup | null {
    return this.formGroupSubject.value;
  }

  get configs(): ISPFieldConfig[] {
    return this.configsSubject.value;
  }

  get options(): ISPFormOptions | null {
    return this.optionsSubject.value;
  }

  get model(): IFormModel {
    return this.modelSubject.value;
  }

  set model(model: IFormModel) {
    this.modelSubject.next(model);
  }

  get context(): DynamicFormContext | null {
    return this.contextSubject.value;
  }

  set context(context: DynamicFormContext) {
    this.contextSubject.next(context);
  }

  /** When updating the form on setValues any field, need to reconfigure the form fields */
  readonly reconfiguringFields$: Subject<string> = new Subject();

  /** Document */
  get doc(): IDocument {
    return this.options.formState.doc;
  }

  /** Page collapse event */
  readonly collapseEvent = new EventEmitter<IFormCollapseEvent>();

  /** Button click event */
  readonly buttonClickEvent = new EventEmitter<IFormButtonClickEvent>();

  /** Link click event */
  readonly linkClickEvent = new EventEmitter<ILinkClickEvent>();

  dropdownParentSelector: string;

  /** Whether hints should be displayed or not */
  showHints = true;

  /** Width of dropdown list for controls, that use dropdown for displaying */
  selectDropdownWidth = LIST_DROPDOWN_DEFAULT_WIDTH;

  templates: TemplateConfig[];

  validationBoundingElement: string =
    DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR;

  /** Should the form initiate in base mode (when it comes with two modes) */
  startInBaseMode = true;

  /** Is general error displayed in for layout */
  showGeneralError = false;

  /**
   * Flag to control the synchronization of the model with doc
   *
   * @TODO e.polyakov for the branding form, there is no need to synchronize with doc, remove this after BILLDr-543
   */
  needSyncModelWithDoc = true;

  constructor(
    private readonly setValuesService: SetValuesService,
    private readonly validationLoader: ValidationService,
    private readonly conditionService: ConditionService,
    private readonly hiddenService: HiddenService,
    private readonly modeService: ModeService,
    private readonly disabledService: DisabledService,
    private readonly buttonsService: ButtonsService,
    private readonly mixedService: MixedService,
    private readonly listFieldService: ListFieldService,
    private readonly selectService: SelectService,
    private readonly layoutService: LayoutService,
    private readonly bus: MessageBusService,
    private readonly captchaService: CaptchaService,
    private readonly notificationService: ISPNotificationService,
  ) {
    this.modeService.setFormId(this.layoutService.formId);

    this.bus
      .on$('toggle-dynamic-form-mode')
      .pipe(
        filter(event => event.payload.formId === this.layoutService.formId),
        untilDestroyed(this),
      )
      .subscribe(message => {
        this.layoutService.togglePageMode(message.payload.mode);
      });
  }

  /**
   * Reduce the existing form model to contain only empty values. Needed to properly reset the form.
   */
  private getEmptyModel(): any {
    const model = {};
    forEachConfig(this.configs, (field: ISPFieldConfig) => {
      if (!isFieldWithControl(field)) {
        return;
      }

      const originalControl = field.templateOptions.originalControl;

      const name = originalControl.$name;

      switch (true) {
        case field.type === ISPFieldType.Checkbox:
          model[name] = 'off';
          break;
        case field.type === ISPFieldType.Select &&
          !field.templateOptions.multiple:
          model[name] = 'null';
          break;
        default:
          model[name] = '';
          break;
      }
    });
    return model;
  }

  /**
   * Get form model with disabled controls and without mixed controls
   */
  private getUnmixedFormModel(): IFormModel {
    return removePropertyList(
      this.formGroup.getRawValue(),
      this.mixedService.getMixedControlNames(),
    );
  }

  /**
   * Set autofocus flag to input-like field (which has error of any usual one)
   *
   * @param configs - form configs
   */
  private setAutofocus(configs: ISPFieldConfig[]): ISPFieldConfig[] {
    let specificFindCondition: (f: ISPFieldConfig) => boolean | undefined;
    if (this.options.formState.doc.error) {
      // first we try to focus a field containing error from server, even if it's currently hidden by form mode (the mode is supposed to be switched a moment later by another method)
      specificFindCondition = f => {
        const hasError =
          f.validators?.[ISPValidator.ErrorFromBackend] !== undefined;
        const isVisibleOrHiddenOnlyByMode =
          !f.templateOptions.isHidden || f.templateOptions.isHiddenByMode;
        return isVisibleOrHiddenOnlyByMode && hasError;
      };
    }

    type FocusableField =
      | ISPFieldConfig<ISPFieldType.InputText>
      | ISPFieldConfig<ISPFieldType.Password>
      | ISPFieldConfig<ISPFieldType.TextArea>;

    const isFieldFocusabel = (field: ISPFieldConfig): field is FocusableField =>
      field.type === ISPFieldType.Password ||
      field.type === ISPFieldType.InputText ||
      field.type === ISPFieldType.TextArea;

    const fieldToFocus = findConfig(configs, (field: ISPFieldConfig) => {
      if (field.type === ISPFieldType.List) {
        return 'skip-node-and-childs';
      }

      return (
        isFieldFocusabel(field) &&
        field.templateOptions?.originalControl?.$readonly !== 'yes' &&
        specificFindCondition &&
        specificFindCondition(field)
      );
    }) as FocusableField | undefined;

    if (fieldToFocus) {
      fieldToFocus.templateOptions.autofocus = true;
    }

    return configs;
  }

  private addHooksToFields(fields: ISPFieldConfig[]): void {
    forEachConfig(fields, field => {
      if (!isFieldWithControl(field)) {
        return;
      }

      this.setValuesService.checkAndStartIntervalSetValues(
        field.templateOptions.originalControl,
      );

      field.hooks = {
        ...field.hooks,
        afterContentInit: (
          fieldConfig: ISPFieldConfig<ISPFieldTypeWithControl>,
        ) => {
          if (fieldConfig.templateOptions.originalControl?.if) {
            fieldConfig.formControl.updateValueAndValidity({
              onlySelf: false,
              emitEvent: true,
            });
          }
        },
        onInit: (fieldConfig: ISPFieldConfig<ISPFieldTypeWithControl>) => {
          // subscribing to value changes will only be for fields that are added to the model formly (by key)
          if (!fieldConfig.key) {
            return;
          }

          let valueChange = fieldConfig.formControl.valueChanges.pipe(
            startWith(fieldConfig.formControl.value),
            distinctUntilChanged((prev, curr) => {
              if (
                fieldConfig.type === ISPFieldType.AutocompleteSelect &&
                curr.length === 0
              ) {
                return false;
              }

              return String(prev) === String(curr);
            }),
            tap(v => {
              this.conditionService.updateControlValue(
                fieldConfig.templateOptions.originalControl,
                v,
              );
            }),
            // skip initial value - it's required to set all hiddenService or mixedService states, and not to trigger setValues
            skip(1),
            tap(() => {
              const originalControl =
                fieldConfig.templateOptions.originalControl;
              if (originalControl && '$mixed' in originalControl) {
                if (
                  fieldConfig.formControl.value === '' &&
                  originalControl.$mixed === 'yes'
                ) {
                  this.mixedService.addControl(originalControl);
                } else {
                  this.mixedService.removeControl(originalControl);
                }
              }
            }),
          );

          if (fieldConfig.type === ISPFieldType.AutocompleteSelect) {
            // need to send a request to the server for an input-like field
            // only after a short delay, marking the end of the user's input
            const eventDelayTime = 200;
            valueChange = valueChange.pipe(
              startWith(''),
              map(value => [
                value,
                isSelectedKeyValue(fieldConfig, this.options.formState),
              ]),
              pairwise(),
              debounce(([[_, prevKeyValue], [value, currentKeyValue]]) => {
                const isUserJustFocusAutoselect =
                  prevKeyValue && !currentKeyValue;
                if (isUserJustFocusAutoselect || value === '') {
                  return timer(0);
                }
                return timer(eventDelayTime);
              }),
            );
          }

          // subscribe to value for setvalues controls and if/else
          valueChange
            .pipe(
              takeUntil(
                merge(
                  this.reconfiguringFields$.pipe(
                    filter(key => key === fieldConfig.key),
                  ),
                  this.destroy$,
                ),
              ),
            )
            .subscribe(() => {
              this.handleFieldChangeEvent(fieldConfig);
            });
        },
      };
    });
  }

  /**
   * Emits the `setValues` event if the changed field has it
   *
   * @param field - formly field config
   */
  private handleFieldChangeEvent(
    field: ISPFieldConfig<ISPFieldTypeWithControl>,
  ): void {
    if (field.type === ISPFieldType.AutocompleteSelect) {
      if (!isSelectedKeyValue(field, this.options.formState)) {
        // is select emit not some options key, then it must be search string value
        // that should be treated by sv_field mechanism
        this.setValuesService
          .handleSetValues('yes', field.key, {
            sv_autocomplete: 'yes',
          })
          .subscribe();
        return;
      }
    }

    if (
      field.type === ISPFieldType.InputText ||
      field.type === ISPFieldType.TextArea
    ) {
      const setValues: TSetValueType = field.templateOptions.setValues;
      if (setValues) {
        const minLength = field.templateOptions.setValuesMinLength;
        if (Boolean(minLength) && field.formControl.value.length < minLength) {
          return;
        }

        this.setValuesService.handleSetValues(setValues, field.key).subscribe();
        return;
      }
    }

    const setValues: TSetValueType = field.templateOptions.setValues;
    if (setValues) {
      this.setValuesService.handleSetValues(setValues, field.key).subscribe();
    }
  }

  /**
   * Gets new options
   *
   * @param doc
   */
  private resetOptions(doc: IDocument): ISPFormOptions {
    const baseFieldSet = DocHelper.getBaseFieldNamesSet(doc);
    this.modeService.setNamesForBaseMode(baseFieldSet);
    this.modeService.toggleMode(
      baseFieldSet.size > 0 && this.startInBaseMode ? 'base' : 'extended',
    );

    this.listFieldService.resetFilter();

    // @TODO i.ablov reset all other services!
    return {
      formState: {
        selectDropdownWidth: this.selectDropdownWidth,
        selectService: this.selectService,
        listService: this.listFieldService,
        disabledService: this.disabledService,
        conditionService: this.conditionService,
        hiddenService: this.hiddenService,
        buttonsService: this.buttonsService,
        modeService: this.modeService,
        layoutService: this.layoutService,
        mixedService: this.mixedService,
        validationService: this.validationLoader,
        doc: doc,
        context: this.context,
        showHints: this.showHints,
        dropdownParentSelector: this.dropdownParentSelector,
      },
    };
  }

  private showFieldValidation(config: ISPFieldConfig): void {
    // validation apears on invalid and touched fields. Assumed that this method used on invalid fields
    this.formGroup.controls[config.key].markAsTouched();

    this.markForCheck(config);
  }

  private scrollToErrorField(fieldName?: string): void {
    const configToSpot: ISPFieldConfig = findConfig(this.configs, config => {
      if (fieldName && 'originalControl' in config.templateOptions) {
        return config.templateOptions.originalControl.$name === fieldName;
      } else {
        return !isFieldValid(config);
      }
    });

    if (!configToSpot) {
      return;
    }

    this.layoutService.scrollToField(configToSpot).then(() => {
      this.showFieldValidation(configToSpot);
    });
  }

  private clearServicesState(): void {
    this.disabledService.clear();
    this.conditionService.clear();
    this.modeService.clear();
    this.mixedService.clear();
    this.selectService.clear();
    this.buttonsService.clear();
    this.listFieldService.clear();
  }

  private syncModelWithDoc(): void {
    const modelConfigs = filterConfigs(
      this.configsSubject.value,
      config => 'originalControl' in config.templateOptions,
    ) as ISPFieldConfig<ISPFieldTypeWithControl>[];
    // reset model value and keep refrence. Reset for doc sync, keep for filter case
    // @TODO e.polyakov remove model from @Input at all! Model contains in doc!
    Object.keys(this.modelSubject.value).forEach(key => {
      delete this.modelSubject.value[key];
    });
    modelConfigs.forEach(config => {
      const key = config.templateOptions.originalControl.$name;
      const value = config.defaultValue || '';
      if (config.key) {
        this.modelSubject.value[key] = value;
      }
    });
    this.modelSubject.next(this.modelSubject.value);
  }

  /**
   * Initializes the service
   *
   * @param doc - response's document
   * @param templates - additional templates configs
   */
  init(doc: IDocument, templates: TemplateConfig[]): void {
    this.templateConfigs = templates;

    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$ = new Subject<void>();
    this.clearServicesState();

    this.formGroupSubject.next(new FormGroup({}));
    this.optionsSubject.next(this.resetOptions(doc));

    this.options.formState.selectService.setOptions(this.doc?.slist);
    this.options.formState.selectService.setFormGroup(this.formGroup);
    this.options.formState.listService.addElements(this.doc?.list);
    this.options.formState.layoutService.init(this);
    this.options.formState.buttonsService.init(this);
    this.setValuesService.init(this);
    this.options.formState.buttonsService.setFooterButtons(
      getButtonList(
        this.doc,
        this.doc?.metadata?.form?.buttons?.button || [],
        this.options.formState.showHints,
      ),
    );
    this.options.formState.validationService.initValidatorMessages(
      this.options.formState,
    );

    const typedForm = getTypedForm(this.options.formState.doc);

    // @WARN initiallize services must be earlier than configs generation!
    setTypedFormToServices(typedForm, this.options.formState);

    this.configsSubject.next(
      this.generateConfigs(typedForm, this.options.formState),
    );
    if (this.needSyncModelWithDoc) {
      this.syncModelWithDoc();
    }
    this.setAutofocus(this.configs);

    if (doc?.error) {
      const generalBackendError = getGeneralBackendError(
        this.configs,
        this.options.formState,
      );

      if (generalBackendError) {
        if (!this.showGeneralError) {
          this.notificationService
            .showError(generalBackendError, this.context.msgError)
            .subscribe();
        }
      } else {
        // set timeout for formly form rendering
        setTimeout(() => {
          this.scrollToErrorField(doc.error.$object);
        }, FORM_RENDER_TIME);

        // remove BE errors after user change form model.
        this.formGroup.valueChanges
          .pipe(
            skipWhile(() => this.formGroup.pristine),
            take(1),
            untilDestroyed(this),
          )
          .subscribe(() => {
            this.formGroup.controls[doc.error.$object].updateValueAndValidity();
          });
      }
    }
  }

  markForCheck(config: ISPFieldConfig): void {
    // @HACK we need to trigger formly change detection system, to activate validation appearing
    // we can use bus to trigger cdr on some certain fields, but this required many boilarplate code
    // in formly v6 this function will be exposed outside, so it will be valid way to trigger cdr
    // @TODO i.ablov update formly to v6, remove this function
    (this.options as any)._markForCheck(config);
  }

  /**
   * Generates configs from doc state and custom templates for dynamic-form
   *
   * @param typedForm - typed doc
   * @param state - form state
   */
  generateConfigs(
    typedForm: TypedForm | undefined,
    state: ISPFormState,
  ): ISPFieldConfig[] {
    const configs = getConfigsFromTypedForm(typedForm, state);

    this.addHooksToFields(configs);

    const configsWithTemplates = concatTemplateConfigs(
      configs,
      this.templateConfigs,
    );

    const configsWithLayout = [getLayoutConfig(configsWithTemplates)];

    return configsWithLayout;
  }

  /**
   * Reset the form
   */
  resetForm(): void {
    this.formGroup?.reset(this.getEmptyModel(), { onlySelf: true });
  }

  /**
   * Emits the page collapse event.
   *
   * @param event - collapse event
   */
  emitPageCollapseEvent(event: IFormCollapseEvent): void {
    this.collapseEvent.emit(event);
  }

  /**
   * Emits the form button click event and form's value
   *
   * @param button - clicked button
   * @param additionalParams - additional params for form submiting
   */
  async emitButtonClick(
    button: IFormButtonUi,
    additionalParams: IFormModel = {},
  ): Promise<void> {
    if (button.type === 'setvalues') {
      button.preloaderSubject.next(true);
      this.setValuesService
        .handleSetValues(
          button.blocking ? 'blocking' : undefined,
          button.name,
          // @TODO i.ablov maybe should add additionalParams to request
          {},
          true,
        )
        .subscribe({
          complete: () => {
            button.preloaderSubject.next(false);
          },
        });
    } else {
      const form: IFormModel = {
        ...this.getUnmixedFormModel(),
        ...additionalParams,
      };
      const event: IFormButtonClickEvent = {
        button,
        form,
      };
      if (this.captchaService.isNeedToGetCaptchaForButtonClick(event)) {
        try {
          const resolvedValue = await this.captchaService
            .getCaptchaResolve$()
            .toPromise();
          this.captchaService.appendCaptchaResolvedValueToParams(
            event.form,
            resolvedValue,
          );
        } catch (e: unknown) {
          // event was rejected or captcha error
          return;
        }
      }
      this.buttonClickEvent.emit(event);
    }
  }

  emitLinkEvent(event: ILinkClickEvent): void {
    this.linkClickEvent.emit(event);
  }

  /**
   * Sends the keyboard event to submit
   *
   * @param event - keyboard "Enter" key press event
   */
  submitFromKeyboard(event: KeyboardEvent): void {
    const allowedTagNameList = ['input'];
    const isAllowed = event.composedPath().some((element: HTMLElement) => {
      const isElementAllowed = allowedTagNameList.includes(element.localName);
      if (isElementAllowed) {
        element.blur();
      }
      return isElementAllowed;
    });
    if (!isAllowed && !event.ctrlKey) {
      return;
    }
    if (!isFormSubmittable(this.configs)) {
      this.scrollToErrorField();
      return;
    }
    // @TODO i.ablov do as in core-manager/dist/skins/orion/src/App.Forms.js:532 clickButtonTrigger function
    const submitButton = this.buttonsService.getFirstSubmitButtonFromFooter();
    if (submitButton && !submitButton.disabledSubject.value) {
      this.emitButtonClick(submitButton);
    }
  }

  reemitConfigs(): void {
    this.configsSubject.next([...this.configsSubject.value]);
  }
}
