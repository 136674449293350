import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
} from '@angular/core';

import { ISPFieldTypeBase } from 'common/dynamic-form/model/field-type-base.class';
import { ISPFieldType } from 'common/dynamic-form/model/field-type.enum';

/**
 * Wrapper component above tinyMCE text editor
 *
 * Use only with Formly
 */
@Component({
  selector: 'isp-formly-texteditor',
  templateUrl: './texteditor.component.html',
  styleUrls: ['./scss/texteditor.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextEditorFieldComponent extends ISPFieldTypeBase<ISPFieldType.TextEditor> {
  pending = true;

  /** Set width 100% to fullwidth field */
  @HostBinding('style.width')
  get width(): string {
    return this.to.isFullWidth ? '100%' : 'auto';
  }

  constructor(private readonly cdr: ChangeDetectorRef) {
    super();
  }

  turnOffPreloaderOnInitEditor(): void {
    this.pending = false;
    this.cdr.markForCheck();
  }
}
