/**
 * Form button's type
 */
export enum FormButtonType {
  /** Submits the form, sets `sok` parameter to `ok` */
  Ok = 'ok',
  /** Closes the form */
  Cancel = 'cancel',
  /** Submits the form, sets `sback` parameter to `ok` and `sok` - to `ok` */
  Back = 'back',
  /** Submits the form, sets `sok` parameter to `ok` */
  Next = 'next',
  /** Submits the form *in a new tab*, sets `sok` parameter to `ok` */
  Blank = 'blank',
  /** Submits the form as `setvalues` and set the name to the button with `sv_field` parameter */
  SetValues = 'setvalues',
  /** Opens the child list or form, function's name is specified in `$func` attribute */
  Func = 'func',
  /** Resets the form to the default values */
  Reset = 'reset',
  /** Undocumented button type */
  Submit = 'submit',
}
