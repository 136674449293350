import { Injectable } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { TabGroupService } from './tab-group.service';
import { Tab } from './tab.class';
import { sortGroupListByPin } from './tab.utils';

/**
 * Service for removing duplication from tabs groups
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TabPinnedService {
  private tabService: TabGroupService;

  private isNeedToBeSorted(): boolean {
    const prev = this.tabService.groups.flat();
    const next = sortGroupListByPin(this.tabService.groups).flat();
    return prev.map(t => t.id).join('') !== next.map(t => t.id).join('');
  }

  private watchForPinnedSorting(): void {
    let lock = false;
    this.tabService.groups$
      .pipe(
        switchMap(groups =>
          merge(...groups.flat().map(tab => tab.doc$.pipe(map(() => tab)))),
        ),
        filter(() => !lock),
        tap(tab => {
          lock = true;

          this.updateAllDuplicatesPinnedState(tab);

          lock = false;
        }),
        filter(() => this.isNeedToBeSorted()),
        tap(() => {
          // force tabs sorting
          this.tabService.updateTabGroups();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private updateAllDuplicatesPinnedState(tab: Tab): void {
    const state = tab.isPinned;
    const allTabsWithThatFunc = this.tabService.groups
      .flat()
      .filter(t => t.func === tab.func && t !== tab && t.isPinned !== state);

    allTabsWithThatFunc.forEach(t => t.setPinned(state));
  }

  init(tabService: TabGroupService): void {
    this.tabService = tabService;

    this.watchForPinnedSorting();
  }
}
