import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, pluck, startWith, switchMap } from 'rxjs/operators';

import { DynamicFormService } from '../dynamic-form.service';
import { ISPFieldConfig, ISPFieldType } from '../model';
import { filterConfigs } from '../utils/formly-configs';

@UntilDestroy()
@Injectable()
export class FontService {
  private readonly formGroupSubject: BehaviorSubject<FormGroup | null> =
    new BehaviorSubject(null);

  private readonly fontFamilyControllersSubject: BehaviorSubject<
    Record<string, string>
  > = new BehaviorSubject({});

  private readonly fontSizeControllersSubject: BehaviorSubject<
    Record<string, string>
  > = new BehaviorSubject({});

  constructor(private readonly dynamicFormService: DynamicFormService) {
    combineLatest([
      this.dynamicFormService.formGroup$,
      this.dynamicFormService.configs$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([formGroup, configs]) => {
        this.init(formGroup, configs);
      });
  }

  private init(formGroup: FormGroup, configs: ISPFieldConfig[]): void {
    this.formGroupSubject.next(formGroup);

    const fontFamilyControllers = filterConfigs(configs, config =>
      Boolean(
        config.type === ISPFieldType.Select &&
          config.templateOptions.originalControl.$targetfontfamilyview,
      ),
    ).reduce((controlles, config: ISPFieldConfig<ISPFieldType.Select>) => {
      controlles[config.templateOptions.originalControl.$targetfontfamilyview] =
        config.templateOptions.originalControl.$name;
      return controlles;
    }, {});
    this.fontFamilyControllersSubject.next(fontFamilyControllers);

    const fontSizeControllers = filterConfigs(configs, config =>
      Boolean(
        config.type === ISPFieldType.Slider &&
          config.templateOptions.originalControl.$targetfontsizeview,
      ),
    ).reduce((controlles, config: ISPFieldConfig<ISPFieldType.Slider>) => {
      controlles[config.templateOptions.originalControl.$targetfontsizeview] =
        config.templateOptions.originalControl.$name;
      return controlles;
    }, {});
    this.fontSizeControllersSubject.next(fontSizeControllers);
  }

  getFontSizeForControl$(name: string): Observable<string> {
    return this.formGroupSubject.pipe(
      filter(formGroup => Boolean(formGroup)),
      switchMap(formGroup =>
        combineLatest([
          formGroup.valueChanges.pipe(startWith(formGroup.value)),
          this.fontSizeControllersSubject.pipe(pluck(name)),
        ]),
      ),
      map(([model, controllerName]) => {
        if (controllerName) {
          const fontSizeValue = model[controllerName];
          return fontSizeValue;
        }
      }),
      filter(fontSize => fontSize !== undefined),
    );
  }

  getFontFamilyForControl$(name: string): Observable<string> {
    return this.formGroupSubject.pipe(
      filter(formGroup => Boolean(formGroup)),
      switchMap(formGroup =>
        combineLatest([
          formGroup.valueChanges.pipe(startWith(formGroup.value)),
          this.fontFamilyControllersSubject.pipe(pluck(name)),
        ]),
      ),
      map(([model, controllerName]) => {
        if (controllerName) {
          const fontFamilyValue = model[controllerName];
          return fontFamilyValue;
        }
      }),
      filter(fontSize => fontSize !== undefined),
    );
  }
}
