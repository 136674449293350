import { IInput, IField } from 'app/services/api5-service/api.interface';

import { getLabel } from 'common/dynamic-form/utils/get-label';

import { DatePickerTO } from './model/date-picker-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';
import { getInputPlaceholder } from '../../utils/get-input-placeholder';

/**
 * Get config for date-picker field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getDatePickerConfig(
  control: IInput,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.DatePicker> {
  const templateOptions: DatePickerTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    datePickMode: control.$date === 'month' ? 'month' : 'date',
    inputLabel: getLabel(field, state.doc),
    placeholder: getInputPlaceholder(field, state.doc),
    mask: control.$mask,
  };

  const config: ISPFieldConfig<ISPFieldType.DatePicker> = {
    key: control.$name,
    type: ISPFieldType.DatePicker,
    wrappers: [ISPFieldWrapper.InputBase, ISPFieldWrapper.ValidationError],
    templateOptions,
  };

  return config;
}
