import { ILink, Func, IField } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

import { LinkTO } from './model/link-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';
import { getImage } from '../../utils/get-image';

/**
 * Link field click event
 */
export interface ILinkClickEvent {
  /** Should tab be opened as child */
  isNewTab: boolean;
  /** Func and params like 'payment&p_num=2&m_cnt=5' */
  funcAndParams: string;
}

/**
 * Get config for link field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getLinkConfig(
  control: ILink,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.Link> {
  const isInternal = control.$internal === 'yes';
  let href = DocHelper.getStringValue(control.$name, state.doc);
  if (isInternal) {
    href = href.replace('func=', '');
  }
  const templateOptions: LinkTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    caption: DocHelper.getMessage(control.$name, state.doc),
    target: control.$target,
    isInternal,
    isNewTab: control.$newtab === 'yes',
    change: null,
    href: href as Func,
    hintDisplayMode: 'center',
    img: getImage(field, state.doc),
  };

  return {
    type: ISPFieldType.Link,
    wrappers: [ISPFieldWrapper.FieldBase],
    templateOptions,
  };
}
