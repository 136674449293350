import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { FormlyConfig } from '@ngx-formly/core';
import {
  IControl,
  IDocument,
  IInput,
} from 'app/services/api5-service/api.interface';
import { Api5Service } from 'app/services/api5-service/api5.service';
import { Observable, Subject } from 'rxjs';
import { filter, pluck } from 'rxjs/operators';

import { DocHelper } from 'utils/dochelper';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFormState,
  ISPValidator,
} from '../model';

/**
 * Loader for validation messages
 */
@Injectable()
export class ValidationService {
  private readonly patchAfterValidationSubject: Subject<{
    key: string;
    value: string;
  }> = new Subject();

  constructor(
    private readonly formlyConfig: FormlyConfig,
    private readonly api: Api5Service,
  ) {}

  /**
   * BE can send fixed value to apply on form after async validation performing. This is similar to 'setvalues' mechanism
   *
   * @param checkResult - async validation check result
   * @param control - control meta
   * @param formControl - form control
   * @param prevValue - previous value of form control on the moment of validation performing
   */
  applySuggestedValueAfterValidation(
    checkResult: IDocument,
    control: IControl,
    formControl: AbstractControl,
    prevValue: string,
  ): void {
    if (!checkResult.value?.$) {
      // do nothing if BE won't suggest anything
      return;
    }

    if (typeof formControl.value !== 'string') {
      // suggestion applying works only with strings
      return;
    }

    let newValue = checkResult.value.$;
    const isZoomControl = (control as IInput).$zoom;
    // replace the corrected value for the validated value from the field zoom
    // so, BE send back fixed value for every zoom value, and we should replace it in original form control
    if (isZoomControl) {
      const zoomControlValues = formControl.value.split(' ');
      const invalidValueIndex = zoomControlValues.findIndex(
        value => value === prevValue,
      );
      zoomControlValues[invalidValueIndex] = checkResult.value.$;

      newValue = zoomControlValues.join(' ');
    }

    if (newValue !== formControl.value) {
      const asyncValidator = formControl.asyncValidator;
      formControl.asyncValidator = null;

      formControl.setValue(newValue, { emitEvent: false });

      formControl.asyncValidator = asyncValidator;
      this.patchAfterValidationSubject.next({
        key: control.$name,
        value: newValue,
      });
    }
  }

  /**
   * Register message for invalid field
   *
   * @param validatorName - validator name
   * @param message - message for invalid validator
   */
  registerValidationMessage(
    validatorName: string,
    message: string | ((error: any, fieldConfig: ISPFieldConfig) => string),
  ): void {
    this.formlyConfig.addValidatorMessage(validatorName, message);
  }

  getNotificationAfterValidationPatch(controlName: string): Observable<string> {
    return this.patchAfterValidationSubject.pipe(
      filter(notify => notify.key === controlName),
      pluck('value'),
    );
  }

  /**
   * Init local validators
   *
   * @param state - form state
   */
  initValidatorMessages(state: ISPFormState): void {
    this.formlyConfig.addValidatorMessage(
      ISPValidator.Required,
      () => state.context.validationRequiredMsg,
    );

    this.formlyConfig.addValidatorMessage(ISPValidator.PassMatch, () =>
      DocHelper.getMessage('msg_pwcheck_donotmatch', state.doc),
    );

    this.formlyConfig.addValidatorMessage(
      ISPValidator.Duplicate,
      () => state.context.validationDuplicateMsg,
    );

    this.formlyConfig.addValidatorMessage(
      ISPValidator.FileMaxSize,
      (_, fieldConfig: ISPFieldConfig<ISPFieldType.InputFile>) => {
        const errorMsg = state.context.validationFileMaxSizeMsg;
        const formDoc = state.doc;
        const maxsizeMsg = DocHelper.getMessage(
          `maxsize_${fieldConfig.templateOptions.originalControl.$name}`,
          formDoc,
        );
        return errorMsg.replace('__VALUE__', maxsizeMsg);
      },
    );

    this.formlyConfig.addValidatorMessage(ISPValidator.ErrorFromBackend, () =>
      DocHelper.getError(state.doc),
    );
  }

  /**
   * Send request to BE in order to validate value
   *
   * @param control - control metadata
   * @param value - control value
   * @param state - form state
   */
  performAsyncValidation(
    control: IControl,
    value: string,
    state: ISPFormState,
  ): Observable<IDocument> {
    return this.api.checkFieldValidation({
      func: `check.${control.$check}`,
      name: control.$name,
      funcname: state.doc.$func,
      value: value,
      args: control.$checkargs,
      tconvert: control.$convert,
    });
  }
}
