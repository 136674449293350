import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExtformPreviewService } from 'app/branding/extform/preview/preview.service';
import { Observable } from 'rxjs';
import { filter, startWith, switchMap, tap } from 'rxjs/operators';

import { SetValuesService } from 'common/dynamic-form/services/set-values.service';
import { DocHelper } from 'utils/dochelper';

import { RadioImgOption } from './model/radioimg-to.interface';
import { getRadioImgOptions } from './radioimg.utils';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * RadioImg field component
 *
 * Use only with Formly
 */
@UntilDestroy()
@Component({
  selector: 'isp-formly-radioimg-field',
  templateUrl: './radioimg.component.html',
  styleUrls: ['./scss/radioimg.scss'],
  providers: [ExtformPreviewService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioImgFieldComponent
  extends ISPFieldTypeBase<ISPFieldType.RadioImg>
  implements OnInit
{
  get optionsUi(): RadioImgOption[] {
    return getRadioImgOptions(this.to.options, this.to.originalControl);
  }

  constructor(
    private readonly previewService: ExtformPreviewService,
    private readonly setValuesService: SetValuesService,
  ) {
    super();
  }

  private onModeFieldChange(controlName: string): Observable<any> {
    return this.form.controls[controlName].valueChanges.pipe(
      startWith(this.form.value[controlName]),
      untilDestroyed(this),
    );
  }

  private subscribeToShadowChange(shadowControl: string): void {
    this.onModeFieldChange(shadowControl).subscribe(shadow => {
      this.previewService.setShadow(shadow === 'on');
    });
  }

  private subscribeToBackgroundType(typeControl: string): void {
    this.onModeFieldChange(typeControl).subscribe(type => {
      this.previewService.setBackgroundType(type === 'image' ? 'img' : 'color');
    });
  }

  private subscribeToBackgroundColor(colorControl: string): void {
    this.onModeFieldChange(colorControl).subscribe(color => {
      this.previewService.setBackgroundColor(color);
    });
  }

  private setInitialImageUrl(): void {
    const initialImageUrl = DocHelper.getStringValue(
      this.to.depends.targetInitialImage,
      this.formState.doc,
    );
    this.previewService.setImageSrc(initialImageUrl);
  }

  private subscribeToBackgroundImage(fileControl: string): void {
    this.onModeFieldChange(fileControl).subscribe(fileList => {
      if (fileList instanceof FileList) {
        const imageFile = fileList[0];
        const imageSrc = URL.createObjectURL(imageFile);
        this.previewService.setImageSrc(imageSrc);
      } else {
        this.setInitialImageUrl();
      }
    });
  }

  private subscribeToBackgroundTiling(tilingControl: string): void {
    this.onModeFieldChange(tilingControl).subscribe(tiling => {
      this.previewService.setBackgroundTiling(tiling === 'on');
    });
  }

  private subscribeToBackgroundVerticalStretch(
    verticalStretchControl: string,
  ): void {
    this.onModeFieldChange(verticalStretchControl).subscribe(vertical => {
      this.previewService.setBackgroundVerticalStretch(vertical === 'on');
    });
  }

  private subscribeToBackgroundHorizontalStretch(
    horizontalStretchControl: string,
  ): void {
    this.onModeFieldChange(horizontalStretchControl).subscribe(horizontal => {
      this.previewService.setBackgroundHorizontalStretch(horizontal === 'on');
    });
  }

  ngOnInit(): void {
    if (this.to.depends) {
      this.subscribeToShadowChange(this.to.depends.targetShadow);
      this.subscribeToBackgroundType(this.to.depends.targetBackgroundType);
      this.subscribeToBackgroundColor(this.to.depends.targetBackgroundColor);
      this.subscribeToBackgroundImage(this.to.depends.targetImageFile);
      this.subscribeToBackgroundTiling(this.to.depends.targetBackgroundTiling);
      this.subscribeToBackgroundVerticalStretch(
        this.to.depends.targetBackgroundVerticalStretch,
      );
      this.subscribeToBackgroundHorizontalStretch(
        this.to.depends.targetBackgroundHorizontalStretch,
      );

      this.setValuesService.setValuesRequest$
        .pipe(
          filter(request => request.field === 'brand_logon_reset'),
          switchMap(request => request.result),
          tap(() => this.setInitialImageUrl()),
          untilDestroyed(this),
        )
        .subscribe();
    }
  }

  onImgClick(option: RadioImgOption): void {
    this.formControl.setValue(option.key);
  }

  trackByFunc(_: number, item: RadioImgOption): string {
    return item.key;
  }
}
