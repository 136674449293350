import { IDocument, IField } from 'app/services/api5-service/api.interface';

import {
  CheckboxControlWithValue,
  getTypeControlForCheckbox,
  isCheckboxControl,
} from './get-typed-control-for-checkbox';
import {
  ColorpickerControlWithValue,
  getTypeControlForColorPicker,
  isColorPickerControl,
} from './get-typed-control-for-colorpicker';
import {
  DatepickerControlWithValue,
  getTypeControlForDatePicker,
  isDatePickerControl,
} from './get-typed-control-for-datepicker';
import {
  FileControlWithValue,
  isFileControl,
  getTypeControlForFile,
} from './get-typed-control-for-file';
import {
  getTypeControlForHidden,
  HiddenControlWithValue,
} from './get-typed-control-for-hidden';
import {
  getTypeControlForPassword,
  isPasswordControl,
  PasswordControlWithValue,
} from './get-typed-control-for-password';
import {
  getTypeControlForText,
  InputControlWithValue,
  isTextControl,
} from './get-typed-control-for-text';

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param field - field metadata
 * @param doc - doc
 */
export function getTypeControlsForInput(
  field: IField,
  doc: IDocument,
): (
  | CheckboxControlWithValue
  | ColorpickerControlWithValue
  | DatepickerControlWithValue
  | FileControlWithValue
  | HiddenControlWithValue
  | InputControlWithValue
  | PasswordControlWithValue
)[] {
  return (field.input || []).map(control => {
    switch (true) {
      case isFileControl(control):
        return getTypeControlForFile(control);
      case isCheckboxControl(control):
        return getTypeControlForCheckbox(control, doc);
      case isDatePickerControl(control):
        return getTypeControlForDatePicker(control, doc);
      case isColorPickerControl(control):
        return getTypeControlForColorPicker(control, doc);
      case isTextControl(control):
        return getTypeControlForText(control, doc);
      case isPasswordControl(control):
        return getTypeControlForPassword(control, doc);
      default:
        return getTypeControlForHidden(control, doc);
    }
  });
}
