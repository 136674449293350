import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { ISPUISwitcherModule } from '@ispui/ng-switcher';

import { SwitcherFieldComponent } from './switcher.component';

import { ISPFieldType } from '../../model';

/**
 * Switcher field module
 */
@NgModule({
  declarations: [SwitcherFieldComponent],
  imports: [
    CommonModule,
    ISPUISwitcherModule,
    ReactiveFormsModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.Switcher,
          component: SwitcherFieldComponent,
        },
      ],
    }),
  ],
})
export class SwitcherFieldModule {}
