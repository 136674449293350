import { Inject, Injectable, Optional } from '@angular/core';

import { mergeDeepRight } from 'ramda';
import { Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
} from 'rxjs/operators';

import { WINDOW, WindowWrapper } from '@ngispui/window-service';

import { LocalStorageKey, LocalStorageValue } from './local-storage.interface';

import { AppService } from '../../app.service';
import { PageInfoService } from '../page-info/page-info.service';

/**
 * LocalStorage wrapper service.
 * Saves only JSON data.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  /** Subject of events of some changes in local storage. Stream of keys of changed items */
  private readonly localStorageChange: Subject<string> = new Subject();

  constructor(
    private readonly pageInfoService: PageInfoService,
    @Inject(WINDOW) private readonly window: WindowWrapper,
    // can be absent for extforms or showcase. BILLmgr case
    @Optional() private readonly appService?: AppService,
  ) {}

  /**
   * Get local storage key, specific for certain user
   *
   * @param key - local storage key
   * @param keyPostfix - key postfix for separating stored data by users. Use app user by deafult. But in order to make general storage you need to provide another key
   */
  private getPostfixedKey(key: LocalStorageKey, keyPostfix?: string): string {
    if (keyPostfix) {
      // use custom prefix if provided
      return `${key}__${keyPostfix}`;
    }

    const userName = this.appService?.getUserName() || 'anonymous';
    const lang = this.pageInfoService.pageInfo.lang;
    const defaultPostfix = `${userName}_${lang}`;
    return `${key}__${defaultPostfix}`;
  }

  /**
   * Get item from local storage by key. With parsing stringified data
   *
   * @param key - local storage key
   */
  private getItem<K extends LocalStorageKey>(
    key: string,
  ): LocalStorageValue[K] | undefined {
    const rawData = this.window.localStorage.getItem(key);
    return (JSON.parse(rawData) as LocalStorageValue[K]) || undefined;
  }

  /**
   * Set item to local storage by key
   *
   * @param key - local storage key
   * @param value - data to set
   */
  private setItem(
    key: string,
    value: LocalStorageValue[LocalStorageKey],
  ): void {
    const stringifyedData = JSON.stringify(value);
    this.window.localStorage.setItem(key, stringifyedData);
  }

  /**
   * Returns the data from LocalStorage
   *
   * @param key - local storage key
   * @param keyPostfix - key postfix for separating stored data by users. Use app user by deafult. But in order to make general storage you need to provide another key
   */
  get<K extends LocalStorageKey>(
    key: K,
    keyPostfix?: string,
  ): LocalStorageValue[K] | undefined {
    const postfixedKey = this.getPostfixedKey(key, keyPostfix);
    return this.getItem<K>(postfixedKey);
  }

  /**
   * Returns the stream of data from LocalStorage. Any changes of local storage throug this service will be observed
   * Instantly retur value from local storage, even if there is no value
   *
   * @param key - local storage key
   * @param keyPostfix - key postfix for separating stored data by users. Use app user by deafult. But in order to make general storage you need to provide another key
   */
  get$<K extends LocalStorageKey>(
    key: K,
    keyPostfix?: string,
  ): Observable<LocalStorageValue[K] | undefined> {
    const postfixedKey = this.getPostfixedKey(key, keyPostfix);
    return this.localStorageChange.pipe(
      filter(k => k === postfixedKey),
      map(() => this.getItem<K>(postfixedKey)),
      startWith(this.getItem<K>(postfixedKey)),
      distinctUntilChanged(),
      // @WARN share is required for multiple using of get$ stream!
      shareReplay({
        refCount: true,
        bufferSize: 1,
      }),
    );
  }

  /**
   * Saves the data to LocalStorage
   *
   * @param key - local storage key
   * @param value - data to store
   * @param keyPostfix - key postfix for separating stored data by users. Use app user by deafult. But in order to make general storage you need to provide another key
   */
  set<K extends LocalStorageKey>(
    key: K,
    value: LocalStorageValue[K],
    keyPostfix?: string,
  ): void {
    const postfixedKey = this.getPostfixedKey(key, keyPostfix);

    this.setItem(postfixedKey, value);

    this.localStorageChange.next(postfixedKey);
  }

  /**
   * Patches the existing data in LocalStorage by key. If no data existing, then patch data will be just setted
   *
   * @param key - local storage key
   * @param value - data for patching existed data in store
   * @param keyPostfix - key postfix for separating stored data by users. Use app user by deafult. But in order to make general storage you need to provide another key
   */
  patch<K extends LocalStorageKey>(
    key: K,
    value: LocalStorageValue[K],
    keyPostfix?: string,
  ): void {
    const postfixedKey = this.getPostfixedKey(key, keyPostfix);

    const data = this.getItem<K>(postfixedKey);
    if (!data) {
      return this.setItem(postfixedKey, value);
    }

    const newData = mergeDeepRight(data, value) as LocalStorageValue[K];

    return this.setItem(postfixedKey, newData);
  }

  /**
   * Removes the data with given key from LocalStorage
   *
   * @param key - local storage key
   * @param keyPostfix - key postfix for separating stored data by users. Use app user by deafult. But in order to make general storage you need to provide another key
   */
  remove(key: LocalStorageKey, keyPostfix?: string): void {
    const postfixedKey = this.getPostfixedKey(key, keyPostfix);

    this.window.localStorage.removeItem(postfixedKey);

    this.localStorageChange.next(postfixedKey);
  }
}
