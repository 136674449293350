import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormButtonModule } from 'components/form-button';

import { FormButtonsComponent } from './form-buttons.component';

@NgModule({
  declarations: [FormButtonsComponent],
  exports: [FormButtonsComponent],
  imports: [CommonModule, FormButtonModule],
})
export class FormButtonsModule {}
