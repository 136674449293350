import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { isHexColor, removeAlphaChanelIfOpaque } from './color-picker.utils';

/**
 * Color picker
 */
@UntilDestroy()
@Component({
  selector: 'isp-color-picker',
  templateUrl: './color-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    },
  ],
  styleUrls: ['./scss/color-picker.component.scss'],
})
export class ColorPickerComponent implements ControlValueAccessor, OnInit {
  private readonly colorPickerChangeDebounce = new Subject<void>();

  @Input() resetValue: string;

  value: string | null;

  inputValue: string;

  colorPickerValue: string;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  private setInputValueFromColor(color: string): void {
    this.inputValue = removeAlphaChanelIfOpaque(color).replace('#', '');
    this.cdr.markForCheck();
  }

  private setColorPickerValue(color: string): void {
    this.colorPickerValue = color;
    this.cdr.markForCheck();
  }

  private isColor(value: string | null): boolean {
    return isHexColor(value);
  }

  ngOnInit(): void {
    // @WARN 5ms was chosen empirically. It's largest debounce time with appealing UX
    this.colorPickerChangeDebounce
      .pipe(debounceTime(5), untilDestroyed(this))
      .subscribe(() => {
        this.writeValue(this.colorPickerValue);
      });
  }

  onInputValueChange(event: InputEvent): void {
    this.inputValue = (event.target as HTMLInputElement).value;
    this.setColorPickerValue(`#${this.inputValue}`);
  }

  onInputValueBlur(): void {
    this.writeValue(`#${this.inputValue}`);
  }

  onColorPickerChange(value: string): void {
    this.colorPickerValue = value;
    this.colorPickerChangeDebounce.next();
  }

  onColorPickerClose(): void {
    this.writeValue(this.colorPickerValue);
  }

  writeValue(value: string | null): void {
    const newValue = this.isColor(value) ? value : this.resetValue || '#ffffff';

    this.value = newValue;
    this.setColorPickerValue(newValue);
    this.setInputValueFromColor(newValue);

    this.onChange(newValue);
    this.onTouched();
    this.cdr.markForCheck();
  }

  onChange = (_: string | null): void => undefined;

  onTouched = (): void => undefined;

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
