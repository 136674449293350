import { Injectable } from '@angular/core';

import { AppService } from 'app/app.service';
import { delay, map, retryWhen } from 'rxjs/operators';

import { Api5Service } from './api5-service/api5.service';
import { MessageBusService } from './messagebus/messagebus.service';

/**
 * Timeout for checking server alive after reboot
 */
const CHECK_ALIVE_TIMEOUT = 2000;

/**
 * Service provide reboot state
 */
@Injectable({ providedIn: 'root' })
export class IndicateRebootService {
  /** Current bootTime */
  private bootTime: string | null = null;

  constructor(
    private readonly appService: AppService,
    private readonly bus: MessageBusService,
    private readonly api: Api5Service,
  ) {}

  private toggleProgressModal(state: boolean): void {
    this.bus.emit(
      'set-progress-modal',
      state
        ? {
            name: 'global-progress-modal',
            state: {
              comment: this.appService.getDesktopMessage(
                'msg_server_rebooting',
              ),
              percent: 100,
            },
          }
        : null,
    );
  }

  /**
   * Start observe server restart status
   */
  private startServerAliveCheck(): void {
    this.api
      .keepalive()
      .pipe(
        map(doc => {
          const bootTime = doc.boottime.$;
          if (bootTime === this.bootTime) {
            this.toggleProgressModal(true);
            // eslint-disable-next-line @typescript-eslint/no-throw-literal, rxjs/throw-error
            throw bootTime;
          }
        }),
        retryWhen(error => error.pipe(delay(CHECK_ALIVE_TIMEOUT))),
      )
      .subscribe(() => {
        this.toggleProgressModal(false);
      });
  }

  setRebootModal(time: string): void {
    if (time !== null) {
      this.bootTime = time;
      this.toggleProgressModal(true);

      this.startServerAliveCheck();
    }
  }
}
