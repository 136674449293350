import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Date picker field component
 */
@Component({
  selector: 'isp-formly-date-picker-field',
  templateUrl: './date-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerFieldComponent extends ISPFieldTypeBase<ISPFieldType.DatePicker> {}
