import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { AppService } from 'app/app.service';
import { IDocument } from 'app/services/api5-service/api.interface';
import { move } from 'ramda';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { TabPosition, TabStatus } from './model';
import { ActiveTabService } from './tab-active.service';
import { TabInitiallize } from './tab-initiallize.service';
import { TabPinnedService } from './tab-pinned.service';
import { TabStorage } from './tab-storage.service';
import { Tab } from './tab.class';
import { sortGroupListByPin } from './tab.utils';

import { handleExternalRedirects } from '../../../utils/handle-external-redirects';
import {
  PreloadedActionOptions,
  PreloadedActionService,
} from '../preloaded-action.service';

/**
 * Tab service
 * Controlled tabs in app
 *
 * @TODO i.ablov handle browser navigation bottons, popstate event etc.
 */
@Injectable({
  providedIn: 'root',
})
export class TabGroupService {
  /** Group list subject */
  private readonly groupsSubject = new BehaviorSubject<Tab[][]>([]);

  /** Group list stream */
  readonly groups$ = this.groupsSubject.asObservable();

  get groups(): Tab[][] {
    return this.groupsSubject.value;
  }

  constructor(
    private readonly activeTabService: ActiveTabService,
    private readonly tabInitiallize: TabInitiallize,
    private readonly tabStorage: TabStorage,
    private readonly tabPinned: TabPinnedService,
    private readonly preloadedActionService: PreloadedActionService,
    private readonly appService: AppService,
  ) {
    this.init();
  }

  /**
   * Tabs init logic
   * restore tab form localstorage, startpage, pinned
   */
  private init(): void {
    this.tabInitiallize
      .getInitialTabs$()
      .pipe(
        tap(({ tabs, activeTab }) => {
          // restore tabs groups
          this.updateTabGroups(tabs);

          // restore active tab, start watching for route changes
          this.activeTabService.init(this, activeTab);
          this.tabPinned.init(this);
          this.tabStorage.startWatching(this, this.activeTabService);
        }),
      )
      .subscribe();
  }

  /**
   * Remove group or tab list
   *
   * @param gIndex - group's index for delete
   * @param index - tab index for delete, if not null delete tab with children in group
   */
  private removeTabList(gIndex: number, index: number = null): void {
    if (index === null && gIndex >= 0) {
      // remove whole group, if no child index provided
      const removedGroupds = this.groupsSubject.value.splice(gIndex, 1);
      removedGroupds.flat().forEach(tab => tab.onDestroy());
      this.updateTabGroups();
    } else if (index >= 0 && gIndex >= 0) {
      // remove certain child in group
      const removedTab = this.groupsSubject.value[gIndex].splice(index);
      removedTab.forEach(tab => tab.onDestroy());
      this.updateTabGroups();
    }
  }

  /**
   * Get postion tab in tab array
   *
   * @param tab - tab instance
   */
  getTabPosition(tab?: Tab): TabPosition {
    if (!tab) {
      return {
        index: -1,
        gIndex: -1,
      };
    }
    for (let i = 0; i < this.groupsSubject.value.length; i++) {
      const group = this.groupsSubject.value[i];
      const activeTabIndex = group.findIndex(t => t === tab);
      if (activeTabIndex !== -1) {
        return {
          index: activeTabIndex,
          gIndex: i,
        };
      }
    }
    return {
      index: -1,
      gIndex: -1,
    };
  }

  /**
   * Add new tab as new tab group
   *
   * @param tab - tab item to add
   * @param instead - tab to replace
   */
  add(tab: Tab, instead?: Tab): void {
    if (instead && !instead.isPinned) {
      const tabGroupToReplace = this.groupsSubject.value.findIndex(
        group => group[0] === instead,
      );

      if (tabGroupToReplace >= 0) {
        this.groupsSubject.value.splice(tabGroupToReplace, 1, [tab]);
        this.updateTabGroups();
        return;
      }
    }
    this.groupsSubject.value.push([tab]);
    this.updateTabGroups();
  }

  /**
   * Add new tab as child of some other tab
   *
   * @WARN will delete all previous prentTab childs, and replace it with new tab!
   * @param tab - tab item to add
   * @param parentTab - existed parent tab
   */
  addChild(tab: Tab, parentTab: Tab): void {
    const { index, gIndex } = this.getTabPosition(parentTab);

    if (gIndex < 0) {
      return;
    }

    // remove old children
    if (this.groupsSubject.value[gIndex].length > 1) {
      this.removeTabList(gIndex, index + 1);
    }

    this.groupsSubject.value[gIndex].push(tab);
    this.updateTabGroups();
  }

  /**
   * Create new tab item of group
   *
   * @param doc - doc instance
   * @param instead - tab to replace
   */
  create(doc: IDocument, instead?: Tab): Tab {
    const tab = Tab.createFromDoc(doc, this.appService.desktop);

    this.add(tab, instead);

    return tab;
  }

  /**
   * Create child tab
   *
   * @param doc - doc instance
   * @param parentTab - parent tab
   */
  createChild(doc: IDocument, parentTab: Tab): Tab | null {
    if (!this.isTabExist(parentTab)) {
      return null;
    }

    const newTab = Tab.createFromDoc(doc, this.appService.desktop);

    this.addChild(newTab, parentTab);

    return newTab;
  }

  getTabParent(tab: Tab): Tab | undefined {
    const { gIndex, index } = this.getTabPosition(tab);
    return this.groupsSubject.value[gIndex]?.[index - 1];
  }

  isTabExist(tab: Tab): boolean {
    return this.groupsSubject.value.flat().some(t => t === tab);
  }

  isChildTab(tab: Tab): boolean {
    const { index } = this.getTabPosition(tab);
    return index > 0;
  }

  /**
   * Close tab
   *
   * @param tabToClose - tab instance
   * @param isUpdateParent - should group parent tab be updated after tab closing
   */
  close(
    tabToClose: Tab,
    isUpdateParent: boolean = tabToClose.status === TabStatus.Changed,
  ): void {
    const { index, gIndex } = this.getTabPosition(tabToClose);

    if (!this.isChildTab(tabToClose)) {
      this.removeTabList(gIndex);
    } else {
      const parentToUpdate = isUpdateParent
        ? this.getTabParent(tabToClose)
        : null;
      this.removeTabList(gIndex, index);

      if (parentToUpdate) {
        this.updateTabDocFromServer(parentToUpdate)
          .pipe(tap(() => this.activeTabService.setActive(parentToUpdate)))
          .subscribe();
      }
    }
  }

  /**
   * Close group by tab
   *
   * @param tabToClose - tab related to group to close
   */
  closeGroup(tabToClose: Tab): void {
    const { gIndex } = this.getTabPosition(tabToClose);

    const tabParent = this.groupsSubject.value[gIndex][0];
    this.close(tabParent);
  }

  /**
   * Close active tabs group
   *
   * @WARN will not close active tab, if it's pinned
   */
  closeActiveGroup(): void {
    const { gIndex: activeGIndex } = this.getTabPosition(
      this.activeTabService.activeTab,
    );
    const shouldCloseActiveGroup =
      activeGIndex > 0 && !this.groupsSubject.value[activeGIndex][0].isPinned;
    if (shouldCloseActiveGroup) {
      this.removeTabList(activeGIndex);
    }
  }

  /**
   * Update doc in tab.
   *
   * @WARN can close tab, if doc demands it
   * @param tab - tab instance
   * @param doc - doc instance
   */
  updateTabDoc(tab: Tab, doc: IDocument): void {
    if (this.isTabExist(tab)) {
      if (handleExternalRedirects(doc)) {
        this.close(tab);
      } else {
        tab.updateDoc(doc, this.appService.desktop);
      }
    }
  }

  /**
   * Refetch tab doc data from server, and update tab state
   *
   * @param tab - tab instance
   * @param options - preloaded request options
   * @param options.additionalParams
   * @param options.preloadedActionOptions
   */
  updateTabDocFromServer(
    tab: Tab,
    options?: {
      additionalParams?: Params;
      preloadedActionOptions?: PreloadedActionOptions;
    },
  ): Observable<IDocument> {
    return this.preloadedActionService
      .getAction(
        options?.additionalParams
          ? {
              ...tab.params,
              ...options.additionalParams,
            }
          : tab.params,
        options?.preloadedActionOptions,
      )
      .pipe(tap(doc => this.updateTabDoc(tab, doc)));
  }

  /**
   * Get first tab, that match for predicate condition
   *
   * @param predicate - match function
   */
  findTab(predicate: (tab: Tab) => boolean): Tab | undefined {
    return this.groupsSubject.value.flat().find(predicate);
  }

  /**
   * Get stream for first tab, that match for predicate condition
   *
   * @param predicate - match function
   */
  findTab$(predicate: (tab: Tab) => boolean): Observable<Tab | undefined> {
    return this.groupsSubject.pipe(map(() => this.findTab(predicate)));
  }

  /**
   * Return first pinned tab in group by func
   *
   * @param func
   */
  getPinnedTabByFunc(func: string): Tab | undefined {
    return this.groupsSubject.value.find(
      group => group[0].isPinned && group[0].func === func,
    )?.[0];
  }

  /**
   * Rearranges the tabs according to drag'n'drop event
   *
   * @param event - drag'n'drop event
   * @param event.currentIndex
   * @param event.previousIndex
   */
  rearrangeTabs({ currentIndex, previousIndex }: CdkDragDrop<Tab[][]>): void {
    const newIndex = currentIndex || 1;
    if (newIndex === previousIndex) {
      return;
    }

    const newGroupList = move(
      previousIndex,
      newIndex,
      this.groupsSubject.value,
    );

    this.updateTabGroups(newGroupList);
  }

  /**
   * Update current tab groups subject
   *
   * @param groups
   */
  updateTabGroups(groups: Tab[][] = this.groupsSubject.value): void {
    this.groupsSubject.next(sortGroupListByPin(groups));
  }
}
