import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';
import { ISPFileModule } from 'components/file/file.module';

import { FileFieldComponent } from './file.component';

import { ISPFieldType } from '../../model';

/**
 * Formly's file field component
 */
@NgModule({
  declarations: [FileFieldComponent],
  exports: [FileFieldComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.InputFile,
          component: FileFieldComponent,
        },
      ],
    }),
    ISPFileModule,
  ],
})
export class FileFieldModule {}
