import {
  IField,
  ICaptcha,
  IDocument,
} from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

import { CaptchaTO } from './model/captcha-to.interface';

import {
  ISPFormState,
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
} from '../../model';

export const RECAPTCHA_RESOLVE_KEY = 'g-recaptcha-response';

/**
 * Get config for captcha field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getCaptchaConfig(
  control: ICaptcha,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.Captcha> {
  const recapchaVersion = DocHelper.getValue('recaptcha_type', state.doc);
  if (recapchaVersion !== '2' && recapchaVersion !== '3') {
    // unknown recapcha version
    return;
  }
  const recaptchaType = recapchaVersion === '2' ? 'normal' : 'invisible';

  const templateOptions: CaptchaTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    // @WARN we use only v2 version! BE send '3', but this means v2 'invisible' recapcha
    recaptchaType,
    recaptchaToken: DocHelper.getValue(control.$name, state.doc) as string,
    layoutDisplayInGroup: recaptchaType === 'normal',
  };

  const config: ISPFieldConfig<ISPFieldType.Captcha> = {
    key: RECAPTCHA_RESOLVE_KEY,
    type: ISPFieldType.Captcha,
    templateOptions,
    wrappers:
      recaptchaType === 'normal' ? [ISPFieldWrapper.ValidationError] : [],
  };

  return config;
}

/**
 * Check that doc have invisible recaptcha error
 *
 * @param doc
 */
export function isInvisibleRecaptchaError(doc: IDocument): boolean {
  const haveRecaptchaError = doc.error?.$object === 'recaptcha_field';
  const isInvisibleRecaptcha =
    DocHelper.getValue('recaptcha_type', doc) === '3';
  return haveRecaptchaError && isInvisibleRecaptcha;
}
