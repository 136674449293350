import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { ExtformPreviewService } from './preview.service';

@Component({
  selector: 'isp-extform-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./scss/preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtformPreviewComponent {
  readonly displayShadow$ = this.previewService.displayShadow$;

  readonly backgroundStyles$ = combineLatest([
    this.previewService.tiling$,
    this.previewService.horizontalStretch$,
    this.previewService.verticalStretch$,
    this.previewService.color$,
    this.previewService.imageSrc$,
    this.previewService.backgroundType$,
  ]).pipe(
    map(([tiling, horizontal, vertical, color, src, type]) => {
      const style: Partial<CSSStyleDeclaration> =
        type === 'color'
          ? {
              backgroundColor: color || 'transparent',
            }
          : {
              backgroundImage: `url(${src})`,
            };

      switch (true) {
        case !horizontal && !vertical:
          style.backgroundSize = '100px auto';
          break;
        case horizontal && vertical:
          style.backgroundSize = 'cover';
          break;
        case !horizontal && vertical:
          style.backgroundSize = 'auto 100%';
          break;
        case horizontal && !vertical:
          style.backgroundSize = '100% auto';
          break;
      }

      style.backgroundRepeat = tiling ? 'repeat' : 'no-repeat';

      return style;
    }),
  );

  @Input() previewName: 'center' | 'right';

  constructor(private readonly previewService: ExtformPreviewService) {}
}
