<isp-file
  [label]="to.caption"
  [accept]="to?.originalControl?.$accept || ''"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [disabled]="to.disabled"
  [multiple]="to?.originalControl?.$multiple"
  [required]="to.required"
  (customFocus)="toggleFocus(true)"
  (customBlur)="toggleFocus(false)"
>
</isp-file>
