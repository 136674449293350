import { AnimationOptions } from 'ngx-lottie';

const ANIMATED_ICONS_PATH = '/manimg/dragon/assets/icons/animated';

/**
 * Returns animation options for Lottie player
 *
 * @param iconName - name of icon
 */
export function getAnimationOptions(iconName: string): AnimationOptions {
  return {
    renderer: 'svg',
    autoplay: true,
    loop: true,
    path: `${ANIMATED_ICONS_PATH}/${iconName}.json`,
    rendererSettings: {
      progressiveLoad: true,
    },
  };
}
