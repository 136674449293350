import {
  IDocument,
  IField,
  IMListMessageType,
  IMListRoot,
  ITicket,
} from 'app/services/api5-service/api.interface';
import { IChatBubble } from 'components/chat-bubble';

import { getLabel } from 'common/dynamic-form/utils/get-label';
import { DocHelper } from 'utils/dochelper';

import { ChatTO } from './model/chat-to.interface';

import { ISPFieldConfig, ISPFieldType, ISPFormState } from '../../model';

/** Interface for a brackets pair of a specific type */
interface IBraketsPair {
  /** Opening bracket character */
  openingBraket: string;
  /** Closing bracket character */
  closingBraket: string;
}

const braketsPairList: IBraketsPair[] = [
  { openingBraket: '(', closingBraket: ')' },
  { openingBraket: '[', closingBraket: ']' },
  { openingBraket: '{', closingBraket: '}' },
];
/**
 * Returns icon name for rate
 *
 * @param icon - name of icon from backend
 */
export function getRateIconName(icon: string): string {
  switch (icon) {
    case 'p-like':
      return 'like';
    case 'p-dislike':
      return 'dislike';
    default:
      return '';
  }
}

/**
 * Get svg icon name for deafult avatar displaying
 *
 * @param type - user avatar type
 */
export function defaultAvatarIcon(type?: string): string {
  switch (type) {
    case 'customer':
      return 'client_avatar';
    case 'provider':
      return 'support_avatar';
    default:
      return 'tech_message';
  }
}

/**
 * Check if the closing braket at line end has a matching opening braket in the line
 *
 * @param braketsPair - brackets pair of matching type
 * @param stringWithBrakets - processed string with brakets
 */
function checkBracketPair(
  braketsPair: IBraketsPair,
  stringWithBrakets: string,
) {
  let isFoundPairForBraket = false;
  let skip = 0;
  const braketsInLink = new RegExp(
    `[^\\${braketsPair.openingBraket}\\${braketsPair.closingBraket}]`,
    'g',
  );
  const onlyBrakets = stringWithBrakets.replace(braketsInLink, '');
  for (
    let index = onlyBrakets.length - 2;
    index >= 0 && !isFoundPairForBraket;
    index--
  ) {
    if (onlyBrakets[index] === braketsPair.openingBraket) {
      isFoundPairForBraket = --skip < 0;
    } else {
      skip++;
    }
  }
  return isFoundPairForBraket;
}

/**
 * Get the HTML markup of the link to replace the found match in the regular expression
 *
 * @param _ - regular expression match found (not used in method)
 * @param stringForLink - the string from which the link will be generated
 * @param specialSymbolsAfterLink - special characters that will not be included in the link (.,!?'"<>)
 */
export function getLinkForReplace(
  _,
  stringForLink: string,
  specialSymbolsAfterLink: string,
): string {
  // looking for a pair of brackets with the matching bracket at string end for link
  const unpreparedLinkEnd = stringForLink[stringForLink.length - 1];
  const braketsPair = braketsPairList.find(
    brakets => brakets.closingBraket === unpreparedLinkEnd,
  );

  let isFoundBracket = false;

  // if there is no opening braket in string, you do not need to include braket at string end in link
  if (braketsPair && stringForLink.includes(braketsPair.openingBraket)) {
    isFoundBracket = checkBracketPair(braketsPair, stringForLink);
  }
  if (isFoundBracket || !braketsPair) {
    return `<a href="${stringForLink}" target="_blank">${stringForLink}</a>${specialSymbolsAfterLink}`;
  } else {
    const preparedLink = stringForLink.substring(0, stringForLink.length - 1);
    const afterLink = `${braketsPair.closingBraket}${specialSymbolsAfterLink}`;

    return `<a href="${preparedLink}" target="_blank">${preparedLink}</a>${afterLink}`;
  }
}

/**
 * Returns compatible messages from backend for chat bubble component
 *
 * @param mlist - message list metadata instance
 * @param doc - document instance
 */
export function getChatMessages(
  mlist: IMListRoot | undefined,
  doc: IDocument,
): IChatBubble[] {
  if (!mlist?.message) {
    return [];
  }

  const techTypes: IMListMessageType[] = ['inner', 'system', 'ticketnote'];

  return mlist.message
    .map(message => {
      const isMessageRated = Boolean(message.rates?.rate);

      const labels = {
        setRate: DocHelper.getMessage('msg_rate_clientsetrate', doc),
        alreadyRated: DocHelper.getMessage('msg_rate_rated', doc),
      };

      // message with type 'info' is displayed in 'isp-formly-chat-summary'
      if (message.$type === 'info') {
        return null;
      }

      const messageText = message.body?.$ || '';
      const bodyHtmlSafety = messageText
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');
      // insert link tags
      const bodyWithLinks = bodyHtmlSafety.replace(
        // ((1)://2)(3)
        // 1 - link protocol (http, https, ftp, ftps)
        // 2 - selects the protocol from the first group and:
        // 2.1 domain name or IP address and port
        // 2.2 path (/path/to/page)
        // 2.3 additional query params (?query=params)
        // 2.4 an optional fragment component (#fragment)
        // 3 - characters that are not explicitly included in the reference
        /((?:http[s]?|ftp[s]?):\/\/.+?)(\??&lt;|\??&gt;|"|\s|$|[,.?!'](?:\s|$))/g,
        getLinkForReplace,
      );
      const baseBubble: IChatBubble = {
        id: message.$id,
        type: message.$type,
        labels,
        chatTitle:
          message.$type === 'system'
            ? message.user?.position?.$
            : message.user?.realname?.$,
        chatTitleCaption: message.date_post?.$,
        body: bodyWithLinks,
        align:
          message.$type === 'outcoming' ||
          message.$type === 'system' ||
          message.$type === 'inner' ||
          message.$type === 'ticketnote'
            ? 'right'
            : 'left',
        avatar: {
          isDefault:
            message.avatar?.$default === 'yes' ||
            techTypes.includes(message.$type),
          defaultSvg: defaultAvatarIcon(message.avatar?.$name),
          type: message.avatar?.$name,
          caption: message.user?.realname?.$,
          path: message.avatar?.$,
        },
        files: message.file?.filter(Boolean).map(file => ({
          title: file.name.$,
          size: file.size.$,
          action: file.$action,
          params: { [file.param.$name]: file.param.$ },
        })),
        rates: {
          canShowRates: Boolean(message.rates),
          isRated: isMessageRated,
          rates: isMessageRated
            ? [
                {
                  img: getRateIconName(message.rates?.rate?.$img),
                },
              ]
            : message.rates?.setrate.map(rate => ({
                img: getRateIconName(rate.$img),
                func: rate.$func,
              })),
        },
      };

      return baseBubble;
    })
    .filter(Boolean);
}

/**
 * Get config for chat field
 *
 * @param control - control metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getChatConfig(
  control: ITicket,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.Chat> {
  const templateOptions: ChatTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    messages: getChatMessages(
      state.doc.mlist?.find(mlist => mlist.$name === control.$name),
      state.doc,
    ),
    fontSize: control.$fontsize,
    fontFamily: control.$fontfamily,
    label: getLabel(field, state.doc),
  };

  return {
    key: control.$name,
    type: ISPFieldType.Chat,
    templateOptions,
  };
}

/**
 * Add messages to chat config, sorting messages and removing dulicates
 *
 * @param config - chat component config
 * @param mlist - mlist with new messages
 * @param doc - doc instance
 */
export function addMessagesToChatConfig(
  config: ISPFieldConfig<ISPFieldType.Chat>,
  mlist: IMListRoot,
  doc: IDocument,
) {
  const oldMessages = config.templateOptions.messages;
  const newMessages = getChatMessages(mlist, doc);

  // it's to remove duplicate messages, Backend can send them to send messages,
  // perhaps then it's worth investigating why
  const oldMessagesIds = new Set(
    oldMessages.map(message => message.id).filter(id => Boolean(id)),
  );
  const uniqueNewMessages = newMessages.filter(
    message => !oldMessagesIds.has(message.id),
  );
  if (!uniqueNewMessages.length) {
    return;
  }

  const resumeIndex = oldMessages.findIndex(
    message => message.type === 'ticketnote',
  );
  if (resumeIndex >= 0) {
    const messages = [...oldMessages];
    // insert new messages before resume
    messages.splice(resumeIndex, 0, ...uniqueNewMessages);
    config.templateOptions.messages = messages;
  } else {
    // just insert new messages in the end of the list, with ref overriding
    config.templateOptions.messages = [...oldMessages, ...uniqueNewMessages];
  }
}
