import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Color picker field component
 *
 * Use only with Formly
 */
@Component({
  selector: 'isp-formly-color-picker-field',
  templateUrl: './color-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPickerFieldComponent extends ISPFieldTypeBase<ISPFieldType.ColorPicker> {}
