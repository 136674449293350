import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ISPUIFileModule } from '@ispui/ng-file';

import { FileComponent } from './file.component';

@NgModule({
  declarations: [FileComponent],
  exports: [FileComponent],
  imports: [CommonModule, ISPUIFileModule, FormsModule],
})
export class ISPFileModule {}
