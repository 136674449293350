import { Injectable } from '@angular/core';

import { ISPUITooltipService } from '@ispui/tooltip';

/**
 * Tooltip service
 */
@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  /** Original service from isp-ui */
  private readonly ispuiTooltipService = new ISPUITooltipService();

  /** Only one tooltip popup can be opened in same time */
  setOnlyOnePopupMode(): void {
    this.ispuiTooltipService.isOnlyOnePopup = true;
  }

  /** Many tooltip popups can be opened in same time */
  setManyPopupMode(): void {
    this.ispuiTooltipService.isOnlyOnePopup = false;
  }
}
