/**
 * Field types for Dragon theme
 */
export enum ISPFieldType {
  InputText = 'input-text',
  DatePicker = 'date-picker',
  Select = 'select',
  AutocompleteSelect = 'autocomplete',
  Radio = 'radio',
  RadioImg = 'radioimg',
  Switcher = 'switcher',
  Checkbox = 'checkbox',
  ColorPicker = 'color-picker',
  Hidden = 'hidden',
  TextData = 'textdata',
  Frame = 'frame',
  List = 'list',
  ListFilter = 'list-filter',
  Slider = 'slider',
  Link = 'link',
  Password = 'password',
  TextArea = 'textarea',
  PhoneGroup = 'phone-group',
  InputFile = 'file',
  Tree = 'tree',
  Code = 'code',
  DateTime = 'datetime',
  Group = 'group',
  Template = 'template',
  Layout = 'layout',
  Chat = 'chat',
  Captcha = 'captcha',
  ChatSummary = 'chat-summary',
  SiteSummary = 'site-summary',
  FormScroller = 'form-scroller',
  TextEditor = 'texteditor',
}

/**
 * All field types with form controls
 */
export type ISPFieldTypeWithControl = Exclude<
  ISPFieldType,
  | ISPFieldType.ChatSummary
  | ISPFieldType.FormScroller
  | ISPFieldType.Group
  | ISPFieldType.Layout
  | ISPFieldType.ListFilter
  | ISPFieldType.PhoneGroup
  | ISPFieldType.SiteSummary
  | ISPFieldType.Template
>;
