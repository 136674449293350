<span class="chat-bubble__rate-text">{{
  rates.isRated ? labels.alreadyRated : labels.setRate
}}</span>
<button
  *ngFor="let rate of rates.rates; trackBy: trackByRate"
  (click)="handleRateClick(rate)"
  [disabled]="rates.isRated"
  class="chat-bubble__rate-button"
  [ngClass]="{
    'chat-bubble__rate-button_like': rate.img === 'like',
    'chat-bubble__rate-button_dislike': rate.img === 'dislike'
  }"
>
  <isp-svg-icon
    [name]="rate.img"
    [customStyle]="{ width: '15px', height: '15px' }"
  >
  </isp-svg-icon>
</button>
