import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IChatBubble } from './model/chat-bubble.interface';

@Component({
  selector: 'isp-chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./scss/chat-bubble.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatBubbleComponent {
  /** Chat bubble data */
  @Input() data: IChatBubble;
}
