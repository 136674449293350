import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormlyFieldConfig } from '@ngx-formly/core';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';

import { convertDateToString } from 'utils/convert-date-to-string';
import { convertStringToDateWithOffset } from 'utils/convert-string-to-date-with-offset';

/**
 * Date picker field component
 */
@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
  selector: 'isp-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./scss/date-picker.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent
  implements AfterViewInit, ControlValueAccessor
{
  /** Date picker component */
  @ViewChild(NzDatePickerComponent)
  private readonly datePicker: NzDatePickerComponent;

  private _value: Date | null;

  /** Is datepicker field has focus */
  private focused = false;

  /** Is datepicker opened */
  private opened = false;

  @Input()
  datePickMode: 'date' | 'month' = 'date';

  @Input()
  mask: string;

  @Input()
  inputLabel: string;

  @Input()
  required: boolean;

  @Input()
  disabled: boolean;

  @Input()
  showError: boolean;

  @Input()
  field: FormlyFieldConfig;

  @Input()
  tabindex: number;

  set value(value: Date | null) {
    this._value = value;

    if (value !== null) {
      this.onChange(convertDateToString(value, this.datePickMode));
    } else {
      this.onChange('');
    }

    this.onTouch();
  }

  get value() {
    return this._value;
  }

  /** Show field highlighting */
  get showHighlight(): boolean {
    return this.focused || this.opened;
  }

  /** Is label in active state - arised above */
  get isLabelActive(): boolean {
    return Boolean(this.focused || this.opened || this.value);
  }

  /**
   * Set mask for datepicker input element
   * if needed and pick mode is `date`
   */
  private setMask(): void {
    const nativeInput = this.datePicker.pickerInput.nativeElement;

    if (this.mask === '9999-99-99' && this.datePickMode === 'date') {
      Inputmask({
        mask: '99.99.9999',
        showMaskOnHover: false,
      }).mask(nativeInput);
    }
  }

  private onChange: (value: string) => void = () => undefined;

  private onTouch: () => void = () => undefined;

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  writeValue(dateString: string): void {
    const parsedDateValue = convertStringToDateWithOffset(dateString);
    this._value = parsedDateValue;
  }

  registerOnTouched(onTouch: () => void): void {
    this.onTouch = onTouch;
  }

  ngAfterViewInit(): void {
    this.setMask();
  }

  /**
   * Handling datepicker popup toggling
   *
   * @param state - popup state
   */
  onDatePickerStateChange(state: boolean): void {
    if (this.opened && !state) {
      this.onTouch();
    }

    this.opened = state;
  }

  /**
   * On date picker focus change
   *
   * @param focus - focus state
   */
  onFocusChange(focus: boolean): void {
    this.focused = focus;
  }
}
