import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo } from 'rxjs/operators';

import { ISPFieldWrapperBase } from 'common/dynamic-form/model/field-wrapper-base.class';
import {
  DYNAMIC_FORM_FOOTER_SELECTOR,
  DYNAMIC_FORM_HEADER_SELECTOR,
  DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR,
} from 'common/dynamic-form/services/layout.service';

/**
 * Base field wrapper component for Formly
 */
@UntilDestroy()
@Component({
  selector: 'isp-formly-field-base',
  templateUrl: './field-base.component.html',
  styleUrls: ['./scss/field-base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldBaseComponent extends ISPFieldWrapperBase implements OnInit {
  /**
   * @HACK
   * ispui-tooltip doesn't watch for clicks outside it's component, so it can't determine when it should be closed
   * so we use this static distincted listener, to determine clicks outside field
   * @TODO add global click watching in ispui-tooltip component
   */
  private static readonly documentClick: Observable<HTMLElement> = fromEvent(
    document,
    'mousedown',
  ).pipe(
    map((event: MouseEvent) => event.target as HTMLElement),
    distinctUntilChanged(),
  );

  get hintAttachContainer(): string {
    switch (this.to.layoutPlace) {
      case 'header':
        return DYNAMIC_FORM_HEADER_SELECTOR;
      case 'footer':
        return DYNAMIC_FORM_FOOTER_SELECTOR;
      default:
        return DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR;
    }
  }

  get isForceHint(): boolean {
    return this.options.formState.context.forceHint;
  }

  /** Icon sizes */
  readonly iconSize = {
    width: '15px',
    height: '14px',
  };

  @ViewChild('tooltip') tooltip: ElementRef<HTMLIspuiTooltipElement>;

  /** Hint placement */
  get hintPlace(): 'field' | 'label' {
    return this.to.hintPlace === 'label' ? 'label' : 'field';
  }

  constructor(private readonly host: ElementRef<HTMLElement>) {
    super();
  }

  @HostListener('change', ['$event']) onChange(): void {
    if (this.isForceHint) {
      this.tooltip?.nativeElement.toggle(true);
    }
  }

  @HostListener('focusin', ['$event']) onFocusIn(): void {
    if (this.isForceHint) {
      this.tooltip?.nativeElement.toggle(true);
    }
  }

  ngOnInit(): void {
    if (this.isForceHint) {
      FieldBaseComponent.documentClick
        .pipe(
          filter(target => !this.host.nativeElement.contains(target)),
          mapTo(false),
          untilDestroyed(this),
        )
        .subscribe(state => {
          this.tooltip?.nativeElement.toggle(state);
        });
    }
  }

  /**
   * Get hint width shadow part
   */
  getHint(): string {
    if (this.to.isShadow) {
      return `${this.to.hint} </br></br><strong>${this.to.shadowHint}</strong>`;
    }
    return this.to.hint;
  }

  /**
   * Get plainhint message
   */
  getPlainHint(): string {
    if (!this.to.plainhints) return '';

    const fieldValue = this.form.getRawValue()[this.to.controlWithPlainHint];

    return this.to.plainhints[
      `hint_${this.to.controlWithPlainHint}__${fieldValue}`
    ];
  }
}
