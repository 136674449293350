import {
  IDocument,
  IRadioImg,
  ISelect,
  SelectType,
} from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

export interface RadioImgControlWithValue {
  readonly control: IRadioImg;
  readonly type: 'radioimg';
  value: string[] | string;
}

/**
 * Check that input control meta is radio image input meta
 *
 * @param control - control metadata
 */
export function isControlRadioImg(control: ISelect): boolean {
  return (
    // @WARN there is REAL RadioImg type on the BE, but for now we use simple select to display them
    // ...and use radioimg field for RadioImgRender.
    control.$type === SelectType.RadioImgRender
  );
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param control - control metadata
 * @param doc - doc
 */
export function getTypeControlForRadioImg(
  control: ISelect,
  doc: IDocument,
): RadioImgControlWithValue {
  return {
    value: DocHelper.getStringValue(control.$name, doc),
    control: control as IRadioImg,
    type: 'radioimg',
  };
}
