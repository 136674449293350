<ng-container *ngIf="displayHeader && title.length">
  <header
    *ngIf="collapsible; else notCollapsibleHeader"
    [class]="
      'form-collapse__header form-collpase__header_' + classNameModificator
    "
    [ngClass]="{ 'form-collapse__header_opened': opened }"
    [tabIndex]="0"
    (click)="toggleCollapse()"
    role="button"
    (keydown.space)="toggleCollapse()"
    (keydown.enter)="toggleCollapse($event)"
    [attr.aria-expanded]="opened"
    [attr.aria-controls]="id"
    [attr.aria-label]="title"
    #header
  >
    <ispui-tooltip
      placement="topRight"
      [disabled]="!headerTooltip"
      [title-text]="headerTooltip"
    >
      <isp-svg-icon
        [name]="icon"
        [customStyle]="iconStyles"
        class="form-collapse__icon"
      ></isp-svg-icon>
    </ispui-tooltip>
    {{ title }}

    <ispui-tooltip
      *ngIf="canRefresh"
      class="form-collapse__icon_refresh"
      placement="topRight"
      [title-text]="refreshTooltipMsg"
    >
      <isp-svg-icon
        name="t-retry"
        [customStyle]="refreshIconStyles"
        class="form-collapse__icon"
        [class.form_collapse__icon_is_spinning]="isRefreshing"
        (click)="refreshContent($event)"
        (keyup.enter)="refreshContent($event)"
        (keyup.space)="refreshContent($event)"
        [tabIndex]="0"
        role="button"
      ></isp-svg-icon>
    </ispui-tooltip>
  </header>
  <ng-template #notCollapsibleHeader>
    <header
      [class]="
        'form-collapse__header_not-expandable form-collpase__header_' +
        classNameModificator
      "
      [attr.aria-controls]="id"
      [attr.aria-label]="title"
    >
      {{ title }}
    </header>
  </ng-template>
</ng-container>
<div
  [class]="'form-collapse__wrap form-collapse__wrap_' + classNameModificator"
  [id]="id"
  [ngClass]="{
    'form-collapse__wrap_hidden': !opened && displayHeader
  }"
>
  <ng-content></ng-content>
</div>
