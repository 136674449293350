import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';
import { ISPColorPickerModule } from 'components/color-picker/color-picker.module';

import { ColorPickerFieldComponent } from './color-picker.component';

import { ISPFieldType } from '../../model';
/**
 * Formly's input field component
 */
@NgModule({
  declarations: [ColorPickerFieldComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ISPColorPickerModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.ColorPicker,
          component: ColorPickerFieldComponent,
        },
      ],
    }),
    FormsModule,
  ],
})
export class ColorPickerFieldModule {}
