import {
  IDocument,
  ISelect,
  SelectType,
} from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

export interface SwitcherControlWithValue {
  readonly control: ISelect;
  readonly type: 'switcher';
  value: string[] | string;
}

/**
 * Check that input control meta is switcher input meta
 *
 * @param control - control metadata
 */
export function isControlSwitcher(control: ISelect): boolean {
  return control.$type === SelectType.Switcher;
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param control - control metadata
 * @param doc - doc
 */
export function getTypeControlForSwitcher(
  control: ISelect,
  doc: IDocument,
): SwitcherControlWithValue {
  return {
    value: DocHelper.getStringValue(control.$name, doc),
    control,
    type: 'switcher',
  };
}
