import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyConfig } from '@ngx-formly/core';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  share,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import { TrianglePositions } from '@ispui/validation-error';

import { DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR } from 'common/dynamic-form/services/layout.service';
import { prefix } from 'common/form-id-prefix/form-id-prefix.utils';
import { awaitStencilElement } from 'utils/await-stencil-element';
import {
  calculatePosition,
  PositioningResult,
  PositioningSide,
} from 'utils/positioning';

import { getErrorMessage$ } from './validation-error.utils';

import { ISPFieldWrapperBase } from '../../model';

@UntilDestroy()
@Component({
  selector: 'isp-formly-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./scss/validation-error.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationErrorWrapperComponent
  extends ISPFieldWrapperBase
  implements DoCheck, OnInit, AfterViewInit
{
  /** Validation state subject. True is field is invalid, otherwise false */
  private readonly showErrorSubject: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  /** Validation popup triangle position */
  get trianglePosition(): TrianglePositions {
    return this.getTrianglePosition(this.positioning.side);
  }

  /** Flag to delay popup displaying, in order to calculate it's position */
  isPopupVisible = false;

  openedState$: Observable<boolean>;

  errorMessage$: Observable<string>;

  /** Validation popup positioning */
  positioning: PositioningResult = {
    side: 'right',
    offset: '0px',
    slide: '0px',
  };

  @ViewChildren('errorPopup') popup: QueryList<ElementRef<HTMLElement>>;

  constructor(
    private readonly host: ElementRef,
    private readonly formlyConfig: FormlyConfig,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  private getTrianglePosition(side: PositioningSide): TrianglePositions {
    switch (side) {
      case 'bottom':
        return 'top';
      case 'top':
        return 'bottom';
      case 'left':
        return 'right';
      case 'right':
        return 'left';
    }
  }

  private recalculatePopupPosition(popup: HTMLElement): void {
    this.positioning = calculatePosition({
      popup,
      anchor: this.host.nativeElement,
      container: prefix(
        this.formState.layoutService,
        DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR,
      ),
      sidePriority: this.to.validationPopupSidePriority,
    });
  }

  private popupElement$(): Observable<HTMLElement> {
    return this.popup.changes.pipe(
      startWith(this.popup),
      map(
        (popupQueryList: QueryList<ElementRef<HTMLElement>>) =>
          popupQueryList.first?.nativeElement,
      ),
      filter(popup => Boolean(popup)),
      switchMap(popup => from(awaitStencilElement(popup, { lazy: true }))),
    );
  }

  ngOnInit(): void {
    this.errorMessage$ = getErrorMessage$(this.field, this.formlyConfig);

    this.openedState$ = combineLatest([
      this.errorMessage$,
      this.showErrorSubject.pipe(distinctUntilChanged()),
    ]).pipe(
      map(([message, showError]) =>
        Boolean(!this.to.doNotShowValidationError && message && showError),
      ),
      share(),
    );
  }

  ngDoCheck(): void {
    // convert Formly to and showError fields changing into stream
    this.showErrorSubject.next(this.showError);
  }

  ngAfterViewInit(): void {
    this.openedState$
      .pipe(
        distinctUntilChanged(),
        switchMap(state => {
          this.isPopupVisible = false;
          this.cdr.markForCheck();
          return state ? this.popupElement$() : of(null);
        }),
        switchMap(popup =>
          // now we can position properly popup. But we should recalculate position, after content change
          // so, subscribe to popup content
          popup
            ? this.errorMessage$.pipe(
                distinctUntilChanged(),
                // should not be recalculated, if no error msg available
                filter(msg => Boolean(msg)),
                // delay time for angular rendering new content in popup, before calculation
                debounceTime(0),
                tap(() => {
                  this.recalculatePopupPosition(popup);
                  this.isPopupVisible = true;
                  this.cdr.markForCheck();
                }),
              )
            : of(null),
        ),
        untilDestroyed(this),
      )
      .subscribe();
  }

  /**
   * Close field warning
   */
  handleCloseWarning(): void {
    this.formControl.markAsUntouched();
  }
}
