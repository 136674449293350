import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  NestedTreeNodeDirective,
  TreeNodeDefDirective,
  TreeNodeDirective,
} from './node.directive';
import { TreeNodeOutletDirective } from './outlet.directive';
import { TreeNodePaddingDirective } from './padding.directive';
import { TreeNodeToggleDirective } from './toggle.directive';
import { TreeComponent } from './tree.component';

/**
 * Tree component component
 */
@NgModule({
  declarations: [
    TreeNodeOutletDirective,
    NestedTreeNodeDirective,
    TreeNodeDefDirective,
    TreeNodeDirective,
    TreeNodeOutletDirective,
    TreeNodePaddingDirective,
    TreeNodeToggleDirective,
    TreeComponent,
  ],
  imports: [CommonModule],
  exports: [
    TreeNodeOutletDirective,
    NestedTreeNodeDirective,
    TreeNodeDefDirective,
    TreeNodeDirective,
    TreeNodeOutletDirective,
    TreeNodePaddingDirective,
    TreeNodeToggleDirective,
    TreeComponent,
  ],
})
export class TreeModule {}
