import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * `@type=file` input field component
 *
 * Use only with Formly
 */
@Component({
  selector: 'isp-formly-file-field',
  templateUrl: './file.component.html',
  styleUrls: ['./scss/file.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileFieldComponent extends ISPFieldTypeBase<ISPFieldType.InputFile> {
  toggleFocus(isFocused = false): void {
    this.to.isFocused = isFocused;
  }
}
