import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PopupPlacement } from '@ispui/tooltip';

import { IFormButtonUi } from './model/form-button.interface';

/**
 * Form button component
 *
 * Usage:
 * ```html
 * <isp-form-button *ngFor="let button of buttonList$ | async" [button]="button"></isp-form-button>
 * ```
 */
@Component({
  selector: 'isp-form-button',
  templateUrl: './form-button.component.html',
  styleUrls: ['./scss/form-button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormButtonComponent {
  /** Button metadata */
  @Input() button: IFormButtonUi;

  /** Button click event */
  @Output() readonly btnClick = new EventEmitter<IFormButtonUi>();

  /** Hint attach container selector */
  @Input() hintAttachSelector: string;

  hintPlacementOrderStringified = JSON.stringify([
    'top',
    'right',
    'bottom',
    'left',
  ] as PopupPlacement[]);

  @Input() set hintPlacementOrder(order: PopupPlacement[]) {
    this.hintPlacementOrderStringified = JSON.stringify(order);
  }

  /** Hint message */
  get hint(): Observable<string> {
    return this.button.disabledSubject.pipe(
      map(disabled => (disabled ? this.button.hintDisabled : this.button.hint)),
    );
  }

  /** Size for button icons */
  readonly svgIconSize = {
    width: '20px',
    height: '20px',
  };

  /**
   * Sends the click event
   */
  handleButtonClick(): void {
    this.btnClick.emit(this.button);
  }
}
