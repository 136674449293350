import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { ISPUILinkModule } from '@ispui/ng-link';

import { LinkFieldComponent } from './link.component';

import { SvgIconModule } from '../../../svg-icon-module/svg-icon.module';
import { ISPFieldType } from '../../model/field-type.enum';

/**
 * Link formly field
 */
@NgModule({
  declarations: [LinkFieldComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ISPUILinkModule,
    SvgIconModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.Link,
          component: LinkFieldComponent,
        },
      ],
    }),
  ],
})
export class LinkFieldModule {}
