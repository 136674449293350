import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDocument } from 'app/services/api5-service/api.interface';
import { PreloadedActionOptions } from 'app/services/preloaded-action.service';
import { ActiveTabService, Tab, TabGroupService } from 'app/services/tab';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Injectable()
export class TabItemService {
  private readonly tabSubject: BehaviorSubject<Tab | null> =
    new BehaviorSubject(null);

  readonly tab$ = this.tabSubject.pipe(filter(tab => Boolean(tab)));

  get tab(): Tab | null {
    return this.tabSubject.value;
  }

  readonly doc$ = this.tab$.pipe(switchMap(tab => tab.doc$));

  get doc(): IDocument | null {
    return this.tabSubject.value?.doc || null;
  }

  readonly isActive$ = combineLatest([
    this.activeTabService.activeTab$,
    this.tab$,
  ]).pipe(map(([activeTab, tab]) => activeTab === tab));

  get isActive(): boolean {
    return this.activeTabService.activeTab === this.tab;
  }

  constructor(
    private readonly tabService: TabGroupService,
    private readonly activeTabService: ActiveTabService,
  ) {}

  setTab(tab: Tab): void {
    this.tabSubject.next(tab);
  }

  reloadTab(options?: {
    additionalParams?: Params;
    preloadedActionOptions?: PreloadedActionOptions;
  }): Observable<IDocument> {
    if (!this.tabSubject.value) {
      throw new Error(
        'You must provide Tab instance to tab.component as Input property!',
      );
    }

    return this.tabService
      .updateTabDocFromServer(this.tabSubject.value, options)
      .pipe(untilDestroyed(this));
  }

  close(): void {
    this.tabService.close(this.tabSubject.value);
  }
}
