/**
 * Converts date object to string 'YYYY-MM-dd'. BE can use only this format
 *
 * @param date - date object to convert
 * @param mode - Pick only month or any date
 * @returns formatted date string, or empty string if date is null
 */
export function convertDateToString(
  date: Date | null,
  mode: 'date' | 'month' = 'date',
): string {
  if (date === null) {
    return '';
  }

  switch (mode) {
    case 'month':
      return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}`;
    case 'date':
    default:
      return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(
        -2,
      )}-${`0${date.getDate()}`.slice(-2)}`;
  }
}
