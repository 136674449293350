import { ITextarea, IField } from 'app/services/api5-service/api.interface';

import { getInputPlaceholder } from 'common/dynamic-form/utils/get-input-placeholder';
import { getLabel } from 'common/dynamic-form/utils/get-label';
import { DocHelper } from 'utils/dochelper';

import { TextAreaTO } from './model/textarea-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';
import { isFullWidth } from '../../utils/is-full-width';

const DEFAULT_ROWS_COUNT = 10;

/**
 * Get config for textarea field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getTextareaConfig(
  control: ITextarea,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.TextArea> {
  const templateOptions: TextAreaTO = {
    fontFamily: control.$fontfamily,
    fontSize: control.$fontsize,
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    setValuesMinLength: Number(control.$svminlength),
    rows: parseInt(control.$rows, 10) || DEFAULT_ROWS_COUNT,
    height: control.$height,
    wrap: control.$wrap,
    bcolor: control.$bcolor,
    mixedPlaceholder: DocHelper.getMessage('placeholder_mixed_msg', state.doc),
    placeholder: getInputPlaceholder(field, state.doc),
    isMixed: state.mixedService.isControlMixed(control),
    isFullWidth: isFullWidth(field),
    quote:
      control.$quote === 'yes'
        ? {
            msg: DocHelper.getMessage('msg_quote', state.doc),
            hint: DocHelper.getMessage('hint_quote', state.doc),
          }
        : undefined,
    savedMessage: control.$savedmessage
      ? {
          msg: DocHelper.getMessage('msg_savedmessage', state.doc),
          hint: DocHelper.getMessage('hint_savedmessage', state.doc),
          func: control.$savedmessage,
        }
      : undefined,
    inputLabel: getLabel(field, state.doc),
  };

  const config: ISPFieldConfig<ISPFieldType.TextArea> = {
    key: control.$name,
    type: ISPFieldType.TextArea,
    wrappers: [ISPFieldWrapper.InputBase, ISPFieldWrapper.ValidationError],
    templateOptions,
    expressionProperties: {
      'templateOptions.isMixed': (_, formState) =>
        formState.mixedService.isControlMixed(control),
      'templateOptions.isFullWidth': () => isFullWidth(field),
    },
  };

  return config;
}

/**
 * Get new caret position and new message with inserted text at current cursor position, and
 * if necessary, delete text from nearest \n break before cursor up to cursor position
 *
 * @param textAreaElement - native textarea element
 * @param textToInsert - text to insert
 * @param removeLineBeforeCaret - delete text from wrap to caret
 */
export function getNewMessageAndPositionCaret(
  textAreaElement: HTMLTextAreaElement,
  textToInsert: string,
  removeLineBeforeCaret = false,
): { newMessage: string; newCaretPosition: number } {
  const textAreaText = textAreaElement.value;
  const caretPosition =
    textAreaText.length === 0 ? 0 : textAreaElement.selectionStart;
  const deleteFrom = removeLineBeforeCaret
    ? textAreaText.lastIndexOf('\n', caretPosition - 1) + 1
    : caretPosition;

  const textBefore = textAreaText.substring(0, deleteFrom);
  const textAfter = textAreaText.substring(caretPosition);
  const newMessage = textBefore + textToInsert + textAfter;
  const newCaretPosition = (textBefore + textToInsert).length;

  return {
    newMessage,
    newCaretPosition,
  };
}
