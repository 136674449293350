import { PasswordStrength } from './services/api5-service/api.interface';

/**
 * Possible routes
 */
export enum Route {
  Login = 'login',
  Dashboard = 'dashboard',
  List = 'list',
  Report = 'report',
  Form = 'form',
  Helpboard = 'helpboard',
}

/**
 * Prerendered page data, getted from xsl
 */
export interface PageInfo {
  /** Current lang, like 'ru' */
  lang: string;
  /** Product binary, like '/billmgr' */
  binary: string;
  /** Product name, like 'billmgr' */
  product: string;
  /** Func that used for doc, like 'register' or 'desktop' */
  func: string;
  /** Url host like 'https://172.31.225.144' */
  host: string;
  /** Host + binary, like 'https://172.31.225.144/billmgr'. @WARN used in prefetch function */
  baseUrl: string;
  /** Theme path like '/manimg/orion/' */
  theme: string;
  /** Directory that should be used for getting static files. By default 'default/' */
  localDir: string;
  /** Is user use some branding. 0 means no branding used, 1 mean branding is used */
  isBranding: 0 | 1;
}

/**
 * Desktop main info
 *
 * @WARN this is global object, that initiallized in desktop.xsl file
 */
export interface IDesktopPageInfo extends PageInfo {
  /** User acess level, like '29' (Administrator), or '16' (User) */
  level: string;
  /** Core version like '5.355.0-2022.09.28_12:58' */
  version: string;
  /** Ispmgr version like '6.60.0-2022.09.28_13:01_j256413_develop' */
  version_ispmanager?: string;
  /** Billmgr version like '6.60.0-2022.09.28_13:01_j256413_develop' */
  version_billmanager?: string;
  /** User name, like 'admin10' */
  userName: string;
  /** User id, like '1' */
  userid: string;
  /** Product licance id, like '1333d37361deef9c8c14acaa9327ef77253198ea4d99e130f6eb7b9229319bd4aas' */
  licid: string;
  /** Startpage is a legacy mechanism for defining default tab to open. Looks like 'payment&p_num=1' - so it's a part of tab query params */
  startpage: string;
  /** Startform is a legacy mechanism for defining default tab to open. Looks like 'payment&p_num=1' - so it's a part of tab query params */
  startform: string;
}

/**
 * Extform main info
 *
 * @WARN this is global object, that initiallized in extform.xsl file
 */
export interface IExtformPageInfo extends PageInfo {
  /** Chars sets, that should be used for password generation on the form. Like '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ' */
  pwgencharacters: string;
  /** Password length, that should be used in password generation */
  pwgenlen: string;
  /** Min password strength, to pass validation */
  pwstrength: PasswordStrength;
  /** Contain query params for request, that was used to get this doc. @WARN used in prefetch function */
  tparams: Record<string, string>;
  /** Some error to display. Usually form validation error */
  error?: {
    /** Field name */
    object: string;
    /** Validation msg */
    msg: string;
  };
}
