import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ChatService } from 'components/chat/chat.service';

import { IChatBubbleFile } from './model/chat-files.interface';

@Component({
  selector: 'isp-chat-files',
  templateUrl: './chat-files.component.html',
  styleUrls: ['./scss/chat-files.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatFilesComponent {
  @Input() files: IChatBubbleFile[];

  constructor(private readonly chatService: ChatService) {}

  /**
   * Handle file click event for form
   *
   * @param file - file data
   */
  handleFileClick(file: IChatBubbleFile): void {
    this.chatService.redirectToFileLoading(file.action, file.params);
  }

  /**
   * TrackBy function for files
   *
   * @param index - file index
   * @param file - file
   */
  trackByFile(index: number, file: IChatBubbleFile): string {
    return `${index}_${file.title}`;
  }
}
