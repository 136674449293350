import { IDocument, IInput } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

export interface ColorpickerControlWithValue {
  readonly control: IInput;
  readonly type: 'colorpicker';
  value: string;
}

/**
 * Check that input control meta is colorpicker meta
 *
 * @param control - control metadata
 */
export function isColorPickerControl(control: IInput): boolean {
  return control.$type === 'colorpicker';
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param control - control metadata
 * @param doc - doc
 */
export function getTypeControlForColorPicker(
  control: IInput,
  doc: IDocument,
): ColorpickerControlWithValue {
  return {
    value: DocHelper.getStringValue(control.$name, doc),
    control,
    type: 'colorpicker',
  };
}
