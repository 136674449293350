import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';
import { environment } from 'environments/environment';

import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: 'https://be41501620a54dbeb2bbd3bec5260f54@sentry.ispsystem.net/16',
    tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
