import { Injectable } from '@angular/core';

import {
  IDocument,
  IFormSummary,
  IFormSummaryPage,
} from 'app/services/api5-service/api.interface';
import { IFormCollapseEvent } from 'components/form-collapse';

import { IFormModel } from 'common/dynamic-form/dynamic-form.interface';
import { LayoutService } from 'common/dynamic-form/services/layout.service';
import { prefix } from 'common/form-id-prefix/form-id-prefix.utils';
import { DocHelper } from 'utils/dochelper';

/**
 * Service for form's summary
 */
@Injectable()
export class SiteSummaryService {
  /** Document */
  private doc: IDocument;

  constructor(private readonly layoutService: LayoutService) {}

  /**
   * Init service
   *
   * @param doc - doc instance
   */
  init(doc: IDocument): void {
    this.doc = doc;
  }

  /**
   * Returns summary's pages with collapsed data
   *
   * @param summary - form summary data
   * @param event - form collapse event
   */
  getSummaryPages(
    summary: IFormSummary,
    event?: IFormCollapseEvent,
  ): IFormSummaryPage[] {
    const formPages = DocHelper.getFormPageList(this.doc);

    return summary.page
      .map(summaryPage => {
        if (
          event &&
          summaryPage.$name === prefix(this.layoutService, event.name)
        ) {
          return {
            ...summaryPage,
            isCollapsed: !event.isOpened,
          };
        }

        const formPage = formPages.find(p => p.$name === summaryPage.$name);

        summaryPage.label?.forEach(l => {
          if (!l.isHidden) {
            l.isHidden = true;
          }

          const formFieldInput = formPage?.field.find(
            field => field.$name === l.$name,
          )?.input;

          if (formFieldInput?.length > 0) {
            l.unlimitValue = formFieldInput[0]?.$unlimit;
          }
        });

        return formPage
          ? {
              ...summaryPage,
              isCollapsed: formPage.$collapsed === 'yes' ?? false,
            }
          : null;
      })
      .filter(page => page !== null);
  }

  /**
   * Returns title for page
   *
   * @param pageName - page name
   */
  getPageTitle(pageName: string): string {
    return (
      DocHelper.getMessage(`summary_${pageName}`, this.doc) ||
      DocHelper.getMessage(pageName, this.doc)
    );
  }

  /**
   * Returns text for label's button
   *
   * @param isHidden - label hidden flag
   */
  getLabelButtonText(isHidden: boolean): string {
    return DocHelper.getMessage(
      isHidden ? 'summary_show' : 'summary_hide',
      this.doc,
    );
  }

  /**
   * Returns text for label
   *
   * @param labelName - label name
   * @param formModel - form model
   * @param canShowValue - is label not hidden
   * @param unlimitValue - label unlimit value
   */
  getLabelText(
    labelName: string,
    formModel: IFormModel,
    canShowValue = true,
    unlimitValue?: string,
  ): string {
    const rawFormValue = formModel?.[labelName] as string;

    const labelSummaryMessage = DocHelper.getMessage(
      `summary_${labelName}`,
      this.doc,
    );
    const labelFormMessage = DocHelper.getMessage(labelName, this.doc);

    if (rawFormValue === null || rawFormValue === undefined) {
      return labelSummaryMessage.length > 0
        ? labelSummaryMessage.replace('__value__', '')
        : `${labelFormMessage}: `;
    }

    if (unlimitValue !== undefined && unlimitValue === rawFormValue) {
      const unlimitMessage = DocHelper.getMessage('summary_unlimit', this.doc);
      return labelSummaryMessage.length > 0
        ? labelSummaryMessage.replace('__value__', unlimitMessage)
        : `${labelFormMessage}: ${unlimitMessage}`;
    }

    const formValueMessage = DocHelper.getMessage(rawFormValue, this.doc);
    const formValue =
      formValueMessage.length > 0 ? formValueMessage : rawFormValue;
    const selectFormValueMessage = this.doc.slist
      .find(slist => slist.$name === labelName)
      ?.val.find(val => val.$key === formValue)?.$;

    const labelValue = selectFormValueMessage ?? formValue;

    if (labelSummaryMessage.length > 0) {
      return labelSummaryMessage.replace(
        '__value__',
        canShowValue ? labelValue : '',
      );
    } else if (labelFormMessage.length > 0) {
      return `${labelFormMessage}: ${canShowValue ? labelValue : ''}`;
    } else {
      return canShowValue ? labelValue : '';
    }
  }
}
