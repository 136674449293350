import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { IUiProp } from 'app/list/table/prop-list/model/prop-list.interface';
import { Observable, of, timer } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import {
  Func,
  IAuthParams,
  IAuthResponse,
  ICollapseParams,
  IDesktopMeta,
  IDocProgess,
  IDocument,
  IFormPage,
  ILogonMeta,
  INotifyDocument,
  ISetValueDocument,
  TBlockDisplay,
  TBlockPosition,
} from './api.interface';

import { IHttpParam } from '../http-base-service/http-base.interface';
import { HttpBaseService } from '../http-base-service/http-base.service';

export interface CheckFieldValidationParams {
  /** Check func name */
  func: string;
  /** Field form func name */
  funcname: string;
  /** Field name */
  name: string;
  /** Field value */
  value: string;
  args?: string;
  tconvert?: string;
}

/**
 * SetValues function parameters
 */
export interface SetValuesParams {
  /** Form func */
  func: string;
  /** Field, that was changed */
  field: string;
  /** Plane form model */
  model: Params;
  /** Elid of form document */
  elid: string;
  /** Plid of form document */
  plid: string;
}

/**
 * ISP5 API service
 */
@Injectable({
  providedIn: 'root',
})
export class Api5Service {
  constructor(private readonly httpBaseService: HttpBaseService) {}

  getBrandings(_projectId: string): Observable<IDocument> {
    return timer(2000).pipe(
      mapTo({
        control_main_color: {
          $: '#da3618',
        },
        control_accordion: {
          $: '#ffd3d2',
        },
        control_table_rows_hover_color: {
          $: '#ffa5a5',
        },
        control_table_rows_hover_color_n1: {
          $: '#e26e6e',
        },
        control_table_rows_hover_color_n2: {
          $: '#812a2a',
        },
        control_header_background: {
          $: '#ff8c8c',
        },
        control_header_global_search_background: {
          $: '#585858',
        },
        control_header_text_and_icons_main: {
          $: '#000000',
        },
        control_header_text_and_icons_secondary: {
          $: '#484848',
        },
        control_header_text_and_icons_warn: {
          $: '#ffbd50',
        },
        control_header_text_and_icons_action: {
          $: '#810000',
        },
        control_header_global_search_inactive: {
          $: '#d9d9d9',
        },
        field_header_global_search: {
          $: '#ffffff',
        },
        control_form_text_button: {
          $: '#532121',
        },
        control_menu_background_color: {
          $: '#5b5b5b',
        },
        control_menu_background_color_expanded: {
          $: '#767676',
        },
        control_menu_collapsed_colors: {
          $: '#ffffff',
        },
        control_menu_expanded_colors: {
          $: '#ffcccc',
        },
        control_menu_selected_colors: {
          $: '#ff7664',
        },
        control_tabs_panel: {
          $: '#d8d8d8',
        },
        control_tabs_tab_background: {
          $: '#F4F4F44D',
        },
        control_tabs_not_active_tab: {
          $: '#786565',
        },
        control_tabs_active_tab: {
          $: '#3e3e3e',
        },
        metadata: {
          form: {
            page: [
              {
                $name: 'page_table_main_color',
                $collapsed: 'no',
                $not_collapsible: 'yes',
                field: [
                  {
                    $name: 'field_table_main_color',
                    input: [
                      {
                        $name: 'control_main_color',
                        $type: 'colorpicker',
                        $default_value: '#1A76E2',
                      },
                    ],
                  },
                ],
              },
              {
                $name: 'page_table_rows_color',
                $collapsed: 'no',
                $not_collapsible: 'yes',
                field: [
                  {
                    $name: 'field_table_rows_hover_color',
                    input: [
                      {
                        $name: 'control_table_rows_hover_color',
                        $type: 'colorpicker',
                        $default_value: '#DBE8FF',
                      },
                    ],
                  },
                  {
                    $name: 'field_table_rows_hover_color_n1',
                    input: [
                      {
                        $name: 'control_table_rows_hover_color_n1',
                        $type: 'colorpicker',
                        $default_value: '#C1DAFF',
                      },
                    ],
                  },
                  {
                    $name: 'field_table_rows_hover_color_n2',
                    input: [
                      {
                        $name: 'control_table_rows_hover_color_n2',
                        $type: 'colorpicker',
                        $default_value: '#AECEFF',
                      },
                    ],
                  },
                ],
              },
              {
                $name: 'page_header_background',
                $collapsed: 'no',
                $not_collapsible: 'yes',
                field: [
                  {
                    $name: 'field_header_background',
                    input: [
                      {
                        $name: 'control_header_background',
                        $type: 'colorpicker',
                        $default_value: '#24262A',
                      },
                    ],
                  },
                  {
                    $name: 'field_header_global_search_background',
                    input: [
                      {
                        $name: 'control_header_global_search_background',
                        $type: 'colorpicker',
                        $default_value: '#585858',
                      },
                    ],
                  },
                ],
              },
              {
                $name: 'page_header_text_and_icons',
                $collapsed: 'no',
                $not_collapsible: 'yes',
                field: [
                  {
                    $name: 'field_header_text_and_icons_main',
                    input: [
                      {
                        $name: 'control_header_text_and_icons_main',
                        $type: 'colorpicker',
                        $default_value: '#FFFFFF',
                      },
                    ],
                  },
                  {
                    $name: 'field_header_text_and_icons_secondary',
                    input: [
                      {
                        $name: 'control_header_text_and_icons_secondary',
                        $type: 'colorpicker',
                        $default_value: '#979797',
                      },
                    ],
                  },
                  {
                    $name: 'field_header_text_and_icons_warn',
                    input: [
                      {
                        $name: 'control_header_text_and_icons_warn',
                        $type: 'colorpicker',
                        $default_value: '#FF7A1A',
                      },
                    ],
                  },
                  {
                    $name: 'field_header_text_and_icons_action',
                    input: [
                      {
                        $name: 'control_header_text_and_icons_action',
                        $type: 'colorpicker',
                        $default_value: '#FFE03A',
                      },
                    ],
                  },
                  {
                    $name: 'field_header_global_search_inactive',
                    input: [
                      {
                        $name: 'control_header_global_search_inactive',
                        $type: 'colorpicker',
                        $default_value: '#d9d9d9',
                      },
                    ],
                  },
                  {
                    $name: 'field_header_global_search',
                    input: [
                      {
                        $name: 'control_header_global_search',
                        $type: 'colorpicker',
                        $default_value: '#ffffff',
                      },
                    ],
                  },
                ],
              },
              {
                $name: 'page_form_main_color',
                $collapsed: 'no',
                $not_collapsible: 'yes',
                field: [
                  {
                    $name: 'field_form_main_color',
                    input: [
                      {
                        $name: 'control_main_color',
                        $type: 'colorpicker',
                        $default_value: '#1A76E2',
                      },
                    ],
                  },
                ],
              },
              {
                $name: 'page_form_accordion',
                $collapsed: 'no',
                $not_collapsible: 'yes',
                field: [
                  {
                    $name: 'field_form_accordion',
                    input: [
                      {
                        $name: 'control_accordion',
                        $type: 'colorpicker',
                        $default_value: '#E9F2FF',
                      },
                    ],
                  },
                ],
              },
              {
                $name: 'page_form_text',
                $collapsed: 'no',
                $not_collapsible: 'yes',
                field: [
                  {
                    $name: 'field_form_text_button',
                    input: [
                      {
                        $name: 'control_form_text_button',
                        $type: 'colorpicker',
                        $default_value: '#FFFFFF',
                      },
                    ],
                  },
                ],
              },
              {
                $name: 'page_dashboard_main_color',
                $collapsed: 'no',
                $not_collapsible: 'yes',
                field: [
                  {
                    $name: 'field_dashboard_main_color',
                    input: [
                      {
                        $name: 'control_main_color',
                        $type: 'colorpicker',
                        $default_value: '#1A76E2',
                      },
                    ],
                  },
                ],
              },
              {
                $name: 'page_dashboard_background_color',
                $collapsed: 'no',
                $not_collapsible: 'yes',
                field: [
                  {
                    $name: 'field_dashboard_accordion_and_hover',
                    input: [
                      {
                        $name: 'control_accordion',
                        $type: 'colorpicker',
                        $default_value: '#E9F2FF',
                      },
                    ],
                  },
                ],
              },
              {
                $name: 'page_menu_background_color',
                $collapsed: 'no',
                $not_collapsible: 'yes',
                field: [
                  {
                    $name: 'field_menu_background_color',
                    input: [
                      {
                        $name: 'control_menu_background_color',
                        $type: 'colorpicker',
                        $default_value: '#EEEEEE',
                      },
                    ],
                  },
                  {
                    $name: 'field_menu_background_color_expanded',
                    input: [
                      {
                        $name: 'control_menu_background_color_expanded',
                        $type: 'colorpicker',
                        $default_value: '#E6E6E6',
                      },
                    ],
                  },
                ],
              },
              {
                $name: 'page_menu_text_and_icons',
                $collapsed: 'no',
                $not_collapsible: 'yes',
                field: [
                  {
                    $name: 'field_menu_collapsed_colors',
                    input: [
                      {
                        $name: 'control_menu_collapsed_colors',
                        $type: 'colorpicker',
                        $default_value: '#24262A',
                      },
                    ],
                  },
                  {
                    $name: 'field_menu_expanded_colors',
                    input: [
                      {
                        $name: 'control_menu_expanded_colors',
                        $type: 'colorpicker',
                        $default_value: '#24262A',
                      },
                    ],
                  },
                  {
                    $name: 'field_menu_selected_colors',
                    input: [
                      {
                        $name: 'control_menu_selected_colors',
                        $type: 'colorpicker',
                        $default_value: '#1A76E2',
                      },
                    ],
                  },
                ],
              },
              {
                $name: 'page_tabs_main_color',
                $collapsed: 'no',
                $not_collapsible: 'yes',
                field: [
                  {
                    $name: 'field_tabs_main_color',
                    input: [
                      {
                        $name: 'control_main_color',
                        $type: 'colorpicker',
                        $default_value: '#1A76E2',
                      },
                    ],
                  },
                ],
              },
              {
                $name: 'page_tabs_background',
                $collapsed: 'no',
                $not_collapsible: 'yes',
                field: [
                  {
                    $name: 'field_tabs_panel',
                    input: [
                      {
                        $name: 'control_tabs_panel',
                        $type: 'colorpicker',
                        $default_value: '#585858',
                      },
                    ],
                  },
                  {
                    $name: 'field_tabs_tab_background',
                    input: [
                      {
                        $name: 'control_tabs_tab_background',
                        $type: 'colorpicker',
                        $default_value: '#F4F4F44D',
                      },
                    ],
                  },
                ],
              },
              {
                $name: 'page_tabs_text_colors',
                $collapsed: 'no',
                $not_collapsible: 'yes',
                field: [
                  {
                    $name: 'field_tabs_not_active_tab',
                    input: [
                      {
                        $name: 'control_tabs_not_active_tab',
                        $type: 'colorpicker',
                        $default_value: '#D9D9D9',
                      },
                    ],
                  },
                  {
                    $name: 'field_tabs_active_tab',
                    input: [
                      {
                        $name: 'control_tabs_active_tab',
                        $type: 'colorpicker',
                        $default_value: '#FFFFFF',
                      },
                    ],
                  },
                ],
              },
            ] as IFormPage[],
            buttons: {
              button: [
                {
                  $name: 'reset_branding',
                  $type: 'ok',
                  $theme: 'sixth',
                },
                {
                  $name: 'cancel',
                  $type: 'cancel',
                },
                {
                  $name: 'preview',
                  $type: 'ok',
                  $theme: 'sixth',
                  $offset: 'yes',
                  $icon: 'p-passopen',
                },
                {
                  $name: 'apply',
                  $type: 'ok',
                },
              ],
            },
          },
        },
        messages: {
          msg: {
            carousel_dashboard: 'Дашборд',
            carousel_table: 'Таблица',
            carousel_form: 'Форма',
            title: 'Настройка цвета интерфейса - billmgr',
            stub_title: 'Изменение цветов',
            stub_description:
              'Кликните на область в интерфейсе, где хотите поменять цвет.',
            msg_reset_branding: 'Вернуть цвета BILLmanager 6',
            msg_preview: 'Перейти в режим предпросмотра',
            msg_apply: 'Применить',
            msg_cancel: 'Отмена',

            page_header_title: 'Цвета в шапке',
            page_header_background: 'Цвета подложки',
            field_header_background: 'Шапка',
            field_header_global_search_background: 'Глобальный поиск',
            page_header_text_and_icons: 'Цвет текста и иконок',
            field_header_text_and_icons_main: 'Основной',
            field_header_text_and_icons_secondary: 'Второстепенный',
            field_header_text_and_icons_warn: 'Предупреждающий',
            field_header_text_and_icons_action: 'Действие',
            field_header_global_search_inactive:
              'Глобальный поиск - неактивный',
            field_header_global_search: 'Глобальный поиск - активный',

            page_form_title: 'Цвета на формах',
            page_form_main_color: 'Основной цвет',
            page_form_accordion: 'Цвет подложки',
            field_form_accordion: 'Аккордеоны и наведение',
            page_form_text: 'Цвет текста',
            field_form_text_button: 'Текст в кнопке, доступной для нажатия',

            page_table_title: 'Цвета в таблицах',
            page_table_main_color: 'Основной цвет',
            field_table_main_color: '',
            page_table_rows_color: 'Цвет строк',
            field_table_rows_hover_color: 'Наведение на строку (белую и серую)',
            field_table_rows_hover_color_n1: 'Выделение белой строки',
            field_table_rows_hover_color_n2: 'Выделение серой строки',

            page_dashboard_title: 'Цвета на дашборде',
            page_dashboard_main_color: 'Основной цвет',
            page_dashboard_background_color: 'Цвет подложки',
            field_dashboard_accordion_and_hover: 'Аккордионы и наведение',
            page_dashboard_text_and_icons: 'Цвета текста и иконок',

            page_menu_title: 'Цвета в левом вертикальном меню',
            page_menu_background_color: 'Цвет подложки',
            field_menu_background_color: 'Левое меню - пункты свернуты',
            field_menu_background_color_expanded:
              'Левое меню - пункты развернуты',
            page_menu_text_and_icons: 'Цвет текста и иконок',
            field_menu_collapsed_colors: 'В свернутом состоянии меню',
            field_menu_expanded_colors: 'В развернутом состоянии меню',
            field_menu_selected_colors: 'У выбранного пункта меню',

            page_tabs_title: 'Цвета в панели вкладок',
            page_tabs_main_color: 'Основной цвет',
            page_tabs_background: 'Цвет подложки',
            field_tabs_panel: 'Панель вкладок',
            field_tabs_tab_background: 'Активная вкладка',
            page_tabs_text_colors: 'Цвет текста',
            field_tabs_not_active_tab: 'Неактивная вкладка',
            field_tabs_active_tab: 'Активная вкладка',

            carousel_title: 'Как это будет выглядеть?',
            carousel_description:
              'Посмотрите отображение разных разделов интерфейса. Некоторые цвета общие для разных разделов.',

            confirm_reset_header: 'Вернуть цвета BILLmanager 6?',
            confirm_reset_description: 'Все изменения будут сброшены',
            confirm_reset_ok: 'Вернуть',
            confirm_reset_cancel: 'отмена',

            confirm_close_header: 'Закрыть форму редактирования?',
            confirm_close_description:
              'Все несохраненные изменения будут сброшены',
            confirm_close_ok: 'Ok',
            confirm_close_cancel: 'отмена',

            confirm_reload_header: 'Перезагрузить форму редактирования?',
            confirm_reload_description:
              'Все несохраненные изменения будут сброшены',
            confirm_reload_ok: 'Ok',
            confirm_reload_cancel: 'отмена',

            confirm_apply_header: 'Применить новый дизайн',
            confirm_apply_description:
              'Выбранная цветовая схема будет применена для всех пользователей системы',
            confirm_apply_ok: 'Ok',
            confirm_apply_cancel: 'отмена',
          },
        },
      }),
    ) as never as Observable<IDocument>;
  }

  /**
   * Get func=desktop
   */
  desktop(): Observable<IDesktopMeta> {
    return this.httpBaseService.get({ func: Func.Desktop });
  }

  /**
   * Get func=login
   */
  logon(): Observable<ILogonMeta> {
    return this.httpBaseService.get({ func: Func.Logon });
  }

  /**
   * Authentication
   *
   * @param data - auth parameters (login and password)
   */
  auth(data: IAuthParams): Observable<IAuthResponse> {
    return this.httpBaseService.get({ func: Func.Auth, ...data });
  }

  /**
   * Func=notify
   */
  notify(): Observable<INotifyDocument> {
    return this.httpBaseService.get({ func: Func.Notify });
  }

  /**
   * Get dashboard block doc
   *
   * @param func - dashboard func
   * @param dashboard - dashboard block name
   * @param params - additional params
   */
  getDashboard<D extends IDocument>(
    func: string,
    dashboard: string,
    params?: Params,
  ): Observable<D> {
    return this.httpBaseService.get<D>({
      func,
      dashboard,
      ...params,
    });
  }

  /**
   * Mark subnotification as readed
   *
   * @param subNotifyId - subnotification id
   */
  markSubNotificationAsRead(subNotifyId: string): Observable<any> {
    return this.httpBaseService.get({
      func: 'notificationbar.delete',
      elid: subNotifyId,
    });
  }

  /**
   * Func=colwidth
   *
   * @param params
   */
  colwidth(params: Params): Observable<IDocument> {
    return this.httpBaseService.get({ func: 'colwidth', ...params });
  }

  /**
   * Saves the state of form's collapsible (page)
   *
   * @param params - save parameters (state (on/off), page name, action name)
   */
  collapse(params: ICollapseParams): Observable<any> {
    return this.httpBaseService.get({ func: Func.Collapse, ...params });
  }

  setValues(params: SetValuesParams): Observable<ISetValueDocument> {
    return this.httpBaseService.post({
      func: params.func,
      ...params.model,
      elid: params.elid,
      plid: params.plid,
      sv_field: params.field,
    });
  }

  setValuesWithFiles(
    params: SetValuesParams,
  ): Observable<HttpEvent<{ doc: ISetValueDocument }>> {
    return this.httpBaseService.uploadFile({
      func: params.func,
      ...params.model,
      elid: params.elid,
      plid: params.plid,
      sv_field: params.field,
    });
  }

  /**
   * Check field validation state on BE
   *
   * @param params - field validation params
   */
  checkFieldValidation(
    params: CheckFieldValidationParams,
  ): Observable<IDocument> {
    const validatorParams: IHttpParam = {
      func: params.func,
      name: params.name,
      funcname: params.funcname,
      value: params.value,
    };
    if (params.args) {
      validatorParams.args = params.args;
    }
    if (params.tconvert) {
      validatorParams.tconvert = params.tconvert;
    }
    return this.httpBaseService.get(validatorParams);
  }

  /**
   * Dismiss banner
   *
   * @param params
   */
  dismiss(params: Params): Observable<IDocument> {
    return this.httpBaseService.post({ func: 'dismiss', ...params });
  }

  /**
   * Delete all notifications from notify bannerList
   */
  deleteAllNotifications(): Observable<IDocument> {
    return this.httpBaseService.post({ func: 'notificationbar.deleteall' });
  }

  /**
   * Get BE task progress status
   *
   * @param elid
   */
  progress(elid: string): Observable<IDocProgess> {
    return this.httpBaseService.get({ func: 'progress.get', elid });
  }

  // Saves dashboard state
  // @param block - saved block
  // @param display - new display
  // @param position -
  dashboardStateSave(
    block: string,
    display: TBlockDisplay,
    position: TBlockPosition,
  ): Observable<IDocument> {
    return this.httpBaseService.get({
      func: Func.DashboardSave,
      block,
      display,
      position,
    });
  }

  /**
   * Get keepalive function
   */
  keepalive(): Observable<IDocument> {
    return this.httpBaseService.get({ func: Func.Keepalive });
  }

  /**
   * Announce server that certain tip was seen
   *
   * @param name - tip name
   */
  tip(name: string): Observable<IDocument> {
    return this.httpBaseService.get({ func: Func.Tip, elid: name });
  }

  globalSearch(elid: string): Observable<IDocument | null> {
    if (!elid?.trim().length) {
      return of(null);
    }

    const params = {
      func: 'globalindex',
      elid,
      out: 'json',
      sfrom: 'ajax',
    };
    return this.httpBaseService.get(params);
  }

  /**
   * Get hint for props with activehint flag. Used in table
   *
   * @param prop - prop data
   * @param tableFunc - table func
   */
  getActiveHint(prop: IUiProp, tableFunc: string): Observable<string> {
    const params = {
      func: tableFunc,
      hint_field: prop.name,
      elid: prop.elid,
    };
    return this.httpBaseService
      .get(params)
      .pipe(map(response => response.hint?.$ || ''));
  }
}
