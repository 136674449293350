import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { untilDestroyed } from '@ngneat/until-destroy';
import { ISelectOption } from 'app/services/api5-service/api.interface';
import { MessageBusService } from 'app/services/messagebus/messagebus.service';
import { fromEvent } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  appendCodeToPhone,
  isPhoneBelongsToCode,
  purifyPhoneFromMaskValue,
  replacePhoneCodeInPhone,
} from './phone-group.utils';

import { ISPFieldConfig, ISPFieldType, ISPFieldTypeBase } from '../../model';

/**
 * Prefixed select-input group for phone field Formly field
 */
@Component({
  selector: 'isp-formly-phone-group-field',
  templateUrl: './phone-group.component.html',
  styleUrls: ['./scss/phone-group.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneGroupComponent
  extends ISPFieldTypeBase<ISPFieldType.PhoneGroup>
  implements OnInit
{
  private lastTimeSelectClosed: number;

  private get inputConfig(): ISPFieldConfig<ISPFieldType.InputText> {
    return this.field.fieldGroup[1] as ISPFieldConfig<ISPFieldType.InputText>;
  }

  private get inputControl(): FormControl {
    const inputConfig = this.inputConfig;
    const inputName = inputConfig.key;
    return this.form.controls[inputName] as FormControl;
  }

  private get inputControlName(): string {
    const inputConfig = this.inputConfig;
    return inputConfig.key;
  }

  private get selectControlName(): string {
    const inputConfig = this.inputConfig;
    const prefixedSelectName =
      inputConfig.templateOptions.originalControl.$prefixselect;
    return prefixedSelectName;
  }

  private get selectControl(): FormControl {
    return this.form.controls[this.selectControlName] as FormControl;
  }

  isDropdownOpened: boolean;

  isSelectFocused: boolean;

  get otherConfigs(): ISPFieldConfig[] {
    return this.field.fieldGroup.slice(2);
  }

  constructor(private readonly bus: MessageBusService) {
    super();
  }

  private setFocusToInput(): void {
    this.bus.emit('dynamic-form-set-focus', {
      key: this.inputControlName,
      formId: this.formState.layoutService.formId,
    });
  }

  private updateInputMask(mask: string): void {
    this.bus.emit('dynamic-form-update-mask', {
      formId: this.formState.layoutService.formId,
      key: this.inputControlName,
      mask,
    });
  }

  private updateInputMaskValue(unmaskedValue?: string): void {
    this.bus.emit('dynamic-form-update-mask-value', {
      key: this.inputControlName,
      unmaskedValue,
      formId: this.formState.layoutService.formId,
    });
  }

  /**
   * Handle input value change. Change related select value
   *
   * @param value
   */
  private onInputChange(value: string): void {
    if (!value) {
      return;
    }

    const purifedValue = purifyPhoneFromMaskValue(value);
    if (purifedValue === '+') {
      return;
    }

    const firstSelectOption = this.formState.selectService
      .getOptionsForSelect(this.selectControlName)
      ?.find(option => purifedValue.startsWith(option.$code));

    if (!firstSelectOption) {
      return;
    }

    if (firstSelectOption.$code.length > purifedValue.length) {
      return;
    }

    const selectedOption =
      this.formState.selectService.getSelectedOptionFromSelect(
        this.selectControlName,
      );

    if (
      selectedOption?.$key !== firstSelectOption.$key &&
      selectedOption?.$code !== firstSelectOption.$code
    ) {
      this.selectControl.setValue(firstSelectOption.$key);
    }
  }

  /**
   * Get next input value after select value change
   *
   * @param prevOption - prev select option
   * @param nextOption - next select option
   * @returns next input value or null, if no next value is needed
   */
  private getInputValueAfterSelectChanges(
    prevOption: ISelectOption | undefined,
    nextOption: ISelectOption,
  ): string | null {
    const prevInputValue =
      this.inputConfig.templateOptions.inputElementSubject.value.value;
    const prevInputValuePurified = purifyPhoneFromMaskValue(prevInputValue);
    const isOptionsChanges =
      prevOption && prevOption.$code !== nextOption.$code;

    if (
      isOptionsChanges &&
      isPhoneBelongsToCode(prevInputValuePurified, prevOption.$code)
    ) {
      return replacePhoneCodeInPhone(
        prevInputValuePurified,
        prevOption.$code,
        nextOption.$code,
      );
    } else {
      if (!prevInputValuePurified.startsWith(nextOption.$code)) {
        const phoneWithoutCode = prevInputValuePurified.replace('+', '');
        return `${nextOption.$code}${phoneWithoutCode}`;
      } else {
        return null;
      }
    }
  }

  /**
   * Update input config, after select value changes
   *
   * @param nextOption - next selected option
   */
  private updateInputMaskFromSelectedOption(nextOption: ISelectOption): void {
    // update input mask, from selected option
    const mask = nextOption.$mask;
    this.updateInputMask(mask);
  }

  /**
   * Append phone country to input, if input is empty
   *
   * @param value - input value
   * @param option - country selected option
   */
  private appendPhoneCountryIfNeeded(
    value: string,
    option?: ISelectOption,
  ): void {
    if (option && !value.startsWith(option.$code)) {
      const nextInputValue = appendCodeToPhone(value, option.$code);
      this.updateInputMaskValue(nextInputValue);
    }
  }

  /**
   * Handle any select control change, either by form or by user. Change related input value
   *
   * @param prevOption - selected option
   * @param nextOption - selected option
   * @param changedByUser - is value was changed by user, not by some form process like setValue
   */
  private onSelectChange(
    prevOption: ISelectOption | undefined,
    nextOption: ISelectOption,
    changedByUser?: boolean,
  ): void {
    this.updateInputMaskFromSelectedOption(nextOption);

    // wait until mask will changes, and then updates input value
    setTimeout(() => {
      const nextInputValue = this.getInputValueAfterSelectChanges(
        prevOption,
        nextOption,
      );
      if (nextInputValue !== null) {
        this.updateInputMaskValue(nextInputValue);
      }
    });

    if (changedByUser) {
      // use setTimeout to wait, until input field will be displayed again, after 'display: none'
      setTimeout(() => {
        this.setFocusToInput();
      });
    }
  }

  ngOnInit(): void {
    // @HACK use native input element to detect value changes. Do not use input formControl, cause it works with 'onBlur' mode
    // and it should stay in that mode!
    this.inputConfig.templateOptions.inputElementSubject
      .pipe(
        filter(input => Boolean(input)),
        switchMap(input => fromEvent(input, 'input')),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe((event: InputEvent) => {
        this.onInputChange((event.target as HTMLInputElement).value);
      });

    this.formState.validationService
      .getNotificationAfterValidationPatch(this.inputControlName)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.updateInputMaskValue(purifyPhoneFromMaskValue(value));
      });

    this.formState.disabledService
      .isFieldDisabled$(this.inputControlName)
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(isDisabled => {
        if (isDisabled) {
          this.formState.disabledService.disableControl(this.selectControlName);
        } else {
          this.formState.disabledService.enableControl(this.selectControlName);
        }
      });

    this.formState.selectService
      .getSelectedOptionFromSelect$(this.selectControlName)
      .pipe(
        filter(option => Boolean(option)),
        distinctUntilChanged((prev, next) => prev.$code === next.$code),
        pairwise(),
        untilDestroyed(this),
      )
      .subscribe(([prevOption, nextOption]) => {
        if (!nextOption) {
          return;
        }

        // @HACK this is a way, to determine user selection change.
        // If select dropdown was closed a few moment ago AND selected option changes I assume,
        // that this was user action. But this is strange hack...
        const isOptionWasChangedByUser =
          new Date().getTime() - this.lastTimeSelectClosed < 50;
        this.onSelectChange(prevOption, nextOption, isOptionWasChangedByUser);
      });

    // for auto code autofill on blur event
    this.inputConfig.templateOptions.inputElementSubject
      .pipe(
        filter(elem => Boolean(elem)),
        switchMap(elem => fromEvent<InputEvent>(elem, 'blur')),
        map(event => (event.target as HTMLInputElement).value),
        distinctUntilChanged(),
        withLatestFrom(
          this.formState.selectService.getSelectedOptionFromSelect$(
            this.selectControlName,
          ),
        ),
        untilDestroyed(this),
      )
      .subscribe(([value, option]) => {
        this.appendPhoneCountryIfNeeded(value, option);
      });
  }

  onInternalAction(
    event: CustomEvent<{
      name: 'dropdown-closing-start' | 'dropdown-open';
    }>,
  ): void {
    switch (event.detail.name) {
      case 'dropdown-closing-start':
        this.isDropdownOpened = false;
        this.lastTimeSelectClosed = new Date().getTime();
        break;
      case 'dropdown-open':
        this.isDropdownOpened = true;
        break;
      default:
    }
  }

  setSelectFocused(state: boolean): void {
    this.isSelectFocused = state;
  }
}
