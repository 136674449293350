<isp-form-button
  *ngFor="let button of buttons; trackBy: trackByButtonFn"
  [button]="button"
  [hintAttachSelector]="hintAttachSelector"
  [hintPlacementOrder]="hintPlacementOrder"
  [ngClass]="{
    button_offset: button.offset
  }"
  (btnClick)="btnClick.next(button)"
></isp-form-button>
