import { minutesToMilliseconds } from 'date-fns';

/**
 * Converts string to date object with offset
 * It is necessary to show the correct date in the datepicker. Without offset e.g. Time on the backend 01.09.2022 becomes 31.08.2022 on the frontend
 *
 * @param str - string to convert
 * @returns date object
 */
export function convertStringToDateWithOffset(str: string): Date | null {
  if (!str) {
    return null;
  }
  const date = new Date(str);
  const offsetMinutes = date.getTimezoneOffset();

  const offsetDate = new Date(
    date.getTime() + minutesToMilliseconds(offsetMinutes),
  );
  return offsetDate;
}
