import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ExtformPreviewComponent } from './preview.component';

@NgModule({
  declarations: [ExtformPreviewComponent],
  exports: [ExtformPreviewComponent],
  imports: [CommonModule],
})
export class ExtfromPreviewModule {}
