import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ExtformPreviewService {
  private readonly displayShadow: BehaviorSubject<boolean> =
    new BehaviorSubject(true);

  private readonly horizontalStretch: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  private readonly verticalStretch: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  private readonly tiling: BehaviorSubject<boolean> = new BehaviorSubject(true);

  private readonly color: BehaviorSubject<string | null> = new BehaviorSubject(
    null,
  );

  private readonly imageSrc: BehaviorSubject<string | null> =
    new BehaviorSubject(null);

  private readonly backgroundType: BehaviorSubject<'color' | 'img'> =
    new BehaviorSubject('img');

  readonly displayShadow$ = this.displayShadow.asObservable();

  readonly horizontalStretch$ = this.horizontalStretch.asObservable();

  readonly verticalStretch$ = this.verticalStretch.asObservable();

  readonly tiling$ = this.tiling.asObservable();

  readonly color$ = this.color.asObservable();

  readonly imageSrc$ = this.imageSrc.asObservable();

  readonly backgroundType$ = this.backgroundType.asObservable();

  setShadow(shadowState: boolean): void {
    this.displayShadow.next(shadowState);
  }

  setBackgroundType(type: 'color' | 'img'): void {
    this.backgroundType.next(type);
  }

  setBackgroundColor(color: string): void {
    this.color.next(color);
  }

  setBackgroundTiling(tiling: boolean): void {
    this.tiling.next(tiling);
  }

  setBackgroundHorizontalStretch(value: boolean): void {
    this.horizontalStretch.next(value);
  }

  setBackgroundVerticalStretch(value: boolean): void {
    this.verticalStretch.next(value);
  }

  setImageSrc(imageUrl: string): void {
    this.imageSrc.next(imageUrl);
  }
}
