import {
  CdkNestedTreeNode,
  CdkTreeNode,
  CdkTreeNodeDef,
} from '@angular/cdk/tree';
import {
  AfterContentInit,
  ContentChildren,
  Directive,
  HostBinding,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';

import { TreeNodeOutletDirective } from './outlet.directive';

/**
 * Wrapper for the CdkTree node with ISP design styles.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'isp-tree-node',
  // eslint-disable-next-line no-use-before-define
  providers: [{ provide: CdkTreeNode, useExisting: TreeNodeDirective }],
})
export class TreeNodeDirective<T> extends CdkTreeNode<T> {
  @Input() disabled: boolean;

  @HostBinding('attr.aria-level')
  get ariaLevel(): number {
    return this.role === 'treeitem' ? this.level : null;
  }

  @HostBinding('attr.aria-expanded')
  get isExpanded(): boolean {
    return this._tree.treeControl.isExpanded(this._data);
  }

  @HostBinding('class.tree-node') readonly treeNodeClass = true;
}

/**
 * Wrapper for the CdkTree node definition with ISP design styles.
 */
@Directive({
  selector: '[ispTreeNodeDef]',
  providers: [
    // eslint-disable-next-line no-use-before-define
    { provide: CdkTreeNodeDef, useExisting: TreeNodeDefDirective },
  ],
})
export class TreeNodeDefDirective<T> extends CdkTreeNodeDef<T> {
  @Input('ispTreeNode') data: T;

  @Input('ispTreeNodeDefWhen')
  when: (index: number, nodeData: T) => boolean;
}

/**
 * Wrapper for the CdkTree nested node with ISP design styles.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'isp-nested-tree-node',
  providers: [
    // eslint-disable-next-line no-use-before-define
    { provide: CdkNestedTreeNode, useExisting: NestedTreeNodeDirective },
    // eslint-disable-next-line no-use-before-define
    { provide: CdkTreeNode, useExisting: NestedTreeNodeDirective },
  ],
})
export class NestedTreeNodeDirective<T>
  extends CdkNestedTreeNode<T>
  implements AfterContentInit, OnDestroy
{
  @Input() disabled: boolean;

  @Input() tabIndex: number;

  @Input('ispNestedTreeNode') node: T;

  @HostBinding('attr.aria-expanded')
  get isExpanded(): boolean {
    return this._tree.treeControl.isExpanded(this._data);
  }

  @HostBinding('class.nested-tree-node')
  readonly nestedTreeNodeClass = true;

  @ContentChildren(TreeNodeOutletDirective, {
    descendants: true,
  })
  nodeOutlet: QueryList<TreeNodeOutletDirective>;
}
