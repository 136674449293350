import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';
import { FormButtonsModule } from 'components/form-buttons/form-buttons.module';

import { ISPUIPreloaderRoundModule } from '@ispui/ng-preloader-round';
import { ISPUITooltipModule } from '@ispui/ng-tooltip';

import { FormIdPrefixModule } from 'common/form-id-prefix/form-id-prefix.module';

import { DynamicFormComponent } from './dynamic-form.component';
import { ISPValidator } from './model';
import { ISP_VALIDATORS } from './services/validators';
import {
  InputFieldModule,
  DatePickerFieldModule,
  DateTimeModule,
  SelectFieldModule,
  AutocompleteSelectFieldModule,
  RadioFieldModule,
  PhoneGroupModule,
  CheckboxFieldModule,
  HiddenFieldModule,
  TextDataFieldModule,
  FrameFieldModule,
  ListFieldModule,
  ListFilterModule,
  SliderFieldModule,
  PasswordFieldModule,
  TreeFieldModule,
  TextareaFieldModule,
  FileFieldModule,
  CodeFieldModule,
  LayoutFieldModule,
  LinkFieldModule,
  GroupFieldModule,
  TemplateFieldModule,
  ChatFieldModule,
  TextEditorFieldModule,
  DynamicFormTemplateDirective,
  ChatSummaryFieldModule,
  SiteSummaryFieldModule,
  FormScrollerFieldModule,
  CaptchaFieldModule,
  SwitcherFieldModule,
  ColorPickerFieldModule,
  RadioImgFieldModule,
} from './types';
import { FieldBaseModule } from './wrappers/field-base/field-base.module';
import { InputBaseModule } from './wrappers/input-base/input-base.module';
import { PageWrapperModule } from './wrappers/page/page.module';
import { ValidationErrorModule } from './wrappers/validation-error/validation-error.module';

const fieldModuleList = [
  InputFieldModule,
  DatePickerFieldModule,
  DateTimeModule,
  SelectFieldModule,
  AutocompleteSelectFieldModule,
  RadioFieldModule,
  RadioImgFieldModule,
  PhoneGroupModule,
  CheckboxFieldModule,
  HiddenFieldModule,
  TextDataFieldModule,
  FrameFieldModule,
  ListFieldModule,
  ListFilterModule,
  SliderFieldModule,
  PasswordFieldModule,
  TreeFieldModule,
  TextareaFieldModule,
  FileFieldModule,
  CodeFieldModule,
  LayoutFieldModule,
  LinkFieldModule,
  GroupFieldModule,
  TemplateFieldModule,
  ChatFieldModule,
  TextEditorFieldModule,
  ChatSummaryFieldModule,
  SiteSummaryFieldModule,
  FormScrollerFieldModule,
  CaptchaFieldModule,
  SwitcherFieldModule,
  ColorPickerFieldModule,
];

const wrapperModuleList = [
  FieldBaseModule,
  InputBaseModule,
  PageWrapperModule,
  ValidationErrorModule,
];

/**
 * Fields list module
 *
 * Usage:
 * ```html
 * <isp-field-list [fieldList]="fieldList$ | async"></isp-field-list>
 * ```
 */
@NgModule({
  declarations: [DynamicFormComponent, DynamicFormTemplateDirective],
  exports: [DynamicFormComponent, DynamicFormTemplateDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormButtonsModule,
    ISPUIPreloaderRoundModule,
    ISPUITooltipModule,
    FormIdPrefixModule,
    ...fieldModuleList,
    ...wrapperModuleList,
    FormlyModule.forRoot({
      validators: [
        {
          name: ISPValidator.PassMatch,
          validation: ISP_VALIDATORS[ISPValidator.PassMatch],
        },
        {
          name: ISPValidator.Duplicate,
          validation: ISP_VALIDATORS[ISPValidator.Duplicate],
        },
        {
          name: ISPValidator.FileMaxSize,
          validation: ISP_VALIDATORS[ISPValidator.FileMaxSize],
        },
        {
          name: ISPValidator.ErrorFromBackend,
          validation: ISP_VALIDATORS[ISPValidator.ErrorFromBackend],
        },
        {
          name: ISPValidator.Required,
          validation: ISP_VALIDATORS[ISPValidator.Required],
        },
      ],
    }),
  ],
})
export class DynamicFormModule {}
