import { IFormPage } from 'app/services/api5-service/api.interface';

import { prefix } from 'common/form-id-prefix/form-id-prefix.utils';
import { DocHelper } from 'utils/dochelper';

import { PageWrapperTO } from './model/page-to.interface';

import { ISPFieldConfig, ISPFormState } from '../../model';

/**
 * Append template options, specific for page field wrapper
 *
 * @param config - config to append
 * @param state - dynamic form state
 * @param page - page metadata
 */
export function appendPageOptions(
  config: ISPFieldConfig,
  state: ISPFormState,
  page: IFormPage,
): void {
  const containsErrorFromServer =
    Boolean(state.doc.error) &&
    page.field?.some(f => f.$name === state.doc.error.$object);
  const templateOptions: PageWrapperTO = {
    title: DocHelper.getMessage(page.$name, state.doc),
    name: prefix(state.layoutService, page.$name),
    displayHeader: state.modeService.mode !== 'base',
    opened: containsErrorFromServer || page.$collapsed !== 'yes',
    collapseTooltipMsg: state.context.collapseTooltipMsg,
    expandTooltipMsg: state.context.expandTooltipMsg,
    collapsible: page.$not_collapsible !== 'yes',
  };

  config.templateOptions = {
    ...templateOptions,
    ...config.templateOptions,
  };

  config.expressionProperties = {
    ...config.expressionProperties,
    'templateOptions.displayHeader': (_, formState: ISPFormState) =>
      formState.modeService.mode !== 'base',
  };
}
