import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AnimationOptions } from 'ngx-lottie';

import { getAnimationOptions } from 'utils/get-animation-options';

const BASE_IMG_PATH = '/manimg/common/img/';

/**
 * Component provide show custom icon and from sprite
 */
@Component({
  selector: 'isp-icon-provider',
  templateUrl: './icon-provider.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconProviderComponent {
  /** Icon name */
  @Input() name: string;

  /** Custom styles for icon */
  @Input() customStyle: {};

  /** Has in sprite */
  @Input() inSprite: boolean;

  /** Git icon */
  @Input() isAnimated: boolean;

  /** Get url for custom img */
  private getUrl(): string {
    return `${BASE_IMG_PATH}${this.name}.png`;
  }

  getAnimatedPath(): string {
    return `${BASE_IMG_PATH}${this.name}.gif`;
  }

  getBgStyle(): string {
    return `url(${this.getUrl()}) top left / cover no-repeat `;
  }

  getAnimationOptions(iconName: string): AnimationOptions {
    return getAnimationOptions(iconName);
  }
}
