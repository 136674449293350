<div
  class="date-picker__group"
  [ngClass]="{
    'date-picker__group_invalid': showError,
    'date-picker__group_highlight': showHighlight,
    'date-picker__group_disabled': disabled
  }"
>
  <nz-date-picker
    [(ngModel)]="value"
    [tabindex]="tabindex || 0"
    [nzDisabled]="disabled"
    [nzSuffixIcon]="icon"
    [nzShowToday]="true"
    [nzAllowClear]="true"
    [nzPlaceHolder]="' '"
    [nzMode]="datePickMode"
    nzFormat="dd.MM.yyyy"
    class="date-picker__date-picker"
    (nzOnOpenChange)="onDatePickerStateChange($event)"
    (focusin)="onFocusChange(true)"
    (focusout)="onFocusChange(false)"
  ></nz-date-picker>

  <ng-template #icon>
    <isp-svg-icon [name]="'m-scheduler-grey'"></isp-svg-icon>
  </ng-template>

  <span
    *ngIf="inputLabel"
    class="date-picker__label"
    [ngClass]="{
      'date-picker__label_active': isLabelActive
    }"
  >
    {{ inputLabel
    }}<span *ngIf="required" class="date-picker__required-mark">*</span>
  </span>

  <fieldset class="ispui-fieldset">
    <legend
      class="ispui-fieldset__legend"
      [ngClass]="{ 'ispui-fieldset__legend_active': isLabelActive }"
    >
      {{ inputLabel }}<span *ngIf="required">*</span>
    </legend>
  </fieldset>
</div>
