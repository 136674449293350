<ispui-file
  [(ngModel)]="value"
  [label]="label"
  [accept]="accept"
  [disabled]="disabled"
  [multiple]="multiple"
  [name]="name"
  [required]="required"
  [invalid]="invalid"
  [hint]="hint"
  [useCustomLinkIcon]="false"
>
</ispui-file>
