import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Slider field component
 *
 * Use only with Formly
 */
@Component({
  selector: 'isp-formly-slider-field',
  templateUrl: './slider.component.html',
  styleUrls: ['./scss/slider.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderFieldComponent extends ISPFieldTypeBase<ISPFieldType.Slider> {
  /** Min attribute */
  get min(): string {
    return this.to.originalField.slider[0]?.$min;
  }

  /** Max attribute */
  get max(): string {
    return this.to.originalField.slider[0]?.$max;
  }

  /** Step attribute */
  get step(): string {
    return this.to.originalField.slider[0]?.$step;
  }

  toggleFocus(isFocused = false): void {
    this.to.isFocused = isFocused;
  }
}
