import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

import { WINDOW, WindowWrapper } from '@ngispui/window-service';

/**
 * Directive making the element resizable with a handle in bottom right corner
 */
@Directive({
  selector: '[ispResizableBlock]',
})
export class ResizableDirective implements OnInit, OnDestroy {
  /** Reference to handle element */
  private handle: HTMLDivElement;

  /** Minimal host height */
  private readonly minHeight = 10;

  /** Handle mouse event listeners */
  private handleListenerList: (() => void)[] = [];

  /** Host height to save before deactivating the directive */
  private activeHeight: number;

  /** Toggles the directive */
  private enable: boolean;

  /** Toggles the directive */
  @Input()
  get ispResizableBlock(): boolean {
    return this.enable;
  }

  set ispResizableBlock(val: boolean) {
    this.enable = val;
    if (val) {
      this.activate();
    } else {
      this.deactivate();
    }
  }

  /** Resize end event emitter */
  @Output() readonly resizeEnd = new EventEmitter();

  constructor(
    private readonly host: ElementRef,
    private readonly renderer: Renderer2,
    @Inject(WINDOW) private readonly window: WindowWrapper,
  ) {}

  private activate(): void {
    if (this.activeHeight) {
      this.renderer.setStyle(
        this.host.nativeElement,
        'height',
        `${this.activeHeight}px`,
      );
    }
    this.appendHandle();
    this.listenHandle();
  }

  private deactivate(): void {
    this.renderer.setStyle(this.host.nativeElement, 'height', null);
    this.handleListenerList.forEach(removeListener => {
      removeListener();
    });
    // we check handle here because angular somehow may do ngOnDestroy before ngOnInit
    if (this.handle) {
      this.handle.remove();
    }
  }

  /**
   * Prepare host to have non-static position
   */
  private prepareHost(): void {
    const currentPosition = this.window.getComputedStyle(
      this.host.nativeElement,
    ).position;
    if (currentPosition === 'static') {
      this.renderer.setStyle(this.host.nativeElement, 'position', 'relative');
    }
  }

  /**
   * Create and append handle div to the host
   */
  private appendHandle(): void {
    const handle = this.renderer.createElement('div');
    const style = {
      position: 'absolute',
      width: '17px',
      height: '15px',
      bottom: '0',
      right: '0',
      cursor: 'ns-resize',
      'z-index': '2',
      'clip-path': 'polygon(100% 0, 0 100%, 100% 100%)',
      'background-image':
        'repeating-linear-gradient(140deg, #d9d9d9, #d9d9d9 1px, transparent 2px, transparent 3px)',
    };
    for (const prop of Object.keys(style)) {
      this.renderer.setStyle(handle, prop, style[prop]);
    }
    this.renderer.appendChild(this.host.nativeElement, handle);
    this.handle = handle;
  }

  /**
   * Listen handle mouse events
   */
  private listenHandle(): void {
    let mouseDownY: number;
    let mouseDownHostHeight: number;
    let isResizing = false;

    const downListener = this.renderer.listen(
      this.handle,
      'mousedown',
      (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        isResizing = true;
        mouseDownY = event.pageY;
        mouseDownHostHeight = this.host.nativeElement.clientHeight;
      },
    );

    const moveListener = this.renderer.listen(
      window,
      'mousemove',
      (event: MouseEvent) => {
        if (isResizing) {
          event.stopPropagation();
          event.preventDefault();
          const diff = event.pageY - mouseDownY;
          const computedNewHeight = mouseDownHostHeight + diff;
          const newHeight =
            computedNewHeight < this.minHeight
              ? this.minHeight
              : computedNewHeight;
          this.activeHeight = newHeight;
          this.renderer.setStyle(
            this.host.nativeElement,
            'height',
            `${newHeight}px`,
          );
        }
      },
    );

    const upListener = this.renderer.listen(
      window,
      'mouseup',
      (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        if (isResizing) {
          mouseDownY = undefined;
          mouseDownHostHeight = undefined;
          isResizing = false;
          this.resizeEnd.emit();
        }
      },
    );

    this.handleListenerList = [downListener, moveListener, upListener];
  }

  ngOnInit(): void {
    this.prepareHost();
  }

  ngOnDestroy(): void {
    this.deactivate();
    this.renderer.destroy();
  }
}
