<div class="container">
  <input
    class="color-picker-input"
    type="text"
    [cpOutputFormat]="'hex'"
    [cpAlphaChannel]="'forced'"
    [colorPicker]="colorPickerValue"
    [cpPosition]="['bottom-right']"
    [style.background]="colorPickerValue"
    (colorPickerChange)="onColorPickerChange($event)"
    (colorPickerClose)="onColorPickerClose()"
  />
  <span class="hash">#</span>
  <ispui-input
    class="input"
    [value]="inputValue"
    (customChange)="onInputValueChange($event)"
    (customBlur)="onInputValueBlur()"
  ></ispui-input>
  <button
    *ngIf="resetValue && value !== resetValue"
    (click)="writeValue(resetValue)"
    type="button"
    class="icon"
  >
    <isp-svg-icon [name]="'cancel-operation'"></isp-svg-icon>
  </button>
</div>
