import { Injectable, NgZone } from '@angular/core';

import { Observable, of } from 'rxjs';

import { IExtformMeta } from '../api5-service/api.interface';

declare const ispPrefetchObject: {
  listeners: ((doc: IExtformMeta) => void)[];
  result?: IExtformMeta;
};

@Injectable({
  providedIn: 'root',
})
export class PrefetchService {
  constructor(private readonly zone: NgZone) {}

  getPrefetch$(): Observable<IExtformMeta> {
    let docStream: Observable<IExtformMeta>;
    if (ispPrefetchObject.result) {
      docStream = of(ispPrefetchObject.result);
    } else {
      docStream = new Observable<IExtformMeta>(observer => {
        const fetchCallback = (doc: IExtformMeta) => {
          // you must use zone, cause Angular won't know about that fetch over
          this.zone.run(() => {
            observer.next(doc);
            observer.complete();
          });
        };
        ispPrefetchObject.listeners.push(fetchCallback);
      });
    }

    return docStream;
  }
}
