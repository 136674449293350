import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LottieModule } from 'ngx-lottie';
import { IconSimularModule } from 'pipes/icon-simular/icon-simular.module';

import { IconProviderComponent } from './icon-provider.component';

import { SvgIconModule } from '../svg-icon-module/svg-icon.module';
import { TrustPipeModule } from '../trust/trust.module';

/**
 * Module for define svg icons and use it
 */
@NgModule({
  imports: [
    CommonModule,
    SvgIconModule,
    IconSimularModule,
    TrustPipeModule,
    LottieModule,
  ],
  declarations: [IconProviderComponent],
  exports: [IconProviderComponent],
})
export class IconProviderModule {}
