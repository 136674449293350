import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';

import { AppService } from 'app/app.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { DocHelper } from 'utils/dochelper';

import { TabParams, TabPosition } from './model';
import { ActiveTabService } from './tab-active.service';
import { TabStorage } from './tab-storage.service';
import { Tab } from './tab.class';
import { getMenuGroupName, parseStringToQueryParams } from './tab.utils';

import { IDesktopMeta, IMenuSubnode } from '../api5-service/api.interface';
import { PageInfoService } from '../page-info/page-info.service';

/**
 * Service for getting tabs initial state
 */
@Injectable({
  providedIn: 'root',
})
export class TabInitiallize {
  constructor(
    private readonly appService: AppService,
    private readonly tabStorage: TabStorage,
    private readonly pageInfoService: PageInfoService,
    private readonly activeTabService: ActiveTabService,
    private readonly router: Router,
  ) {}

  /**
   * Get permanent dashboard tab
   *
   * @param desktop - desktop doc
   */
  private createDashboard(desktop: IDesktopMeta): Tab {
    // @WARN dashboard groupName is realy doesn't matter, cause group name used for icon displaying and dashboard have no icon
    return new Tab({
      params: {
        func: 'dashboard',
      },
      groupName: '',
      isPinned: false,
      title: DocHelper.getMessage('dashboard', desktop),
    });
  }

  /**
   * Get ponned menu nodes from desktop menu
   *
   * @param desktop - desktop doc
   */
  private getPinnedMenuNodes(desktop: IDesktopMeta): IMenuSubnode[] {
    const mainMenu = desktop.mainmenu?.node || [];
    const subNodes = mainMenu.flatMap(node => node.node);
    const pinnedNodes = subNodes.filter(node => node.$pin);

    return pinnedNodes;
  }

  /**
   * Get tab from desktop pinned menu items
   *
   * @param desktop - desktop doc
   */
  private createFromPinnedMenuNode(desktop: IDesktopMeta): Tab[] {
    const pinnedNodes = this.getPinnedMenuNodes(desktop);

    return pinnedNodes
      .filter(node => node.$action !== ('dashboard' as unknown))
      .map(
        node =>
          new Tab({
            params: { func: node.$action },
            title: DocHelper.getMessage(`menu_${node.$action}`, desktop),
            groupName: getMenuGroupName(node.$name, desktop),
            isPinned: true,
          }),
      );
  }

  /**
   * Get tabs, that was saved in previous session in local storage
   */
  private createFromStorage(): {
    tabs: Tab[][];
    activeTabPosition: TabPosition;
  } {
    const tabsData = this.tabStorage.getTabs();

    // in case of corrupted data in local storage
    try {
      const tabs = tabsData.tabs
        .map(group => group.map(data => Tab.deserialize(data)))
        .filter(group => group.length);
      const { index, gIndex } = tabsData.activeTabPosition;
      const isValidActiveTabPosition = tabs[gIndex][index] instanceof Tab;
      return {
        tabs,
        activeTabPosition: isValidActiveTabPosition
          ? tabsData.activeTabPosition
          : undefined,
      };
    } catch (e: unknown) {
      // data corrupted - do not use it
      return {
        tabs: [],
        activeTabPosition: undefined,
      };
    }
  }

  /**
   * Create tab from pageInfo.startpage
   *
   * @WARN This is legacy feature from Sirius. Startpage was some table, that should be opened. Not this is just like some tab func
   * @param desktop - desktop doc
   */
  private createFromStartPage(desktop: IDesktopMeta): Tab | null {
    const startPage = this.pageInfoService.desktopPageInfo.startpage;
    // do not treat startform if it include dashboard
    if (!startPage || startPage.includes('dashboard')) {
      return null;
    }

    const startPageQueryParams = parseStringToQueryParams(
      `func=${startPage}`,
    ) as TabParams;

    const groupName = getMenuGroupName(startPage, desktop);
    const tab = new Tab({
      params: startPageQueryParams,
      title: DocHelper.getMessage(`menu_${startPage}`, desktop),
      groupName,
      isPinned: false,
    });

    // guarantee data loading, in order to prevent wrong title displaying
    tab.setLazyLoadable(false);

    return tab;
  }

  /**
   * Create tab from pageInfo.startform
   *
   * @WARN This is legacy feature from Sirius. Startform was some form modal, that should be opened over some table. Now this is just like simple tab func
   * @param desktop - desktop doc
   */
  private createFromStartForm(desktop: IDesktopMeta): Tab | null {
    const startForm = this.pageInfoService.desktopPageInfo.startform;
    // do not treat startform if it include dashboard
    if (!startForm || startForm.includes('dashboard')) {
      return null;
    }

    const startFormQueryParams = parseStringToQueryParams(
      `func=${startForm}`,
    ) as TabParams;

    const groupName = getMenuGroupName(startForm, desktop);
    // @WARN we don't know tab title on the moment of creating it from url
    // AND tabs docs with title downloads lazily,
    // so we must to set it active, in order to start loading tab doc
    const tab = new Tab({
      params: startFormQueryParams,
      title: '',
      groupName,
      isPinned: false,
    });

    // guarantee data loading, in order to prevent wrong title displaying
    tab.setLazyLoadable(false);

    return tab;
  }

  /**
   * Get tabs, that should be created from billing settings (startform or startpage) or from user url request
   *
   * @param queryParams - url query params
   * @param desktop - desktop doc
   */
  private getRedirectTabs(queryParams: Params, desktop: IDesktopMeta): Tab[] {
    // url is a major source of new tab. User can go to app by url, can be redirected by url, can go after logon or register with url
    const urlTab = this.activeTabService.createFromUrl(queryParams, desktop);

    if (urlTab) {
      // guarantee data loading, in order to prevent wrong title displaying
      urlTab.setLazyLoadable(false);
      return [urlTab];
    }

    // startform and startpage is a legacy system for opening default tab instead of dashboard
    // ONLY if there is no other tab in url. Mean ONLY for default behavior
    const startpageTab = this.createFromStartPage(desktop);
    const startformTab = this.createFromStartForm(desktop);
    if (startpageTab && startformTab) {
      return [startpageTab, startformTab];
    }

    if (startpageTab) {
      return [startpageTab];
    }

    if (startformTab) {
      return [startformTab];
    }

    return [];
  }

  /**
   * Remove duplication from tabs, that was getted from local storage and BE
   *
   * @param dashboard - dashboard tab
   * @param pinnedTabs - pinned tab from BE
   * @param restoredTabs - restored from local storage tabs
   */
  private removeDuplicationFromRestoredTabs(
    dashboard: Tab,
    pinnedTabs: Tab[],
    restoredTabs: Tab[][],
  ): Tab[][] {
    // this array will be used to keep all old tabs. We need to fill it
    // dashboard ALWAYS exist in tabs
    const oldTabs: Tab[][] = [[dashboard]];

    // make copy of all restored tabs. Make copy in order to keep this function clean. Cause we will mutate this refrence
    const restored = restoredTabs
      .map(groups => {
        // remove dashboard from restored tab
        const newGroup = groups.filter(tab => tab.func !== 'dashboard');
        return newGroup;
      })
      .filter(group => group.length);

    // iterate over all pinned tabs and try to add them to old tabs. Pinned tabs are weaker than restored - cause restored keep more information
    for (const pinned of pinnedTabs) {
      // seek for pinned tab duplication
      const storedPinnedDuplicateIndex = restored.findIndex(group => {
        // it seems that only top level tabs can be pinned...
        // so it only makes sense to look for duplication on first tab of restored groups
        return group[0].func === pinned.func;
      });

      if (storedPinnedDuplicateIndex === -1) {
        // there is no duplication. Just add pinned tab
        oldTabs.push([pinned]);
      } else {
        // remove duplication group from restored tabs
        const duplicationGroup = restored.splice(
          storedPinnedDuplicateIndex,
          1,
        )[0];

        // mark first tab as pinned
        duplicationGroup[0].setPinned(true);

        // add this group to all tabs
        oldTabs.push(duplicationGroup);
      }
    }

    // push all other restored tabs, that was left after removing duplication
    oldTabs.push(...restored);

    return oldTabs;
  }

  /**
   * Add newly created redirect tabs to initial tabs
   *
   * @param oldTabGroups - initial tabs, restored from local storage and BE pinned tabs
   * @param redirectTabGroup - newly created from url or legace startform/startpage redirect tabs
   * @returns - final initial tab groups and tab, that should be active first. If there is no redirect tabs, then no active tab will be returned
   */
  private addRedirectTabs(
    oldTabGroups: Tab[][],
    redirectTabGroup: Tab[],
  ): {
    initialTabGroups: Tab[][];
    redirectActiveTab?: Tab;
  } {
    const allTabGroups: Tab[][] = [...oldTabGroups];
    if (!redirectTabGroup.length) {
      // if there is no redirect tabs - just use old one tabs
      return {
        initialTabGroups: allTabGroups,
      };
    }

    // forward redirection on some tab case. Seek for existed tab to activate it
    if (redirectTabGroup.length === 1) {
      if (redirectTabGroup[0].func === 'dashboard') {
        return {
          initialTabGroups: allTabGroups,
          redirectActiveTab: allTabGroups
            .flat()
            .find(tab => tab.func === 'dashboard'),
        };
      }

      // seek by params include match
      // for example if we go on 'startform=payment' this will be matched to existed 'func=payment&p_num=1&any_other_params=any' tab
      const existedTabToRedirect = allTabGroups
        .flat()
        .find(tab => tab.isIncludeQueryParams(redirectTabGroup[0].params));

      if (existedTabToRedirect) {
        // return initial tabs with new tab to redirect - already existed target tab
        return {
          initialTabGroups: allTabGroups,
          redirectActiveTab: existedTabToRedirect,
        };
      }
    }

    // startpage + startform case
    if (redirectTabGroup.length === 2) {
      const existedTabGroupToRedirectIndex = allTabGroups.findIndex(group =>
        group[0].isIncludeQueryParams(redirectTabGroup[0].params),
      );

      if (existedTabGroupToRedirectIndex !== -1) {
        // if redirect to existed group - replace this group with redirect group
        // then this redirect group will be determined as active
        allTabGroups.splice(
          existedTabGroupToRedirectIndex,
          1,
          redirectTabGroup,
        );
        return {
          initialTabGroups: allTabGroups,
          // use startform as initial active tab
          redirectActiveTab: redirectTabGroup[1],
        };
      }
    }

    // if there is no duplication with redirect group, just add new group and new active tab
    allTabGroups.push(redirectTabGroup);
    return {
      initialTabGroups: allTabGroups,
      redirectActiveTab: redirectTabGroup[redirectTabGroup.length - 1],
    };
  }

  /**
   * Determine active tab among all tabs. Make sure that there is at least one active tab
   *
   * @param tabGroups - tabs groups
   * @param restoredActiveTabPosition
   * @param redirectActiveTab
   */
  private determineActiveTab(
    tabGroups: Tab[][],
    restoredActiveTabPosition?: TabPosition,
    redirectActiveTab?: Tab,
  ): Tab | undefined {
    // seek for dashboard in tabs as fallback...
    const dashboard = tabGroups.flat().find(tab => tab.func === 'dashboard')!;

    const restoredActiveTab =
      tabGroups[restoredActiveTabPosition?.gIndex]?.[
        restoredActiveTabPosition.index
      ];

    const activeTab = redirectActiveTab || restoredActiveTab || dashboard;

    return activeTab;
  }

  /**
   * Get initial tabs from desktop meta, page info, url and storage
   *
   * @param desktop - desktop doc
   */
  private getInitialTabs(desktop: IDesktopMeta): {
    tabs: Tab[][];
    activeTab?: Tab;
  } {
    // dashboard permanently exist. Have NO 'isActive' information
    const dashboard = this.createDashboard(desktop);
    // pinned tabs recieved from BE, remote tabs. Have NO 'isActive' information
    const pinnedTabs = this.createFromPinnedMenuNode(desktop);

    // resotred tabs are lies in the local machine. Contain ALL tabs from previous work session. Have 'isActive' information
    const {
      tabs: restoredGroups,
      activeTabPosition: restoredActiveTabPosition,
    } = this.createFromStorage();

    // merged local and remote tabs. Cleared from duplication. Tabs that was existed
    const oldTabGroups = this.removeDuplicationFromRestoredTabs(
      dashboard,
      pinnedTabs,
      restoredGroups,
    );

    const queryParams = this.router.parseUrl(this.router.url).queryParams;
    // tabs, that was newly created imperatively. Newly created tabs
    const redirectTabGroup = this.getRedirectTabs(queryParams, desktop);

    // add redirect tab to all other tabs
    const { initialTabGroups, redirectActiveTab } = this.addRedirectTabs(
      oldTabGroups,
      redirectTabGroup,
    );

    // determine right active tab
    const activeTab = this.determineActiveTab(
      // we have initial tab groups...
      initialTabGroups,
      // we have restored from previous session active tab position...
      restoredActiveTabPosition,
      // we have active tab from redirect group...
      redirectActiveTab,
    );

    return {
      tabs: initialTabGroups,
      activeTab,
    };
  }

  /**
   * Get initial tabs after application start
   */
  getInitialTabs$(): Observable<{
    tabs: Tab[][];
    activeTab?: Tab;
  }> {
    return this.appService.desktop$.pipe(
      take(1),
      map(doc => this.getInitialTabs(doc)),
    );
  }
}
