import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { of } from 'ramda';
import { Observable, OperatorFunction, pipe } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';

import {
  DYNAMIC_FORM_FOOTER_CLASS,
  DYNAMIC_FORM_HEADER_CLASS,
  DYNAMIC_FORM_SCROLLABLE_CONTAINER_CLASS,
  LayoutService,
} from 'common/dynamic-form/services/layout.service';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Pipe operator to switch map on html element resize event
 */
export function switchMapToResizeObserver(): OperatorFunction<
  HTMLElement,
  HTMLElement
> {
  return pipe(
    switchMap((element?: HTMLElement) => {
      if (!element || !(element instanceof Element)) {
        return of(element);
      }

      return new Observable<HTMLElement>(observer => {
        observer.next(element);

        const resizeObserver = new ResizeObserver(() => {
          observer.next(element);
        });
        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
      });
    }),
  );
}

/**
 * Field for form layout. Assumed that it's used on top level and have only one instance
 */
@Component({
  selector: 'isp-formly-layout-field',
  templateUrl: './layout.component.html',
  styleUrls: ['./scss/layout.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutFieldComponent
  extends ISPFieldTypeBase<ISPFieldType.Layout>
  implements AfterViewInit
{
  readonly headerClass = DYNAMIC_FORM_HEADER_CLASS;

  readonly footerClass = DYNAMIC_FORM_FOOTER_CLASS;

  readonly formContainerClass = DYNAMIC_FORM_SCROLLABLE_CONTAINER_CLASS;

  @ViewChildren('header') headerElement: QueryList<ElementRef<HTMLElement>>;

  @ViewChild('footer') footerElement: ElementRef<HTMLElement>;

  @ViewChild('form') formElement: ElementRef<HTMLElement>;

  headerHeight$: Observable<string>;

  constructor(private readonly layoutService: LayoutService) {
    super();
  }

  ngAfterViewInit(): void {
    this.headerHeight$ = this.headerElement.changes.pipe(
      startWith(this.headerElement),
      map(header => header.first?.nativeElement),
      tap(header => (this.layoutService.formHeader = header)),
      switchMapToResizeObserver(),
      map(header => (header ? `${header.clientHeight}px` : '0')),
    );

    this.layoutService.formContainer = this.formElement.nativeElement;
    this.layoutService.formFooter = this.footerElement?.nativeElement;
  }
}
