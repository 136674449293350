import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ColorPickerModule } from 'ngx-color-picker';

import { ISPUIInputModule } from '@ispui/ng-input';

import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { ColorPickerComponent } from './color-picker.component';

/**
 * Color picker module
 */
@NgModule({
  declarations: [ColorPickerComponent],
  exports: [ColorPickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ISPUIInputModule,
    SvgIconModule,
    ColorPickerModule,
  ],
})
export class ISPColorPickerModule {}
