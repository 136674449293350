<div
  *ngFor="let file of files; trackBy: trackByFile"
  class="chat-bubble__file"
  (click)="handleFileClick(file)"
>
  <isp-svg-icon
    class="chat-bubble__file-icon"
    name="p-file-100"
    [customStyle]="{ width: '14px', height: '17.5px' }"
  >
  </isp-svg-icon>

  <div class="chat-bubble__file-text">
    <span class="chat-bubble__file-title">{{ file.title }}</span>
    <span class="chat-bubble__file-size">{{ file.size }}</span>
  </div>
</div>
