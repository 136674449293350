import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { FieldValidatorFn } from '@ngx-formly/core/lib/services/formly.config';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldTypeWithControl,
  ISPValidationErrors,
  ISPValidator,
} from '../model';

/**
 * Get file max size validator function
 *
 * @param control
 * @param config
 */
function fileMaxSizeValidator(
  control: AbstractControl,
  config: ISPFieldConfig<ISPFieldType.InputFile>,
): ISPValidationErrors | null {
  const value = control.value;
  if (!(value instanceof FileList)) {
    return null;
  }

  const originalControl = config.templateOptions.originalControl;
  const maxSize = originalControl.$maxsize
    ? Number(originalControl.$maxsize)
    : NaN;
  const totalSize = Array.from(value).reduce(
    (size, file) => size + file.size,
    0,
  );

  if (totalSize > maxSize) {
    return {
      [ISPValidator.FileMaxSize]: true,
    };
  }

  return null;
}

/**
 * Checks duplicates in the control's value
 *
 * @param control - abstract control
 */
function duplicateValidator(
  control: AbstractControl,
): ISPValidationErrors | null {
  const value = control.value;
  if (typeof value !== 'string') {
    return null;
  }

  const splitedValue = control.value.split(/\s+/);
  const uniqueValues = new Set<string>(splitedValue);
  if (uniqueValues.size !== splitedValue.length) {
    return {
      [ISPValidator.Duplicate]: true,
    };
  }

  return null;
}

/**
 * Match values of two fields
 *
 * @WARN must be used only on group fields!
 * @param passwordFormGroup - password Form Group with 'password' and 'confirm' controls
 * @param config
 */
function passMatchValidator(
  passwordFormGroup: FormGroup,
  config: ISPFieldConfig<ISPFieldType.Group>,
): ISPValidationErrors | null {
  const passwordField: ISPFieldConfig = config.fieldGroup.find(
    (field: ISPFieldConfig) => {
      if (field.type !== ISPFieldType.Password) {
        return false;
      }
      return field.templateOptions.originalControl?.$checkpasswd;
    },
  );
  if (!passwordField) {
    return null;
  }
  const confirmFieldKey: string = (passwordField.templateOptions as any)
    .originalControl?.$checkpasswd;
  if (!confirmFieldKey) {
    return null;
  }
  const confirmControl: FormControl = passwordFormGroup.controls[
    confirmFieldKey
  ] as FormControl;
  const password: string = passwordFormGroup.value[passwordField.key as string];
  const confirm: string = passwordFormGroup.value[confirmFieldKey];

  // avoid displaying the message error when values are empty
  if (!password) {
    return null;
  }

  if (confirm === password) {
    confirmControl.setErrors(null);
    confirmControl.markAsTouched();
    // applying _markForCheck will probably need to be changed when moving to Formly v.6
    const confirmField = (confirmControl as any)._fields[0];
    confirmField.options._markForCheck(confirmField);

    return null;
  }

  if (confirmControl) {
    confirmControl.setErrors({
      [ISPValidator.PassMatch]: true,
    });
    confirmControl.markAsTouched();
    // applying _markForCheck will probably need to be changed when moving to Formly v.6
    const confirmField = (confirmControl as any)._fields[0];
    confirmField.options._markForCheck(confirmField);
  }

  return { [ISPValidator.PassMatch]: true };
}

/**
 * Error after form submit validator function. Need to display error on the field
 *
 * @param formControl - form control
 * @param fieldConfig - field config
 * @returns - true for valid value, false for invalid
 */
function submitErrorValidator(
  formControl: AbstractControl,
  fieldConfig: ISPFieldConfig<ISPFieldTypeWithControl>,
): ISPValidationErrors | null {
  if (fieldConfig.type === ISPFieldType.Captcha) {
    return Boolean(formControl.value)
      ? null
      : {
          [ISPValidator.ErrorFromBackend]: true,
        };
  }

  if (formControl.parent.pristine) {
    return {
      [ISPValidator.ErrorFromBackend]: true,
    };
  }
  return null;
}

export const ISP_VALIDATORS: Record<ISPValidator, FieldValidatorFn> = {
  [ISPValidator.FileMaxSize]: fileMaxSizeValidator,
  [ISPValidator.Duplicate]: duplicateValidator,
  [ISPValidator.PassMatch]: passMatchValidator,
  [ISPValidator.ErrorFromBackend]: submitErrorValidator,
  // eslint-disable-next-line @typescript-eslint/unbound-method
  [ISPValidator.Required]: Validators.required,
};
