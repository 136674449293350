import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { prepareModelToSubmit, submitForm } from 'app/form/form.utils';
import { IFormButtonUi } from 'components/form-button';
import { Observable, of, Subject, Subscription, zip } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ConfirmOptions } from '@ispui/confirm';
import { NotifyBannerEvents, NotifyBannerTypes } from '@ngispui/notification';
import { WINDOW, WindowWrapper } from '@ngispui/window-service';

import {
  IFormButtonClickEvent,
  IFormModel,
} from 'common/dynamic-form/dynamic-form.interface';
import {
  createProgressIdForDownloadList,
  createProgressIdForFormSubmitting,
  isWaitProgressTypeById,
} from 'common/progress-task-modal/progress-task.utils';
import { convertParamsToStringWithEncode } from 'utils/convert-params-to-string';
import { convertStringToParamsWithDecode } from 'utils/convert-string-to-params';
import { DocHelper } from 'utils/dochelper';
import { hasFile } from 'utils/has-file';

import {
  IBanner,
  IDocument,
  INotificationBanner,
  IOk,
  IToolbarAction,
  IToolButton,
  OkType,
  TActionType,
  TStringBool,
} from './api5-service/api.interface';
import { OkTabAction } from './api5-service/api.interface/ok-tabaction.enum';
import { Api5Service } from './api5-service/api5.service';
import { ConfirmService } from './confirm/confirm.service';
import { IHttpParam } from './http-base-service/http-base.interface';
import { IndicateRebootService } from './indicate-reboot.service';
import {
  ISPNotificationService,
  Notification,
  NotificationEvent,
} from './notification.service';
import {
  PreloadedActionOptions,
  PreloadedActionService,
} from './preloaded-action.service';
import { ProgressBarService } from './progressbar-service/progressbar.service';
import { ActiveTabService, TabGroupService, Tab } from './tab';

import { AppService } from '../app.service';

const CONFIRM_RENDER_TIMEOUT = 50;

const CONFIRM_TOP_OFFSET = 10;

const CONFIRM_LEFT_OFFSET = 50;

/**
 * Action service
 * Manage action open
 */
@Injectable({
  providedIn: 'root',
})
export class ActionService {
  /** Cache form reponse for "wait" progress */
  private readonly responseCache: Record<
    string,
    {
      response: IDocument;
      dataSource: {
        tab: Tab;
        params: Params;
        options: PreloadedActionOptions;
      };
    }
  > = {};

  /** Errror banner notification */
  private readonly bannerMap: Record<
    string,
    {
      id: number;
      subscription?: Subscription;
    }
  > = {};

  /** Subject for cancel prev request */
  private readonly cancelMenuRequest$ = new Subject<void>();

  constructor(
    private readonly activeTabService: ActiveTabService,
    private readonly tabService: TabGroupService,
    private readonly appService: AppService,
    @Inject(WINDOW) private readonly window: WindowWrapper,
    private readonly preloadedActionService: PreloadedActionService,
    private readonly progressbarService: ProgressBarService,
    private readonly notificationService: ISPNotificationService,
    private readonly api: Api5Service,
    private readonly rebootService: IndicateRebootService,
    private readonly confirmService: ConfirmService,
  ) {}

  /**
   * Gets the description for a confirm window
   *
   * @param toolbtn - pressed toolbar button
   * @param tab - tab instance
   * @param doc - doc instance
   */
  private getConfirmMessage(
    toolbtn: Pick<
      IToolButton,
      '$func' | '$name' | '$sametab' | '$type' | '$warning'
    >,
    tab: Tab,
    doc: IDocument,
  ): string {
    return (
      DocHelper.getMessage(`msg_confirm_${toolbtn.$name}`, doc) ||
      DocHelper.getMessage(`msg_confirm_${toolbtn.$name}`, tab.doc)
    );
  }

  /**
   * Open confirm box and update tab after request
   *
   * @param confirmOptions - map with confirmBox options
   * @param params - param for request
   * @param tab - tab instance
   * @param canRefreshTab - whether the tab should be refreshed after resolving
   */
  private openConfirm(
    confirmOptions: ConfirmOptions,
    params: Params,
    tab: Tab,
    canRefreshTab = true,
  ): Observable<IDocument> {
    return this.confirmService.open(confirmOptions).pipe(
      filter(confirmResult => confirmResult.resolve),
      switchMap(() => this.preloadedActionService.postAction(params)),
      switchMap(actionResult => {
        if (actionResult.ok && Object.keys(actionResult.ok).length > 0) {
          this.handleRedirects({
            doc: actionResult,
            tab,
          });
          return of(actionResult);
        } else {
          return canRefreshTab
            ? this.tabService.updateTabDocFromServer(tab).pipe(
                tap(refreshedTabDoc => {
                  if (actionResult.banner) {
                    this.handleNotificationBanners(
                      DocHelper.getNotificationBanners(actionResult),
                    );
                  }

                  if (actionResult.warning) {
                    refreshedTabDoc.warning = actionResult.warning;
                  }

                  this.tabService.updateTabDoc(tab, refreshedTabDoc);
                  this.activeTabService.setActive(tab);
                }),
              )
            : of(actionResult);
        }
      }),
    );
  }

  /**
   * Calculate confirm position
   *
   * @param anchorElement - link to anchor html element
   */
  private async positionConfirm(anchorElement: HTMLElement): Promise<void> {
    if (!anchorElement) {
      return;
    }

    const anchorRect = anchorElement.getBoundingClientRect();
    await new Promise(resolve => setTimeout(resolve, CONFIRM_RENDER_TIMEOUT));
    const confirmContainer: HTMLElement = document.querySelector(
      '.ispui-confirm__container',
    );
    if (!confirmContainer) {
      return;
    }

    const confirmRect = confirmContainer.getBoundingClientRect();

    confirmContainer.style.position = 'absolute';
    if (
      confirmRect.height <
      document.documentElement.clientHeight -
        anchorRect.bottom -
        CONFIRM_TOP_OFFSET
    ) {
      confirmContainer.style.top = `${
        anchorRect.bottom + CONFIRM_TOP_OFFSET
      }px`;
    }

    if (
      document.documentElement.clientWidth - anchorRect.left >
      confirmRect.width - CONFIRM_LEFT_OFFSET
    ) {
      confirmContainer.style.left = `${
        anchorRect.left - CONFIRM_LEFT_OFFSET
      }px`;
    } else {
      confirmContainer.style.left = `${
        document.documentElement.clientWidth - confirmRect.width
      }px`;
    }
  }

  /**
   * Returns has metadata type for render tab
   *
   * @param doc - doc instance
   */
  private hasMetaData(doc: IDocument): boolean {
    return ['list', 'form', 'report', 'helpboard', 'branding'].includes(
      DocHelper.type(doc),
    );
  }

  /**
   * Update main menu by `refreshmenu` attribute
   *
   * @param ok - redirect's `ok` data
   */
  private handleRefreshMenu(ok: IOk | undefined): void {
    if (ok?.$refreshmenu) {
      this.progressbarService.hideProgressBar();
      this.appService.getDesktop$().subscribe();
    }
  }

  /**
   * Handle redirects. Check attributes of response `ok` object
   * and redirect to other pages if needed.
   *
   * @param param - redirects parameters
   * @param param.doc - document instance
   * @param param.tab - current tab
   * @param param.defaultAction - callback for default redirect action
   */
  private handleRedirects({
    doc,
    tab,
    defaultAction,
  }: {
    doc: IDocument;
    tab?: Tab;
    defaultAction?: () => void;
  }): void {
    const isDocHasMetadata = this.hasMetaData(doc);
    const ok = doc.ok;
    if (!isDocHasMetadata && !ok) {
      this.tabService
        .updateTabDocFromServer(tab)
        .pipe(tap(() => this.activeTabService.setActive(tab)))
        .subscribe();
      return;
    }

    this.handleRefreshMenu(ok);

    switch (ok?.$type) {
      case OkType.Blank:
        this.openNewWindow(ok.$);
        this.handleTabAction(doc, tab, ok);
        return;
      case OkType.Url:
        this.redirectToUrl(ok.$);
        this.handleTabAction(doc, tab, ok);
        return;
      case OkType.Top:
        this.handleTopRedirect(ok, tab);
        this.handleTabAction(doc, tab, ok);
        return;
      case OkType.Form:
      case OkType.List:
        this.handleFormOrListRedirect(ok, tab);
        return;
      case OkType.Reboot:
        this.rebootService.setRebootModal(ok.$);
        this.progressbarService.hideProgressBar();
        break;
      default:
        this.progressbarService.hideProgressBar();
        break;
    }

    if (ok?.$tabaction) {
      this.handleTabAction(doc, tab, ok);
    } else if (ok?.$child === 'yes') {
      const newTab = this.tabService.createChild(doc, tab);
      if (newTab) {
        this.activeTabService.setActive(newTab);
      }
    } else if (defaultAction) {
      defaultAction();
    }
  }

  /**
   * Handle `tabaction` attribute. This attribute is used
   * to control the behavior of tab (close current, open new as child,
   * and so on).
   *
   * @param doc - doc instance
   * @param tab - current tab
   * @param ok - redirect's ok data
   * @param createOnKeep - should create new tab on keep action
   */
  private handleTabAction(
    doc: IDocument,
    tab?: Tab,
    ok?: IOk,
    createOnKeep = false,
  ): void {
    if (!Boolean(tab)) {
      return;
    }

    switch (ok?.$tabaction) {
      case OkTabAction.KeepTab:
        if (createOnKeep) {
          this.tabService.closeActiveGroup();
          const newTab = this.tabService.create(doc);
          this.activeTabService.setActive(newTab);
        }
        break;
      case OkTabAction.OpenAsChild: {
        const newTab = this.tabService.createChild(doc, tab);
        this.activeTabService.setActive(newTab);
        break;
      }
      case OkTabAction.ReplaceGroup: {
        this.tabService.closeGroup(tab);
        this.tabService.closeActiveGroup();
        const newTab = this.tabService.create(doc);
        this.activeTabService.setActive(newTab);
        break;
      }
      default:
        this.tabService.close(tab, true);
        break;
    }
  }

  /**
   * Handle redirect for type `top`. Reloads
   * browser's tab.
   *
   * @param ok - redirect's `ok` data
   * @param tab - tab instance for close
   */
  private handleTopRedirect(ok: IOk, tab?: Tab): void {
    if (ok.$tabaction === OkTabAction.KeepTab) {
      location.reload();
    } else {
      // close form before reload page
      if (tab?.type === 'form') {
        this.tabService.close(tab, false);
      }
      location.hash = '';
      location.reload();
    }
  }

  /**
   * Handle redirect for type `form` or `list`
   *
   * @param ok - redirect's `ok` data
   * @param tab - current tab
   */
  private handleFormOrListRedirect(ok: IOk, tab?: Tab): void {
    const params = convertStringToParamsWithDecode(ok.$);

    if (tab?.params.table_params) {
      params.table_params = tab.params.table_params;
    }

    // @HACK update tab to apply filters
    // Used when clicking "Filter by ..." button in the toolbar
    if (
      Object.keys(params).length === 1 &&
      params[Object.keys(params)[0]] === 'undefined'
    ) {
      this.tabService
        .updateTabDocFromServer(tab)
        .pipe(tap(() => this.activeTabService.setActive(tab)))
        .subscribe();
      return;
    }

    this.preloadedActionService
      .getAction(params, { hideProgressbar: false })
      .subscribe((doc: IDocument) => {
        this.handleRedirects({
          doc: doc,
          tab,
          defaultAction: () => {
            if (ok?.$tabaction) {
              this.handleTabAction(doc, tab, ok, true);
            } else if (ok?.$child) {
              const newTab = this.tabService.createChild(doc, tab);
              // eslint-disable-next-line rxjs/no-nested-subscribe
              this.activeTabService.setActive(newTab);
            } else {
              this.tabService.closeActiveGroup();
              const newTab = this.tabService.create(doc);
              // eslint-disable-next-line rxjs/no-nested-subscribe
              this.activeTabService.setActive(newTab);
            }
          },
        });
      });
  }

  /**
   * Handle form response
   *
   * @param doc
   * @param tab - tab instance
   */
  private applyFormSubmitResponse(doc: IDocument, tab: Tab): void {
    if (doc.ok) {
      this.handleRedirects({
        doc,
        tab,
        defaultAction: () => this.tabService.close(tab, true),
      });
    } else {
      // from backend can come error without form (without metadata) with function `error`, in this case just update the tab
      if (this.hasMetaData(doc)) {
        this.tabService.updateTabDoc(tab, doc);
        this.activeTabService.setActive(tab);
      } else {
        this.tabService
          .updateTabDocFromServer(tab)
          .pipe(
            tap(newDoc => {
              if (!newDoc.error && doc.error) {
                newDoc.error = doc.error;
              }
            }),
            tap(() => this.activeTabService.setActive(tab)),
          )
          .subscribe();
      }
    }
  }

  /**
   * Shows the confirmation window and downloads the files
   * by making a GET request in new tab if confirmed
   *
   * @param confirmOptions - confirm window parameters (header, desc, etc)
   * @param params - parameters to add to new tab
   * @param cgi
   */
  private openConfirmAndDownload(
    confirmOptions: ConfirmOptions,
    params: Params,
    cgi = '',
  ): void {
    this.confirmService.open(confirmOptions).subscribe(result => {
      if (result.resolve) {
        this.window.open(
          `${this.appService.host}${
            cgi ? cgi : this.appService.binary
          }?${convertParamsToStringWithEncode(params)}`,
          '_download',
        );
      }
    });
  }

  /**
   * Submit form with files. Turn on progress bar preloader
   *
   * @param tab - tab instance. To turn on preloader
   * @param params - post params
   * @param preloadedActionOptions - additional options for post
   */
  private submitFormWithFiles(
    tab: Tab,
    params: Params,
    preloadedActionOptions: PreloadedActionOptions,
  ): Observable<IDocument> {
    return this.preloadedActionService
      .uploadAction(params as IHttpParam, preloadedActionOptions)
      .pipe(
        filter(res => {
          if (res.type === HttpEventType.UploadProgress) {
            const uploadProgress = Math.round((res.loaded / res.total) * 100);
            tab.setUploadFileProgressModal(
              uploadProgress,
              this.appService.getDesktopMessage('msg_form_sending'),
            );
            return false;
          } else {
            tab.setUploadFileProgressModal(null);
            return true;
          }
        }),
        map(res => (res as HttpResponse<IDocument>).body),
      );
  }

  /**
   * Submit form by post action. Automatically determine whether params have files and turn on preloader if so
   *
   * @param tab - tab instance. To turn on preloader
   * @param params - post params
   * @param preloadedActionOptions - additional options for post
   */
  private submitForm(
    tab: Tab,
    params: Params,
    preloadedActionOptions: PreloadedActionOptions,
  ): Observable<IDocument> {
    if (hasFile(params)) {
      return this.submitFormWithFiles(
        tab,
        params as IHttpParam,
        preloadedActionOptions,
      );
    } else {
      return this.preloadedActionService.postAction(
        params as IHttpParam,
        preloadedActionOptions,
      );
    }
  }

  /**
   * Info banner "more" link handler
   *
   * @param banner
   */
  infoBannerHandler(banner: IBanner): void {
    switch (banner.$infotype) {
      case 'url':
      case 'help':
        this.openNewWindow(banner.$info);
        break;
      case 'func':
      case 'formfunc': {
        const params: Params = {
          ...convertStringToParamsWithDecode(`func=${banner.$info}`),
        };
        if (banner.$infoelid) {
          params.elid = banner.$infoelid;
        }
        this.handleAction(params, null, true);
        break;
      }
    }
  }

  /**
   * Send request about close banner
   *
   * @param banner
   */
  dismissBanner(banner: IBanner): void {
    const params: Params = { id: banner.$id };
    if (banner.$infoelid) {
      params.elid = banner.$infoelid;
    }
    this.api.dismiss(params).subscribe();
  }

  /**
   * Redirect to url in this window
   *
   * @param url
   */
  redirectToUrl(url: string): void {
    this.window.open(url, '_self');
  }

  /**
   * Open new browser window
   *
   * @param url
   */
  openNewWindow(url: string): void {
    this.window.open(url, '_blank');
  }

  /**
   * Handle action form menu
   *
   * @param params - params
   * @param type - action type
   * @param blank - have to open new tab
   */
  handleAction(params: Params, type: TActionType, blank?: boolean): void {
    switch (type) {
      case 'url':
        this.openNewWindow(params.func);
        break;
      case 'window':
        this.window.open(
          `${this.window.location.origin}/${type}/${params.func}&newwindow=yes`,
        );
        break;
      case 'action':
        this.preloadedActionService
          .getAction({ ...params })
          .subscribe((doc: IDocument) => {
            this.handleRedirects({ doc });
          });
        break;
      default: {
        if (!blank) {
          this.cancelMenuRequest$.next();
        }

        const isTabAlreadyOpened =
          this.activeTabService.activeTab.func === params.func;
        if (isTabAlreadyOpened && !blank) {
          return;
        }

        const alreadyExistedTab = this.tabService.findTab(
          tab => tab.func === params.func,
        );
        if (alreadyExistedTab && !blank) {
          this.activeTabService.setActive(alreadyExistedTab);
          return;
        }

        const pinnedTab = this.tabService.getPinnedTabByFunc(params.func);
        if (pinnedTab) {
          this.activeTabService.setActive(pinnedTab);
          return;
        }

        this.open(params, blank, {
          hideProgressbar: false,
        })
          .pipe(takeUntil(this.cancelMenuRequest$))
          .subscribe();
        break;
      }
    }
  }

  /**
   * Open child tab for current tab w/ params
   *
   * @param params - collection of params
   * @param tab - tab instance
   * @param options - preloaded action options
   */
  openChild(
    params: Params,
    tab: Tab,
    options?: PreloadedActionOptions,
  ): Observable<IDocument> {
    return this.preloadedActionService.getAction(params, options).pipe(
      tap(doc => {
        this.handleRedirects({
          doc: doc,
          tab,
          defaultAction: () => {
            const newTab = this.tabService.createChild(doc, tab);
            this.activeTabService.setActive(newTab);
          },
        });
      }),
    );
  }

  /**
   * Open new tab w/ given params
   *
   * @param params - collection of params
   * @param blank - create new tab and do not close old one
   * @param options - preloaded action options
   */
  open(
    params: Params,
    blank = false,
    options?: PreloadedActionOptions,
  ): Observable<IDocument> {
    return this.preloadedActionService.getAction(params, options).pipe(
      tap(doc => {
        this.handleRedirects({
          doc: doc,
          defaultAction: () => {
            const newTab = !blank
              ? this.tabService.create(doc, this.activeTabService.activeTab)
              : this.tabService.create(doc);
            this.activeTabService.setActive(newTab);
          },
        });
      }),
    );
  }

  /**
   * Handle action form toolbar
   *
   * @param params - toolbutton params
   * @param params.elidList - entity's ids
   * @param params.toolbtn - toolbtn entity
   * @param params.tab - tab instance
   * @param params.buttonElement - button
   * @param params.progressId - action progress id
   * @param params.additionalParams - additional params for toobtn handling
   * @param params.canRefreshTab - is tab can be refreshed
   * @param params.doc - doc instance
   */
  handleToolBtn({
    elidList,
    toolbtn,
    tab,
    buttonElement,
    progressId,
    additionalParams,
    canRefreshTab,
    doc,
  }: IToolbarAction): Observable<IDocument> {
    if (progressId) {
      tab.state.progressIdSubject.next(progressId);
    } else {
      tab.state.progressIdSubject.next(null);
    }

    const empty = of(null);
    if (!toolbtn.$type) {
      return empty;
    }
    const func = toolbtn.$func;
    const cgi = toolbtn.$cgi;
    const plid = tab.plid;
    const elid = elidList.join(', ');
    const confirmMsg: string = this.getConfirmMessage(toolbtn, tab, doc);

    let params: Params = { func };

    if (doc?.tparams.table_params) {
      params.table_params = doc.tparams.table_params.$;
    }

    if (additionalParams?.tconvert) {
      params = {
        ...params,
        tconvert: additionalParams.tconvert,
      };
    }

    if (progressId) {
      params.progressid = progressId;
    }
    if (
      additionalParams?.elnameList?.length &&
      additionalParams.elnameList.filter(Boolean).length === elidList.length
    ) {
      params.elname = additionalParams.elnameList.join(', ');
    }

    let updateTab = false;
    const sameTab = toolbtn.$sametab === 'yes';
    switch (toolbtn.$type) {
      case 'action':
        if (elid) {
          params.elid = elid;
        }
        if (plid) {
          params.plid = plid;
        }
        updateTab = true;
        break;
      case 'back':
        if (!sameTab) {
          // if go back from reports, do not update the parent tab (taken from orion)
          const refreshParentTab =
            this.tabService.isChildTab(tab) && tab.type !== 'report';
          this.tabService.close(tab, refreshParentTab);
          return empty;
        } else {
          let pos = plid.lastIndexOf('/');
          if (pos !== -1) {
            const nextPlid = plid.substring(0, pos);
            pos = nextPlid.lastIndexOf('/');
            // root element, has not plid
            if (pos === -1) {
              params.elid = nextPlid;
            } else {
              params.plid = nextPlid.substring(0, pos);
              params.elid = nextPlid.substr(pos + 1);
            }
          }
        }

        break;
      case 'edit':
        if (elid) {
          params.elid = elid;
        }
        if (plid) {
          params.plid = plid;
        }
        if (elidList.length > 1) {
          params.faction = params.func;
          params.func = 'groupedit';
        }
        break;
      case 'groupform':
      case 'editlist':
        if (elid) {
          params.elid = elid;
        }
        if (plid) {
          params.plid = plid;
        }
        break;
      case 'groupformnosel':
      case 'editnosel':
        if (elid) {
          params.elid = elid;
        }
        if (plid !== undefined) {
          params.plid = plid;
        }
        break;
      case 'group': {
        params.elid = elid;
        params.plid = plid;

        const isAllElementsHaveNames =
          additionalParams?.elnameList?.length === elidList.length;

        const confirmElemList = isAllElementsHaveNames
          ? additionalParams.elnameList
          : elidList;

        const maxElemCount = 10;

        let confirmTextContent = confirmElemList
          .slice(0, maxElemCount)
          .map(elname => {
            const maxElnameLength = 50;
            if (elname.length > maxElnameLength) {
              return `${elname.substring(0, maxElnameLength)}...`;
            }
            return elname;
          })
          .join(', ');

        if (confirmElemList.length > maxElemCount) {
          const messageTotal = DocHelper.getMessage('msg_totalelem', doc);
          confirmTextContent += messageTotal.replace(
            '__s__',
            confirmElemList.length.toString(),
          );
        }

        return zip(
          this.openConfirm(
            {
              header:
                toolbtn.$warning === 'yes'
                  ? this.appService.getDesktopMessage('msg_warning')
                  : '',
              description: `${confirmMsg} ${confirmTextContent}?`,
              ok: this.appService.getDesktopMessage('msg_ok'),
              cancel: this.appService.getDesktopMessage('msg_cancel'),
            },
            params,
            tab,
            canRefreshTab,
          ),
          of(null).pipe(
            tap(() => {
              this.positionConfirm(buttonElement);
            }),
          ),
        ).pipe(map(([confirmResult]) => confirmResult));
      }
      case 'groupdownload': {
        params.elid = elid;
        params.plid = plid;
        params.progressid = createProgressIdForDownloadList();

        const description = `${confirmMsg} ${params.elid}?`;
        this.openConfirmAndDownload(
          {
            header: '',
            description,
            ok: this.appService.getDesktopMessage('msg_ok'),
            cancel: this.appService.getDesktopMessage('msg_cancel'),
          },
          params,
          cgi,
        );
        return empty;
      }
      case 'window':
      case 'windownosel':
      case 'groupwindow': {
        const url = cgi ? doc.$host + cgi : doc.$host + doc.$binary;
        this.openNewWindow(
          `${url}?${convertParamsToStringWithEncode({
            func,
            elid,
            plid,
            newwindow: 'yes',
          })}`,
        );
        return empty;
      }
      case 'list':
      case 'new':
        params.plid = plid;
        delete params.elname;
        break;
      case 'preview':
        console.warn("Type preview isn't implemented yet.");
        return empty;
      case 'refresh':
        if (plid) {
          params.plid = plid;
        }
        updateTab = true;
        break;
      case 'url': {
        const url = cgi ? cgi : func;
        this.openNewWindow(url);
        return empty;
      }
      default:
        console.warn(`Not support type ${toolbtn.$type} of the button`);
        return empty;
    }
    return this.preloadedActionService.getAction({ ...params }).pipe(
      tap((actionResult: IDocument) => {
        this.handleRedirects({
          doc: actionResult,
          tab,
          defaultAction: () => {
            if (actionResult?.ok && !actionResult.ok.$type) {
              if (Object.keys(actionResult.ok).length > 0) {
                return;
              }
              // if an empty object came to OK, then backend performed the action and
              // you do not need to go anywhere and you can update the list
              updateTab = true;
            }

            if (sameTab) {
              this.tabService.updateTabDoc(tab, actionResult);
              this.activeTabService.setActive(tab);
            } else if (updateTab) {
              this.tabService
                .updateTabDocFromServer(tab)
                .pipe(tap(() => this.activeTabService.setActive(tab)))
                .subscribe();
            } else {
              const newTab = this.tabService.createChild(actionResult, tab);
              this.activeTabService.setActive(newTab);
            }
          },
        });
      }),
    );
  }

  /**
   * Prepare form params and submit form
   *
   * @param params - query params
   * @param params.button
   * @param params.form
   * @param params.tab
   * @param params.emitOnError
   * @param params.shouldHandleError
   * @param params.additionalParams
   */
  prepareAndSubmitForm$({
    tab,
    form,
    button,
    emitOnError,
    shouldHandleError,
    additionalParams,
  }: Pick<PreloadedActionOptions, 'emitOnError' | 'shouldHandleError'> & {
    tab: Tab;
    form: IFormModel;
    button?: IFormButtonUi;
    additionalParams?: Params;
  }): Observable<any> {
    const params = prepareModelToSubmit({
      form,
      button,
      func: tab.func,
      elid: tab.elid,
      plid: tab.plid,
      table_params: tab.params.table_params,
      sok: true,
      ...additionalParams,
    });

    const preloadedActionOptions: PreloadedActionOptions = hasFile(params)
      ? {
          action: tab.doc.metadata?.form?.$action,
          shouldHandleError,
        }
      : {
          action: tab.doc.metadata?.form?.$action,
          emitOnError,
          shouldHandleError,
        };

    const progressType = tab.doc.metadata?.form?.$progress;
    if (progressType && button && button.type !== 'back') {
      const progressId = createProgressIdForFormSubmitting(progressType);
      params.progressid = progressId;
      tab.state.progressIdSubject.next(progressId);
    }

    return this.submitForm(tab, params, preloadedActionOptions).pipe(
      tap(response => {
        const isWaitForSomeTask = isWaitProgressTypeById(
          params.progressid as string,
        );

        // if progressType="wait" do nothing, wait will update tab when finish
        if (isWaitForSomeTask) {
          const isBEAcceptSomeTask = response.ok || response.progressok;
          if (isBEAcceptSomeTask) {
            // будь осторожен путник, не каждый поймет этот код и не каждый дойдет до конца
            this.responseCache[tab.paramsHash] = {
              response,
              dataSource: {
                tab,
                params,
                options: preloadedActionOptions,
              },
            };
            return;
          }
        }
        tab.state.progressIdSubject.next(null);

        this.applyFormSubmitResponse(response, tab);
      }),
    );
  }

  /**
   * Submits the form in the new window. Also sets the `sok` parameter with value `ok`
   *
   * @param event - click button event
   * @param tab - tab model
   */
  prepareAndSubmitFakeFormAndOpenNewWindow(
    event: IFormButtonClickEvent,
    tab: Tab,
  ): void {
    submitForm({
      doc: tab.doc,
      form: event.form,
      button: event.button,
      func: tab.func,
      elid: tab.elid,
      plid: tab.plid,
      table_params: tab.params.table_params,
      sok: true,
      openNewWindow: true,
    });

    if (event.button.keepform !== 'blank') {
      this.tabService.close(tab, true);
    }
  }

  // Handle "cached" form response after finish "wait" progress
  handleResponseAfterProgress(tab: Tab): void {
    const cache = this.responseCache[tab.paramsHash];
    if (cache) {
      if (cache.response.progressok?.$ === 'ok') {
        delete cache.dataSource.params.progressid;
        this.submitForm(
          cache.dataSource.tab,
          cache.dataSource.params,
          cache.dataSource.options,
        ).subscribe(response => {
          this.applyFormSubmitResponse(response, tab);
        });
        delete this.responseCache[tab.paramsHash];
      } else {
        this.applyFormSubmitResponse(cache.response, tab);
        tab.state.progressIdSubject.next(null);
      }
      delete this.responseCache[tab.paramsHash];
    }
  }

  /**
   * Handle form response
   *
   * @param d
   * @param tab - tab instance
   */
  handleResponseForm(d: IDocument, tab: Tab): void {
    if (d?.ok) {
      this.handleRedirects({
        doc: d,
        tab,
        defaultAction: () => this.tabService.close(tab, true),
      });
    } else {
      this.tabService.updateTabDoc(tab, d);
      this.activeTabService.setActive(tab);
    }
  }

  /**
   * Process all notification-like banners, i.e. show these notifications and handle their events
   *
   * @param bannerList - a list of INotificationBanner
   */
  handleNotificationBanners(bannerList: INotificationBanner[]): void {
    bannerList.forEach(banner => {
      if (this.bannerMap[banner.$id]) {
        this.notificationService.hideById(this.bannerMap[banner.$id].id);
        delete this.bannerMap[banner.$id];
      }

      this.bannerMap[banner.$id] = {
        id: Date.now(),
      };

      const notification: Notification = {
        id: this.bannerMap[banner.$id].id,
        title: this.appService.getDesktopMessage('msg_error'),
        content: banner.msg?.$ || banner.msg?.[0]?.$,
        type: NotifyBannerTypes.ERROR_FAST,
        duration: Infinity,
        link: '',
        data: banner,
        source: 'banners',
      };

      if (banner.$infotype) {
        notification.link = this.appService.getDesktopMessage('msg_moreinfo');
      }

      switch (banner.$status) {
        case '1':
          notification.type = NotifyBannerTypes.ERROR_FAST;
          notification.title = this.appService.getDesktopMessage('mobile_info');
          break;
        case '2':
          notification.type = NotifyBannerTypes.REST_FAST;
          notification.title = this.appService.getDesktopMessage('mobile_info');
          break;
        default:
          notification.type = NotifyBannerTypes.NORMAL_FAST;
          notification.title = this.appService.getDesktopMessage('msg_finish');
          break;
      }

      this.bannerMap[banner.$id].subscription = this.notificationService
        .showNotification(notification)
        .pipe(
          filter(({ type }) =>
            [
              NotifyBannerEvents.LINK_CLICK,
              NotifyBannerEvents.HIDE,
              NotifyBannerEvents.CLOSE,
            ].includes(type),
          ),
        )
        .subscribe((e: NotificationEvent) => {
          if (e.type === NotifyBannerEvents.CLOSE) {
            this.dismissBanner(e.data);
          } else if (e.type === NotifyBannerEvents.LINK_CLICK) {
            this.infoBannerHandler(e.data);
            this.notificationService.close(e.id);
          }
          if (e.data) {
            delete this.bannerMap[e.data.$id];
          }
        });
    });
  }

  /**
   * Handle nested list link new tab opening. Used in some lists cells.
   *
   * @param options - nested list options
   * @param options.elid - row elid
   * @param options.colValue - cell value
   * @param options.colName - col name
   * @param options.tab - tab instance
   * @param options.nestedList - child list func
   * @param options.blank - should child list tab be opened in new tab
   * @param options.parentFilterModel - parent list filter model. Can be absent in case of no filter applied
   */
  handleNestedListLink(options: {
    elid: string;
    colValue: string;
    colName: string;
    tab: Tab;
    nestedList: string;
    blank: TStringBool;
    parentFilterModel?: IFormModel;
  }): void {
    this.open(
      {
        elid: options.elid,
        plid: options.tab.plid,
        col_value: options.colValue,
        col: options.colName,
        nestedlist: options.nestedList,
        func: 'nestedlist',
        pfunc: options.tab.func,
        parentfilter: options.parentFilterModel
          ? convertParamsToStringWithEncode(options.parentFilterModel)
          : undefined,
      },
      options.blank === 'yes',
    ).subscribe();
  }

  /**
   * Handle edit form link new tab opening. Used in some lists cells.
   *
   * @param options - edit form link options
   * @param options.elid - row elid
   * @param options.tab - tab instance
   * @param options.editform - new tab func
   */
  handleEditFormLink(options: {
    elid: string;
    tab: Tab;
    editform: string;
  }): void {
    this.openChild(
      {
        elid: options.elid,
        func: options.editform,
      },
      options.tab,
    ).subscribe();
  }

  /**
   * Covert value with given converter.
   *
   * @param value - value to convert
   * @param name - name of converter (e.g. 'bytes')
   */
  convertValue(value: string, name: string): Observable<string | undefined> {
    return this.preloadedActionService
      .getAction({
        func: 'convert',
        value,
        name,
      })
      .pipe(map(res => res?.value?.$));
  }
}
