import { Injectable } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  getFirstSubmitButton,
  IFormButtonUi,
  setSubmitButtonDisableState,
} from 'components/form-button';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';

import { DynamicFormService } from '../dynamic-form.service';
import { isFormSubmittable } from '../dynamic-form.utils';

@UntilDestroy()
@Injectable()
export class ButtonsService {
  /** Df service. @TODO i.ablov remove this relation */
  private dynamicformService: DynamicFormService;

  /** Form footer buttons */
  private readonly footerButtonsSubject: BehaviorSubject<IFormButtonUi[]> =
    new BehaviorSubject([]);

  /** All form lists buttons */
  private readonly listsButtonsSubject: BehaviorSubject<
    Map<string, IFormButtonUi[]>
  > = new BehaviorSubject(new Map());

  /** All form buttons */
  private readonly formButtons$ = combineLatest([
    this.footerButtonsSubject,
    this.listsButtonsSubject,
  ]).pipe(
    map(([footerButtons, listsButtons]) => {
      const allListsButtons = Array.from(listsButtons.values()).flat();
      return footerButtons.concat(allListsButtons);
    }),
  );

  readonly getFooterButtons$ = this.footerButtonsSubject.asObservable();

  private disableAllButtons(
    buttons: IFormButtonUi[],
    exceptButtonIds: Symbol[],
  ): void {
    buttons
      .filter(({ id }) => !exceptButtonIds.includes(id))
      .forEach(({ disabledSubject }) => disabledSubject.next(true));
  }

  private restoreButtonsState(buttons: IFormButtonUi[]): void {
    buttons.forEach(({ disabledSubject, originalDisabled }) =>
      disabledSubject.next(originalDisabled),
    );

    this.updateFooterButtonsDisabledState();
  }

  private updateFooterButtonsDisabledState(): void {
    const isSubmittable = isFormSubmittable(this.dynamicformService.configs);
    setSubmitButtonDisableState(
      this.footerButtonsSubject.value,
      !isSubmittable,
    );
  }

  /**
   * Subscribe to form status changes
   */
  private subscribeToStatusChange(): void {
    this.dynamicformService.formGroup.valueChanges
      // @TODO this uses a delay to ensure that formly receives all the changes and returns
      // the correct form submittable value, and properly disables or undisables the form buttons.
      // It is advisable to somehow get away from this and get on the status of form validation
      .pipe(
        startWith(this.dynamicformService.formGroup.valueChanges),
        debounceTime(50),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.updateFooterButtonsDisabledState();
      });
  }

  /**
   * Subscribe to buttons preloader changing
   * UX ask us to disable ALL other buttons, if some button have preloader
   * this is what happend here
   */
  private subscribeToButtonsPreloadingChange(): void {
    this.formButtons$
      .pipe(
        switchMap(allButtons =>
          combineLatest(
            allButtons.map(button =>
              button.preloaderSubject.pipe(
                tap(preloader => {
                  if (preloader) {
                    // if preloader turns on - then disable all other buttons
                    this.disableAllButtons(allButtons, [button.id]);
                  } else {
                    // else restore normal buttons disabled state
                    this.restoreButtonsState(allButtons);
                  }
                }),
              ),
            ),
          ),
        ),
        untilDestroyed(this),
      )
      .subscribe();
  }

  init(dynamicformService: DynamicFormService): void {
    this.dynamicformService = dynamicformService;
    this.subscribeToStatusChange();
    this.subscribeToButtonsPreloadingChange();
  }

  getFirstSubmitButtonFromFooter(): IFormButtonUi {
    return getFirstSubmitButton(this.footerButtonsSubject.value);
  }

  getFooterButtons(): IFormButtonUi[] {
    return this.footerButtonsSubject.value;
  }

  setFooterButtons(buttons: IFormButtonUi[]): void {
    this.footerButtonsSubject.next(buttons);
  }

  setListButtons(name: string, buttons: IFormButtonUi[]): void {
    this.listsButtonsSubject.value.set(name, buttons);
    this.listsButtonsSubject.next(this.listsButtonsSubject.value);
  }

  clear(): void {
    this.footerButtonsSubject.next([]);
    this.listsButtonsSubject.next(new Map());
  }
}
