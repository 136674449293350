import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TabItemService } from 'app/layout/tab-item/tab-item.service';
import { Api5Service } from 'app/services/api5-service/api5.service';
import { Observable, timer } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { Components, DropdownAlign, DropdownDirection } from '@ispui/dropdown';

import { SessionTipService } from './session-tip.service';

import { IDocument } from '../../app/services/api5-service/api.interface';

/**
 * Tooltip with a hint from server
 */
@UntilDestroy()
@Component({
  selector: 'isp-session-tip',
  templateUrl: './session-tip.component.html',
  styleUrls: ['./scss/session-tip.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionTipComponent
  implements OnDestroy, AfterContentInit, OnChanges
{
  private readonly DELAY = 1000;

  @Input() name: string;

  @Input() attachSelector: string;

  @Input() doc: IDocument;

  @Input() direction: DropdownDirection = 'bottom';

  @Input() alignment: DropdownAlign = 'auto';

  @ViewChild('dropdown') dropdown: ElementRef<Components.IspuiDropdown>;

  constructor(
    private readonly api: Api5Service,
    private readonly sessionTipService: SessionTipService,
    @Optional() private readonly tabItemService?: TabItemService,
  ) {}

  get message(): string {
    if (this.doc?.tips?.tip?.$ === this.name) {
      return this.doc?.messages?.msg[`tip_${this.name}`];
    } else {
      return null;
    }
  }

  sendSeenRequest(): void {
    this.api
      .tip(this.name)
      .subscribe(() => (this.sessionTipService.currentTip = null));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.doc) {
      if (this.doc?.tips?.tip?.$ !== this.name) {
        this.dropdown?.nativeElement.closeDropdown();
      }
    }
  }

  ngAfterContentInit(): void {
    let trigger: Observable<any>;
    if (this.tabItemService) {
      trigger = this.tabItemService.isActive$.pipe(
        debounceTime(this.DELAY),
        filter(isActive => isActive),
      );
    } else {
      trigger = timer(this.DELAY);
    }

    trigger.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.message && !this.sessionTipService.currentTip) {
        this.dropdown.nativeElement.showDropdown();
        this.sessionTipService.currentTip = this.name;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.sessionTipService.currentTip === this.name) {
      this.sessionTipService.currentTip = null;
      this.dropdown.nativeElement.showDropdown();
    }
  }
}
