import { IInput, IField } from 'app/services/api5-service/api.interface';

import { ColorPickerTO } from './model/color-picker-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';

/**
 * Get config for color picker field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param _state - dynamic form state
 */
export function getColorPickerConfig(
  control: IInput,
  field: IField,
  _state: ISPFormState,
): ISPFieldConfig<ISPFieldType.ColorPicker> {
  const templateOptions: ColorPickerTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    resetValue: control.$default_value,
    labelOffset: '15px',
  };

  const config: ISPFieldConfig<ISPFieldType.ColorPicker> = {
    key: control.$name,
    type: ISPFieldType.ColorPicker,
    wrappers: [ISPFieldWrapper.FieldBase],
    templateOptions,
    modelOptions: {
      updateOn: 'change',
    },
  };

  return config;
}
