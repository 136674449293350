<div class="phone-group-container">
  <!-- @WARN use formly-field div wrapping, cause formly override provided css classes -->
  <div
    class="select"
    [ngClass]="{
      select_expanded: isDropdownOpened,
      select_focused: isSelectFocused || isDropdownOpened
    }"
  >
    <formly-field
      (internalAction)="onInternalAction($event)"
      (blur)="setSelectFocused(false)"
      (focus)="setSelectFocused(true)"
      [field]="field.fieldGroup[0]"
    ></formly-field>
  </div>
  <div class="delimiter-input-container">
    <div
      class="delimiter"
      [ngClass]="{ delimiter_hidden: isDropdownOpened }"
    ></div>
    <div
      class="isp-phone-group-input"
      [ngClass]="{
        input_collapsed: isDropdownOpened
      }"
    >
      <formly-field [field]="field.fieldGroup[1]"></formly-field>
    </div>
  </div>
</div>
<div class="other-field-subfields">
  <formly-field
    *ngFor="let config of otherConfigs"
    [field]="config"
  ></formly-field>
</div>
