import {
  IDocument,
  IOk,
  OkType,
} from '../app/services/api5-service/api.interface';

/**
 * Handle external (type = `top`/`url`/`blank`) redirects.
 * Returns `true` when tab should be closed.
 *
 * DON'T USE THIS FUNCTION TO HANDLE REDIRECTS!
 * INSTEAD, USE PRIVATE FUNCTION `handleRedirects` IN `ActionService`!
 *
 * @param doc - given to document to handle
 */
export function handleExternalRedirects(doc: IDocument): boolean {
  const ok: IOk = doc?.ok;
  const redirectType: OkType = ok?.$type;

  switch (redirectType) {
    case OkType.Top:
      location.hash = '';
      location.reload();
      return false;
    case OkType.Blank:
      window.open(ok.$, '_blank');
      // for close tab
      return false;
    case OkType.Url:
      location.href = ok.$;
      // for close tab
      return true;
    default:
      return Boolean(redirectType);
  }
}
