import {
  IField,
  IRadioImg,
  ISelectOption,
  SelectType,
} from 'app/services/api5-service/api.interface';

import { FIELD_HIDDEN_CLASS } from 'common/dynamic-form/utils/class-fields';

import { RadioImgOption, RadioImgTO } from './model/radioimg-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';
import {
  autoselectSelectOptions,
  isSelectHidden,
} from '../select/select.utils';

/**
 * Map select options to radio img options
 *
 * @param options
 * @param control - control (subfield) metadata
 */
export function getRadioImgOptions(
  options: ISelectOption[],
  control: IRadioImg,
): RadioImgOption[] {
  return options.map(option => {
    const optionUI = {
      msg: option.$,
      key: option.$key,
      img: option.$img,
    } as RadioImgOption;

    if (control.$type === SelectType.RadioImgRender) {
      // @WARN @HACK hardcoded branding keys of options for displaying svg
      switch (optionUI.key) {
        case 'right':
          optionUI.customSvg = 'right';
          break;
        case 'center':
          optionUI.customSvg = 'center';
          break;
      }
    }

    return optionUI;
  });
}

/**
 * Get config for radio field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getRadioImgConfig(
  control: IRadioImg,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.RadioImg> {
  const options = state.selectService.getOptionsForSelect(control.$name) || [];
  const templateOptions = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    options: options,
    hintPlace: 'label',
    labelOffset: '20px',
    isHidden: isSelectHidden(state, control, field, options),
    depends: control.depends?.reduce((depends, depend) => {
      switch (true) {
        case '$shadow' in depend:
          depends.targetShadow = depend.$field;
          break;
        case '$background_type' in depend:
          depends.targetBackgroundType = depend.$field;
          break;
        case '$background_color' in depend:
          depends.targetBackgroundColor = depend.$field;
          break;
        case '$background_img' in depend:
          depends.targetInitialImage = depend.$field;
          break;
        case '$background_img_file' in depend:
          depends.targetImageFile = depend.$field;
          break;
        case '$background_img_tiling' in depend:
          depends.targetBackgroundTiling = depend.$field;
          break;
        case '$background_img_vertical_stretch' in depend:
          depends.targetBackgroundVerticalStretch = depend.$field;
          break;
        case '$background_img_horizontal_stretch' in depend:
          depends.targetBackgroundHorizontalStretch = depend.$field;
          break;
      }
      return depends;
    }, {} as RadioImgTO['depends']),
  } as RadioImgTO;

  return {
    key: control.$name,
    type: ISPFieldType.RadioImg,
    wrappers: [ISPFieldWrapper.FieldBase],
    templateOptions,
    expressionProperties: {
      className: (_, __, fieldConfig) =>
        fieldConfig.templateOptions.isHidden ? FIELD_HIDDEN_CLASS : '',
      'templateOptions.isHidden': (_, formState, fieldConfig) =>
        isSelectHidden(
          formState,
          fieldConfig.templateOptions.originalControl,
          fieldConfig.templateOptions.originalField,
          fieldConfig.templateOptions.options,
        ),
      'templateOptions.options': (model, formState, fieldConfig) => {
        const selectOptions =
          formState.selectService.getOptionsForSelect(control.$name) || [];

        autoselectSelectOptions(selectOptions, model, fieldConfig);

        return selectOptions;
      },
    },
  };
}
