import { IField, ISelect } from 'app/services/api5-service/api.interface';

import { FIELD_HIDDEN_CLASS } from 'common/dynamic-form/utils/class-fields';

import { SwitcherTO } from './model/switcher-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';
import {
  autoselectSelectOptions,
  isSelectHidden,
} from '../select/select.utils';

/**
 * Get config for radio field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getSwitcherConfig(
  control: ISelect,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.Switcher> {
  const options = state.selectService.getOptionsForSelect(control.$name) || [];
  const templateOptions: SwitcherTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    options: options,
    hintPlace: 'label',
    isHidden: isSelectHidden(state, control, field, options),
  };

  return {
    key: control.$name,
    type: ISPFieldType.Switcher,
    wrappers: [ISPFieldWrapper.FieldBase],
    templateOptions,
    expressionProperties: {
      className: (_, __, fieldConfig) =>
        fieldConfig.templateOptions.isHidden ? FIELD_HIDDEN_CLASS : '',
      'templateOptions.isHidden': (_, formState, fieldConfig) =>
        isSelectHidden(
          formState,
          fieldConfig.templateOptions.originalControl,
          fieldConfig.templateOptions.originalField,
          fieldConfig.templateOptions.options,
        ),
      'templateOptions.options': (model, formState, fieldConfig) => {
        const selectOptions =
          formState.selectService.getOptionsForSelect(control.$name) || [];

        autoselectSelectOptions(selectOptions, model, fieldConfig);

        return selectOptions;
      },
    },
  };
}
