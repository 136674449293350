<!-- @TODO i.ablov scroll-selector doesn't work for ispui-tooltip-wrapper! Fix it! -->
<div
  [attr.data-ispui-tooltip-text]="hint | async"
  [attr.data-ispui-tooltip-disabled]="!(hint | async)"
  [attr.data-ispui-tooltip-attach-selector]="hintAttachSelector"
  [attr.data-ispui-tooltip-scroll-selector]="hintAttachSelector"
  [attr.data-ispui-tooltip-placement-order]="hintPlacementOrderStringified"
>
  <ispui-preloader-round
    [loading]="button.preloaderSubject | async"
    view="overlay"
    [spinnerSize]="25"
  >
    <ispui-link
      *ngIf="button.displayType === 'link'; else simpleButtonBlock"
      [disabled]="button.disabledSubject | async"
      type="default-hover"
      class="link"
      (click)="handleButtonClick()"
    >
      {{ button.label }}
    </ispui-link>

    <ng-template #simpleButtonBlock>
      <ispui-button
        type="button"
        (click)="handleButtonClick()"
        [theme]="button.buttonTheme"
        [disabled]="button.disabledSubject | async"
        [class.button_accent]="button.buttonColor === 'accent'"
        [class.button_icon]="button.icon"
        [class.button_theme-fourth]="button.buttonTheme === 'fourth'"
      >
        <ispui-button-icon *ngIf="button.icon" [right]="0" [top]="4">
          <isp-svg-icon
            [name]="button.icon"
            [customStyle]="svgIconSize"
          ></isp-svg-icon>
        </ispui-button-icon>
        {{ button.label }}
      </ispui-button>
    </ng-template>
  </ispui-preloader-round>
</div>
