import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import * as Sentry from '@sentry/angular';

import { NgISPUINotificationModule } from '@ngispui/notification';
import { WINDOW_PROVIDERS } from '@ngispui/window-service';

import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { AppComponent } from './app.component';
import { ExtformModule } from './extform/extform.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    SvgIconModule,
    ExtformModule,
    NgISPUINotificationModule,
  ],
  providers: [
    WINDOW_PROVIDERS,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // @TODO i.ablov add localization for datepicker!
}
