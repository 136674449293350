import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';

import { ChatService } from './chat.service';

import { IChatBubble } from '../chat-bubble';

/**
 * Chat formly component
 */
@UntilDestroy()
@Component({
  selector: 'isp-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./scss/chat-wrapper.scss'],
  providers: [ChatService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent {
  /** List of messages */
  @Input() messages: IChatBubble[] = [];
}
