import { Injectable } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge, Observable, of, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { IFormButtonClickEvent, IFormModel } from '../dynamic-form.interface';
import { RECAPTCHA_RESOLVE_KEY } from '../types';

@UntilDestroy()
@Injectable()
export class CaptchaService {
  /** Recaptcha resolve event. Contain captcha response */
  private readonly captchaResolve: Subject<string> = new Subject();

  /** Recaptcha reject event. Need to cut off captcha waiting. Can happen on bad network, or any other action */
  private readonly captchaReject: Subject<void> = new Subject();

  private readonly captchaTrigger: Subject<void> = new Subject();

  /** Flag to determine, whether form should to wait until captcha will be passed or not */
  private isNeedToWaitInvisibleCaptcha = false;

  /** Stream to trigger recaptcha in captcha component  */
  readonly captchaTrigger$ = this.captchaTrigger.asObservable();

  /**
   * Check that form model should wait for captcha to resolve
   *
   * @param event - form model params
   */
  isNeedToGetCaptchaForButtonClick(event: IFormButtonClickEvent): boolean {
    // no need to wait captcha if its already setted
    if (event.form[RECAPTCHA_RESOLVE_KEY]) {
      return false;
    }

    // if this is not submit button, then there is no need to wait captcha to resolve
    if (event.button.type !== 'ok') {
      return false;
    }

    return this.isNeedToWaitInvisibleCaptcha;
  }

  /**
   * Wait for captcha resolve event
   */
  getCaptchaResolve$(): Observable<string> {
    // check that form marked as waiting for captcha
    // only captcha field mark form in that way
    if (!this.isNeedToWaitInvisibleCaptcha) {
      return of('');
    }

    // to reset all previous awaitings after button click
    this.captchaReject.next();

    // trigger captcha on captcha field
    this.captchaTrigger.next();

    return merge(
      this.captchaResolve,
      this.captchaReject.pipe(
        tap(() => {
          throw Error('Error of recaptcha check or action was rejected');
        }),
      ),
    ).pipe(take(1), untilDestroyed(this)) as Observable<string>;
  }

  /**
   * Append captcha resolved value to params with right key
   *
   * @param params - some params, that should me marked with recaptcha response token
   * @param resolved - recaptcha response token
   */
  appendCaptchaResolvedValueToParams(
    params: IFormModel,
    resolved: string,
  ): void {
    params[RECAPTCHA_RESOLVE_KEY] = resolved;
  }

  /**
   * Set mark to form, that means, on every button click (or maybe any other action)
   * action should wait until invisible recaptcha to be resolved
   *
   * @param wait - mark or unmark flag
   */
  markFormToWaitCaptcha(wait: boolean): void {
    this.isNeedToWaitInvisibleCaptcha = wait;
  }

  /**
   * Push captcha resolve value
   *
   * @param resolved - captha response token
   */
  setCaptchaResolve(resolved: string): void {
    this.captchaResolve.next(resolved);
  }

  /**
   * Push captcha error
   */
  setCaptchaReject(): void {
    this.captchaReject.next();
  }
}
