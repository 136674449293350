import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
} from '@angular/core';

import { FormlyConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ISPFieldWrapperBase } from 'common/dynamic-form/model/field-wrapper-base.class';
import {
  DYNAMIC_FORM_FOOTER_SELECTOR,
  DYNAMIC_FORM_HEADER_SELECTOR,
  DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR,
} from 'common/dynamic-form/services/layout.service';

import { getErrorMessage$ } from '../validation-error/validation-error.utils';

/**
 * Base input wrapper component for Formly
 */
@Component({
  selector: 'isp-formly-input-base',
  templateUrl: './input-base.component.html',
  styleUrls: ['./scss/input-base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputBaseComponent extends ISPFieldWrapperBase {
  private get haveErrorMessageToDisplay$(): Observable<boolean> {
    return getErrorMessage$(this.field, this.formlyConfig).pipe(
      map(message => Boolean(message)),
    );
  }

  get hintAttachContainer(): string {
    switch (this.to.layoutPlace) {
      case 'header':
        return DYNAMIC_FORM_HEADER_SELECTOR;
      case 'footer':
        return DYNAMIC_FORM_FOOTER_SELECTOR;
      default:
        return DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR;
    }
  }

  get isForceHint(): boolean {
    return this.options.formState.context.forceHint;
  }

  get haveHintMessageToDisplay(): boolean {
    return Boolean(this.to.hint);
  }

  readonly iconSize = {
    width: '15px',
    height: '14px',
  };

  get isErrorDisplayed(): Observable<boolean> {
    return this.haveErrorMessageToDisplay$.pipe(
      map(message => message && this.showError),
    );
  }

  @ViewChild('tooltip') tooltip: ElementRef<HTMLIspuiTooltipElement>;

  constructor(private readonly formlyConfig: FormlyConfig) {
    super();
  }

  @HostListener('focusin', ['$event']) onFocus(): void {
    if (this.isForceHint && this.haveHintMessageToDisplay && !this.showError) {
      this.tooltip?.nativeElement.toggle(true);
    }
  }

  @HostListener('focusout', ['$event']) onBlur(): void {
    this.tooltip?.nativeElement.toggle(false);
  }

  /**
   * Get hint with shadow part
   */
  getHint(): string {
    if (this.to.isShadow) {
      return `${this.to.hint} </br></br> <strong>${this.to.shadowHint}</strong>`;
    }
    return this.to.hint;
  }

  /**
   * Get plainhint message
   */
  getPlainHint(): string {
    if (!this.to.plainhints) return '';

    const fieldValue = this.form.getRawValue()[this.to.controlWithPlainHint];

    return this.to.plainhints[
      `hint_${this.to.controlWithPlainHint}__${fieldValue}`
    ];
  }
}
