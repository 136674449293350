import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FontService } from 'common/dynamic-form/services/font.service';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Component provide textdata and htmldata fields
 */
@Component({
  selector: 'isp-textdada',
  templateUrl: './textdata.component.html',
  styleUrls: ['./scss/textdata.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextDataFieldComponent
  extends ISPFieldTypeBase<ISPFieldType.TextData>
  implements OnInit
{
  size$: Observable<string>;

  fontFamily$: Observable<string>;

  constructor(private readonly fontService: FontService) {
    super();
  }

  ngOnInit(): void {
    this.size$ = this.fontService
      .getFontSizeForControl$(this.to.originalControl.$name)
      .pipe(map(size => (size !== undefined ? `${size}px` : 'inherit')));
    this.fontFamily$ = this.fontService
      .getFontFamilyForControl$(this.to.originalControl.$name)
      .pipe(
        map(fontFamily => (fontFamily !== undefined ? fontFamily : 'inherit')),
      );
  }
}
