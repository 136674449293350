import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { DatePickerComponent } from './date-picker.component';

/**
 * Date picker input component
 */
@NgModule({
  declarations: [DatePickerComponent],
  exports: [DatePickerComponent],
  imports: [CommonModule, FormsModule, NzDatePickerModule, SvgIconModule],
})
export class ISPDatePickerModule {}
