import { IField } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

import { PhoneGroupTO } from './model/phone-group-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';
import { FIELD_HIDDEN_CLASS } from '../../utils/class-fields';
import { isGroupFieldShouldBeHidden } from '../group/group.utils';

interface PhoneGroup {
  input: ISPFieldConfig<ISPFieldType.InputText>;
  select: ISPFieldConfig<ISPFieldType.Select>;
  other: ISPFieldConfig[];
}

/**
 * Clean phone value from formatting symbols. Map '+7 (950) 083-71-98' to '+79500837198'
 *
 * @param phone - phone string
 */
export function purifyPhoneFromMaskValue(phone: string): string {
  return phone.replace(/[ ()\-_]/g, '');
}

/**
 * Check, that phone string belongs to provided code
 * Example:
 * 1. phone '+7950332' belongs to code '+7'
 * 2. phone '+52' belongs to code '+528'
 * 3. phone '+10002223344' do not belongs to code '+900'
 *
 * @param phone - phone string
 * @param code - coutry code
 */
export function isPhoneBelongsToCode(phone: string, code: string): boolean {
  switch (true) {
    case !code:
      return false;
    case code.length > phone.length:
      return code.startsWith(phone);
    case code.length < phone.length:
      return phone.startsWith(code);
    default:
      return phone === code;
  }
}

/**
 * Append country code to phone
 *
 * @param phone - phone
 * @param code - country code
 */
export function appendCodeToPhone(phone: string, code: string) {
  const purifiedValue = purifyPhoneFromMaskValue(phone);
  const valueWithoutCode = purifiedValue.replace('+', '');
  const valueWithOptionCode = `${code}${valueWithoutCode}`;
  return valueWithOptionCode;
}

/**
 * Replace phone code in string
 * Example:
 * 1. phone '+7 950', prev code '+7' next code '+682' will be replaced to '+682 950'
 * 3. phone '+7 950', prev code '+8' next code '+682' will be not changed - '+7 950'
 * 2. phone '+', prev code '+7' next code '+682' will be replaced to '+682'
 *
 * @param phone - phone
 * @param prevCode - prev phone code
 * @param nextCode - next phone code
 */
export function replacePhoneCodeInPhone(
  phone: string,
  prevCode: string,
  nextCode: string,
): string {
  if (phone.startsWith(prevCode)) {
    return phone.replace(prevCode, nextCode);
  } else if (phone.startsWith(nextCode)) {
    return phone;
  } else {
    return appendCodeToPhone(phone, nextCode);
  }
}

/**
 * Get config for phone group field
 *
 * @param fields - group subfields
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getPhoneGroupConfig(
  fields: PhoneGroup,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.PhoneGroup> {
  // @WARN order matters!
  const fieldGroup = [fields.select, fields.input, ...fields.other];
  const templateOptions: PhoneGroupTO = {
    label: field.$noname ? '' : DocHelper.getMessage(field.$name, state.doc),
    hintPlace: 'field',
    required: fields.input.templateOptions.required,
    isHidden:
      state.hiddenService.isHidden(field.$name) ||
      isGroupFieldShouldBeHidden(fieldGroup),
  } as PhoneGroupTO;

  fields.select.templateOptions.isPrefixForInput = true;
  fields.input.templateOptions.invisibleMask = false;

  const config: ISPFieldConfig<ISPFieldType.PhoneGroup> = {
    type: ISPFieldType.PhoneGroup,
    wrappers: [ISPFieldWrapper.FieldBase],
    templateOptions,
    expressionProperties: {
      'templateOptions.isHidden': (_, __, fieldConfig) =>
        state.hiddenService.isHidden(field.$name) ||
        isGroupFieldShouldBeHidden(fieldConfig.fieldGroup),
      className: (_, __, fieldConfig) =>
        fieldConfig.templateOptions.isHidden ? FIELD_HIDDEN_CLASS : '',
    },
    fieldGroup,
  };

  return config;
}

/**
 * Wrapp fields into prefixed group if needed
 *
 * @param fields - field configs
 */
export function getPhoneGroupFields(
  fields: ISPFieldConfig[],
): PhoneGroup | undefined {
  if (fields.length < 2) {
    return;
  }

  const input = fields.find(
    field => field.type === ISPFieldType.InputText,
  ) as ISPFieldConfig<ISPFieldType.InputText>;

  const prefixedSelect = input?.templateOptions.originalControl.$prefixselect;
  if (!prefixedSelect) {
    return;
  }

  const select = fields.find(
    prefixField =>
      prefixField.type === ISPFieldType.Select &&
      prefixField.key === prefixedSelect,
  ) as ISPFieldConfig<ISPFieldType.Select>;
  if (!select) {
    return;
  }

  return {
    input,
    select,
    other: fields.filter(
      field => field.key !== input.key && field.key !== select.key,
    ),
  };
}
