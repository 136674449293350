import { Injectable } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConditionService } from './condition.service';
import { ModeService } from './mode.service';

/**
 * Service that handle field visibility/hidding
 */
@Injectable()
export class HiddenService {
  constructor(
    private readonly conditionService: ConditionService,
    private readonly modeService: ModeService,
  ) {}

  private isHiddenByMeta(names: string[]): boolean {
    return this.conditionService.isHidden(names);
  }

  private isHiddenByMeta$(names: string[]): Observable<boolean> {
    return this.conditionService.isHidden$(names);
  }

  private isHiddenByMode(names: string[]): boolean {
    return this.modeService.isHidden(names);
  }

  private isHiddenByMode$(names: string[]): Observable<boolean> {
    return this.modeService.isHidden$(names);
  }

  /**
   * Check that page/field/control by name should be hidden. By condition, metadata, or by form mode
   *
   * @param names - page/field/control names
   */
  isHidden(...names: string[]): boolean {
    return this.isHiddenByMeta(names) || this.isHiddenByMode(names);
  }

  /**
   * Stream of page/field/control by name hidding state. By condition, metadata, or by form mode
   *
   * @param names - page/field/control names
   */
  isHidden$(...names: string[]): Observable<boolean> {
    return combineLatest([
      this.isHiddenByMeta$(names),
      this.isHiddenByMode$(names),
    ]).pipe(map(([byMeta, byMode]) => byMeta || byMode));
  }

  /**
   * Check that page/field/control by name should be hidden ONLY because of form mode
   *
   * @param names - page/field/control names
   */
  isHiddenOnlyByMode(...names: string[]): boolean {
    return !this.isHiddenByMeta(names) && this.isHiddenByMode(names);
  }

  /**
   * Stream of page/field/control by name hidding state ONLY because of form mode
   *
   * @param names - page/field/control names
   */
  isHiddenOnlyByMode$(...names: string[]): Observable<boolean> {
    return combineLatest([
      this.isHiddenByMeta$(names),
      this.isHiddenByMode$(names),
    ]).pipe(map(([byMeta, byMode]) => !byMeta && byMode));
  }

  /**
   * Check that page/field/control by name should be hidden ONLY because of some condition or metadata
   *
   * @param names - page/field/control names
   */
  isHiddenOnlyByMeta(...names: string[]): boolean {
    return this.isHiddenByMeta(names) && !this.isHiddenByMode(names);
  }

  /**
   * Stream of page/field/control by name hidding state ONLY because of some condition or metadata
   *
   * @param names - page/field/control names
   */
  isHiddenOnlyByMeta$(...names: string[]): Observable<boolean> {
    return combineLatest([
      this.isHiddenByMeta$(names),
      this.isHiddenByMode$(names),
    ]).pipe(map(([byMeta, byMode]) => byMeta && !byMode));
  }
}
