// @TODO i.ablov this enum is veeeeery incomplete, what cause some type troubles... maybe we should replace it by 'string'?
export enum Func {
  Logon = 'logon',
  Branding = 'project.brand.colors',
  Auth = 'auth',
  Desktop = 'desktop',
  Notify = 'notify',
  Collapse = 'collapse',
  Error = 'error',
  DashboardSave = 'dashboard.save',
  Keepalive = 'keepalive',
  Tip = 'tip',
}
