import { Params } from '@angular/router';

import { getObjectSortedString } from 'utils/object.hash';

import { TabParams } from './model';
import { Tab } from './tab.class';

import {
  IDesktopMeta,
  IDocument,
  IMenuNode,
  IMenuSubnode,
} from '../api5-service/api.interface';

/**
 * Get query params object from query params string
 *
 * @param queryParamsString
 */
export function parseStringToQueryParams(queryParamsString: string): Params {
  const params = queryParamsString
    .split('&')
    .reduce<Params>((p, keyValuePair) => {
      const [key, value] = keyValuePair.split('=');
      if (key && value) {
        p[decodeURIComponent(key)] = decodeURIComponent(value);
      }
      return p;
    }, {});
  return params;
}

/**
 * Create tab params from query params. Tab params must have func, that can be created from func, startform or startpage queryParam
 *
 * @param queryParams - url query params
 */
export function queryParamsToTabParams(queryParams: Params): TabParams | null {
  const func =
    queryParams.func || queryParams.startform || queryParams.startpage;
  if (!func) {
    return null;
  }
  const params: TabParams = { func, ...queryParams };
  // remove any other func-like params
  delete params.startform;
  delete params.startpage;

  // remove lang param. This params reqly shouldn't be in tab params, cause it's easyly breaks up all lang logic in interface
  delete params.lang;

  return params;
}

/**
 * Get tab params hash. Hash can be used to identify tab instance and compare it with url
 *
 * @param params - tab params
 */
export function getTabParamsHash(params: TabParams): string {
  const reducedParams = {
    ...params,
  };
  // remove 'out' property from tab params in order to properly compare it with url query params from orion
  delete reducedParams.out;

  return getObjectSortedString(reducedParams);
}

/**
 * Transform doc tparams and pager params from doc to IHttpParam
 *
 * @param doc - doc instance
 */
export function getTabParamsFromDoc(doc: IDocument): TabParams {
  const queryParams: TabParams = {
    func: doc.$func,
  };

  const tparams = doc.tparams;
  if (tparams) {
    Object.keys(tparams).forEach(key => {
      queryParams[key] = tparams[key].$ !== undefined ? tparams[key].$ : '';
    });
  }

  if (doc.p_num?.$) {
    queryParams.p_num = doc.p_num.$;
  }

  // you need to remove p_cnt from tab params, cause in other way pagination will break
  // and you won't be able to change page count throug table settings
  if (queryParams.p_cnt) {
    delete queryParams.p_cnt;
  }

  return queryParams;
}

/**
 * Get all navigation menu nodes
 *
 * @param desktop - desktop metadata
 */
export function getDesktopMenuNodes(desktop: IDesktopMeta): IMenuNode[] {
  const menu = desktop.mainmenu;

  const modernMenuNodes = menu?.modernmenu?.node || [];
  const oldMenuNodes = menu?.node || [];

  return [...modernMenuNodes, ...oldMenuNodes];
}

/**
 * Get all navigation menu subnodes
 *
 * @param desktop
 */
export function getDesktopMenuSubNodes(desktop: IDesktopMeta): IMenuSubnode[] {
  const menuNodes = getDesktopMenuNodes(desktop);

  return menuNodes.map(node => node.node).flat();
}

/**
 * Get menu subnode that related to provided func
 *
 * @param funcName - tab's func name
 * @param desktop
 */
export function getMenuSubNode(
  funcName: string,
  desktop: IDesktopMeta,
): IMenuSubnode | undefined {
  const menuNodes = getDesktopMenuNodes(desktop);

  const node = menuNodes.find(n =>
    n.node.some(subnode => subnode.$name === funcName),
  );

  return node;
}

/**
 * Get group name of item, for set icon of pin tab
 *
 * @param funcName - tab's func name
 * @param desktop
 */
export function getMenuGroupName(
  funcName: string,
  desktop: IDesktopMeta,
): string {
  const node = getMenuSubNode(funcName, desktop);

  if (!node) {
    return '';
  }

  return node.$type === 'noname' ? funcName : node.$name;
}

/**
 * Sort group tab pin tab
 *
 * @param groupList
 */
export function sortGroupListByPin(groupList: Tab[][]): Tab[][] {
  return [...groupList].sort((a, b) => {
    const aPinnedOrDashboard = a[0].isPinned || a[0].type === 'dashboard';
    const bPinnedOrDashboard = b[0].isPinned || b[0].type === 'dashboard';
    if (aPinnedOrDashboard && !bPinnedOrDashboard) {
      return -1;
    }
    if (!aPinnedOrDashboard && bPinnedOrDashboard) {
      return 1;
    }
    if (aPinnedOrDashboard === bPinnedOrDashboard) {
      return 0;
    }
  });
}
