import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Radio field component
 *
 * Use only with Formly
 */
@Component({
  selector: 'isp-formly-radio-field',
  templateUrl: './radio.component.html',
  styleUrls: ['./scss/radio.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioFieldComponent extends ISPFieldTypeBase<ISPFieldType.Radio> {}
