import {
  ISPFieldType,
  ISPFieldTypeWithControl,
  ISPFieldConfig,
} from '../model';

/**
 * Type guard for field, to check that it's have originalControl
 *
 * @param field - field config
 */
export function isFieldWithControl(
  field: ISPFieldConfig<any>,
): field is ISPFieldConfig<ISPFieldTypeWithControl> {
  return ![
    ISPFieldType.Group,
    ISPFieldType.Layout,
    ISPFieldType.PhoneGroup,
    ISPFieldType.ListFilter,
    ISPFieldType.SiteSummary,
    ISPFieldType.Template,
    ISPFieldType.ChatSummary,
    ISPFieldType.FormScroller,
  ].includes(field.type);
}
