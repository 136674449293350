import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  IFormSummaryLabel,
  IFormSummaryPage,
} from 'app/services/api5-service/api.interface';
import { MessageBusService } from 'app/services/messagebus/messagebus.service';
import { Observable } from 'rxjs';
import { first, map, pluck, shareReplay, startWith } from 'rxjs/operators';

import { prefix } from 'common/form-id-prefix/form-id-prefix.utils';

import { SiteSummaryService } from './site-summary.service';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Site summary field component
 */
@UntilDestroy()
@Component({
  selector: 'isp-formly-site-summary',
  templateUrl: './site-summary.component.html',
  styleUrls: ['./scss/site-summary.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SiteSummaryService],
})
export class SiteSummaryFieldComponent
  extends ISPFieldTypeBase<ISPFieldType.SiteSummary>
  implements OnInit
{
  summaryPages$: Observable<IFormSummaryPage[]>;

  readonly pageIconStyles: Partial<CSSStyleDeclaration> = {
    width: '20px',
    height: '20px',
  };

  constructor(
    private readonly formSummaryService: SiteSummaryService,
    private readonly bus: MessageBusService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.formSummaryService.init(this.formState.doc);

    this.summaryPages$ = this.bus.on$('form-collapse-state').pipe(
      pluck('payload'),
      startWith(null),
      map(event =>
        this.formSummaryService.getSummaryPages(this.to.summary, event),
      ),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      }),
    );

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  /**
   * Is page hidden
   *
   * @param page - page
   */
  isPageHidden(page: IFormSummaryPage): boolean {
    const isPageHiddenInService = this.formState?.hiddenService.isHidden(
      page.$name,
    );

    return (
      isPageHiddenInService ||
      page.label?.every(l => this.isLabelHidden(l.$name))
    );
  }

  /**
   * Returns title for page
   *
   * @param pageName - page name
   */
  getPageTitle(pageName: string): string {
    return this.formSummaryService.getPageTitle(pageName);
  }

  /**
   * Returns text for label
   *
   * @param labelName - label name
   * @param isHidden - label hidden flag
   * @param unlimitValue - label unlimit value
   */
  getLabelText(
    labelName: string,
    isHidden = false,
    unlimitValue?: string,
  ): string {
    return this.formSummaryService.getLabelText(
      labelName,
      this.model,
      !isHidden,
      unlimitValue,
    );
  }

  /**
   * Toggle `hidden = 'yes'` label's content
   *
   * @param pageName - page name
   * @param labelName - label name
   */
  toggleLabelContent(pageName: string, labelName: string): void {
    const label = this.to.summary.page
      .find(p => p.$name === pageName)
      .label?.find(l => l.$name === labelName);

    if (!label) return;

    label.isHidden = !label.isHidden;
  }

  /**
   * Returns text for label's button
   *
   * @param isHidden - label hidden flag
   */
  getLabelButtonText(isHidden: boolean): string {
    return this.formSummaryService.getLabelButtonText(isHidden);
  }

  /**
   * Whether label is hidden
   *
   * @param labelName - label name
   */
  isLabelHidden(labelName: string): boolean {
    return Boolean(this.formState?.conditionService.isHidden(labelName));
  }

  /**
   * Toggle page collapse state and scroll on open
   *
   * @param pageName - page name
   * @param pageIndex - page index
   * @param isOpened - is page opened
   */
  handlePageClick(
    pageName: string,
    pageIndex: number,
    isOpened: boolean,
  ): void {
    this.summaryPages$
      .pipe(
        first(),
        map(async pages => {
          await this.bus.emit('form-collapse-toggle', {
            openState: !isOpened,
            name: prefix(this.formState.layoutService, pageName),
          });

          this.bus.emit('form-collapse-scroll-to', {
            name: prefix(this.formState.layoutService, pageName),
            scrollPosition:
              pageIndex === 0
                ? 'top'
                : pageIndex === pages.length - 1
                ? 'bottom'
                : undefined,
          });
        }),
      )
      .subscribe();
  }

  /**
   * TrackBy function for summary's page
   *
   * @param index - page index
   * @param page - page instance
   */
  trackByPage(index: number, page: IFormSummaryPage): string {
    return `${index}${page.$name}`;
  }

  /**
   * TrackBy function for page's label
   *
   * @param index - label index
   * @param label - label
   */
  trackByLabel(index: number, label: IFormSummaryLabel): string {
    return `${index}${label.$name}`;
  }
}
